import { gql } from "@apollo/client";

export const FLOWS = gql`
  query Flows(
    $where: FlowWhereInput
    $take: Int
    $skip: Int
    $like: FlowWhereInput
  ) {
    flows(where: $where, take: $take, skip: $skip, like: $like) {
      data {
        id
        name
        tracers {
          id
          name
        }
        process {
          id
          name
          processStatuses {
            id
            name
            template
          }
        }
      }
      count
    }
  }
`;

export const FLOW = gql`
  query Flow($where: FlowWhereInput) {
    flow(where: $where) {
      id
      name
      tracers {
        id
        name
      }
      process {
        id
        processStatuses {
          id
          name
          template
        }
      }
    }
  }
`;

const flows = { FLOWS, FLOW };

export default flows;
