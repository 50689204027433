const Guarantee = ({ name, value, onClick, active }) => {
  return (
    <div className={`card__guarantee ${active ? 'active' : ''} `} onClick={onClick}>
      <span>{name}</span>
      <span>{value}</span>
    </div>
  );
};

export default Guarantee;
