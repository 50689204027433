import { gql } from "@apollo/client";

const GENERAL_COST_BUILT = gql`
  subscription generalCostBuilt($where: GeneralCostWhereInput) {
    generalCostBuilt(where: $where) {
      id
      cost
      currency
      commission {
        id
      }
      insurancePlan {
        id
        icon
        name
        description
        params {
          deltaStart
          deltaStartRIA
        }
        commissions {
          id
          value
          type
          withholding
          broker {
            id
          }
        }
        coverages {
          id
          type
          identifier
          coverageBenefits {
            id
            type
            requirement
            category
            limits {
              id
              code
              value
            }
            supportingInformation {
              id
              category
              value
            }
          }
        }
      }
      project {
        id
      }
    }
  }
`;

const GENERAL_COST_ADDED = gql`
  subscription generalCostAdded($where: GeneralCostWhereInput) {
    generalCostAdded(where: $where) {
      id
      cost
      currency
      commission {
        id
      }
      insurancePlan {
        id
        icon
        name
        description
        params {
          deltaStart
          deltaStartRIA
        }
        commissions {
          id
          value
          type
          withholding
          broker {
            id
          }
        }
        coverages {
          id
          type
          identifier
          coverageBenefits {
            id
            type
            requirement
            category
            limits {
              id
              code
              value
            }
            supportingInformation {
              id
              category
              value
            }
          }
        }
      }
      project {
        id
      }
    }
  }
`;

const GENERAL_COST_UPDATED = gql`
  subscription generalCostUpdated($where: GeneralCostWhereInput) {
    generalCostUpdated(where: $where) {
      id
      cost
      currency
      commission {
        id
      }
      insurancePlan {
        id
        icon
        name
        description
        params {
          deltaStart
          deltaStartRIA
        }
        commissions {
          id
          value
          type
          withholding
          broker {
            id
          }
        }
        coverages {
          id
          type
          identifier
          coverageBenefits {
            id
            type
            requirement
            category
            limits {
              id
              code
              value
            }
            supportingInformation {
              id
              category
              value
            }
          }
        }
      }
      project {
        id
      }
    }
  }
`;

const costs = { GENERAL_COST_BUILT, GENERAL_COST_ADDED, GENERAL_COST_UPDATED };

export default costs;
