import { gql } from "@apollo/client";

export const NOTES = gql`
  query Notes(
    $where: NoteWhereInput
    $take: Int
    $skip: Int
    $gt: NoteWhereInput
    $lt: NoteWhereInput
  ) {
    notes(where: $where, take: $take, skip: $skip, gt: $gt, lt: $lt) {
      data {
        id
        content
        createdDate
        author {
          id
          firstname
          lastname
        }
        qualification {
          id
          content
          qualifier {
            id
            name
            fields
          }
        }
      }
      count
    }
  }
`;

export const NOTE = gql`
  query Note($where: NoteWhereInput) {
    note(where: $where) {
      id
      content
      createdDate
      author {
        id
        firstname
        lastname
      }
      qualification {
        id
        qualifier {
          id
          name
          fields
        }
      }
    }
  }
`;

const notes = { NOTES, NOTE };

export default notes;
