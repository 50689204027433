import { Upload } from "antd";

const Component = ({
  children,
  handleFileUpload,
  onFileRemove,
  onUpload,
  listType,
  ...rest
}) => {
  return (
    <div className="file-dragger--wrapper">
      <Upload
        onChange={async (info) => {
          const fileList = await handleFileUpload(info);
          onUpload(fileList);
        }}
        listType={listType}
        onRemove={onFileRemove}
        {...rest}
      >
        {children}
      </Upload>
    </div>
  );
};

export default Component;
