import Qualifiers from "entities/Qualifiers";
import { useState } from "react";
import View from "./View";

const List = () => {
  const [pagination, setPagination] = useState({});
  const filter = { where: {} };

  return (
    <Qualifiers
      View={View}
      filter={{ ...filter, ...pagination }}
      setValues={setPagination}
      skip={pagination.skip || 0}
      NoData={View}
    />
  );
};

export default List;
