import { SCREENS } from "utils/constants";
import Detail from "./Detail";
const routes = [
  {
    path: SCREENS.PRIVATE.ADMIN.CATEGORIES_WEIGHT.LIST.path,
    exact: true,
    element: <Detail />,
  },
];

export default routes;
