import { get } from "lodash";
import { useNavigate } from "react-router-dom";
import Select from "shared/components/Select";
import graphql from "utils/api/graphql";
import { SCREENS } from "utils/constants";

const Qualify = ({ qualification }) => {
  const navigate = useNavigate();
  return (
    <Select
      type="async"
      value={get(qualification, "qualifier.name")}
      node={{
        query: graphql.queries.QUALIFIERS,
        variables: { where: {} },
        accessors: { root: "qualifiers.data", label: "name", value: "id" },
      }}
      onChange={(_, qualifier) => {
        return navigate(SCREENS.PRIVATE.ADMIN.QUALIFICATIONS.CREATE.path, {
          state: {
            qualification,
            qualifier,
          },
        });
      }}
    />
  );
};

export default Qualify;
