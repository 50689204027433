import { gql } from "@apollo/client";

export const COVERAGE_BENEFITS = gql`
  query CoverageBenefits($where: CoverageBenefitWhereInput, $orderBy: [JSONObject]) {
    coverageBenefits(where: $where, orderBy: $orderBy) {
      data {
        id
        order
        type
        limits {
          id
          value
        }
        supportingInformation {
          id
          value
          category
        }
      }
      count
    }
  }
`;

export const COVERAGE_BENEFIT = gql`
  query CoverageBenefit($where: CoverageBenefitWhereInput) {
    coverageBenefit(where: $where) {
      id
      supportingInformation {
        id
        value
        category
      }
    }
  }
`;

const coverageBenefits = { COVERAGE_BENEFITS, COVERAGE_BENEFIT };

export default coverageBenefits;
