import { gql } from "@apollo/client";

const QUALIFICATION_ADDED = gql`
  subscription qualificationAdded($where: QualificationWhereInput) {
    qualificationAdded(where: $where) {
      id
      last
      hasNote
      createdDate
      content
      contact {
        id
        name
        createdDate
        notes {
          id
          content
          createdDate
        }
        footprint {
          id
          source {
            id
            name
          }
        }
        raw
        fields
        user {
          id
          firstname
          lastname
          gender
          phone
          birthDate
        }
        relatedPersons {
          id
          relationship
          identity {
            id
            socialRegime
            socioProfessionalCategory
            user {
              id
              firstname
              lastname
              birthDate
            }
          }
        }
        telecoms {
          id
          system
          value
        }
      }
      qualifier {
        id
        name
        fields
        isDefault
      }
      broker {
        id
        firstname
        lastname
        username
      }
      notes {
        id
        content
        createdDate
        author {
          id
          firstname
          lastname
        }
      }
    }
  }
`;

const QUALIFICATION_UPDATED = gql`
  subscription qualificationUpdated($where: QualificationWhereInput) {
    qualificationUpdated(where: $where) {
      id
      last
      hasNote
      createdDate
      content
      contact {
        id
        name
        createdDate
        notes {
          id
          content
          createdDate
        }
        footprint {
          id
          source {
            id
            name
          }
        }
        raw
        fields
        user {
          id
          firstname
          lastname
          gender
          phone
          birthDate
        }
        relatedPersons {
          id
          relationship
          identity {
            id
            socialRegime
            socioProfessionalCategory
            user {
              id
              firstname
              lastname
              birthDate
            }
          }
        }
        telecoms {
          id
          system
          value
        }
      }
      qualifier {
        id
        name
        fields
        isDefault
      }
      broker {
        id
        firstname
        lastname
        username
      }
      notes {
        id
        content
        createdDate
        author {
          id
          firstname
          lastname
        }
      }
    }
  }
`;

const QUALIFICATION_DELETED = gql`
  subscription qualificationDeleted($where: QualificationWhereInput) {
    qualificationDeleted(where: $where) {
      id
      last
      hasNote
      createdDate
      content
      contact {
        id
        name
        createdDate
        notes {
          id
          content
          createdDate
        }
        footprint {
          id
          source {
            id
            name
          }
        }
        raw
        fields
        user {
          id
          firstname
          lastname
          gender
          phone
          birthDate
        }
        relatedPersons {
          id
          relationship
          identity {
            id
            socialRegime
            socioProfessionalCategory
            user {
              id
              firstname
              lastname
              birthDate
            }
          }
        }
        telecoms {
          id
          system
          value
        }
      }
      qualifier {
        id
        name
        fields
        isDefault
      }
      broker {
        id
        firstname
        lastname
        username
      }
      notes {
        id
        content
        createdDate
        author {
          id
          firstname
          lastname
        }
      }
    }
  }
`;

const qualifications = {
  QUALIFICATION_ADDED,
  QUALIFICATION_UPDATED,
  QUALIFICATION_DELETED,
};

export default qualifications;
