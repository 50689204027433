import { gql } from "@apollo/client";

export const REMINDERS = gql`
  query Reminders(
    $where: ReminderWhereInput
    $take: Int
    $skip: Int
    $gt: ReminderWhereInput
    $lt: ReminderWhereInput
  ) {
    reminders(where: $where, take: $take, skip: $skip, gt: $gt, lt: $lt) {
      data {
        id
        heading
        content
        contact {
          user {
            firstname
            lastname
          }
          telecoms {
            value
            system
          }
        }
        qualifier {
          id
          name
        }
        scheduledDate
      }
      count
    }
  }
`;

export const REMINDER = gql`
  query Reminder($where: ReminderWhereInput) {
    reminder(where: $where) {
      id
      heading
      content
      contact {
        user {
          firstname
          lastname
        }
        telecoms {
          value
          system
        }
      }
      qualifier {
        id
        name
      }
      scheduledDate
    }
  }
`;

const reminders = { REMINDERS, REMINDER };

export default reminders;
