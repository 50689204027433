import { Popover } from "antd";
import { MdInfoOutline } from "react-icons/md";
import { COLORS } from "shared/style/colors";

const Label = ({ label, subLabel, info, expanded, onToggle, link }) => (
  <div className="coverage-label">
    <div className="coverage-label__flex">
      {label}
      {info && (
        <div>
          <Popover content={info} placement="right">
            <MdInfoOutline size={24} color={COLORS.C_PRIMARY} />
          </Popover>
        </div>
      )}
    </div>
    {subLabel && (
      <div onClick={onToggle}>
        <span className="coverage-label__more">{link}</span>
      </div>
    )}
    {expanded && <span className="coverage-label__sub">{subLabel}</span>}
  </div>
);

export default Label;
