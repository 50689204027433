import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { SCREENS } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Detail = ({ onSubmit, data, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.USER);
  const navigate = useNavigate();
  const DETAIL = t("DETAIL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const { firstname, lastname, phone, email } = data;
  return (
    <Formik
      initialValues={{
        firstname,
        lastname,
        phone,
        email,
      }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, ...formProps }) => [
        <Header
          key={"header"}
          title={DETAIL.HEADER.TITLE}
          subtitle={DETAIL.HEADER.ACTION}
          actions={[
            {
              text: DETAIL.HEADER.DISCARD,
              onClick: cancel,
              type: "primary--outlined",
              icon: <BiChevronLeft size={20} />,
            },
            {
              text: DETAIL.HEADER.DELETE,
              onClick: () =>
                navigate(
                  `${SCREENS.PRIVATE.ADMIN.USERS.DELETE.path}/${data.id}`
                ),
              type: "danger--outlined",
              icon: <BiTrash size={20} />,
            },
            {
              text: DETAIL.HEADER.SAVE,
              onClick: handleSubmit,
              type: "primary",
              icon: <BiCheck size={20} />,
            },
          ]}
        />,
        <Card key="card" type={"simple"}>
          <div className="grid--2">
            <Input
              placeholder={FORM.FIRST_NAME}
              label={FORM.FIRST_NAME}
              {...bindInputProps({ name: "firstname", ...formProps })}
            />
            <Input
              placeholder={FORM.LAST_NAME}
              label={FORM.LAST_NAME}
              {...bindInputProps({ name: "lastname", ...formProps })}
            />
            <Input
              placeholder={FORM.PHONE}
              label={FORM.PHONE}
              {...bindInputProps({ name: "phone", ...formProps })}
            />
            <Input
              placeholder={FORM.EMAIL}
              label={FORM.EMAIL}
              {...bindInputProps({ name: "email", ...formProps })}
            />
          </div>
        </Card>,
      ]}
    </Formik>
  );
};

export default Detail;
