import { gql } from "@apollo/client";

const BROKER_ADDED = gql`
  subscription brokerAdded($where: BrokerWhereInput) {
    brokerAdded(where: $where) {
      id
      orias
      organization {
        id
        name
        type
        form
        users {
          id
          gender
          firstname
          lastname
          phone
          meta
        }
      }
    }
  }
`;

const BROKER_UPDATED = gql`
  subscription brokerUpdated($where: BrokerWhereInput) {
    brokerUpdated(where: $where) {
      id
      orias
      organization {
        id
        name
        type
        form
        users {
          id
          gender
          firstname
          lastname
          phone
          meta
        }
      }
    }
  }
`;

const BROKER_DELETED = gql`
  subscription brokerDeleted($where: BrokerWhereInput) {
    brokerDeleted(where: $where) {
      id
      orias
      organization {
        id
        name
        type
        form
        users {
          id
          gender
          firstname
          lastname
          phone
          meta
        }
      }
    }
  }
`;

const brokers = { BROKER_ADDED, BROKER_UPDATED, BROKER_DELETED };

export default brokers;
