import gql from "graphql-tag";

export const CREATE_WORKFLOW_WEBHOOK = gql`
  mutation addWorkflowWebhook($data: WebhookDataInput) {
    addWorkflowWebhook(data: $data) {
      id
    }
  }
`;

const workflows = { CREATE_WORKFLOW_WEBHOOK };

export default workflows;
