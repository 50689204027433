import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { SCREENS } from "utils/constants";

const Qualify = ({ qualification, filter }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.QUALIFICATION);
  const QUALIFIERS = t("QUALIFIERS", { returnObjects: true });

  return (
    <Select
      type="async"
      defaultValue={get(qualification, "qualifier.id")}
      node={{
        query: graphql.queries.QUALIFIERS,
        variables: { where: {} },
        accessors: { root: "qualifiers.data", label: "name", value: "id" },
      }}
      onChange={(_, qualifier) => {
        return navigate(SCREENS.PRIVATE.BROKER.QUALIFICATIONS.CREATE.path, {
          state: {
            qualification,
            qualifier,
            filter,
          },
        });
      }}
      labels={QUALIFIERS}
    />
  );
};

export default Qualify;
