import Card from "antd/es/card/Card";
import InsurancePlans from "entities/InsurancePlans";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Header from "shared/components/Header";
import Search from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { SCREENS } from "utils/constants";
import View from "./View";

const List = () => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.INSURANCE_PLAN);
  const LIST = t("LIST", { returnObjects: true });
  const [pagination, setPagination] = useState({});
  const [search, setSearch] = useState("");
  const filter = {
    like: isEmpty(search)
      ? undefined
      : {
          OR: [
            { name: `%${search}%` },
            { description: `%${search}%` },
            { alias: `%${search}%` },
          ],
        },
  };
  const navigate = useNavigate();

  const buttons = [
    {
      icon: <MdAdd size={24} />,
      text: LIST.HEADER.SAVE,
      onClick: () =>
        navigate(SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.CREATE.path),
    },
  ];

  return (
    <>
      <Header title={LIST.HEADER.TITLE} actions={buttons} />
      <Card type="simple">
        <div className="m-bottom-20 grid--4">
          <Search
            placeholder={LIST.FILTERS.SEARCH}
            type="search"
            onChange={({ target: { value } }) => setSearch(value)}
          />
        </div>
        <InsurancePlans
          View={View}
          filter={{ ...filter, ...pagination }}
          setValues={setPagination}
          skip={pagination.skip || 0}
          NoData={View}
        />
      </Card>
    </>
  );
};

export default List;
