import { gql } from "@apollo/client";

export const GENERAL_COSTS = gql`
  query generalCosts(
    $where: GeneralCostWhereInput
    $gt: GeneralCostWhereInput
    $lt: GeneralCostWhereInput
    $take: Int
    $skip: Int
    $isIn: JSONObject
  ) {
    generalCosts(
      where: $where
      take: $take
      skip: $skip
      isIn: $isIn
      gt: $gt
      lt: $lt
    ) {
      data {
        id
        cost
        currency
        commission {
          id
        }
        insurancePlan {
          id
          icon
          name
          description
          distributions {
            id
            broker {
              id
            }
            commissions {
              id
              value
              type
              withholding
            }
          }
          params {
            deltaStart
            deltaStartRIA
          }
          coverages {
            id
            type
            identifier
            coverageBenefits {
              id
              type
              subtype
              additionalInfo
              requirement
              order
              limits {
                id
                code
                value
              }
              supportingInformation {
                id
                category
                value
              }
            }
          }
        }
        project {
          id
        }
      }
      count
    }
  }
`;

export const GENERAL_COST = gql`
  query generalCost($where: GeneralCostWhereInput) {
    generalCost(where: $where) {
      cost
      commission {
        id
      }
      insurancePlan {
        id
      }
      project {
        id
      }
    }
  }
`;

const generalCosts = { GENERAL_COSTS, GENERAL_COST };

export default generalCosts;
