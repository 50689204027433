import { useMemo } from "react";
import PropTypes from "prop-types";
import Range from "./Range";

const MODES = {
  range: Range,
};

const DatePicker = ({ type, ...props }) => {
  const View = useMemo(() => MODES[type], [type]);
  return <View {...props} />;
};

DatePicker.propTypes = {
  type: PropTypes.oneOf(Object.keys(MODES)),
};

DatePicker.defaultProps = {
  type: "range",
};

export default DatePicker;
