import { useMemo } from "react";
import PropTypes from "prop-types";
import Table from "./Table";
import Normal from "./Normal";

const MODES = {
  table: Table,
  normal: Normal,
};

const Empty = ({ type, ...props }) => {
  const View = useMemo(() => MODES[type], [type]);
  return <View {...props} />;
};

Empty.propTypes = {
  type: PropTypes.oneOf(Object.keys(MODES)),
};

Empty.defaultProps = {
  type: 'table',
};

export default Empty;
