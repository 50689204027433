import cuid from "cuid";
import lodash, { find, get, isEmpty, isNil } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useLazyQuery, useMutation, useQuery } from "shared/hooks/useApi";
import format from "string-template";
import graphql from "utils/api/graphql";
import { CREATE_WORKFLOW_WEBHOOK } from "utils/api/graphql/mutations/webhook";
import { ACTION_TYPES, SUBSCRIPTION_STATUSES } from "utils/constants";
import Templates from "./templates";

const LOCKED_STYLE = {
  [true]: "locked",
  [false]: "",
};

const filterByConditions = (array, entity) =>
  array.filter((element = {}) => {
    return element.conditions
      .map(
        (condition) =>
          lodash[condition.operation](
            get(entity, condition.leftOperand.accessor)
          ) === condition.rightOperand.value
      )
      .every((x) => x === true);
  });

const Detail = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [addWorkflowWebhook] = useMutation(CREATE_WORKFLOW_WEBHOOK);

  const ACTIONS = {
    [ACTION_TYPES.NAVIGATE]: ({ args = {}, actionsTemplate = {} }) => {
      if (isEmpty(actionsTemplate)) return;
      navigate(format(args.url, actionsTemplate));
    },
    [ACTION_TYPES.WEBHOOK]: ({ args = {} }) => {
      if (args.workflow)
        addWorkflowWebhook({
          variables: {
            data: {
              id: cuid(),
              meta: {
                workflow: args.workflow,
                project: id,
              },
            },
          },
        });
    },
  };

  const { id } = useParams();
  const [getProcess] = useLazyQuery(graphql.queries.PROCESS);

  const { data, error } = useQuery(graphql.queries.PROJECT, {
    variables: { where: { id } },
    onCompleted: async ({ project }) => {
      const actionsToExecute = filterByConditions(
        get(project, "status.actions", []),
        project
      );
      actionsToExecute.forEach(({ args, type }) => {
        const subscriptionId = find(project.subscriptions, (subscription) =>
          [
            SUBSCRIPTION_STATUSES.PENDING,
            SUBSCRIPTION_STATUSES.SIGNED,
          ].includes(subscription.status)
        )?.id;
        subscriptionId &&
          ACTIONS[type]({
            args,
            actionsTemplate: {
              id: subscriptionId,
            },
          });
      });
      if (
        project.locked ||
        !parseInt(get(project, "status.order")) ||
        !project.expired
      )
        return setLoading(false);

      const {
        data: { process },
      } = await getProcess({
        variables: {
          where: { id: get(project, "status.process.id") },
        },
      });
      const status = omitDeep(process.processStatuses, "__typename").reduce(
        (previous, current) =>
          Number(previous?.order) < Number(current.order) ? previous : current
      );
      updateProject({
        variables: { where: { id }, data: { status } },
        onCompleted: () => setLoading(false),
      });
    },
  });
  const [updateProject] = useMutation(graphql.mutations.UPDATE_PROJECT, {
    refetchQueries: [
      {
        query: graphql.queries.PROJECT,
        awaitRefetchQueries: true,
        variables: { where: { id } },
      },
    ],
  });
  const onNext = ({ payload = {}, actionsTemplate = {} }) => {
    const {
      status: { possibleStatuses, actions },
    } = data.project;
    const project = { ...data.project, ...payload };
    const [possibleStatus] = filterByConditions(possibleStatuses, project);
    const actionsToExecute = filterByConditions(actions, project);
    actionsToExecute.forEach(({ args, type }) =>
      ACTIONS[type]({ args, actionsTemplate })
    );
    updateProject({
      variables: {
        where: {
          id,
        },
        data: {
          ...(!isNil(possibleStatus?.nextStatus) && {
            status: {
              id: possibleStatus.nextStatus,
            },
          }),
          ...payload,
        },
      },
    });
  };
  const onBack = async () => {
    const currentOrder = get(data, "project.status.order");
    const {
      data: { process },
    } = await getProcess({
      variables: {
        where: { id: get(data, "project.status.process.id") },
      },
    });
    const status = omitDeep(process.processStatuses, "__typename").reduce(
      (previous, current) =>
        Number(current?.order) >= Number(previous?.order) &&
        Number(current?.order) < currentOrder
          ? current
          : previous,
      { order: 0 }
    );
    updateProject({
      variables: {
        where: {
          id,
        },
        data: {
          status,
        },
      },
    });
  };

  if (loading || error) return <Loading />;

  const Template =
    data.project.status.name === "Quote Sent"
      ? Templates.FAST
      : get(Templates, data.project.status.template, Templates.default);

  return (
    <div className={LOCKED_STYLE[get(data, "project.locked")]}>
      <Template
        project={omitDeep(data.project, "__typename")}
        onNext={onNext}
        onBack={onBack}
      />
    </div>
  );
};

export default Detail;
