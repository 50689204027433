import { notification } from "antd";
import cuid from "cuid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { CREATE_PRICER } from "utils/api/graphql/mutations/pricers";
import { PRICERS } from "utils/api/graphql/queries/pricers";
import { INITIAL_VALUES, PRICER_TYPES, SCREENS } from "utils/constants";
import View from "./View";

const Create = () => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.PRICER);
  const ERRORS = t("ERRORS", { returnObjects: true });
  const navigate = useNavigate();
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.PRICERS.LIST.path);
  const [createPricer] = useMutation(CREATE_PRICER, {
    refetchQueries: [
      {
        query: PRICERS,
        awaitRefetchQueries: true,
        variables: INITIAL_VALUES,
      },
    ],
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.ADMIN.PRICERS.LIST.path);
    },
    onError: () => notification.error({ message: ERRORS.DATE_CONFLICT }),
  });

  const onSubmit = ({ prices, dates, ...values }) => {
    const [startDate, endDate] = dates;
    return createPricer({
      variables: {
        data: {
          id: cuid(),
          ...values,
          type: PRICER_TYPES.DB,
          startDate: startDate.startOf("day"),
          endDate: endDate.endOf("day"),
          prices: prices.map(
            ({
              costs,
              departments,
              socialRegime,
              commission,
              beneficiaryType,
            }) => ({
              costs: JSON.parse(costs),
              socialRegime,
              departments,
              beneficiaryType,
              commission,
            })
          ),
        },
      },
    });
  };

  return <View onSubmit={onSubmit} cancel={cancel} />;
};

export default Create;
