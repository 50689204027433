import { notification } from "antd";
import cuid from "cuid";
import dayjs from "dayjs";
import {
  defaultTo,
  defaults,
  defaultsDeep,
  filter,
  get,
  isEmpty,
  isNil,
  isNumber,
  isObject,
  keyBy,
  map,
  mapValues,
  set,
} from "lodash";
import omitDeep from "omit-deep-lodash";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useLazyQuery, useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { SIGN_DOCUMENT } from "utils/api/graphql/mutations/esign";

import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import { USER_AUTH } from "utils/api/graphql/queries/users";
import {
  BANK_ACCOUNT_OWNER_TYPE,
  CASE_TYPES,
  CONTACT_POINT_SYSTEM,
  INITIAL_VALUES,
  PAYMENT_RECEIVER,
  PROJECT_TYPES,
  RELATIONSHIP,
  SALE_MODES,
  SUBSCRIPTION_FORM_KEYS,
  SUBSCRIPTION_STATUSES,
} from "utils/constants";
import View from "./View";
import { showExerciseFrame } from "./Widgets/AdditionalInformation/Forms/Activity";
import { DOCUMENTS_TYPE } from "./Widgets/Proof";

export const STEPPER_KEYS = {
  ADDITIONAL_INFORMATION: "ADDITIONAL_INFORMATION",
  PROOF: "PROOF",
  DOCUMENT: "DOCUMENT",
};

const Detail = () => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });

  const { id } = useParams();
  const [signDocuments, { loading: signLoading }] = useMutation(SIGN_DOCUMENT, {
    refetchQueries: [
      {
        query: graphql.queries.SUBSCRIPTION,
        awaitRefetchQueries: true,
        variables: { where: { id } },
      },
      {
        query: graphql.queries.PROJECTS,
        awaitRefetchQueries: true,
        variables: INITIAL_VALUES,
      },
    ],
  });
  const [initialValues, setInitialValues] = useState({});
  const [checkUser] = useLazyQuery(USER_AUTH);

  const initSubscription = useCallback(({ subscription: data }) => {
    const subscription = omitDeep(data, "__typename");
    const shouldBeAdherent =
      get(subscription, "payment.payer.owner.isAdherent", true) ||
      get(subscription, "insurancePlan.fields.payment.shouldBeAdherent", false);
    if (isEmpty(subscription)) return;
    const contactTelecoms = get(subscription, "project.contact.telecoms", []);
    const payerTelecoms = get(subscription, "payment.payer.owner.telecoms", []);
    const initialDisabled = {
      project: {
        ria: true,
        contract: {
          caseType: get(subscription, "project.ria", false),
          saleMode: get(subscription, "project.fields.twoTimesSale", false),
        },
        contact: {
          exerciseFrame: !showExerciseFrame(
            get(subscription, "project.contact.socioProfessionalCategory")
          ),
          siretNumber: true,
          optMadelin: !get(subscription, "insurancePlan.fields.madelin"),
          fields: {
            affiliateOrganizationCode: true,
          },
        },
        projectType:
          get(subscription, "project.fields.twoTimesSale") ||
          [SALE_MODES.FACE_TO_FACE].includes(
            get(subscription, "project.contract.saleMode")
          ),
      },
      createdDate: true,
      proof: {
        payerCin: get(subscription, "payment.payer.owner.isAdherent", false),
        refundAccount:
          get(subscription, "payment.payer.id", "") ===
          get(subscription, "payment.receiver.id"),
      },
      telecoms: {
        payer: {
          firstName: shouldBeAdherent,
          lastName: shouldBeAdherent,
          [CONTACT_POINT_SYSTEM.ADDRESS_NUMBER]: {
            value:
              shouldBeAdherent &&
              !get(subscription, "insurancePlan.fields.tns"),
          },
          [CONTACT_POINT_SYSTEM.REPETITION_INDEX]: {
            value: true,
          },
          [CONTACT_POINT_SYSTEM.STREET_TYPE]: {
            value: true,
          },
          [CONTACT_POINT_SYSTEM.STREET]: {
            value:
              shouldBeAdherent &&
              !get(subscription, "insurancePlan.fields.tns"),
          },
          [CONTACT_POINT_SYSTEM.ZIP_CODE]: {
            value:
              shouldBeAdherent &&
              !get(subscription, "insurancePlan.fields.tns"),
          },
          [CONTACT_POINT_SYSTEM.CITY]: {
            value:
              shouldBeAdherent &&
              !get(subscription, "insurancePlan.fields.tns"),
          },
        },
        contact: {
          [CONTACT_POINT_SYSTEM.REPETITION_INDEX]: {
            value: true,
          },
          [CONTACT_POINT_SYSTEM.STREET_TYPE]: {
            value: true,
          },
        },
      },
      [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.TERMINATION_REQUEST]: !get(
        subscription,
        "project.ria",
        false
      ),
      [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.BROKERAGE_MANDATE]: !get(
        subscription,
        "project.ria",
        false
      ),
      [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.PARTNER]: !get(
        subscription,
        "project.contact.relatedPersons",
        []
      ).find((person) => person.relationship === RELATIONSHIP.SPOUSE),
      [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.CHILDREN]: !get(
        subscription,
        "project.contact.relatedPersons",
        []
      ).find((person) => person.relationship === RELATIONSHIP.CHILD),
      [SUBSCRIPTION_FORM_KEYS.PROOF.KBIS]: !get(
        subscription,
        "insurancePlan.fields.tns"
      ),
      [SUBSCRIPTION_FORM_KEYS.PROOF.SOCIAL_SECURITY_CERTIFICATE]: !get(
        subscription,
        "project.contact.fields.teletransmission"
      ),
    };
    const telecoms = defaultsDeep(
      {
        contact: keyBy(contactTelecoms, "system"),
        payer: keyBy(
          shouldBeAdherent ? contactTelecoms : payerTelecoms,
          "system"
        ),
      },
      {
        contact: {
          [CONTACT_POINT_SYSTEM.ADDRESS_NUMBER]: { value: null },
          [CONTACT_POINT_SYSTEM.REPETITION_INDEX]: { value: null },
          [CONTACT_POINT_SYSTEM.STREET]: { value: null },
          [CONTACT_POINT_SYSTEM.ZIP_CODE]: { value: null },
          [CONTACT_POINT_SYSTEM.CITY]: { value: null },
          [CONTACT_POINT_SYSTEM.PHONE]: { value: null },
          [CONTACT_POINT_SYSTEM.LANDLINE]: { value: null },
          [CONTACT_POINT_SYSTEM.EMAIL]: { value: null },
        },
        payer: {
          [CONTACT_POINT_SYSTEM.ADDRESS_NUMBER]: { value: null },
          [CONTACT_POINT_SYSTEM.REPETITION_INDEX]: { value: null },
          [CONTACT_POINT_SYSTEM.STREET]: { value: null },
          [CONTACT_POINT_SYSTEM.ZIP_CODE]: { value: null },
          [CONTACT_POINT_SYSTEM.CITY]: { value: null },
        },
      }
    );
    const children = filter(
      get(subscription, "project.contact.relatedPersons", []),
      {
        relationship: RELATIONSHIP.CHILD,
      }
    );

    const fields = {
      [STEPPER_KEYS.ADDITIONAL_INFORMATION]: {
        [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.CONTRACT]: [
          "project.contract.issuanceDate",
          "project.contract.caseType",
          "project.ria",
          "project.contract.saleMode",
          "project.projectType",
          "createdDate",
        ],
        [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.TERMINATION_REQUEST]: [
          "project.terminationRequest.contractNumber",
          "project.terminationRequest.recipient",
          "project.terminationRequest.dueDate",
          "project.terminationRequest.madeIn",
          "project.terminationRequest.address",
        ],
        [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.BROKERAGE_MANDATE]: [
          "project.terminationRequest.brokerageMandate.lastName",
          "project.terminationRequest.brokerageMandate.firstName",
          "project.terminationRequest.brokerageMandate.currentCompany",
        ],
        [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.ADHERENT]: [
          "project.contact.user.gender",
          "project.contact.user.lastname",
          "project.contact.user.firstname",
          "project.contact.user.birthDate",
          "project.contact.socialRegime",
          "project.contact.socialSecurityNumber",
          "project.contact.fields.affiliateOrganizationCode",
          "project.contact.fields.familySituation",
        ],
        [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.ACTIVITY]: [
          "project.contact.socioProfessionalCategory",
          "project.contact.politicallyExposedPerson",
          "project.contact.exerciseFrame",
          "project.contact.siretNumber",
          "project.contact.profession",
          "project.contact.optMadelin",
        ],
        [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.CONTACT]: [
          `telecoms.contact.${CONTACT_POINT_SYSTEM.ADDRESS_NUMBER}.value`,
          `telecoms.contact.${CONTACT_POINT_SYSTEM.REPETITION_INDEX}.value`,
          `telecoms.contact.${CONTACT_POINT_SYSTEM.STREET}.value`,
          `telecoms.contact.${CONTACT_POINT_SYSTEM.STREET_TYPE}.value`,
          `telecoms.contact.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`,
          `telecoms.contact.${CONTACT_POINT_SYSTEM.CITY}.value`,
          `telecoms.contact.${CONTACT_POINT_SYSTEM.PHONE}.value`,
          `telecoms.contact.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
          "project.contact.acceptContact",
          "project.contact.acceptDigital",
        ],
        [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.PARTNER]: [
          "spouse.identity.fields.teletransmission",
          "spouse.identity.user.firstname",
          "spouse.identity.user.lastname",
          "spouse.identity.user.birthDate",
          "spouse.identity.socioProfessionalCategory",
          "spouse.identity.socialRegime",
          "spouse.identity.socialSecurityNumber",
        ],
        [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.CHILDREN]:
          children.flatMap((_, i) => [
            `children.${i}.identity.user.firstname`,
            `children.${i}.identity.user.lastname`,
            `children.${i}.identity.user.birthDate`,
            `children.${i}.identity.socialRegime`,
            `children.${i}.identity.socialSecurityNumber`,
          ]),
        [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.PAYMENT]: [
          "payment.type",
          "payment.frequency",
          "payment.debitDay",
          "payment.payer.IBAN",
          "payment.payer.BIC",
          ...(get(subscription, "insurancePlan.fields.tns")
            ? ["payment.payer.owner.socialReason"]
            : [
                "payment.payer.owner.firstName",
                "payment.payer.owner.lastName",
              ]),
          `telecoms.payer.${CONTACT_POINT_SYSTEM.ADDRESS_NUMBER}.value`,
          `telecoms.payer.${CONTACT_POINT_SYSTEM.STREET_TYPE}.value`,
          `telecoms.payer.${CONTACT_POINT_SYSTEM.STREET}.value`,
          `telecoms.payer.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`,
          `telecoms.payer.${CONTACT_POINT_SYSTEM.CITY}.value`,
        ],
        [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.REFUND]: [
          "isPayerReceiver",
          "payment.receiver.owner.firstName",
          "payment.receiver.owner.lastName",
          "payment.receiver.IBAN",
          "payment.receiver.BIC",
        ],
        [SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.INFORMATION]: [],
      },
      [STEPPER_KEYS.PROOF]: {
        [SUBSCRIPTION_FORM_KEYS.PROOF.SOCIAL_SECURITY_CERTIFICATE]: [
          "proof.socialSecurityCertificate",
        ],
        [SUBSCRIPTION_FORM_KEYS.PROOF.ID]: ["proof.cin", "proof.payerCin"],
        [SUBSCRIPTION_FORM_KEYS.PROOF.BANK_ACCOUNT]: [
          "proof.paymentAccount",
          "proof.refundAccount",
        ],
        [SUBSCRIPTION_FORM_KEYS.PROOF.KBIS]: ["proof.kbis"],
      },
    };

    const spouse = get(subscription, "project.contact.relatedPersons", []).find(
      ({ relationship }) => relationship === RELATIONSHIP.SPOUSE
    );

    const additionalInfoInitialValues = {
      ...subscription,
      telecoms,
      project: {
        ...subscription.project,
        ...(subscription.project.fields.twoTimesSale && {
          projectType: PROJECT_TYPES.COLD_LEAD,
        }),
        contact: defaultsDeep(subscription.project.contact, {
          fields: { teletransmission: true, familySituation: null },
        }),
        contract: {
          ...subscription.project.contract,
          ...(subscription.project.ria && {
            caseType: CASE_TYPES.RETURN_TO_COMPETITION,
          }),
          ...(subscription.project.fields.twoTimesSale && {
            saleMode: SALE_MODES.DISTANT,
          }),
        },
        ...(subscription.project.ria
          ? {
              terminationRequest: {
                id: cuid(),
                ...subscription.project.terminationRequest,
                dueDate: dayjs(
                  get(subscription, "project.terminationRequest.dueDate")
                ),
                brokerageMandate: {
                  id: cuid(),
                  firstName: get(
                    subscription,
                    "project.contact.user.firstname"
                  ),
                  lastName: get(subscription, "project.contact.user.lastname"),
                  ...subscription.project.terminationRequest?.brokerageMandate,
                },
              },
            }
          : {}),
      },
      isPayerReceiver:
        get(subscription, "payment.payer.id", "") ===
        get(subscription, "payment.receiver.id")
          ? PAYMENT_RECEIVER.SAME_ACCOUNT
          : PAYMENT_RECEIVER.ANOTHER_ACCOUNT,
      payment: {
        id: cuid(),
        type: null,
        frequency: null,
        debitDay: null,
        ...subscription.payment,
        payer: {
          id: cuid(),
          IBAN: null,
          BIC: null,
          ...subscription.payment?.payer,
          owner: {
            id: cuid(),
            ...subscription.payment?.payer?.owner,
            firstName: defaultTo(
              subscription.payment?.payer?.owner?.firstName,
              shouldBeAdherent
                ? get(subscription, "project.contact.user.firstname")
                : null
            ),
            lastName: defaultTo(
              subscription.payment?.payer?.owner?.lastName,
              shouldBeAdherent
                ? get(subscription, "project.contact.user.lastname")
                : null
            ),
            isAdherent: defaultTo(
              subscription.payment?.payer?.owner?.isAdherent,
              shouldBeAdherent
            ),
            type: get(subscription, "insurancePlan.fields.tns")
              ? BANK_ACCOUNT_OWNER_TYPE.COMPANY
              : BANK_ACCOUNT_OWNER_TYPE.PARTICULAR,
          },
        },
        receiver: defaultsDeep(subscription.payment?.receiver, {
          id: cuid(),
          IBAN: null,
          BIC: null,
          owner: { id: cuid() },
        }),
      },
      children,
      spouse: isEmpty(spouse)
        ? undefined
        : defaultsDeep(spouse, {
            identity: { fields: { teletransmission: true } },
          }),
    };

    const proofInitialValues = {
      proof: mapValues(DOCUMENTS_TYPE, (type) =>
        map(
          filter(get(subscription, "attachments", []), { type }),
          ({ id, name, fileUrl }) => ({
            uid: id,
            name,
            status: "done",
            url: fileUrl,
          })
        )
      ),
    };
    setDisabled(initialDisabled);
    setInitialValues({
      [STEPPER_KEYS.ADDITIONAL_INFORMATION]: additionalInfoInitialValues,
      [STEPPER_KEYS.PROOF]: proofInitialValues,
      fields,
    });

    if (subscription.status === SUBSCRIPTION_STATUSES.SIGNED)
      setActive(STEPPER_KEYS.PROOF);
    if (subscription.status === SUBSCRIPTION_STATUSES.PENDING)
      setActive(STEPPER_KEYS.DOCUMENT);
  }, []);

  const { data, loading, error } = useQuery(graphql.queries.SUBSCRIPTION, {
    variables: { where: { id } },
    onCompleted: initSubscription,
  });

  const [updateSubscription] = useMutation(
    graphql.mutations.UPDATE_SUBSCRIPTION,
    {
      refetchQueries: [
        {
          query: graphql.queries.SUBSCRIPTION,
          awaitRefetchQueries: true,
          variables: { where: { id } },
        },
      ],
    }
  );

  const [active, setActive] = useState(STEPPER_KEYS.ADDITIONAL_INFORMATION);
  const [disabled, setDisabled] = useState({});
  const [realProgress, setRealProgress] = useState({
    [STEPPER_KEYS.ADDITIONAL_INFORMATION]: 0,
    [STEPPER_KEYS.PROOF]: 0,
  });
  const [progress, setProgress] = useState({
    [STEPPER_KEYS.ADDITIONAL_INFORMATION]: {},
    [STEPPER_KEYS.PROOF]: {},
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [active]);

  const calculateBlockProgress = (parent, block, values, disabled) => {
    if (disabled[block]) return;
    const maxLength = Object.values(
      get(initialValues, `fields.${parent}.${block}`, [])
    ).filter((g) => !get(disabled, g)).length;
    const filedLength = Object.values(
      get(initialValues, `fields.${parent}.${block}`, [])
    ).filter(
      (g) =>
        !isNil(get(values, g)) &&
        get(values, g) !== "" &&
        (!isObject(get(values, g)) || !isEmpty(get(values, g))) &&
        !get(disabled, g)
    ).length;
    return maxLength ? Math.ceil((filedLength / maxLength) * 100) : 100;
  };

  const calculateRealTimeProgress = (
    parent,
    block,
    values,
    initialDisabled = disabled
  ) => {
    setProgress((prev) => ({
      ...prev,
      [parent]: {
        ...prev[parent],
        [block]: calculateBlockProgress(parent, block, values, initialDisabled),
      },
    }));
  };

  const calculateProgressMean = (
    parent,
    values,
    initialDisabled = disabled
  ) => {
    const { count, sum } = Object.keys(
      get(initialValues, `fields.${parent}`, [])
    ).reduce(
      (acc, block) => {
        const progress = calculateBlockProgress(
          parent,
          block,
          values,
          initialDisabled
        );
        return isNumber(progress)
          ? { sum: (acc.sum += progress), count: (acc.count += 1) }
          : acc;
      },
      { count: 0, sum: 0 }
    );
    setRealProgress((prev) => ({
      ...prev,
      [parent]: +(sum / count).toFixed(2),
    }));
  };

  const onFormSubmit = (values = {}) => {
    const {
      isPayerReceiver,
      telecoms: { contact, payer },
      spouse,
      children,
      attachments,
      locked,
      status,
      envelopeId,
      ...data
    } = values;

    if (locked) return;

    const contactTelecoms = map(contact, (item, key) =>
      defaults(item, { id: cuid(), system: key })
    );
    const payerTelecoms = map(payer, (item, key) =>
      defaults(item, { id: cuid(), system: key })
    );
    const relatedPersons = [
      ...(get(spouse, "identity.user.birthDate")
        ? [
            defaultsDeep(spouse, {
              id: cuid(),
              relationship: RELATIONSHIP.SPOUSE,
              identity: { id: cuid(), user: { id: cuid() } },
            }),
          ]
        : []),
      ...children,
    ];
    if (isPayerReceiver === PAYMENT_RECEIVER.SAME_ACCOUNT)
      set(data, "payment.receiver", get(values, "payment.payer"));
    set(data, "project.contact.telecoms", contactTelecoms);
    set(data, "project.contact.relatedPersons", relatedPersons);
    set(
      data,
      "payment.payer.owner.telecoms",
      get(data, "payment.payer.owner.isAdherent")
        ? contactTelecoms
        : payerTelecoms
    );

    updateSubscription({
      variables: {
        where: {
          id,
        },
        data,
      },
      onCompleted: () => {
        calculateProgressMean(
          STEPPER_KEYS.ADDITIONAL_INFORMATION,
          values,
          disabled
        );
      },
    });
  };

  const signDocument = async () => {
    const { subscription } = omitDeep(data, "__typename");
    if (subscription.status !== SUBSCRIPTION_STATUSES.DRAFT) return;
    await signDocuments({
      variables: {
        data: {
          id,
        },
      },
      onCompleted: () => {
        notification.open({
          message: SUBSCRIPTION.E_SIGNATURE_SENT,
          duration: 5,
          type: "success",
        });
      },
    });
  };

  if (loading || error || isEmpty(initialValues) || isEmpty(disabled))
    return <Loading />;

  return (
    <View
      onFormSubmit={onFormSubmit}
      active={active}
      setActive={setActive}
      data={omitDeep(data, "__typename")}
      progress={progress}
      setProgress={setProgress}
      disabled={disabled}
      setDisabled={setDisabled}
      signDocument={signDocument}
      signLoading={signLoading}
      setPercent={calculateRealTimeProgress}
      realProgress={realProgress}
      calculateProgressMean={calculateProgressMean}
      updateSubscription={updateSubscription}
      initialValues={initialValues}
      checkUser={checkUser}
    />
  );
};

export default Detail;
