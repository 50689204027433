import { Col, Row } from "antd";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import Icon from "react-material-symbols/rounded";
import Button from "shared/components/Button";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";

const Id = ({ values, onFileRemove, setFieldValue, setPercent, disabled }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });

  return (
    <div className="subscription-form--wrapper">
      <Form
        type="vertical"
        title={SUBSCRIPTION.FORM.PROOF.ID.TITLE}
        subtitle={SUBSCRIPTION.FORM.PROOF.ID.SUBTITLE}
      >
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <div className="subscription-form--group-label">
              <Icon icon="card_membership" size={16} key="card_membership" />
              <span className="group-label--text">
                {SUBSCRIPTION.FORM.PROOF.ADHERENT}
              </span>
            </div>
          </Col>
          <Col lg={24} xs={24}>
            <div className="subscription-form--custom-upload">
              <Input
                type="file"
                variant="uploader"
                maxCount={1}
                onUpload={(fileList) => {
                  setFieldValue("proof.cin", fileList);
                  setPercent({
                    ...values,
                    proof: { ...values.proof, cin: fileList },
                  });
                }}
                onFileRemove={onFileRemove}
                fileList={get(values, "proof.cin", [])}
              >
                <Row align="center">
                  <Button type="primary--link">
                    {SUBSCRIPTION.FORM.PROOF.ACTION.ADD}
                  </Button>
                </Row>
              </Input>
            </div>
          </Col>
        </Row>
        {!get(disabled, "proof.payerCin") && (
          <Row>
            <Col lg={24} xs={24}>
              <div className="subscription-form--group-label">
                <Icon icon="card_membership" size={16} key="card_membership" />
                <span className="group-label--text">
                  {SUBSCRIPTION.FORM.PROOF.PAYER}
                </span>
              </div>
            </Col>
            <Col lg={24} xs={24}>
              <div className="subscription-form--custom-upload">
                <Input
                  type="file"
                  variant="uploader"
                  maxCount={1}
                  onUpload={(fileList) => {
                    setFieldValue("proof.payerCin", fileList);
                    setPercent({
                      ...values,
                      proof: { ...values.proof, payerCin: fileList },
                    });
                  }}
                  onFileRemove={onFileRemove}
                  fileList={get(values, "proof.payerCin", [])}
                >
                  <Row align="center">
                    <Button type="primary--link">
                      {SUBSCRIPTION.FORM.PROOF.ACTION.ADD}
                    </Button>
                  </Row>
                </Input>
              </div>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
};

export default Id;
