import React, { useState } from "react";
import classnames from "classnames";
import { BsPlus } from "react-icons/bs";

const ToggleButton = ({ name }) => {
  const [check, setCheck] = useState(false)
  const onClick = () => setCheck(!check)
  return (
    <div className={classnames("composant__area", check ? 'active' : '')} onClick={onClick}>
      <BsPlus size={24} /> <span>{name}</span>
    </div>
  );
};

ToggleButton.propTypes = {
};

ToggleButton.defaultProps = {};

export default ToggleButton;
