import WithRoutePermission from "shared/components/HOC/WithRoutePermission";
import { MODULES, SCREENS } from "utils/constants";
import Brokers from "./Brokers";
import CategoriesWeight from "./CategoriesWeight";
import CommissionStatements from "./CommissionStatements";
import Commissions from "./Commissions";
import CoverageBenefits from "./CoverageBenefits";
import Coverages from "./Coverages";
import Dashboard from "./Dashboard";
import Flows from "./Flows";
import InsurancePlans from "./InsurancePlans";
import Pricers from "./Pricers";
import Processes from "./Processes";
import Qualifications from "./Qualifications";
import Qualifiers from "./Qualifiers";
import Sources from "./Sources";
import Tracers from "./Tracers";
import Ui from "./Ui";
import Users from "./Users";
import Webhooks from "./Webhooks";
import Workflows from "./Workflows";

const routes = {
  [MODULES.SOURCE]: Sources,
  [MODULES.TRACER]: Tracers,
  [MODULES.FLOW]: Flows,
  [MODULES.PROCESS]: Processes,
  [MODULES.USER]: Users,
  [MODULES.INSURANCE_PLAN]: InsurancePlans,
  [MODULES.COVERAGE]: Coverages,
  [MODULES.COVERAGE_BENEFIT]: CoverageBenefits,
  [MODULES.QUALIFIER]: Qualifiers,
  [MODULES.QUALIFICATION]: Qualifications,
  [MODULES.WEBHOOK]: Webhooks,
  [MODULES.ORGANIZATION]: Brokers,
  [MODULES.WORKFLOW]: Workflows,
  [MODULES.PRICER]: Pricers,
  [MODULES.COMMISSION_STATEMENT]: CommissionStatements,
  [MODULES.COMMISSION]: Commissions,
};
const screens = (permissions) => [
  {
    exact: false,
    path: SCREENS.PRIVATE.ADMIN.INDEX.path,
    element: (
      <WithRoutePermission submodules={{}} WrappedComponent={Dashboard} />
    ),
  },
  ...Ui,
  ...CategoriesWeight,
  ...Object.entries(routes)
    .filter(([module]) =>
      permissions?.find(
        ({ module: permissionModule, granted }) =>
          granted && module === permissionModule
      )
    )
    .reduce((acc, [_, item]) => [...acc, ...item], []),
];

export default screens;
