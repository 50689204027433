import { gql } from "@apollo/client";

export const PRICERS = gql`
  query Pricers(
    $where: PricerWhereInput
    $like: PricerWhereInput
    $take: Int
    $skip: Int
  ) {
    pricers(where: $where, take: $take, skip: $skip, like: $like) {
      data {
        id
        name
        startDate
        endDate
        insurancePlan {
          id
          name
        }
        prices
        discount
      }
      count
    }
  }
`;

export const PRICER = gql`
  query Pricer($where: PricerWhereInput) {
    pricer(where: $where) {
      id
      name
      startDate
      endDate
      insurancePlan {
        id
        name
      }
      prices
      discount
    }
  }
`;

const pricers = { PRICER, PRICERS };

export default pricers;
