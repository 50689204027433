import { get } from "lodash";
import { useParams } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import Comparison from "./Comparison";

const Detail = () => {
  const { id } = useParams();
  const { data, loading, error } = useQuery(graphql.queries.QUOTE, {
    variables: { where: { id } },
  });

  if (loading || error) return <Loading />;

  return <Comparison project={get(data, "quote.project", {})} />;
};

export default Detail;
