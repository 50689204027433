import { REGEX_EXPS } from "utils/constants";
import * as yup from "yup";

const validation = (errors) => {
  return yup.object().shape({
    passcode: yup.string().typeError(errors.REQUIRED).required(errors.REQUIRED),
    password: yup
      .string()
      .typeError(errors.REQUIRED)
      .matches(REGEX_EXPS.password, errors.PASSWORD.FORMAT)
      .required(errors.REQUIRED),
    confirmPassword: yup
      .string()
      .typeError(errors.REQUIRED)
      .oneOf([yup.ref("password"), null], errors.PASSWORD.MATCH)
      .required(errors.REQUIRED),
  });
};

export default validation;
