import { Col, Row } from "antd";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import Icon from "react-material-symbols/rounded";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { SUBSCRIPTION_FORM_KEYS } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";

const BrokerageMandate = ({
  formProps,
  values,
  setPercent,
  isFieldRequired,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });

  return (
    get(values, "project.ria", false) && (
      <div
        className="subscription-form--wrapper"
        id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.BROKERAGE_MANDATE}-section`}
      >
        <Form
          type="vertical"
          title={
            SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.BROKERAGE_MANDATE.TITLE
          }
          subtitle={
            SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.BROKERAGE_MANDATE.SUBTITLE
          }
        >
          <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
              <Input
                placeholder={SUBSCRIPTION.FORM.LAST_NAME.PLACEHOLDER}
                label={SUBSCRIPTION.FORM.LAST_NAME.LABEL}
                icon={<Icon icon="free_cancellation" size={16} />}
                {...bindInputProps({
                  name: "project.terminationRequest.brokerageMandate.lastName",
                  values,
                  ...formProps,
                  onBlur: () => setPercent(values),
                })}
                disabled
                required={isFieldRequired(
                  "project.terminationRequest.brokerageMandate.lastName"
                )}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Input
                placeholder={SUBSCRIPTION.FORM.FIRST_NAME.PLACEHOLDER}
                label={SUBSCRIPTION.FORM.FIRST_NAME.LABEL}
                icon={<Icon icon="free_cancellation" size={16} />}
                {...bindInputProps({
                  name: "project.terminationRequest.brokerageMandate.firstName",
                  values,
                  ...formProps,
                  onBlur: () => setPercent(values),
                })}
                disabled
                required={isFieldRequired(
                  "project.terminationRequest.brokerageMandate.firstName"
                )}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Input
                placeholder={SUBSCRIPTION.FORM.CURRENT_COMPANY.PLACEHOLDER}
                label={SUBSCRIPTION.FORM.CURRENT_COMPANY.LABEL}
                icon={<Icon icon="share_location" size={16} />}
                {...bindInputProps({
                  name: "project.terminationRequest.brokerageMandate.currentCompany",
                  values,
                  ...formProps,
                  onBlur: () => setPercent(values),
                })}
                required={isFieldRequired(
                  "project.terminationRequest.brokerageMandate.currentCompany"
                )}
              />
            </Col>
          </Row>
        </Form>
      </div>
    )
  );
};

export default BrokerageMandate;
