import { NAME_SPACES } from "shared/locales/constants";
import client from "./client";
import collaborator from "./collaborator";
import contract from "./contract";
import note from "./note";
import organization from "./organization";
import project from "./project";
import qualification from "./qualification";
import reminder from "./reminder";
import subscription from "./subscription";
import user from "./user";

const translation = {
  [NAME_SPACES.PRIVATE.BROKER.CLIENT]: client,
  [NAME_SPACES.PRIVATE.BROKER.CONTRACT]: contract,
  [NAME_SPACES.PRIVATE.BROKER.ORGANIZATION]: organization,
  [NAME_SPACES.PRIVATE.BROKER.PROJECT]: project,
  [NAME_SPACES.PRIVATE.BROKER.USER]: user,
  [NAME_SPACES.PRIVATE.BROKER.QUALIFICATION]: qualification,
  [NAME_SPACES.PRIVATE.BROKER.NOTE]: note,
  [NAME_SPACES.PRIVATE.BROKER.REMINDER]: reminder,
  [NAME_SPACES.PRIVATE.BROKER.COLLABORATOR]: collaborator,
  [NAME_SPACES.PRIVATE.BROKER.SUBSCRIPTION]: subscription,
};

export default translation;
