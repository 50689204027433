import { Switch } from "antd";

const Component = ({ checkedChildren, unCheckedChildren, ...rest }) => {
  return (
    <div className="switch__wrapper">
      <Switch
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
        defaultChecked
        {...rest}
      />
    </div>
  );
};

export default Component;
