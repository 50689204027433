import { useTranslation } from "react-i18next";
import Header from "shared/components/Header";
import { NAME_SPACES } from "shared/locales/constants";

const Dashboard = () => {
  const { t } = useTranslation(NAME_SPACES.HOME);
  return <Header title={t("DASHBOARD")} />;
};

export default Dashboard;
