import { isEmpty } from "lodash";
import moment from "moment";
import {
  REGEX_EXPS,
  SOCIAL_REGIMES,
  SOCIO_PROFESSIONAL_CATEGORIES,
} from "utils/constants";
import { isMajor } from "utils/helpers/date";
import * as yup from "yup";

const validator = (messages, validateZipCode, setCity) => {
  const requiredString = yup
    .string()
    .typeError(messages.REQUIRED)
    .required(messages.REQUIRED);
  const childValidationSchema = yup.object().shape({
    identity: yup.object().shape({
      user: yup.object().shape({
        birthDate: yup
          .date()
          .typeError(messages.REQUIRED)
          .required(messages.REQUIRED),
      }),
      socialRegime: requiredString,
    }),
  });
  return yup.object().shape({
    contract: yup.object().shape({
      issuanceDate: yup
        .date()
        .typeError(messages.REQUIRED)
        .min(moment().startOf("day"), messages.MIN_TODAY)
        .required(messages.REQUIRED),
    }),
    contact: yup.object().shape({
      user: yup.object().shape({
        gender: requiredString,
        lastname: requiredString,
        firstname: requiredString,
        birthDate: yup
          .date()
          .typeError(messages.REQUIRED)
          .max(isMajor, messages.AGE_18)
          .required(messages.REQUIRED),
      }),
      socialRegime: requiredString,
      socioProfessionalCategory: yup.mixed().when("socialRegime", {
        is: (regime) => regime === SOCIAL_REGIMES.REGIME_SALARIES_AGRICOLES,
        then: requiredString,
      }),
      exerciseFrame: yup.mixed().when("socioProfessionalCategory", {
        is: (category) =>
          [
            SOCIO_PROFESSIONAL_CATEGORIES.FARMERS,
            SOCIO_PROFESSIONAL_CATEGORIES.ARTISANS,
            SOCIO_PROFESSIONAL_CATEGORIES.LIBERAL_PROFESSIONS_AND_SIMILAR,
            SOCIO_PROFESSIONAL_CATEGORIES.TRADERS_AND_SIMILAR,
            SOCIO_PROFESSIONAL_CATEGORIES.ENTREPRENEURS,
          ].includes(category),
        then: requiredString,
      }),
    }),
    zipCode: yup.object().shape({
      value: yup
        .string()
        .typeError(messages.REQUIRED)
        .test("isValid", messages.INVALID_VALUE, async function (value) {
          const isValidRegex = new RegExp(REGEX_EXPS.zipCode).test(value);
          if (!isValidRegex) return false;
          const {
            data: { addressZipCodeCity },
          } = await validateZipCode({
            variables: {
              where: { zipCode: value },
            },
          });
          setCity((prev) => ({ ...prev, value: addressZipCodeCity[0]?.city }));
          return !isEmpty(addressZipCodeCity);
        }),
    }),
    children: yup.array().of(childValidationSchema),
    hasSpouse: yup.boolean().nullable(),
    spouse: yup.object().when("hasSpouse", {
      is: (hasSpouse) => hasSpouse,
      then: yup.object().shape({
        identity: yup.object().shape({
          user: yup.object().shape({
            birthDate: yup
              .date()
              .typeError(messages.REQUIRED)
              .max(isMajor, messages.AGE_18)
              .required(messages.REQUIRED),
          }),
          socialRegime: requiredString,
        }),
      }),
    }),
  });
};

export default validator;
