import { ApolloLink, Observable } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import {
  checkDocument,
  hasDirectives,
  removeDirectivesFromDocument,
} from "apollo-utilities";
import { KEYS } from "utils/constants";
import { updateOperationHeader } from "./utils";

export const AUTH_ANNOTATION = "authBff";
export const PRIVATE_AUTH_ANNOTATION = "privateAuthBff";

const sanitizedQueryCache = new Map();

const authLink = createUploadLink({
  uri: "https://id.preprod.kayros.tech/id", // TODO: reset to process.env.REACT_APP_REST_AUTH_BFF_URL
  // uri: process.env.REACT_APP_REST_AUTH_BFF_URL,
});

const getQueryFromCache = (query) => {
  const sanitizedQuery = sanitizedQueryCache.get(JSON.stringify(query));
  if (sanitizedQuery) {
    return sanitizedQuery;
  }
  const directives = [
    { name: AUTH_ANNOTATION },
    { name: PRIVATE_AUTH_ANNOTATION },
  ];
  const sanitized = removeDirectivesFromDocument(directives, query);
  checkDocument(sanitized);
  sanitizedQueryCache.set(JSON.stringify(query), sanitized);
  return sanitized;
};

const AuthBffInterceptor = () =>
  new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        const token = localStorage.getItem(KEYS.ACCESS_TOKEN);
        if (hasDirectives([PRIVATE_AUTH_ANNOTATION], operation.query)) {
          updateOperationHeader(operation, token);
        }
        operation.query = getQueryFromCache(operation.query);
        forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
  );

export const authBffLink = ApolloLink.from([AuthBffInterceptor(), authLink]);
