import { gql } from "@apollo/client";

const ATTACHMENT_ADDED = gql`
  subscription attachmentAdded($where: ContactWhereInput) {
    attachmentAdded(where: $where) {
      id
      name
      fileUrl
      contentType
      type
    }
  }
`;

const ATTACHMENT_UPDATED = gql`
  subscription attachmentUpdated($where: ContactWhereInput) {
    attachmentUpdated(where: $where) {
      id
      name
      fileUrl
      contentType
      type
    }
  }
`;

const ATTACHMENT_DELETED = gql`
  subscription attachmentDeleted($where: AttachmentWhereInput) {
    attachmentDeleted(where: $where) {
      id
      name
      fileUrl
      contentType
      type
    }
  }
`;

const attachments = {
  ATTACHMENT_ADDED,
  ATTACHMENT_UPDATED,
  ATTACHMENT_DELETED,
};

export default attachments;
