import { notification } from "antd";
import cuid from "cuid";
import GeneralCosts from "entities/GeneralCosts";
import { get } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { CREATE_SUBSCRIPTION } from "utils/api/graphql/mutations/subscription";
import { CONTACT_POINT_SYSTEM } from "utils/constants";
import { removeDuplicates } from "utils/helpers/array";
import View from "./View";

const Quote = ({ project, onNext, onBack }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const [addSubscription] = useMutation(CREATE_SUBSCRIPTION);

  const insurancePlans = useMemo(
    () =>
      get(project, "fields.insurancePlans").map((id) => ({
        insurancePlan: { id },
      })),
    []
  );

  const { data: attachmentsList, loading } = useQuery(
    graphql.queries.ATTACHMENTS,
    {
      variables: {
        where: {
          OR: [
            {
              project: {
                id: project.id,
              },
            },
            ...insurancePlans,
          ],
        },
      },
    }
  );

  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    const uniqAttachments = removeDuplicates(
      get(attachmentsList, "attachments.data", [])
    );
    setAttachments(uniqAttachments);
  }, [loading]);

  const [sendQuoteMutation] = useMutation(graphql.mutations.SEND_QUOTE, {
    onCompleted: (_) => {
      notification.open({
        message: DETAIL.TEMPLATES.QUOTE.NOTIFICATION.SUCCESS,
        duration: 5,
        type: "success",
      });
      get(project, "fields.insurancePlans").forEach((insurancePlanId) => {
        addSubscription({
          variables: {
            data: {
              id: cuid(),
              project: { id: project.id },
              insurancePlan: {
                id: insurancePlanId,
              },
            },
          },
        });
      });
      onNext({
        payload: {
          locked: true,
        },
      });
    },
  });
  const [updateContact] = useMutation(graphql.mutations.UPDATE_CONTACT, {
    onCompleted: () =>
      sendQuoteMutation({
        variables: { data: { project: { id: project.id } } },
      }),
  });

  const sendQuote = (telecoms) => {
    updateContact({
      variables: {
        where: {
          id: project.contact.id,
        },
        data: {
          telecoms: [
            ...project.contact.telecoms.filter(
              (telecom) => telecom.system !== CONTACT_POINT_SYSTEM.EMAIL
            ),
            ...telecoms.map(({ label, value }) => ({
              id: value,
              system: CONTACT_POINT_SYSTEM.EMAIL,
              value: label,
            })),
          ],
        },
      },
    });
  };

  const generalCostsFilter = {
    where: {
      project: {
        id: project.id,
      },
    },
    isIn: {
      insurancePlan: {
        id: get(project, "fields.insurancePlans"),
      },
    },
  };

  return (
    <GeneralCosts
      filter={generalCostsFilter}
      loading={loading}
      updateContact={updateContact}
      attachments={attachments}
      setAttachments={setAttachments}
      project={project}
      onNext={sendQuote}
      onBack={onBack}
      View={View}
      NoData={View}
    />
  );
};

export default Quote;
