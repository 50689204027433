import { Switch } from "antd";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { MdOutlineInsertComment } from "react-icons/md";
import Alert from "shared/components/Alert";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { SUBSCRIPTION_FORM_KEYS } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";

const Information = ({ values, setFieldValue, formProps }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const INFORMATION = t(
    "SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.INFORMATION",
    {
      returnObjects: true,
    }
  );

  return (
    <div
      className="subscription-form--wrapper"
      id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.INFORMATION}-section`}
    >
      <Form type="vertical" title={INFORMATION.TITLE}>
        <div className="grid--1">
          <div className="d-flex justify--between">
            <span className="contact-stepper__content--radio--title">
              {INFORMATION.POLITICALLY_EXPOSED_PERSON.LABEL}
            </span>
            <Switch
              className="contact-stepper__content--radio--switch button__switch"
              checkedChildren={INFORMATION.OPTIONS.YES}
              unCheckedChildren={INFORMATION.OPTIONS.NO}
              defaultChecked={get(
                values,
                "project.contact.politicallyExposedPerson"
              )}
              onChange={(e) => {
                setFieldValue("project.contact.politicallyExposedPerson", e);
              }}
            />
          </div>
          <Alert
            showIcon
            type="info"
            message={INFORMATION.POLITICALLY_EXPOSED_PERSON.INFO}
          />
          <div className="d-flex justify--between">
            <span className="contact-stepper__content--radio--title">
              {INFORMATION.CMU_C_BENEFICIARY}
            </span>
            <Switch
              className="contact-stepper__content--radio--switch button__switch"
              checkedChildren={INFORMATION.OPTIONS.YES}
              unCheckedChildren={INFORMATION.OPTIONS.NO}
              defaultChecked={get(values, "project.fields.info.cmuBeneficiary")}
              onChange={(e) => {
                setFieldValue("project.fields.info.cmuBeneficiary", e);
              }}
            />
          </div>
          <div className="d-flex justify--between">
            <span className="contact-stepper__content--radio--title">
              {INFORMATION.CSH_BENEFICIARY}
            </span>
            <Switch
              className="contact-stepper__content--radio--switch button__switch"
              checkedChildren={INFORMATION.OPTIONS.YES}
              unCheckedChildren={INFORMATION.OPTIONS.NO}
              defaultChecked={get(values, "project.fields.info.cshBeneficiary")}
              onChange={(e) => {
                setFieldValue("project.fields.info.cshBeneficiary", e);
              }}
            />
          </div>
          <div className="d-flex justify--between">
            <span className="contact-stepper__content--radio--title">
              {INFORMATION.CC_CHI_BENEFICIARY}
            </span>
            <Switch
              className="contact-stepper__content--radio--switch button__switch"
              checkedChildren={INFORMATION.OPTIONS.YES}
              unCheckedChildren={INFORMATION.OPTIONS.NO}
              defaultChecked={get(
                values,
                "project.fields.info.ccChiBeneficiary"
              )}
              onChange={(e) => {
                setFieldValue("project.fields.info.ccChiBeneficiary", e);
              }}
            />
          </div>
          <div className="d-flex justify--between">
            <span className="contact-stepper__content--radio--title">
              {INFORMATION.ALD_BENEFICIARY}
            </span>
            <Switch
              className="contact-stepper__content--radio--switch button__switch"
              checkedChildren={INFORMATION.OPTIONS.YES}
              unCheckedChildren={INFORMATION.OPTIONS.NO}
              defaultChecked={get(values, "project.fields.info.aldBeneficiary")}
              onChange={(e) => {
                setFieldValue("project.fields.info.aldBeneficiary", e);
              }}
            />
          </div>
          <div className="d-flex justify--between">
            <span className="contact-stepper__content--radio--title">
              {INFORMATION.CURATORSHIP_GUARDIANSHIP}
            </span>
            <Switch
              className="contact-stepper__content--radio--switch button__switch"
              checkedChildren={INFORMATION.OPTIONS.YES}
              unCheckedChildren={INFORMATION.OPTIONS.NO}
              defaultChecked={get(
                values,
                "project.fields.info.curatorshipGuardianship"
              )}
              onChange={(e) => {
                setFieldValue("project.fields.info.curatorshipGuardianship", e);
              }}
            />
          </div>
          <Input
            type="textarea"
            label={INFORMATION.COMMENT}
            placeholder={INFORMATION.COMMENT}
            icon={<MdOutlineInsertComment size={16} />}
            {...bindInputProps({
              name: "project.fields.info.comment",
              values,
              ...formProps,
            })}
            autoSize={{ minRows: 2, maxRows: 10 }}
          />
        </div>
      </Form>
    </div>
  );
};

export default Information;
