import { useMemo } from "react";

import PropTypes from "prop-types";

import Dot from "./Dot";
import Line from "./Line";

const MODES = {
  dot: Dot,
  line: Line,
};

const Slider = ({ type, ...props }) => {
  const View = useMemo(() => MODES[type], [type]);
  return <View {...props} />;
};

Slider.propTypes = {
  type: PropTypes.oneOf(Object.keys(MODES)),
};

Slider.defaultProps = {
  type: "dot",
};

export default Slider;
