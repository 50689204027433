import { useMemo } from "react";

import PropTypes from "prop-types";

import Checkbox from "./Checkbox";
import Color from "./Color";
import ColorPicker from "./ColorPicker";
import Date from "./Date";
import File from "./File";
import Number from "./Number";
import Password from "./Password";
import Radio from "./Radio";
import Search from "./Search";
import Simple from "./Simple";
import TextArea from "./TextArea";

const MODES = {
  simple: Simple,
  search: Search,
  radio: Radio,
  date: Date,
  number: Number,
  file: File,
  textarea: TextArea,
  password: Password,
  checkbox: Checkbox,
  color: Color,
  colorPicker: ColorPicker,
};

const Input = ({ type, ...props }) => {
  const View = useMemo(() => MODES[type], [type]);
  return <View {...props} />;
};

Input.propTypes = {
  type: PropTypes.oneOf(Object.keys(MODES)),
};

Input.defaultProps = {
  type: "simple",
};

export default Input;
