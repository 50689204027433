import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { BsSearch, BsTrash } from "react-icons/bs";
import Button from "shared/components/Button";
import Pagination from "shared/components/Pagination";
import { NAME_SPACES } from "shared/locales/constants";

const ListCoverages = ({
  data,
  count,
  showCoverageBenefits,
  deleteCoverage,
  skip,
  setFilter,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.INSURANCE_PLAN);
  const FORM = t("FORM", { returnObjects: true });

  const coverageColumns = [
    {
      title: FORM.COVERAGE.NAME,
      dataIndex: "identifier",
    },
    {
      title: FORM.COVERAGE.CATEGORY,
      dataIndex: "type",
      render: (type) => FORM.COVERAGE.CATEGORIES[type],
    },
    {
      title: FORM.COVERAGE.WAITING_DAYS,
      dataIndex: "waitingDays",
    },
    {
      title: "Actions",
      dataIndex: "id",
      align: "right",
      render: (id, coverage) => (
        <div className="d-flex justify--end">
          <Button
            type="primary--link"
            icon={<BsSearch />}
            onClick={() => showCoverageBenefits(coverage)}
          >
            {FORM.COVERAGE.ACTIONS.SHOW}
          </Button>
          <Button
            type="danger--link"
            icon={<BsTrash />}
            onClick={() => deleteCoverage(id)}
          >
            {FORM.COVERAGE.ACTIONS.DELETE}
          </Button>
        </div>
      ),
    },
  ];
  return (
    <>
      <Table
        dataSource={data}
        columns={coverageColumns}
        pagination={false}
        scroll={{ x: true }}
        className="table__wrapper"
        rowKey={"id"}
      />
      <Pagination count={count} setValues={setFilter} skip={skip} />
    </>
  );
};

export default ListCoverages;
