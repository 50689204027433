import gql from "graphql-tag";

export const CREATE_COVERAGE = gql`
  mutation AddCoverage($data: CoverageDataInput) {
    addCoverage(data: $data) {
      id
    }
  }
`;

export const DUPLICATE_COVERAGE = gql`
  mutation duplicateCoverage($where: CoverageWhereInput) {
    duplicateCoverage(where: $where) {
      id
    }
  }
`;

export const UPDATE_COVERAGE = gql`
  mutation UpdateCoverage(
    $where: CoverageWhereInput
    $data: CoverageDataInput
  ) {
    updateCoverage(where: $where, data: $data) {
      id
    }
  }
`;

export const DELETE_COVERAGE = gql`
  mutation DeleteCoverage($where: CoverageWhereInput) {
    deleteCoverage(where: $where) {
      id
    }
  }
`;

const coverages = {
  CREATE_COVERAGE,
  UPDATE_COVERAGE,
  DELETE_COVERAGE,
  DUPLICATE_COVERAGE,
};

export default coverages;
