import { isNil } from "lodash";

export const joinList = (list, separator) => {
  const filteredList = list.filter((item) => !isNil(item));
  return filteredList.join(separator);
};

export const removeDuplicates = (array, proptety = "name") => {
  let seen = {};
  return array.filter((obj) => {
    return seen.hasOwnProperty(obj[proptety])
      ? false
      : (seen[obj[proptety]] = true);
  });
};
