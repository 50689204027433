import { PlusOutlined } from "@ant-design/icons";
import { Col, Row, Switch, Tabs } from "antd";
import cuid from "cuid";
import dayjs from "dayjs";
import { FieldArray, Formik } from "formik";
import { defaultTo, get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import DatePicker from "shared/components/Form/DatePicker";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import queries from "utils/api/graphql/queries";
import {
  ATTACHMENT_TYPES,
  CONTACT_EXERCISE_FRAME,
  COVERAGE_NEEDS,
  DEBIT_POSSIBLE_DATES,
  DELTA_END_TYPES,
  PAYMENT_FREQUENCIES,
  PAYMENT_TYPES,
  PERIOD_UNITS,
  SCREENS,
  SOCIAL_REGIMES,
} from "utils/constants";
import { bindArrayInputProps, bindInputProps } from "utils/helpers/input";
import Documents from "./Blocks/Documents";
import Recommendation from "./Blocks/Recommendation";
import validation from "./validation";

const DELTA_END_INITIAL_VALUES = {
  [DELTA_END_TYPES.END_OF_YEAR]: () => 0,
  [DELTA_END_TYPES.ADD_PERIOD]: (start, end) => end - start,
  DEFAULT: () => null,
};

const Detail = ({
  onSubmit,
  cancel,
  data,
  addAttachment,
  deleteAttachment,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.INSURANCE_PLAN);
  const navigate = useNavigate();
  const DETAIL = t("DETAIL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const { startDate, endDate, params, needs, ...rest } = data;
  const handleFileUpload = async (file, setFieldValue) => {
    const { base64, ...fileData } = file;
    setFieldValue("iconAttachment", {
      ...fileData,
      id: cuid(),
      content: base64,
      type: ATTACHMENT_TYPES.ICON,
    });
    setFieldValue("icon", file.base64);
  };

  return (
    <Formik
      initialValues={{
        ...rest,
        needs: defaultTo(needs, {
          id: cuid(),
          [COVERAGE_NEEDS.OPTICAL]: 0,
          [COVERAGE_NEEDS.DENTAL]: 0,
          [COVERAGE_NEEDS.MEDICAL_CARE]: 0,
          [COVERAGE_NEEDS.HOSPITALIZATION]: 0,
          [COVERAGE_NEEDS.EQUIPMENT]: 0,
          [COVERAGE_NEEDS.ALTERNATIVE_MEDICINE]: 0,
        }),
        params: params.map(
          ({ deltaEnd, deltaEndRIA, deltaStart, deltaStartRIA, ...values }) => {
            const type =
              (deltaEnd === 0 && DELTA_END_TYPES.END_OF_YEAR) ||
              (deltaEnd > 0 && DELTA_END_TYPES.ADD_PERIOD) ||
              null;
            return {
              ...values,
              deltaStart,
              deltaStartRIA,
              deltaEnd: {
                value: DELTA_END_INITIAL_VALUES[type || "DEFAULT"](
                  deltaStart,
                  deltaEnd
                ),
                type,
                unit: deltaEnd > 0 ? PERIOD_UNITS.DAYS : null,
              },
              deltaEndRIA: {
                value: DELTA_END_INITIAL_VALUES[type || "DEFAULT"](
                  deltaStartRIA,
                  deltaEndRIA
                ),
                type:
                  (deltaEndRIA === 0 && DELTA_END_TYPES.END_OF_YEAR) ||
                  (deltaEndRIA > 0 && DELTA_END_TYPES.ADD_PERIOD) ||
                  null,
                unit: deltaEndRIA > 0 ? PERIOD_UNITS.DAYS : null,
              },
            };
          }
        ),
        date: [startDate, endDate],
        commissions: get(data, "commissions", []).map(({ id }) => id),
      }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, values, setFieldValue, ...formProps }) => [
        <Header
          key={"header"}
          title={DETAIL.HEADER.TITLE}
          subtitle={DETAIL.HEADER.ACTION}
          actions={[
            {
              text: DETAIL.HEADER.DISCARD,
              onClick: cancel,
              type: "primary--outlined",
              icon: <BiChevronLeft size={20} />,
            },
            {
              text: DETAIL.HEADER.DELETE,
              onClick: () =>
                navigate(
                  `${SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.DELETE.path}/${data.id}`
                ),
              type: "danger--outlined",
              icon: <BiTrash size={20} />,
            },
            {
              text: DETAIL.HEADER.SAVE,
              onClick: handleSubmit,
              type: "primary",
              icon: <BiCheck size={20} />,
            },
          ]}
        />,
        <Card title={FORM.GENERAL_INFO} divider={false} key="generalInfo">
          <div className="grid--2">
            <div className="d-flex">
              <strong>{FORM.ACTIVE}</strong>
              <Switch
                className="button__switch"
                checkedChildren={FORM.YES}
                unCheckedChildren={FORM.NO}
                defaultChecked={get(values, "active")}
                onChange={(e, _) => setFieldValue("active", e)}
              />
            </div>
            <div className="d-flex">
              <span>{FORM.MADELIN}</span>
              <Switch
                className="button__switch"
                checkedChildren={FORM.YES}
                unCheckedChildren={FORM.NO}
                defaultChecked={get(values, "fields.madelin")}
                onChange={(e, _) => setFieldValue("fields.madelin", e)}
              />
            </div>
            <Input
              placeholder={FORM.NAME}
              label={FORM.NAME}
              {...bindInputProps({ name: "name", values, ...formProps })}
            />
            <Input
              placeholder={FORM.DESCRIPTION}
              label={FORM.DESCRIPTION}
              {...bindInputProps({
                name: "description",
                values,
                ...formProps,
              })}
            />
            <Input
              placeholder={FORM.ALIAS}
              label={FORM.ALIAS}
              {...bindInputProps({ name: "alias", values, ...formProps })}
            />
            <DatePicker
              name="date"
              placeholder={FORM.DATE}
              placeholders={[FORM.START_DATE, FORM.END_DATE]}
              defaultValue={
                startDate && endDate && [dayjs(startDate), dayjs(endDate)]
              }
              onChange={(_, dates) => setFieldValue("date", dates)}
            />
            <Input
              type="file"
              variant="uploader"
              listType="picture"
              showUploadList={false}
              maxCount={1}
              onUpload={([file]) => handleFileUpload(file, setFieldValue)}
              fileList={
                isEmpty(get(values, "icon", [])) ? [] : [get(values, "icon")]
              }
            >
              <Button type="primary--link">{FORM.ICON}</Button>
              <div>
                {get(values, "icon") && (
                  <img className="icon" alt="icon" src={get(values, "icon")} />
                )}
              </div>
            </Input>
          </div>
        </Card>,
        <div key="margin-1" className="m-bottom-16" />,
        <Card title={FORM.COMMISSIONS.TITLE} divider={false} key="commission">
          <div className="grid--4">
            <Select
              type="async"
              mode="multiple"
              node={{
                query: queries.COMMISSIONS,
                accessors: {
                  root: "commissions.data",
                  label: {
                    separator: "/",
                    keys: ["value", "withholding/value"],
                  },
                },
              }}
              placeholder={FORM.COMMISSIONS.TITLE}
              label={FORM.COMMISSIONS.TITLE}
              {...bindInputProps({
                name: "commissions",
                values,
                ...formProps,
              })}
              onChange={(value) => setFieldValue("commissions", value)}
            />
          </div>
        </Card>,
        <div key="margin-2" className="m-bottom-16" />,
        <Card title={FORM.CONDITIONS.TITLE} divider={false} key="params">
          <div className="grid--3 m-bottom-16">
            <Input
              type="checkbox"
              label={FORM.TNS}
              {...bindInputProps({ name: "fields.tns", ...formProps })}
              checked={!!get(values, "fields.tns")}
            />
            <Input
              type="checkbox"
              label={FORM.CONDITIONS.ALLOWED_PAYER}
              {...bindInputProps({
                name: "fields.payment.shouldBeAdherent",
                ...formProps,
              })}
              checked={!!get(values, "fields.payment.shouldBeAdherent")}
            />
          </div>
          <div className="grid--3  m-bottom-16">
            <Select
              mode="multiple"
              options={Object.entries(PAYMENT_TYPES).map(([key, value]) => ({
                value,
                label: FORM.CONDITIONS.PAYMENT_TYPE.OPTIONS[key],
              }))}
              placeholder={FORM.CONDITIONS.PAYMENT_TYPE.LABEL}
              label={FORM.CONDITIONS.PAYMENT_TYPE.LABEL}
              {...bindInputProps({
                name: "fields.payment.type",
                values,
                ...formProps,
              })}
              onChange={(value) => setFieldValue(`fields.payment.type`, value)}
            />
            <Select
              mode="multiple"
              options={Object.entries(PAYMENT_FREQUENCIES).map(
                ([key, value]) => ({
                  value,
                  label: FORM.CONDITIONS.PAYMENT_FREQUENCY.OPTIONS[key],
                })
              )}
              placeholder={FORM.CONDITIONS.PAYMENT_FREQUENCY.LABEL}
              label={FORM.CONDITIONS.PAYMENT_FREQUENCY.LABEL}
              {...bindInputProps({
                name: "fields.payment.frequency",
                values,
                ...formProps,
              })}
              onChange={(value) =>
                setFieldValue(`fields.payment.frequency`, value)
              }
            />
            <Select
              mode="multiple"
              options={DEBIT_POSSIBLE_DATES.map((value) => ({
                value,
                label: value,
              }))}
              placeholder={FORM.CONDITIONS.DEBIT_POSSIBLE_DATES}
              label={FORM.CONDITIONS.DEBIT_POSSIBLE_DATES}
              {...bindInputProps({
                name: "fields.payment.debitDay",
                values,
                ...formProps,
              })}
              onChange={(value) =>
                setFieldValue(`fields.payment.debitDay`, value)
              }
            />
          </div>
          <FieldArray
            name={"params"}
            render={(arrayHelpers) => [
              get(values, "params", []).map((_, i) => (
                <div key="params" className="grid--1">
                  <div
                    className={"d-flex justify--between m-top-8 delete-icon"}
                  >
                    <h3 className="card--title">{`${
                      FORM.CONDITIONS.PARAMETERS
                    } ${i + 1}`}</h3>
                    <BiTrash
                      color={COLORS.C_DANGER}
                      size={24}
                      onClick={() => {
                        arrayHelpers.remove(i);
                      }}
                    />
                  </div>

                  <Row gutter={[16, 16]} align={"middle"}>
                    <Col span={4}>{FORM.CONDITIONS.ISSUANCE_DATE}</Col>
                    <Col sm={24} md={10}>
                      <Row align={"middle"} gutter={8}>
                        <Col>{FORM.CONDITIONS.TODAY + " +"}</Col>
                        <Col flex="1">
                          <Input
                            type="number"
                            placeholder={FORM.CONDITIONS.START_DATE}
                            {...bindArrayInputProps({
                              parent: "params",
                              index: i,
                              name: "deltaStart",
                              values,
                              ...formProps,
                            })}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={24} md={10}>
                      <Row align={"middle"} gutter={8}>
                        <Col flex={1}>
                          <Select
                            options={Object.entries(DELTA_END_TYPES).map(
                              ([key, value]) => ({
                                label:
                                  FORM.CONDITIONS.MAX_ISSUANCE_DATE_OPTIONS[
                                    key
                                  ],
                                value,
                              })
                            )}
                            allowClear
                            placeholder={FORM.CONDITIONS.END_DATE}
                            {...bindArrayInputProps({
                              parent: "params",
                              index: i,
                              name: "deltaEnd.type",
                              values,
                              ...formProps,
                            })}
                            onChange={(value) => {
                              value
                                ? setFieldValue(
                                    `params.${i}.deltaEnd.type`,
                                    value
                                  )
                                : setFieldValue(`params.${i}.deltaEnd`, null);
                            }}
                          />
                        </Col>
                        {get(values, `params.${i}.deltaEnd.type`) ===
                          DELTA_END_TYPES.ADD_PERIOD && (
                          <>
                            <Col span={9}>
                              <Input
                                type="number"
                                placeholder={FORM.CONDITIONS.NUMBER_OF}
                                {...bindArrayInputProps({
                                  parent: "params",
                                  index: i,
                                  name: "deltaEnd.value",
                                  values,
                                  ...formProps,
                                })}
                              />
                            </Col>
                            <Col span={5}>
                              <Select
                                options={Object.entries(PERIOD_UNITS).map(
                                  ([key, value]) => ({
                                    label: FORM.CONDITIONS[key],
                                    value,
                                  })
                                )}
                                placeholder={FORM.CONDITIONS.UNIT}
                                {...bindArrayInputProps({
                                  parent: "params",
                                  index: i,
                                  name: "deltaEnd.unit",
                                  values,
                                  ...formProps,
                                })}
                              />
                            </Col>
                          </>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} align={"middle"}>
                    <Col span={4}>{FORM.CONDITIONS.ISSUANCE_DATE_RIA}</Col>
                    <Col span={10}>
                      <Row align={"middle"} gutter={8}>
                        <Col>{FORM.CONDITIONS.TODAY + " +"}</Col>
                        <Col flex="1">
                          <Input
                            type="number"
                            placeholder={FORM.CONDITIONS.START_DATE_RIA}
                            {...bindArrayInputProps({
                              parent: "params",
                              index: i,
                              name: "deltaStartRIA",
                              values,
                              ...formProps,
                            })}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={10}>
                      <Row align={"middle"} gutter={8}>
                        <Col flex={1}>
                          <Select
                            options={Object.entries(DELTA_END_TYPES).map(
                              ([key, value]) => ({
                                label:
                                  FORM.CONDITIONS.MAX_ISSUANCE_DATE_OPTIONS[
                                    key
                                  ],
                                value,
                              })
                            )}
                            placeholder={FORM.CONDITIONS.END_DATE_RIA}
                            {...bindArrayInputProps({
                              parent: "params",
                              index: i,
                              name: "deltaEndRIA.type",
                              values,
                              ...formProps,
                            })}
                            allowClear
                            onChange={(value) => {
                              value
                                ? setFieldValue(
                                    `params.${i}.deltaEndRIA.type`,
                                    value
                                  )
                                : setFieldValue(
                                    `params.${i}.deltaEndRIA`,
                                    null
                                  );
                            }}
                          />
                        </Col>
                        {get(values, `params.${i}.deltaEndRIA.type`) ===
                          DELTA_END_TYPES.ADD_PERIOD && (
                          <>
                            <Col span={9}>
                              <Input
                                type="number"
                                placeholder={FORM.CONDITIONS.NUMBER_OF}
                                {...bindArrayInputProps({
                                  parent: "params",
                                  index: i,
                                  name: "deltaEndRIA.value",
                                  values,
                                  ...formProps,
                                })}
                              />
                            </Col>
                            <Col span={5}>
                              <Select
                                options={Object.entries(PERIOD_UNITS).map(
                                  ([key, value]) => ({
                                    label: FORM.CONDITIONS[key],
                                    value,
                                  })
                                )}
                                placeholder={FORM.CONDITIONS.UNIT}
                                {...bindArrayInputProps({
                                  parent: "params",
                                  index: i,
                                  name: "deltaEndRIA.unit",
                                  values,
                                  ...formProps,
                                })}
                              />
                            </Col>
                          </>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} align={"middle"}>
                    <Col span={4}>{FORM.CONDITIONS.AGE}</Col>
                    <Col span={10}>
                      <Input
                        type="number"
                        placeholder={FORM.CONDITIONS.MIN}
                        {...bindArrayInputProps({
                          parent: "params",
                          index: i,
                          name: "minAge",
                          values,
                          ...formProps,
                        })}
                      />
                    </Col>
                    <Col sm={10}>
                      <Input
                        type="number"
                        placeholder={FORM.CONDITIONS.MAX}
                        {...bindArrayInputProps({
                          parent: "params",
                          index: i,
                          name: "maxAge",
                          values,
                          ...formProps,
                        })}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} align={"middle"}>
                    <Col span={4}>{FORM.CONDITIONS.SOCIAL_REGIMES}</Col>
                    <Col sm={20} lg={10}>
                      <Select
                        mode="multiple"
                        options={Object.entries(SOCIAL_REGIMES).map(
                          ([key, value]) => ({
                            value,
                            label: FORM.CONDITIONS.SOCIAL_REGIMES_OPTIONS[key],
                          })
                        )}
                        value={get(values, "params.socialRegimes") || []}
                        placeholder={FORM.CONDITIONS.SOCIAL_REGIMES}
                        {...bindArrayInputProps({
                          parent: "params",
                          index: i,
                          name: "socialRegimes",
                          values,
                          ...formProps,
                        })}
                        onChange={(value) =>
                          setFieldValue(`params.${i}.socialRegimes`, value)
                        }
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} align={"middle"}>
                    <Col span={4}>{FORM.CONDITIONS.EXERCISE_FRAME.LABEL}</Col>
                    <Col span={20}>
                      <Input
                        type="radio"
                        variant="group"
                        values={[
                          {
                            value: CONTACT_EXERCISE_FRAME.EMPLOYEE,
                            label:
                              FORM.CONDITIONS.EXERCISE_FRAME.OPTIONS.EMPLOYEE,
                          },
                          {
                            value: CONTACT_EXERCISE_FRAME.INDEPENDENT,
                            label:
                              FORM.CONDITIONS.EXERCISE_FRAME.OPTIONS
                                .INDEPENDENT,
                          },
                          {
                            value: "",
                            label: FORM.CONDITIONS.EXERCISE_FRAME.OPTIONS.ANY,
                          },
                        ]}
                        {...bindArrayInputProps({
                          parent: "params",
                          index: i,
                          name: "exerciseFrame",
                          values,
                          ...formProps,
                        })}
                      />
                    </Col>
                  </Row>
                </div>
              )),
              <Button
                type="primary"
                key="add-row"
                onClick={() => {
                  arrayHelpers.push({
                    id: cuid(),
                    socialRegimes: [],
                    deltaEnd: { type: null, unit: null },
                    deltaEndRIA: { type: null, unit: null },
                  });
                }}
              >
                <PlusOutlined color={COLORS.C_WHITE} />
              </Button>,
            ]}
          />
        </Card>,
        <div key="margin-3" className="m-bottom-16" />,
        <Card
          title={FORM.RECOMMENDATION.TITLE}
          divider={false}
          key="recommendation"
        >
          <div className="grid--1">
            <Recommendation
              setFieldValue={setFieldValue}
              needs={get(values, "needs", {})}
            />
          </div>
        </Card>,
        <div key="margin-4" className="m-bottom-16" />,
        <Tabs
          key="documents"
          items={[
            {
              key: "static",
              label: FORM.DOCUMENTS.TABS.STATIC,
              children: (
                <Documents
                  values={values}
                  setFieldValue={setFieldValue}
                  formProps={formProps}
                  translation={FORM}
                  addAttachment={addAttachment}
                  deleteAttachment={deleteAttachment}
                />
              ),
            },
            {
              key: "dynamic",
              label: FORM.DOCUMENTS.TABS.DYNAMIC,
              children: (
                <Documents
                  values={values}
                  setFieldValue={setFieldValue}
                  formProps={formProps}
                  translation={FORM}
                  addAttachment={addAttachment}
                  deleteAttachment={deleteAttachment}
                />
              ),
            },
          ]}
        />,
      ]}
    </Formik>
  );
};

export default Detail;
