import { faker } from "@faker-js/faker";
import cuid from "cuid";
import { defaults, get, map } from "lodash";
import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import { StoreContext } from "shared/store";
import graphql from "utils/api/graphql";
import { INITIAL_VALUES, SCREENS, USER_TYPES } from "utils/constants";
import View from "./View";

const Create = () => {
  const navigate = useNavigate();
  const { user } = useContext(StoreContext);
  const [userId, orgId] = useMemo(() => [cuid(), cuid()], []);
  const cancel = () => navigate(SCREENS.PRIVATE.BROKER.COLLABORATORS.LIST.path);
  const [signUpAuth] = useMutation(graphql.mutations.SIGN_UP_AUTH);
  const [requestSignUp] = useMutation(graphql.mutations.REQUEST_SIGN_UP, {
    refetchQueries: [
      {
        query: graphql.queries.USERS,
        awaitRefetchQueries: true,
        variables: {
          isIn: {
            meta: [
              { type: USER_TYPES.BROKER_EMPLOYEE.type },
              { type: USER_TYPES.BROKER_REPRESENTATIVE.type },
              { type: USER_TYPES.INSURANCE_REPRESENTATIVE.type },
            ],
          },
          ...INITIAL_VALUES,
        },
      },
    ],
    onCompleted: () => navigate(SCREENS.PRIVATE.BROKER.COLLABORATORS.LIST.path),
  });

  const onSubmit = (data) => {
    const {
      email,
      orias,
      code,
      rcsNumber,
      socialReason,
      meta,
      phone,
      telecoms,
      ...values
    } = data;
    const organizationTelecoms = map(telecoms, (item, key) =>
      defaults(item, { id: cuid(), system: key })
    );
    const organization = {
      [USER_TYPES.BROKER_REPRESENTATIVE.type]: {
        id: orgId,
        name: socialReason,
        telecoms: get(user, "organization.telecoms", [])?.map(
          ({ value, system }) => ({ value, system, id: cuid() })
        ),
        broker: {
          id: cuid(),
          orias,
          socialReason,
          isInternal: false,
          code: get(user, "organization.broker.code"),
          rcsNumber: get(user, "organization.broker.rcsNumber"),
        },
      },
      [USER_TYPES.INSURANCE_REPRESENTATIVE.type]: {
        id: orgId,
        name: socialReason,
        telecoms: organizationTelecoms,
        broker: {
          id: cuid(),
          orias,
          socialReason,
          isInternal: false,
          code,
          rcsNumber,
        },
      },
      [USER_TYPES.BROKER_EMPLOYEE.type]: { id: get(user, "organization.id") },
    };

    signUpAuth({
      variables: {
        data: {
          id: userId,
          username: email,
          password: faker.internet.password(),
          email,
          organization: {
            id: get(organization[meta.type], "id"),
            name: get(organization[meta.type], "name"),
          },
          meta,
          phone,
        },
      },
      onCompleted: ({ signUp: { id, username, email } }) => {
        requestSignUp({
          variables: {
            data: {
              id,
              username,
              email,
              meta,
              phone,
              organization: organization[meta.type],
              ...values,
            },
          },
        });
      },
    });
  };

  return <View onSubmit={onSubmit} cancel={cancel} />;
};

export default Create;
