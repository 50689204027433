import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import logo from "shared/assets/images/logo.svg";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const View = ({ onSubmit }) => {
  const { t: auth } = useTranslation(NAME_SPACES.AUTH);
  const { t: errors } = useTranslation(NAME_SPACES.ERRORS);
  const RESET_PASSWORD = auth("RESET_PASSWORD", {
    returnObjects: true,
  });
  const VALIDATIONS = errors("VALIDATIONS", {
    returnObjects: true,
  });
  return (
    <div className="auth">
      <img alt="logo" src={logo} />
      <Formik
        initialValues={{
          passcode: "",
          password: "",
          confirmPassword: "",
        }}
        onSubmit={onSubmit}
        validationSchema={validation(VALIDATIONS)}
        validateOnBlur
        validateOnChange={false}
      >
        {({ isValid, handleSubmit, ...formProps }) => (
          <div className="auth__body">
            <Input
              label={RESET_PASSWORD.PASS_CODE}
              placeholder={RESET_PASSWORD.PASS_CODE}
              {...bindInputProps({
                name: "passcode",
                ...formProps,
              })}
            />

            <Input
              type="password"
              label={RESET_PASSWORD.PASSWORD}
              placeholder={RESET_PASSWORD.PASSWORD}
              {...bindInputProps({
                name: "password",
                ...formProps,
              })}
            />

            <Input
              type="password"
              label={RESET_PASSWORD.CONFIRM_PASSWORD}
              placeholder={RESET_PASSWORD.CONFIRM_PASSWORD}
              {...bindInputProps({
                name: "confirmPassword",
                ...formProps,
              })}
            />
            <Button type="primary" onClick={handleSubmit}>
              {RESET_PASSWORD.SUBMIT}
            </Button>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default View;
