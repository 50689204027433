import { TEMPLATES } from "utils/constants";
import CollectionNeeds from "./CollectionNeeds";
import Comparison from "./Comparison";
import Contact from "./Contact";
import Contract from "./Contract";
import Default from "./Default";
import Fast from "./Fast";
import Product from "./Product";
import Quote from "./Quote";
import SelectProducts from "./SelectProducts";

const Templates = {
  [TEMPLATES.CONTACT]: Contact,
  [TEMPLATES.CONTRACT]: Contract,
  [TEMPLATES.QUOTE]: Quote,
  [TEMPLATES.FAST]: Fast,
  [TEMPLATES.COLLECTION_NEEDS]: CollectionNeeds,
  [TEMPLATES.SELECT_PRODUCTS]: SelectProducts,
  [TEMPLATES.COMPARISON]: Comparison,
  ...Product,
  default: Default,
};
export default Templates;
