import React from "react";
import { useTranslation } from "react-i18next";
import empty from "shared/assets/images/empty.svg";
import { NAME_SPACES } from "shared/locales/constants";

const EmptyTable = () => {
  const { t } = useTranslation(NAME_SPACES.GENERIC);
  return <div className={"empty__table"}>
    <img src={empty} alt=""  />
    <p> {t('EMPTY_TABLE_STATUS')}</p>
  </div>;
};

export default EmptyTable;
