import { Col, Modal, Row } from "antd";
import { find, get } from "lodash";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { NAME_SPACES } from "shared/locales/constants";
import { CONTACT_POINT_SYSTEM, NAVIGATION_DIRECTIONS } from "utils/constants";
import Interactions from "./Interactions";
import Qualify from "./Qualify";

const Create = () => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.QUALIFICATION);
  const SHOW = t("SHOW", { returnObjects: true });
  const { state } = useLocation();
  const navigate = useNavigate();
  const { qualification } = state;
  const { contact } = qualification;
  const { user } = contact;
  return (
    <Modal
      open={true}
      onOk={() => {
        navigate(NAVIGATION_DIRECTIONS.BACK);
      }}
      onCancel={() => navigate(NAVIGATION_DIRECTIONS.BACK)}
      width={1024}
    >
      <Row gutter={[50, 50]}>
        <Col className="interaction--wrapper" lg={12} xs={24}>
          <Interactions qualification={qualification} />
        </Col>
        <Col lg={12} xs={24}>
          <div>
            {SHOW.BIRTHDAY_DATE}: {user.birthDate}
          </div>
          <div>
            {SHOW.EMAIL}:{" "}
            {
              find(get(contact, "telecoms", []), {
                system: CONTACT_POINT_SYSTEM.EMAIL,
              })?.value
            }
          </div>
          <div>
            {SHOW.FIRSTNAME}: {user.firstname}
          </div>
          <div>
            {SHOW.LASTNAME}: {user.lastname}
          </div>
          <div>
            {SHOW.GENDER.TITLE}: {user.gender}
          </div>
          <div>
            {SHOW.SITUATION}: {contact.fields.situation}
          </div>
          <div>
            {SHOW.PHONE}:{" "}
            {
              find(get(contact, "telecoms", []), {
                system: CONTACT_POINT_SYSTEM.PHONE,
              })?.value
            }
          </div>
          <div>
            {SHOW.ZIP_CODE}:{" "}
            {
              find(get(contact, "telecoms", []), {
                system: CONTACT_POINT_SYSTEM.ZIP_CODE,
              })?.value
            }
          </div>
          <div>
            {SHOW.SOCIAL_REGIME}: {contact.fields.socialRegime}
          </div>
          <div>
            {SHOW.CONTACT_OPTION}: {contact.fields.contactMe}
          </div>
          <div>
            {SHOW.DATE_REGISTRED}: {contact.fields.dateRegistered}
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Qualify qualification={qualification} />
        </Col>
      </Row>
    </Modal>
  );
};

export default Create;
