import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Header from "shared/components/Header";
import { NAME_SPACES } from "shared/locales/constants";
import { SCREENS } from "utils/constants";

const Contract = ({ onSubmit, project, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const navigate = useNavigate();
  const DETAIL = t("DETAIL", { returnObjects: true });

  return (
    <Header
      title={DETAIL.HEADER.TITLE}
      subtitle={`${DETAIL.HEADER.ACTION} (${project.status.name} - ${project.status.progress})`}
      actions={[
        {
          text: DETAIL.HEADER.DISCARD,
          onClick: () => navigate(SCREENS.PRIVATE.BROKER.PROJECTS.LIST.path),
          type: "gray",
        },
        {
          text: DETAIL.HEADER.DELETE,
          onClick: () =>
            navigate(
              `${SCREENS.PRIVATE.BROKER.PROJECTS.DELETE.path}/${project.id}`
            ),
          type: "danger",
        },
        {
          text: DETAIL.HEADER.SAVE,
          onClick: onSubmit,
        },
      ]}
    />
  );
};

export default Contract;
