import { Input } from "antd";
import PropTypes from "prop-types";

const { Search } = Input;

const CustomSearch = ({ placeholder, onSearch, onChange, ...rest }) => {
  return (
    <div className="input__search">
      <Search
        placeholder={placeholder}
        onChange={onChange}
        onSearch={onSearch}
        {...rest}
      />
    </div>
  );
};

CustomSearch.propTypes = {
  placeholder: PropTypes.string,
};

CustomSearch.defaultProps = {
  placeholder: "Search",
};

export default CustomSearch;
