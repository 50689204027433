import { gql } from "@apollo/client";

export const TRACERS = gql`
  query Tracers(
    $where: TracerWhereInput
    $take: Int
    $skip: Int
    $like: TracerWhereInput
  ) {
    tracers(where: $where, take: $take, skip: $skip, like: $like) {
      data {
        id
        name
        destination
        source {
          id
          name
        }
        flow {
          id
          process {
            id
            processStatuses {
              id
              name
              progress
              template
              order
              possibleStatuses {
                nextStatus
                conditions {
                  operation
                  rightOperand
                  leftOperand
                }
              }
              moveToNext
            }
          }
        }
      }
      count
    }
  }
`;

export const TRACER = gql`
  query Tracer($where: TracerWhereInput) {
    tracer(where: $where) {
      id
      name
      destination
      source {
        id
        name
      }
      flow {
        id
        process {
          id
          processStatuses {
            id
            name
            progress
            template
            order
            possibleStatuses {
              nextStatus
              conditions {
                operation
                rightOperand
                leftOperand
              }
            }
            moveToNext
          }
        }
      }
    }
  }
`;

const tracers = { TRACERS, TRACER };

export default tracers;
