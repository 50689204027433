import { gql } from "@apollo/client";

export const CATEGORIES_WEIGHTS = gql`
  query CategoryWeights(
    $where: CategoryWeightWhereInput
    $like: CategoryWeightWhereInput
    $take: Int
    $skip: Int
  ) {
    categoryWeights(where: $where, take: $take, skip: $skip, like: $like) {
      data {
        id
        hospitalization
        medicalExpenses
        optical
        dental
        hearingAid
        alternativeMedicine
      }
      count
    }
  }
`;

export const CATEGORIES_WEIGHT = gql`
  query CategoryWeight($where: CategoryWeightWhereInput) {
    categoryWeight(where: $where) {
      id
      hospitalization
      medicalExpenses
      optical
      dental
      hearingAid
      alternativeMedicine
    }
  }
`;

const categoryWeights = { CATEGORIES_WEIGHT, CATEGORIES_WEIGHTS };

export default categoryWeights;
