export const names = {
  CONTACTS: "CONTACTS",
  SOURCES: "SOURCES",
  TRACERS: "TRACERS",
  FLOWS: "FLOWS",
  PROCESSES: "PROCESSES",
  USERS: "USERS",
  CONTRACTS: "CONTRACTS",
  ORGANIZATIONS: "ORGANIZATIONS",
  INSURANCE_PLANS: "INSURANCE_PLANS",
  PROJECTS: "PROJECTS",
  PROJECT: "PROJECT",
  COVERAGES: "COVERAGES",
  GENERAL_COSTS: "GENERAL_COSTS",
  WEBHOOKS: "WEBHOOKS",
  QUALIFICATIONS: "QUALIFICATIONS",
  NOTES: "NOTES",
  QUALIFIERS: "QUALIFIERS",
  REMINDERS: "REMINDERS",
  COVERAGE_BENEFITS: "COVERAGE_BENEFITS",
  ATTACHMENTS: "ATTACHMENTS",
  SUBSCRIPTION: "SUBSCRIPTION",
  BROKER: "BROKERS",
};

export const SUBSCRIPTION_EVENT_TYPES = {
  ADD: "add",
  UPDATE: "update",
  DELETE: "delete",
};
