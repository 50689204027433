import { Input } from "antd";
import PropTypes from "prop-types";

import classnames from "classnames";
import Label from "shared/components/Label";

const Simple = ({
  label,
  placeholder,
  errors,
  touched,
  icon,
  innerRef,
  required,
  ...rest
}) => {
  return (
    <div
      className={classnames("input__wrapper", { __error: touched && errors })}
    >
      <Label label={label} icon={icon} required={required} />
      <div className={`input__wrapper--field`}>
        <Input
          className={classnames({
            "input--borderd--red": touched && errors,
            "disable--input": rest.disabled,
          })}
          placeholder={placeholder}
          ref={innerRef}
          {...rest}
        />
        {touched && errors && <p className={"input--errors"}>{errors}</p>}
      </div>
    </div>
  );
};

Simple.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

Simple.defaultProps = {
  label: "",
  placeholder: "",
};
export default Simple;
