import { ApolloClient, InMemoryCache, split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { hasDirectives } from "apollo-utilities";
import {
  AUTH_ANNOTATION,
  PRIVATE_AUTH_ANNOTATION,
  authBffLink,
} from "./links/authBff.link";
import authWSLink from "./links/authWs.link";
import bffLink from "./links/bff.link";
import wsLink from "./links/ws.link";

const rootLink = split(
  ({ query }) =>
    hasDirectives([AUTH_ANNOTATION], query) ||
    hasDirectives([PRIVATE_AUTH_ANNOTATION], query),
  authBffLink,
  bffLink
);

const wssLink = split(
  ({ query }) =>
    hasDirectives([AUTH_ANNOTATION], query) ||
    hasDirectives([PRIVATE_AUTH_ANNOTATION], query),
  authWSLink,
  wsLink
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wssLink,
  rootLink
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

export default client;
