import { gql } from "@apollo/client";

export const COMMISSION_STATEMENTS = gql`
  query CommissionStatements(
    $where: CommissionStatementWhereInput
    $like: CommissionStatementWhereInput
    $take: Int
    $skip: Int
    $orderBy: [CommissionStatementWhereInput]
  ) {
    commissionStatements(
      where: $where
      take: $take
      skip: $skip
      like: $like
      orderBy: $orderBy
    ) {
      data {
        id
        year
        month
        broker {
          id
          orias
          socialReason
        }
        attachment {
          id
          name
        }
      }
      count
    }
  }
`;

export const COMMISSION_STATEMENT = gql`
  query CommissionStatement($where: CommissionStatementWhereInput) {
    commissionStatement(where: $where) {
      id
      year
      month
      broker {
        id
        code
      }
      attachment {
        id
        name
      }
    }
  }
`;

const commissionStatements = { COMMISSION_STATEMENTS, COMMISSION_STATEMENT };

export default commissionStatements;
