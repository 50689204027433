import * as yup from "yup";

const validation = (messages) => {
  const requiredNumber = yup
    .number()
    .min(0, `${messages.MIN} 0`)
    .required(messages.REQUIRED);
  return yup.object().shape({
    hospitalization: requiredNumber,
    medicalExpenses: requiredNumber,
    optical: requiredNumber,
    dental: requiredNumber,
    hearingAid: requiredNumber,
    alternativeMedicine: requiredNumber,
  });
};

export default validation;
