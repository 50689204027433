import { Col, Modal, Row } from "antd";
import { get } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "react-material-symbols/rounded";
import Alert from "shared/components/Alert";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import { NAME_SPACES } from "shared/locales/constants";
import InsurancePlan from "./InsurancePlan";

const DownloadOrSend = ({
  data,
  open,
  onClose,
  onSendQuote,
  onDownload,
  fields,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const [selectedData, setSelectedData] = useState(
    get(fields, "insurancePlans", [])
  );
  const [justifications, setJustifications] = useState(
    get(fields, "justifications", {})
  );

  const onCheck = (id, state) => {
    if (state) return setSelectedData((prev) => [...prev, id]);
    setSelectedData((prev) => [...prev.filter((item) => item !== id)]);
    onJustify(undefined, id);
  };

  const onJustify = (justification, insuranceId) =>
    setJustifications((prev) => ({
      ...prev,
      [insuranceId]: justification,
    }));

  return (
    <Modal
      open={open}
      onCancel={onClose}
      width={"80%"}
      justify={"center"}
      footer={
        <Row gutter={[8, 8]} justify={"center"} align={"middle"}>
          <Col>
            <Button
              onClick={() => {
                onDownload({
                  selectedData,
                  payload: {
                    locked: true,
                    fields: {
                      ...fields,
                      insurancePlans: selectedData,
                      justifications,
                    },
                  },
                });
                onClose();
              }}
              disabled={!selectedData.length}
              type="primary--outlined"
            >
              {DETAIL.TEMPLATES.COMPARISON.DOWNLOAD}
              <Icon icon="download" size={16} />
            </Button>
          </Col>
          <Col>
            <span>{DETAIL.TEMPLATES.COMPARISON.DOWNLOAD_OR_SEND.OR}</span>
          </Col>
          <Col>
            <Button
              onClick={() => {
                onSendQuote({
                  selectedData,
                  payload: {
                    fields: {
                      ...fields,
                      insurancePlans: selectedData,
                      justifications,
                    },
                  },
                });
                onClose();
              }}
              disabled={!selectedData.length}
              type="primary"
            >
              {DETAIL.TEMPLATES.COMPARISON.SEND_BY_EMAIL}
              <Icon icon="forward_to_inbox" size={16} />
            </Button>
          </Col>
        </Row>
      }
    >
      <Card type="simple">
        <Row gutter={[16, 16]} justify={"center"}>
          <div>
            <div className="download__header">
              {DETAIL.TEMPLATES.COMPARISON.DOWNLOAD_OR_SEND.TITLE}
            </div>
            <Alert
              message={DETAIL.TEMPLATES.COMPARISON.DOWNLOAD_OR_SEND.SUGGESTION}
              showIcon
            />
          </div>
          {data.map((item, index) => (
            <Col span={24} key={"product-" + index}>
              <InsurancePlan
                onCheck={(state) => onCheck(item?.insurancePlan?.id, state)}
                checked={selectedData.includes(item?.insurancePlan?.id)}
                logo={item.insurancePlan.icon}
                name={item.insurancePlan.name}
                description={item.insurancePlan.description}
                cost={item.cost}
                currency={item.currency}
                translation={DETAIL.TEMPLATES.COMPARISON.DOWNLOAD_OR_SEND}
                justification={justifications[item?.insurancePlan?.id]}
                onJustify={(justification) =>
                  onJustify(justification, item?.insurancePlan?.id)
                }
                disabled={selectedData.length > 3}
              />
            </Col>
          ))}
        </Row>
      </Card>
    </Modal>
  );
};

export default DownloadOrSend;
