import { Modal } from "antd";
import PropTypes from "prop-types";
import { TYPES } from "utils/constants";
import Button from "../Button";

const ModalColors = {
  [TYPES.DEFAULT]: {
    ok: "secondary--light",
    cancel: "default",
  },
  [TYPES.INFO]: {
    ok: "primary--light",
    cancel: "primary--link",
  },
  [TYPES.DANGER]: {
    ok: "success--light",
    cancel: "success--link",
  },
  [TYPES.SUCCESS]: {
    ok: "danger--light",
    cancel: "danger--link",
  },
  [TYPES.WARNING]: {
    ok: "warning--light",
    cancel: "warning--link",
  },
};

const Dialog = ({
  okText,
  cancelText,
  onOk,
  onCancel,
  message,
  child,
  visible,
  type,
  ...rest
}) => (
  <Modal
    className="modal-container"
    open={visible}
    onCancel={onCancel}
    footer={
      <>
        <Button type={ModalColors[type].cancel} onClick={onCancel}>
          {cancelText}
        </Button>
        <Button type={ModalColors[type].ok} onClick={onOk}>
          {okText}
        </Button>
      </>
    }
    {...rest}
  >
    {message}
    {child}
  </Modal>
);

Dialog.propTypes = {
  visible: PropTypes.bool,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  message: PropTypes.string,
  type: PropTypes.oneOf(Object.values(TYPES)),
};
Dialog.defaultProps = {
  onOk: () => {},
  onCancel: () => {},
  type: "default",
};

export default Dialog;
