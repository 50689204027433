import { COMMISSION_TYPES } from "utils/constants";
import * as yup from "yup";

const validation = (messages) => {
  return yup.object().shape({
    type: yup.string().typeError(messages.REQUIRED).required(messages.REQUIRED),
    value: yup
      .number()
      .min(0, `${messages.MIN} 0`)
      .typeError(messages.REQUIRED)
      .required(messages.REQUIRED),
    withholding: yup.mixed().when("type", {
      is: (type) => type === COMMISSION_TYPES.WITHHOLDING,
      then: yup
        .number()
        .min(0, `${messages.MIN} 0`)
        .typeError(messages.REQUIRED)
        .required(messages.REQUIRED),
    }),
  });
};

export default validation;
