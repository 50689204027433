import { COMMISSION_TYPES } from "utils/constants";
import * as yup from "yup";

const validation = (messages) => {
  return yup.object().shape({
    commissions: yup.array().of(
      yup.object().shape({
        type: yup
          .string()
          .typeError(messages.REQUIRED)
          .required(messages.REQUIRED),
        value: yup
          .number()
          .min(0)
          .typeError(messages.REQUIRED)
          .required(messages.REQUIRED),
        withholding: yup.mixed().when("type", {
          is: (type) => type === COMMISSION_TYPES.WITHHOLDING,
          then: yup
            .number()
            .typeError(messages.REQUIRED)
            .min(0)
            .required(messages.REQUIRED),
        }),
      })
    ),
  });
};

export default validation;
