import Webhooks from "entities/Webhooks";
import { useState } from "react";
import View from "./View";

const List = () => {
  const [pagination, setPagination] = useState({});
  const filter = { where: {} };

  return (
    <Webhooks
      View={View}
      filter={{ ...filter, ...pagination }}
      setValues={setPagination}
      skip={pagination.skip || 0}
    />
  );
};

export default List;
