import PropTypes from "prop-types";
import { COLORS } from "shared/style/colors";

const Status = ({ style, text, icon }) => {
  return (
    <div className={`status__wrap`} style={style}>
      {icon}
      <span> {text}</span>
    </div>
  );
};

Status.propTypes = {
  text: PropTypes.string.isRequired,
  style: PropTypes.object,
};

Status.defaultProps = {
  style: {
    background: COLORS.C_BACKGROUND,
    color: COLORS.C_BLACK,
  },
};

export default Status;
