import gql from "graphql-tag";

export const CREATE_BROKER = gql`
  mutation addBroker($data: BrokerDataInput) {
    addBroker(data: $data) {
      id
    }
  }
`;

export const UPDATE_BROKER = gql`
  mutation updateBroker($data: BrokerDataInput, $where: BrokerWhereInput) {
    updateBroker(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_BROKER = gql`
  mutation deleteBroker($where: BrokerWhereInput) {
    deleteBroker(where: $where) {
      id
    }
  }
`;

const brokers = { CREATE_BROKER, UPDATE_BROKER, DELETE_BROKER };

export default brokers;
