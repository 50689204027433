import gql from "graphql-tag";

export const CREATE_COMMISSION = gql`
  mutation AddCommission($data: CommissionDataInput) {
    addCommission(data: $data) {
      id
    }
  }
`;

export const CREATE_COMMISSIONS = gql`
  mutation AddCommissions($data: [CommissionDataInput]) {
    addCommissions(data: $data) {
      id
    }
  }
`;

export const UPDATE_COMMISSION = gql`
  mutation UpdateCommission(
    $data: CommissionDataInput
    $where: CommissionWhereInput
  ) {
    updateCommission(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_COMMISSION = gql`
  mutation DeleteCommission($where: CommissionWhereInput) {
    deleteCommission(where: $where) {
      id
    }
  }
`;

const commissions = {
  CREATE_COMMISSION,
  CREATE_COMMISSIONS,
  UPDATE_COMMISSION,
  DELETE_COMMISSION,
};

export default commissions;
