import cuid from "cuid";
import omitDeep from "omit-deep-lodash";
import { useNavigate } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { COMMISSION_STATEMENTS } from "utils/api/graphql/queries/commission-statements";
import { INITIAL_VALUES, SCREENS } from "utils/constants";
import View from "./View";

const Create = () => {
  const navigate = useNavigate();

  const cancel = () =>
    navigate(SCREENS.PRIVATE.ADMIN.COMMISSION_STATEMENTS.LIST.path);
  const [addCommissionStatement] = useMutation(
    graphql.mutations.CREATE_COMMISSION_STATEMENT,
    {
      refetchQueries: [
        {
          query: COMMISSION_STATEMENTS,
          awaitRefetchQueries: true,
          variables: {
            orderBy: [{ year: "DESC", month: "DESC" }],
            ...INITIAL_VALUES,
          },
        },
      ],
      onCompleted: () => {
        navigate(SCREENS.PRIVATE.ADMIN.COMMISSION_STATEMENTS.LIST.path);
      },
      onError: () => {},
    }
  );
  const onSubmit = ({ month, year, ...values }) => {
    Object.entries(values).forEach(([year, months]) => {
      Object.entries(months).forEach(([month, brokers]) =>
        Object.entries(brokers).forEach(([brokerId, attachment]) =>
          addCommissionStatement({
            variables: {
              data: {
                id: cuid(),
                month,
                year,
                broker: { id: brokerId },
                attachment: omitDeep(attachment, "uid"),
              },
            },
          })
        )
      );
    });
  };
  return <View onSubmit={onSubmit} cancel={cancel} />;
};

export default Create;
