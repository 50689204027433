import gql from "graphql-tag";

export const CREATE_PROJECT = gql`
  mutation AddProject($data: ProjectDataInput) {
    addProject(data: $data) {
      id
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($where: ProjectWhereInput, $data: ProjectDataInput) {
    updateProject(where: $where, data: $data) {
      id
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation DeleteProject($where: ProjectWhereInput) {
    deleteProject(where: $where) {
      id
    }
  }
`;

export const DUPLICATE_PROJECT = gql`
  mutation duplicateProject($where: ProjectWhereInput) {
    duplicateProject(where: $where) {
      id
    }
  }
`;

const projects = {
  CREATE_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  DUPLICATE_PROJECT,
};

export default projects;
