import { Divider, Tag } from "antd";
import { get, isEmpty, isNull } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { BsPencil } from "react-icons/bs";
import { useNavigate } from "react-router";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Descriptions from "shared/components/Descriptions";

import { NAME_SPACES } from "shared/locales/constants";
import { DATE_FORMAT, SCREENS } from "utils/constants";

const Detail = ({ data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.INSURANCE_PLAN);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });

  const ProductInfo = [
    {
      key: FORM.ID,
      label: FORM.ID,
      children: data.id || "-",
    },
    {
      key: FORM.NAME,
      label: FORM.NAME,
      children: data.name || "-",
    },
    {
      key: FORM.DESCRIPTION,
      label: FORM.DESCRIPTION,
      children: data.description || "-",
    },
    {
      key: FORM.ALIAS,
      label: FORM.ALIAS,
      children: data.alias || "-",
    },
    {
      key: FORM.START_DATE,
      label: FORM.START_DATE,
      children: data.startDate
        ? moment(data.startDate).format(DATE_FORMAT)
        : "-",
    },
    {
      key: FORM.END_DATE,
      label: FORM.END_DATE,
      children: data.endDate ? moment(data.endDate).format(DATE_FORMAT) : "-",
    },
    {
      key: FORM.ICON,
      label: FORM.ICON,
      children: get(data, "icon", "-") ? (
        <img className="controlled" alt="icon" src={get(data, "icon")} />
      ) : (
        "-"
      ),
    },
    {
      key: FORM.MADELIN,
      label: FORM.MADELIN,
      children: FORM[get(data, "fields.madelin", false) ? "YES" : "NO"],
    },
  ];

  const ProductParams = (data) => [
    {
      key: FORM.CONDITIONS.MIN_ISSUANCE_DATE,
      label: FORM.CONDITIONS.MIN_ISSUANCE_DATE,
      children: get(data, "deltaStart")
        ? `${FORM.CONDITIONS.TODAY} + ${get(data, "deltaStart", 0)} ${
            FORM.CONDITIONS.DAYS
          }`
        : 0,
    },
    {
      key: FORM.CONDITIONS.MAX_ISSUANCE_DATE,
      label: FORM.CONDITIONS.MAX_ISSUANCE_DATE,
      children: isNull(get(data, "deltaEnd"))
        ? "-"
        : get(data, "deltaEnd")
        ? `${FORM.CONDITIONS.TODAY} + ${get(data, "deltaEnd")} ${
            FORM.CONDITIONS.DAYS
          }`
        : FORM.CONDITIONS.MAX_ISSUANCE_DATE_OPTIONS.END_OF_YEAR,
    },
    {
      key: FORM.CONDITIONS.MIN_ISSUANCE_DATE_RIA,
      label: FORM.CONDITIONS.MIN_ISSUANCE_DATE_RIA,
      children: get(data, "deltaStartRIA")
        ? `${FORM.CONDITIONS.TODAY} + ${get(data, "deltaStartRIA")} ${
            FORM.CONDITIONS.DAYS
          }`
        : 0,
    },
    {
      key: FORM.CONDITIONS.MAX_ISSUANCE_DATE_RIA,
      label: FORM.CONDITIONS.MAX_ISSUANCE_DATE_RIA,
      children: isNull(get(data, "deltaEndRIA"))
        ? "-"
        : get(data, "deltaEndRIA")
        ? `${FORM.CONDITIONS.TODAY} + ${get(data, "deltaEndRIA")} ${
            FORM.CONDITIONS.DAYS
          }`
        : FORM.CONDITIONS.MAX_ISSUANCE_DATE_OPTIONS.END_OF_YEAR,
    },
    {
      key: FORM.CONDITIONS.MIN_AGE,
      label: FORM.CONDITIONS.MIN_AGE,
      children: get(data, "minAge") || "-",
    },
    {
      key: FORM.CONDITIONS.MAX_AGE,
      label: FORM.CONDITIONS.MAX_AGE,
      children: get(data, "maxAge") || "-",
    },
    {
      key: FORM.CONDITIONS.SOCIAL_REGIMES,
      label: FORM.CONDITIONS.SOCIAL_REGIMES,
      span: 2,
      children: isEmpty(get(data, "socialRegimes")) ? (
        "-"
      ) : (
        <div className="d-flex flex--wrap">
          {get(data, "socialRegimes").map((regime) => (
            <Tag key={regime}>
              {FORM.CONDITIONS.SOCIAL_REGIMES_OPTIONS[regime]}
            </Tag>
          ))}
        </div>
      ),
    },
  ];

  return (
    <Card type="simple">
      <Descriptions title={FORM.GENERAL_INFO} items={ProductInfo} />
      {get(data, "params", []).map((item, index) => (
        <div key={`params-${index}`}>
          <Divider />
          <Descriptions
            title={!index && FORM.CONDITIONS.TITLE}
            items={ProductParams(item)}
            column={{ xs: 1, sm: 2, md: 4 }}
          />
        </div>
      ))}
      <div className="d-flex justify--end">
        <Button
          type="primary--outlined"
          icon={<BsPencil />}
          onClick={() =>
            navigate(
              `${SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.EDIT.path}/${data.id}`
            )
          }
        >
          {DETAIL.EDIT}
        </Button>
      </div>
    </Card>
  );
};

export default Detail;
