import PropTypes from "prop-types";
import Loader from "shared/components/Loader";
import { useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";

const Brokers = ({ View, filter, ...props }) => {
  const variables = {
    ...filter,
    skip: parseInt(filter.skip),
    take: parseInt(filter.take),
  };
  const query = useQuery(graphql.queries.BROKERS, {
    variables,
  });
  const WrappedView = Loader(View);
  return (
    <WrappedView
      {...{
        ...props,
        ...query,
        type: "brokers",
      }}
    />
  );
};

Brokers.propTypes = {
  View: PropTypes.func.isRequired,
  filter: PropTypes.object,
};

Brokers.defaultProps = {
  filter: {},
};

export default Brokers;
