import { Upload } from "antd";
import { BsCloudDownload } from "react-icons/bs";
import { COLORS } from "shared/style/colors";

const { Dragger } = Upload;

const Component = ({
  children,
  handleFileUpload,
  onFileRemove,
  onUpload,
  listType,
  ...rest
}) => (
  <div className="file-dragger--wrapper">
    <div className="dragger__wrapper">
      <Dragger
        onChange={async (info) => {
          const fileList = await handleFileUpload(info);
          onUpload(fileList);
        }}
        onRemove={onFileRemove}
        listType={listType}
        {...rest}
      >
        <div className="dragger__wrapper--icon">
          <BsCloudDownload size={24} color={COLORS.C_PRIMARY} />
        </div>
        <div className="dragger__wrapper--content">
          <p>Glissez votre fichier ici</p>
          <span className="dragger__wrapper--text">
            ou cliquez pour sélectionner depuis le répertoire
          </span>
        </div>
      </Dragger>
    </div>
  </div>
);

export default Component;
