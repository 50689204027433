import PropTypes from "prop-types";
import { COMPONENTS_SIZES } from "utils/constants";

const TYPES = ["normal", "bordered"];

const Image = ({ image, alt, size, type }) => {
  return (
    <img
      src={image}
      className={`avatar--image avatar--${size} avatar--${type} `}
      alt={alt}
    />
  );
};

Image.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(COMPONENTS_SIZES)),
  type: PropTypes.oneOf(TYPES),
};

Image.defaultProps = {
  size: "large",
  type: "normal",
};

export default Image;
