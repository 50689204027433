import { useMemo } from "react";

import PropTypes from "prop-types";

import { message, Upload } from "antd";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import { convertToBase64 } from "utils/helpers/input";
import Dragger from "./Dragger";
import Uploader from "./Uploader";

const VARIANT = {
  uploader: Uploader,
  dragger: Dragger,
};

const File = ({ variant, onFileRemove, validTypes = [], ...props }) => {
  const { t } = useTranslation(NAME_SPACES.ERRORS);
  const VALIDATIONS = t("VALIDATIONS", { returnObjects: true });
  const View = useMemo(() => VARIANT[variant], [variant]);

  const handleFileUpload = async ({ file, fileList }) => {
    const { name, type, originFileObj } = file;
    return Promise.all(
      fileList.map(async (file) => {
        if (file.base64 || file.url) return file;
        const base64 = await convertToBase64(originFileObj);
        return { name, contentType: type, base64 };
      })
    );
  };

  const beforeUpload = (file) => {
    const isValidFormat = isEmpty(validTypes) || validTypes.includes(file.type);
    const isValidSize = file.size / 1024 / 1024 <= 5;
    if (!isValidFormat) message.error(VALIDATIONS.FILE.FORMAT);
    if (!isValidSize) message.error(VALIDATIONS.FILE.SIZE);
    return (isValidFormat && isValidSize) || Upload.LIST_IGNORE;
  };

  const customRequest = ({ onSuccess }) => {
    onSuccess(true);
  };

  return (
    <View
      handleFileUpload={handleFileUpload}
      beforeUpload={beforeUpload}
      customRequest={customRequest}
      onFileRemove={onFileRemove}
      listType="picture"
      {...props}
    />
  );
};

File.propTypes = {
  variant: PropTypes.oneOf(Object.keys(VARIANT)),
  onFileRemove: PropTypes.func,
};

File.defaultProps = {
  variant: "uploader",
  onFileRemove: () => {},
};

export default File;
