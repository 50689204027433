import Card from "antd/es/card/Card";
import Commissions from "entities/Commissions";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Header from "shared/components/Header";
import { NAME_SPACES } from "shared/locales/constants";
import { SCREENS } from "utils/constants";
import View from "./View";

const List = () => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.COMMISSION);
  const LIST = t("LIST", { returnObjects: true });
  const [pagination, setPagination] = useState({});

  const navigate = useNavigate();

  const buttons = [
    {
      icon: <MdAdd size={24} />,
      text: LIST.HEADER.SAVE,
      onClick: () => navigate(SCREENS.PRIVATE.ADMIN.COMMISSIONS.CREATE.path),
    },
  ];

  return (
    <>
      <Header title={LIST.HEADER.TITLE} actions={buttons} />
      <Card type="simple">
        <Commissions
          View={View}
          filter={pagination}
          setValues={setPagination}
          skip={pagination.skip || 0}
          NoData={View}
        />
      </Card>
    </>
  );
};

export default List;
