import { Alert } from "antd";

const Component = ({ ...props }) => {
  return (
    <div className="alert--wrapper">
      <Alert className="alert-color" {...props} />
    </div>
  );
};

export default Component;
