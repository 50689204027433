import gql from "graphql-tag";

export const CREATE_NOTE = gql`
  mutation AddNote($data: NoteDataInput) {
    addNote(data: $data) {
      id
    }
  }
`;

export const DELETE_NOTE = gql`
  mutation DeleteNote($where: NoteWhereInput) {
    deleteNote(where: $where) {
      id
    }
  }
`;

const notes = { CREATE_NOTE, DELETE_NOTE };

export default notes;
