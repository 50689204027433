import PropTypes from "prop-types";
import { AiOutlineRight } from "react-icons/ai";
import Breadcrumb from "../Breadcrumb";

const Header = ({ title, subTitle, headerRight, breadcrumb }) => {
  return (
    <div className="header-wrapper">
      <div className={"header__actions"}>
        <div className={"header__actions--content"}>
          <h3 className={"title"}>{title}</h3>
          {subTitle && [
            <AiOutlineRight key="icon" />,
            <p key="text" className={"subtitle"}>
              {subTitle}
            </p>,
          ]}
        </div>
        <div className={"header__actions--btns"}>{headerRight}</div>
      </div>
      {breadcrumb && <Breadcrumb items={breadcrumb} />}
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  headerRight: PropTypes.arrayOf(PropTypes.node),
};
export default Header;
