import { isEmpty } from "lodash";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import NotFoundImage from "shared/assets/images/404.png";
import Button from "shared/components/Button";
import { NAME_SPACES } from "shared/locales/constants";
import { StoreContext } from "shared/store";
import { NAVIGATION_DIRECTIONS, SCREENS } from "utils/constants";

const NotFound = () => {
  const { user } = useContext(StoreContext);
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.COMPONENTS);
  const NOT_FOUND = t("NOT_FOUND", { returnObjects: true });

  useEffect(() => {
    if (isEmpty(user)) navigate(SCREENS.AUTH.SIGN_IN.path);
  }, [user]);
  const handleGoBack = () => navigate(NAVIGATION_DIRECTIONS.BACK);

  return (
    <div className="not-found__container">
      <div className="not-found__container--body">
        <img src={NotFoundImage} alt="" />
        <Button type="primary--link" onClick={handleGoBack}>
          {NOT_FOUND.GO_BACK}
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
