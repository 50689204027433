import Label from "@bit/taillislabs.pega.label";
import { Col, Row } from "antd";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Form from "shared/components/Form";
import Input from "shared/components/Form/Input";
import Select from "shared/components/Form/Select";
import Header from "shared/components/Header";
import { NAME_SPACES } from "shared/locales/constants";
import {
  CONTRACT_CONTENT_DERIVATIVE,
  CONTRACT_EXPIRATION_TYPES,
  CONTRACT_SCOPE,
  CONTRACT_TYPES,
  SCREENS,
} from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Detail = ({ onSubmit, cancel, contract }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const navigate = useNavigate();
  const DETAIL = t("DETAIL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const TYPE = t("TYPE", { returnObjects: true });
  const CONTENT_DERIVATIVE = t("CONTENT_DERIVATIVE", { returnObjects: true });
  const EXPIRATION_TYPES = t("EXPIRATION_TYPES", { returnObjects: true });
  const SCOPE = t("SCOPE", { returnObjects: true });
  return (
    <Formik
      initialValues={{
        name: contract.name,
        title: contract.title,
        subTitle: contract.subTitle,
        alias: contract.alias,
        url: contract.url,
        scope: contract.scope,
        type: contract.type,
        contentDerivative: contract.contentDerivative,
        expirationType: contract.expirationType,
        version: contract.version,
        instantiatesUri: contract.instantiatesUri,
      }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, ...formProps }) => [
        <Header
          key={"header"}
          title={DETAIL.HEADER.TITLE}
          subtitle={DETAIL.HEADER.ACTION}
          actions={[
            {
              text: DETAIL.HEADER.DISCARD,
              onClick: cancel,
              type: "gray",
            },
            {
              text: DETAIL.HEADER.DELETE,
              onClick: () =>
                navigate(
                  `${SCREENS.PRIVATE.BROKER.CONTRACTS.DELETE.path}/${contract.id}`
                ),
              type: "danger",
            },
            {
              text: DETAIL.HEADER.SAVE,
              onClick: handleSubmit,
            },
          ]}
        />,
        <Row gutter={[16, 16]} key={"row"}>
          <Col span={24}>
            <Form title={FORM.GENERAL_INFO}>
              <Col lg={24}>
                <Label placeholder={FORM.ID} value={contract.id} />
              </Col>
              <Col lg={8}>
                <Input
                  placeholder={FORM.NAME}
                  {...bindInputProps({ name: "name", ...formProps })}
                />
              </Col>
              <Col lg={8}>
                <Input
                  placeholder={FORM.TITLE}
                  {...bindInputProps({ name: "title", ...formProps })}
                />
              </Col>
              <Col lg={8}>
                <Input
                  placeholder={FORM.SUBTITLE}
                  {...bindInputProps({ name: "subTitle", ...formProps })}
                />
              </Col>
              <Col lg={12}>
                <Input
                  placeholder={FORM.ALIAS}
                  {...bindInputProps({ name: "alias", ...formProps })}
                />
              </Col>
              <Col lg={12}>
                <Input
                  placeholder={FORM.URL}
                  {...bindInputProps({ name: "url", ...formProps })}
                />
              </Col>
              <Col lg={12}>
                <Select
                  items={CONTRACT_SCOPE.map((el) => ({
                    label: SCOPE[el],
                    value: el,
                  }))}
                  placeholder={FORM.SCOPE}
                  {...bindInputProps({ name: "scope", ...formProps })}
                />
              </Col>
              <Col lg={12}>
                <Select
                  items={Object.values(CONTRACT_TYPES).map((el) => ({
                    label: TYPE[el],
                    value: el,
                  }))}
                  placeholder={FORM.TYPE}
                  {...bindInputProps({ name: "type", ...formProps })}
                />
              </Col>
              <Col lg={12}>
                <Select
                  items={CONTRACT_CONTENT_DERIVATIVE.map((el) => ({
                    label: CONTENT_DERIVATIVE[el],
                    value: el,
                  }))}
                  placeholder={FORM.CONTENT_DERIVATIVE}
                  {...bindInputProps({
                    name: "contentDerivative",
                    ...formProps,
                  })}
                />
              </Col>
              <Col lg={12}>
                <Select
                  items={CONTRACT_EXPIRATION_TYPES.map((el) => ({
                    label: EXPIRATION_TYPES[el],
                    value: el,
                  }))}
                  placeholder={FORM.EXPIRATION_TYPE}
                  {...bindInputProps({ name: "expirationType", ...formProps })}
                />
              </Col>
              <Col lg={12}>
                <Input
                  placeholder={FORM.VERSION}
                  {...bindInputProps({ name: "version", ...formProps })}
                />
              </Col>
              <Col lg={12}>
                <Input
                  placeholder={FORM.INSTANTIATES_URI}
                  {...bindInputProps({ name: "instantiatesUri", ...formProps })}
                />
              </Col>
            </Form>
          </Col>
        </Row>,
      ]}
    </Formik>
  );
};

export default Detail;
