import gql from "graphql-tag";

export const CREATE_ATTACHMENT = gql`
  mutation AddAttachment($data: AttachmentDataInput) {
    addAttachment(data: $data) {
      id
      key
      name
      fileUrl
      contentType
      type
      filename
    }
  }
`;

export const GENERATE_QUOTE_DOCUMENT = gql`
  mutation GenerateQuoteDocument($data: AttachmentPayload) {
    generateQuoteDocument(data: $data) {
      id
      name
      fileUrl
      contentType
    }
  }
`;

export const GENERATE_DUTY_OF_ADVICE_QUOTE_DOCUMENT = gql`
  mutation GenerateDutyOfAdviceQuoteDocument($data: AttachmentPayload) {
    generateDutyOfAdviceQuoteDocument(data: $data) {
      id
      name
      fileUrl
      contentType
    }
  }
`;

export const GENERATE_DUTY_OF_ADVICE_DOCUMENT = gql`
  mutation GenerateDutyOfAdviceDocument($data: AttachmentPayload) {
    generateDutyOfAdviceDocument(data: $data) {
      id
      name
      fileUrl
      contentType
      base64
    }
  }
`;

export const GENERATE_SEPA_DIRECT_DEBIT_MANDATE_DOCUMENT = gql`
  mutation GenerateSepaDirectDebitMandateDocument($data: AttachmentPayload) {
    generateSepaDirectDebitMandateDocument(data: $data) {
      id
      name
      fileUrl
      contentType
      base64
    }
  }
`;

export const GENERATE_TERMINATION_MANDATE_DOCUMENT = gql`
  mutation GenerateTerminationMandateDocument($data: AttachmentPayload) {
    generateTerminationMandateDocument(data: $data) {
      id
      name
      fileUrl
      contentType
      base64
    }
  }
`;

export const GENERATE_TERMINATION_LETTER_DOCUMENT = gql`
  mutation GenerateTerminationLetterDocument($data: AttachmentPayload) {
    generateTerminationLetterDocument(data: $data) {
      id
      name
      fileUrl
      contentType
      base64
    }
  }
`;

export const GENERATE_MEMBERSHIP_FORM_DOCUMENT = gql`
  mutation GenerateMembershipFormDocument($data: AttachmentPayload) {
    generateMembershipFormDocument(data: $data) {
      id
      name
      fileUrl
      contentType
      base64
    }
  }
`;

export const GENERATE_ESIGN_DOCUMENTS = gql`
  mutation GenerateEsingDocuments($data: SubscriptionWhereInput) {
    generateEsingDocuments(data: $data) {
      base64
      attachments {
        id
      }
    }
  }
`;

export const GENERATE_QUOTE_DOCUMENTS = gql`
  mutation GenerateQuoteDocuments($data: QuoteDocumentsWhereInput) {
    generateQuoteDocuments(data: $data) {
      base64
      attachments {
        id
      }
    }
  }
`;

export const DELETE_ATTACHMENT = gql`
  mutation DeleteAttachment($where: AttachmentWhereInput) {
    deleteAttachment(where: $where) {
      id
    }
  }
`;

const attachments = {
  CREATE_ATTACHMENT,
  DELETE_ATTACHMENT,
  GENERATE_DUTY_OF_ADVICE_DOCUMENT,
  GENERATE_DUTY_OF_ADVICE_QUOTE_DOCUMENT,
  GENERATE_QUOTE_DOCUMENT,
  GENERATE_MEMBERSHIP_FORM_DOCUMENT,
  GENERATE_TERMINATION_MANDATE_DOCUMENT,
  GENERATE_TERMINATION_LETTER_DOCUMENT,
};

export default attachments;
