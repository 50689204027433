import { SCREENS } from "utils/constants";
import Contacts from "./Contacts";
import Quotes from "./Quotes";

const screens = [
  // {
  //   exact: false,
  //   path: SCREENS.PUBLIC.INDEX.path,
  //   element: <Welcome />,
  // },
  {
    exact: false,
    path: SCREENS.PUBLIC.CONTACTS.INDEX.path,
    element: <Contacts />,
  },
  ...Quotes,
];

export default screens;
