import { get } from "lodash";
import Icon from "react-material-symbols/rounded";
import { Link } from "react-router-dom";
import { COLORS } from "shared/style/colors";
import { COVERAGE_LIMIT_CATEGORIES } from "utils/constants";
import SupportingInfoItem from "./SupportingInfoItem";

const HoverCard = ({
  coverageBenefit,
  translation,
  setHover,
  toggleHighlighted,
}) => {
  const { supportingInformation, limits = [{ value: {} }] } = coverageBenefit;
  const coverageBenefitLimitValue = (value) => ({
    [COVERAGE_LIMIT_CATEGORIES.PERCENTAGE]: `${value} % BR`,
    [COVERAGE_LIMIT_CATEGORIES.COST]: `${value} €`,
    [COVERAGE_LIMIT_CATEGORIES.COST_PER_YEAR]: `${translation.TEMPLATES.COMPARISON.UP_TO} ${value} € / ${translation.TEMPLATES.COMPARISON.YEAR}`,
    [COVERAGE_LIMIT_CATEGORIES.TEXT]: value,
  });

  return (
    <div className="card-hover">
      <div className="card-hover__header">
        <div className="card-hover__header--title">{coverageBenefit.type}</div>
        <Link onClick={() => setHover(false)}>
          {translation.TEMPLATES.COMPARISON.HIDE}
        </Link>
        <div
          className="card-hover__header--icon fav"
          onClick={() => toggleHighlighted(coverageBenefit.id)}
        >
          <Icon icon="favorite" size={24} color={COLORS.C_PRIMARY}>
            {coverageBenefit.type}
          </Icon>
        </div>
      </div>
      <div className="card-hover__body">
        <div className="card-hover__body--title">
          {limits.map(({ id, value }) => (
            <span key={id}>
              {
                coverageBenefitLimitValue(get(value, "value", "-"))[
                  get(value, "category", COVERAGE_LIMIT_CATEGORIES.TEXT)
                ]
              }
            </span>
          ))}
        </div>
        {supportingInformation.map((information) => {
          const { id, category, value } = information;
          return (
            <SupportingInfoItem
              key={id}
              category={category}
              value={value}
              translation={translation}
            />
          );
        })}
      </div>
    </div>
  );
};

export default HoverCard;
