import { Radio as RadioInput } from "antd";

const Radio = ({
  label,
  icon,
  options = [],
  errors,
  touched,
  isHorizontal = true,
  ...rest
}) => {
  return (
    <div className={`radio-group ${isHorizontal && "group--horizental"}`}>
      <div className="radio-group__content">
        <div className="radio-group__content--icon" hidden={!icon}>
          {icon}
        </div>
        <label className="radio-group__content--label">{label}</label>
      </div>
      <div className="radio-group__radio">
        <RadioInput.Group
          className="radio-group__radio--radio"
          options={options}
          optionType="button"
          buttonStyle="solid"
          {...rest}
        />
        {touched && (
          <small className={"radio-group__radio--error"}>{errors}</small>
        )}
      </div>
    </div>
  );
};

export default Radio;
