import Icon from "@ant-design/icons";
import CheckCircleFilled from "@ant-design/icons/CheckCircleFilled";
import { Row, Tag } from "antd";
import Qualifiers from "entities/Qualifiers";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";

const Qualifier = ({ qualifier, setFilter, filter, translation }) => {
  const { data } = useQuery(graphql.queries.QUALIFICATIONS, {
    variables: {
      where: {
        qualifier: { id: qualifier.id },
        last: true,
      },
    },
  });

  return (
    <Tag
      icon={
        qualifier.id === get(filter, "where.qualifier.id") ? (
          <Icon component={CheckCircleFilled} key={qualifier} />
        ) : null
      }
      key={qualifier.id}
      onClick={() => {
        setFilter((filter) => ({
          ...filter,
          where: {
            ...filter.where,
            qualifier: { id: qualifier.id },
          },
        }));
      }}
    >
      {`${translation[qualifier.name]} (${get(
        data,
        "qualifications.count",
        ""
      )})`}
    </Tag>
  );
};

const List = ({ setFilter, filter, translation }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.QUALIFICATION);
  const LIST = t("LIST", { returnObjects: true });
  return (
    <Row>
      <Tag
        onClick={() =>
          setFilter((filter) => ({
            ...filter,
            where: {
              ...filter.where,
              qualifier: undefined,
            },
          }))
        }
      >
        {LIST.FILTERS.RESET}
      </Tag>
      <Qualifiers
        View={(props) => {
          return props.data.map((qualifier) => (
            <Qualifier
              key={qualifier.id}
              qualifier={qualifier}
              setFilter={setFilter}
              filter={filter}
              translation={translation}
            />
          ));
        }}
      />
    </Row>
  );
};

export default List;
