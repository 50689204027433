import { findKey, isEmpty } from "lodash";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import Loading from "shared/components/Spin";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import { StoreContext } from "shared/store";
import graphql from "utils/api/graphql";
import { SIGN_IN } from "utils/api/graphql/mutations/users";
import { SCREENS, USER_TYPES } from "utils/constants";
import View from "./View";

const SignIn = () => {
  const navigate = useNavigate();
  const { setUser, setAccessToken, accessToken } = useContext(StoreContext);

  const [checkUser, { loading }] = useLazyQuery(graphql.queries.CHECK_USER, {
    fetchPolicy: "no-cache",
    onCompleted: ({ profile }) => onSignIn(profile),
  });

  const [signIn] = useMutation(SIGN_IN);

  const onSubmit = ({ username, password }) => {
    signIn({
      variables: {
        data: {
          username,
          password,
        },
      },
      onCompleted: ({ signIn }) =>
        setAccessToken({ accessToken: signIn?.accessToken }),
    });
  };

  useEffect(() => {
    if (accessToken) checkUser();
  }, [checkUser, accessToken]);

  const onSignIn = (user) => {
    if (isEmpty(user)) return;
    setUser({ user });
    navigate(
      USER_TYPES[
        findKey(USER_TYPES, (element) => element.type === user?.meta?.type)
      ].index
    );
  };

  const onLinkClick = () => navigate(SCREENS.AUTH.FORGOT_PASSWORD.path);

  if (loading) return <Loading />;
  return <View onSubmit={onSubmit} onLinkClick={onLinkClick} />;
};

export default SignIn;
