import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft } from "react-icons/bi";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Details = ({ onSubmit, cancel, data }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.FLOW);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const { name, tracers = [], process } = data;
  return (
    <Formik
      initialValues={{
        name,
        process: { id: process?.id },
        tracers: tracers.map((tracer) => tracer?.id),
      }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, setFieldValue, ...formProps }) => [
        <Header
          key={"header"}
          title={DETAIL.HEADER.TITLE}
          subtitle={DETAIL.HEADER.ACTION}
          actions={[
            {
              text: DETAIL.HEADER.DISCARD,
              onClick: cancel,
              type: "primary--outlined",
              icon: <BiChevronLeft size={20} />,
            },
            {
              text: DETAIL.HEADER.SAVE,
              onClick: handleSubmit,
              type: "primary",
              icon: <BiCheck size={20} />,
            },
          ]}
        />,
        <Card key="card" type={"simple"}>
          <div className="grid--2">
            <Input
              label={FORM.NAME}
              placeholder={FORM.NAME}
              {...bindInputProps({ name: "name", ...formProps })}
            />
            <Select
              type="async"
              mode="multiple"
              node={{
                query: graphql.queries.TRACERS,
                variables: { where: {} },
                accessors: { root: "tracers.data" },
              }}
              placeholder={FORM.TRACERS}
              label={FORM.TRACERS}
              {...bindInputProps({ name: "tracers", ...formProps })}
              onChange={(values) => setFieldValue("tracers", values)}
            />
            <Select
              type="async"
              node={{
                query: graphql.queries.PROCESSES,
                variables: { where: {} },
                accessors: { root: "processes.data" },
              }}
              placeholder={FORM.PROCESS}
              label={FORM.PROCESS}
              {...bindInputProps({ name: "process.id", ...formProps })}
            />
          </div>
        </Card>,
      ]}
    </Formik>
  );
};

export default Details;
