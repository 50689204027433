import cuid from "cuid";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { SCREENS } from "utils/constants";
import View from "./View";

const Create = () => {
  const navigate = useNavigate();
  const id = useMemo(() => cuid(), []);
  const cancel = () => navigate(SCREENS.PRIVATE.BROKER.USERS.LIST.path);
  const [addUser] = useMutation(graphql.mutations.CREATE_USER, {
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.BROKER.USERS.LIST.path);
    },
    onError: () => {},
  });
  const onSubmit = (values) => {
    return addUser({
      variables: {
        data: {
          id,
          ...values,
        },
      },
    });
  };
  return <View onSubmit={onSubmit} id={id} cancel={cancel} />;
};

export default Create;
