import { gql } from "@apollo/client";

const INSURANCE_PLAN_ADDED = gql`
  subscription insurancePlanAdded($where: InsurancePlanWhereInput) {
    insurancePlanAdded(where: $where) {
      id
      name
      alias
      icon
      description
      params {
        id
        minAge
        maxAge
        deltaStart
        deltaEnd
        deltaStartRIA
        deltaEndRIA
        socialRegimes
        exerciseFrame
      }
      fields
      startDate
      endDate
      coverages {
        id
        type
        identifier
        waitingDays
        coverageBenefits {
          id
          type
          requirement
          category
          limits {
            id
            code
            value
          }
          supportingInformation {
            id
            category
            value
          }
        }
      }
      commissions {
        id
        type
        value
        withholding
        broker {
          id
          name
        }
      }
      attachments {
        id
        type
        contentType
        name
        filename
      }
    }
  }
`;

const INSURANCE_PLAN_UPDATED = gql`
  subscription insurancePlanUpdated($where: InsurancePlanWhereInput) {
    insurancePlanUpdated(where: $where) {
      id
      name
      alias
      icon
      description
      params {
        id
        minAge
        maxAge
        deltaStart
        deltaEnd
        deltaStartRIA
        deltaEndRIA
        socialRegimes
        exerciseFrame
      }
      fields
      startDate
      endDate
      coverages {
        id
        type
        identifier
        waitingDays
        coverageBenefits {
          id
          type
          requirement
          category
          limits {
            id
            code
            value
          }
          supportingInformation {
            id
            category
            value
          }
        }
      }
      commissions {
        id
        type
        value
        withholding
        broker {
          id
          name
        }
      }
      attachments {
        id
        type
        contentType
        name
        filename
      }
    }
  }
`;

const INSURANCE_PLAN_DELETED = gql`
  subscription insurancePlanDeleted($where: InsurancePlanWhereInput) {
    insurancePlanDeleted(where: $where) {
      id
      name
      alias
      icon
      description
      params {
        id
        minAge
        maxAge
        deltaStart
        deltaEnd
        deltaStartRIA
        deltaEndRIA
        socialRegimes
        exerciseFrame
      }
      fields
      startDate
      endDate
      coverages {
        id
        type
        identifier
        waitingDays
        coverageBenefits {
          id
          type
          requirement
          category
          limits {
            id
            code
            value
          }
          supportingInformation {
            id
            category
            value
          }
        }
      }
      commissions {
        id
        type
        value
        withholding
        broker {
          id
          name
        }
      }
      attachments {
        id
        type
        contentType
        name
        filename
      }
    }
  }
`;

const products = {
  INSURANCE_PLAN_ADDED,
  INSURANCE_PLAN_UPDATED,
  INSURANCE_PLAN_DELETED,
};

export default products;
