import { useMemo } from "react";

import PropTypes from "prop-types";
import Dialog from "./Dialog";
import Simple from "./Simple";

const MODES = {
  simple: Simple,
  dialog: Dialog,
};

const Modal = ({ mode, ...props }) => {
  const View = useMemo(() => MODES[mode], [mode]);
  return <View {...props} />;
};

Modal.propTypes = {
  mode: PropTypes.oneOf(Object.keys(MODES)),
};

Modal.defaultProps = {
  mode: "dialog",
};

export default Modal;
