import { Col, Row, notification } from "antd";
import { useFormik } from "formik";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "react-material-symbols/rounded";
import { useBeforeUnload } from "react-router-dom";
import Header from "shared/components/Header";
import Modal from "shared/components/Modal";
import Tabs from "shared/components/Tab";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import { RELATIONSHIP } from "utils/constants";
import { STEPPER_KEYS } from ".";
import AdditionalInformation from "./Widgets/AdditionalInformation";
import validation from "./Widgets/AdditionalInformation/validation";
import Document from "./Widgets/Document";
import Proof from "./Widgets/Proof";

const View = ({
  onFormSubmit,
  data,
  signDocument,
  signLoading,
  active,
  setActive,
  progress,
  setProgress,
  disabled,
  setDisabled,
  setPercent,
  realProgress,
  calculateProgressMean,
  updateSubscription,
  initialValues,
  checkUser,
}) => {
  const { subscription } = data;
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });
  const [modalKey, setModalKey] = useState(null);

  useEffect(() => {
    calculateProgressMean(
      STEPPER_KEYS.ADDITIONAL_INFORMATION,
      initialValues[STEPPER_KEYS.ADDITIONAL_INFORMATION]
    );
    calculateProgressMean(
      STEPPER_KEYS.PROOF,
      initialValues[STEPPER_KEYS.PROOF]
    );
    Object.entries(initialValues.fields).forEach(([parent, blocks]) =>
      Object.keys(blocks).forEach((block) => {
        setPercent(parent, block, {
          ...initialValues[STEPPER_KEYS.ADDITIONAL_INFORMATION],
          ...initialValues[STEPPER_KEYS.PROOF],
        });
      })
    );
  }, []);

  const subscriptionForm = useFormik({
    initialValues: initialValues[STEPPER_KEYS.ADDITIONAL_INFORMATION],
    onSubmit: onFormSubmit,
    validationSchema: validation(
      t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, {
        returnObjects: true,
      }),
      {
        hasRia: get(subscription, "project.ria", false),
        hasPartner: !!get(
          subscription,
          "project.contact.relatedPersons",
          []
        ).find((person) => person.relationship === RELATIONSHIP.SPOUSE),
        checkUser,
      }
    ),
  });

  const saveDataOnLeave = (e) => {
    if (
      active === STEPPER_KEYS.ADDITIONAL_INFORMATION &&
      subscriptionForm.dirty
    ) {
      if (!subscriptionForm.isValid) {
        e.preventDefault();
        e.returnValue = "";
        return notification.error({
          message: SUBSCRIPTION.ADDITIONAL_INFORMATION.ERROR,
          duration: 5,
          placement: "bottomRight",
          style: {
            backgroundColor: COLORS.C_DANGER_BACKGROUND,
          },
        });
      }
      subscriptionForm.handleSubmit();
    }
  };

  useEffect(() => {
    return subscriptionForm.handleSubmit;
  }, []);

  useBeforeUnload(saveDataOnLeave, { capture: true });

  const handleTabChange = (key) => {
    if (
      active === STEPPER_KEYS.ADDITIONAL_INFORMATION &&
      subscriptionForm.dirty
    ) {
      if (!subscriptionForm.isValid) {
        return setModalKey(key);
      }
      subscriptionForm.handleSubmit();
    }
    setActive(key);
  };

  const isFieldRequired = (block, value) =>
    get(
      initialValues.fields,
      `${STEPPER_KEYS.ADDITIONAL_INFORMATION}.${block}`,
      []
    ).includes(value) && !get(disabled, value);

  const TABS = [
    {
      key: STEPPER_KEYS.ADDITIONAL_INFORMATION,
      children: (
        <AdditionalInformation
          onFormSubmit={onFormSubmit}
          subscription={subscription}
          progress={progress}
          setProgress={setProgress}
          disabled={disabled}
          setDisabled={setDisabled}
          setPercent={setPercent}
          initialValues={initialValues[STEPPER_KEYS.ADDITIONAL_INFORMATION]}
          active={active}
          setActive={setActive}
          calculateProgressMean={calculateProgressMean}
          subscriptionForm={subscriptionForm}
          isFieldRequired={isFieldRequired}
        />
      ),
      label: (
        <div className="subscription-step--wrapper">
          <Icon
            className="step-icon"
            icon="dashboard"
            size={16}
            key="dashboard1"
          />
          <span className="step-title">
            `
            {`${SUBSCRIPTION.ADDITIONAL_INFORMATION.TITLE} (${
              realProgress[STEPPER_KEYS.ADDITIONAL_INFORMATION]
            }%)`}
          </span>
        </div>
      ),
    },
    {
      key: STEPPER_KEYS.PROOF,
      children: (
        <Proof
          subscription={subscription}
          progress={progress}
          setProgress={setProgress}
          disabled={disabled}
          setDisabled={setDisabled}
          setPercent={setPercent}
          initialValues={initialValues[STEPPER_KEYS.PROOF]}
          setActive={setActive}
          calculateProgressMean={calculateProgressMean}
        />
      ),
      label: (
        <div className="subscription-step--wrapper">
          <Icon
            className="step-icon"
            icon="dashboard"
            size={16}
            key="dashboard2"
          />
          <span className="step-title">
            {`${SUBSCRIPTION.PROOF.TITLE} (${
              realProgress[STEPPER_KEYS.PROOF]
            }%)`}
          </span>
        </div>
      ),
    },
    {
      key: STEPPER_KEYS.DOCUMENT,
      children: (
        <Document
          subscription={subscription}
          progress={progress}
          setProgress={setProgress}
          disabled={disabled}
          setDisabled={setDisabled}
          setPercent={setPercent}
          setActive={setActive}
          signDocument={signDocument}
          signLoading={signLoading}
          additionalInfoPercentage={
            realProgress[STEPPER_KEYS.ADDITIONAL_INFORMATION]
          }
          proofPercentage={realProgress[STEPPER_KEYS.PROOF]}
          updateSubscription={updateSubscription}
        />
      ),
      label: (
        <div className="subscription-step--wrapper">
          <Icon
            className="step-icon"
            icon="dashboard"
            size={16}
            key="dashboard3"
          />
          <span className="step-title">{SUBSCRIPTION.DOCUMENT.TITLE}</span>
        </div>
      ),
    },
  ];

  return (
    <>
      <Header key={"header"} title={SUBSCRIPTION.ADDITIONAL_INFORMATION.NAME} />
      <div className="template-subscription--container">
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <Tabs items={TABS} activeKey={active} onChange={handleTabChange} />
          </Col>
        </Row>
      </div>
      <Modal
        type="info"
        okText={SUBSCRIPTION.ADDITIONAL_INFORMATION.MODAL.OK}
        cancelText={SUBSCRIPTION.ADDITIONAL_INFORMATION.MODAL.CANCEL}
        onCancel={() => {
          setActive(modalKey);
          setModalKey(null);
        }}
        onOk={() => setModalKey(null)}
        title={SUBSCRIPTION.ADDITIONAL_INFORMATION.MODAL.TITLE}
        message={SUBSCRIPTION.ADDITIONAL_INFORMATION.MODAL.MESSAGE}
        open={!!modalKey}
      />
    </>
  );
};

export default View;
