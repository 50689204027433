import View from "./View";

const CollectionNeeds = ({ project, onNext, onBack }) => {
  const next = (fields, needs) =>
    onNext({
      payload: {
        fields,
        needs,
      },
    });

  return <View next={next} project={project} onBack={onBack} />;
};

export default CollectionNeeds;
