import * as yup from "yup";

const validation = (errors) => {
  return yup.object().shape({
    email: yup
      .string()
      .typeError(errors.REQUIRED)
      .email(errors.EMAIL)
      .required(errors.REQUIRED),
  });
};

export default validation;
