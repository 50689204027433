import { gql } from "@apollo/client";

export const PROJECTS = gql`
  query Projects(
    $where: ProjectWhereInput
    $like: JSONObject
    $isIn: JSONObject
    $take: Int
    $skip: Int
  ) {
    projects(
      where: $where
      like: $like
      isIn: $isIn
      take: $take
      skip: $skip
    ) {
      data {
        id
        name
        createdDate
        locked
        ria
        contract {
          issuanceDate
        }
        status {
          id
          template
          name
          tag
          progress
          order
          color
          background
        }
        fields
        contact {
          user {
            firstname
            lastname
            gender
          }
        }
        author {
          id
          firstname
          lastname
        }
        subscriptions {
          id
        }
        expired
      }
      count
    }
  }
`;

export const PROJECT = gql`
  query Project($where: ProjectWhereInput) {
    project(where: $where) {
      id
      name
      ria
      needs {
        id
        hospitalization
        medicalExpenses
        optical
        dental
        hearingAid
        alternativeMedicine
      }
      status {
        id
        template
        name
        tag
        progress
        order
        color
        background
        moveToNext
        possibleStatuses {
          nextStatus
          conditions {
            operation
            rightOperand
            leftOperand
          }
        }
        actions {
          id
          type
          args
          conditions {
            id
            operation
            rightOperand
            leftOperand
          }
        }
        process {
          id
        }
      }
      fields
      contact {
        id
        socialRegime
        socioProfessionalCategory
        exerciseFrame
        telecoms {
          id
          system
          value
        }
        user {
          id
          firstname
          lastname
          gender
          phone
          birthDate
        }
        relatedPersons {
          id
          relationship
          identity {
            id
            socialRegime
            socioProfessionalCategory
            user {
              id
              firstname
              lastname
              birthDate
            }
          }
        }
        telecoms {
          id
          system
          value
        }
        fields
      }
      contract {
        id
        issuanceDate
      }
      locked
      expired
      subscriptions {
        id
        status
        insurancePlan {
          id
        }
      }
    }
  }
`;

const projects = { PROJECTS, PROJECT };

export default projects;
