import moment from "moment";
import { DATE_FORMAT } from "utils/constants";

const Signed = ({ translation, subscription }) => {
  return (
    <div className="signature_signed">
      <span>{`${translation.E_SIGNATURE_SIGNED} ${moment(
        subscription.signedDate
      ).format(DATE_FORMAT)}`}</span>
    </div>
  );
};

export default Signed;
