import { useNavigate, useParams } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useMutation, useQuery } from "shared/hooks/useApi";
import { UPDATE_WORKFLOW } from "utils/api/graphql/mutations/workflows";
import { WORKFLOW, WORKFLOWS } from "utils/api/graphql/queries/workflows";
import { SCREENS } from "utils/constants";
import View from "./View";

const Detail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.WORKFLOWS.LIST.path);
  const [updateWorkflow] = useMutation(UPDATE_WORKFLOW, {
    refetchQueries: [
      {
        query: WORKFLOWS,
        awaitRefetchQueries: true,
      },
    ],
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.ADMIN.WORKFLOWS.LIST.path);
    },
    onError: () => {},
  });
  const { data, loading, error } = useQuery(WORKFLOW, {
    variables: { where: { id } },
  });
  const onSubmit = (data) => {
    return updateWorkflow({
      variables: {
        where: {
          id,
        },
        data,
      },
    });
  };

  if (loading || error) return <Loading />;

  return <View onSubmit={onSubmit} data={data.workflow} cancel={cancel} />;
};

export default Detail;
