import { gql } from "@apollo/client";

export const PROCESSES = gql`
  query Processes(
    $where: ProcessWhereInput
    $take: Int
    $skip: Int
    $like: ProcessWhereInput
  ) {
    processes(where: $where, take: $take, skip: $skip, like: $like) {
      data {
        id
        name
        processStatuses {
          id
          name
          tag
          template
          progress
          order
          color
          background
          moveToNext
          possibleStatuses {
            id
            nextStatus
            conditions {
              id
              operation
              rightOperand
              leftOperand
            }
          }
        }
      }
      count
    }
  }
`;

export const PROCESS = gql`
  query Process($where: ProcessWhereInput) {
    process(where: $where) {
      id
      name
      processStatuses {
        id
        name
        tag
        template
        progress
        order
        color
        background
        moveToNext
        possibleStatuses {
          id
          nextStatus
          conditions {
            id
            operation
            rightOperand
            leftOperand
          }
        }
        actions {
          id
          type
          args
          conditions {
            id
            operation
            rightOperand
            leftOperand
          }
        }
      }
    }
  }
`;

const processes = { PROCESSES, PROCESS };

export default processes;
