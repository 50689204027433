import WithRoutePermission from "shared/components/HOC/WithRoutePermission";
import { MODULES, SCREENS, SUBMODULES } from "utils/constants";
import Create from "./Create";
import Delete from "./Delete";
import Detail from "./Detail";
import List from "./List";
import IdentifyTracer from "./Tracers/Identify";

const routes = [
  {
    path: SCREENS.PRIVATE.BROKER.PROJECTS.LIST.path,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.PROJECT]: SUBMODULES.BROKER_LIST }}
        WrappedComponent={List}
      />
    ),
  },
  {
    path: SCREENS.PRIVATE.BROKER.PROJECTS.TRACERS.IDENTIFY.path,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.PROJECT]: SUBMODULES.BROKER_IDENTIFY }}
        WrappedComponent={IdentifyTracer}
      />
    ),
  },
  {
    path: `${SCREENS.PRIVATE.BROKER.PROJECTS.DETAIL.path}/:id`,
    breadcrumb: SCREENS.PRIVATE.BROKER.PROJECTS.DETAIL.breadcrumb,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.PROJECT]: SUBMODULES.BROKER_DETAIL }}
        WrappedComponent={Detail}
      />
    ),
  },
  {
    path: `${SCREENS.PRIVATE.BROKER.PROJECTS.DELETE.path}/:id`,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.PROJECT]: SUBMODULES.BROKER_DELETE }}
        WrappedComponent={Delete}
      />
    ),
  },
  ...Create,
];

export default routes;
