import cuid from "cuid";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { SCREENS } from "utils/constants";
import View from "./View";

const Create = () => {
  const navigate = useNavigate();
  const id = useMemo(() => cuid(), []);
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.QUALIFIERS.LIST.path);
  const [addQualifier] = useMutation(graphql.mutations.CREATE_QUALIFIER, {
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.ADMIN.QUALIFIERS.LIST.path);
    },
    onError: () => {},
  });
  const onSubmit = (_) => {
    const { name, requirements, priority, isDefault, isBlocking, hasReminder } =
      _;
    return addQualifier({
      variables: {
        data: {
          id,
          name,
          priority,
          isDefault,
          isBlocking,
          hasReminder,
          fields: { requirements },
        },
      },
    });
  };
  return <View onSubmit={onSubmit} id={id} cancel={cancel} />;
};

export default Create;
