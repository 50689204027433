import React from "react";

import PropTypes from "prop-types";
import { BsFillCircleFill } from "react-icons/bs";

const Status = ({ category, text }) => {
  return (
    <div className={`category__wrap ${category}--variant`}>
      <BsFillCircleFill size={8} />
      <span>{text}</span>
    </div>
  );
};

Status.propTypes = {
  text: PropTypes.string.isRequired,
};

Status.defaultProps = {};

export default Status;
