import { get, isEmpty } from "lodash";
import {
  COVERAGE_LIMIT_CATEGORIES,
  SUPPORTING_INFORMATION_CATEGORIES,
} from "utils/constants";

const Card = ({ coverageBenefit = [], translation, expanded }) => {
  const supportingInformation = get(
    coverageBenefit,
    "supportingInformation",
    []
  );
  const limits = get(coverageBenefit, "limits", []);
  const coverageBenefitLimitValue = (value) => ({
    [COVERAGE_LIMIT_CATEGORIES.BASE_REFUND]: `${value} % BR`,
    [COVERAGE_LIMIT_CATEGORIES.REAL_COSTS]: translation.REAL_COSTS,
    [COVERAGE_LIMIT_CATEGORIES.INTEGRAL_RBT]: translation.INTEGRAL_RBT,
    [COVERAGE_LIMIT_CATEGORIES.COST]: `${value} €`,
    [COVERAGE_LIMIT_CATEGORIES.COST_PER_YEAR]: `${translation.UP_TO} ${value} € / ${translation.YEAR}`,
    [COVERAGE_LIMIT_CATEGORIES.TEXT]: value,
  });
  const coverageCardContentStyle = {
    [SUPPORTING_INFORMATION_CATEGORIES.BASE_REFUND]:
      "coverage-card__content--base",
    [SUPPORTING_INFORMATION_CATEGORIES.DESCRIPTION]:
      "coverage-card__content--base",
  };

  return (
    <div className="coverage-card">
      <div className="coverage-card__header">
        {isEmpty(limits)
          ? translation.NON_SUPPORTED
          : limits.map(({ id, value }) => (
              <div key={id}>
                {coverageBenefitLimitValue(value?.value)[value?.category]}
              </div>
            ))}
      </div>
      {expanded && (
        <div className="coverage-card__body">
          {supportingInformation.map((information) => {
            const { id, category, value } = information;
            return (
              <div className="coverage-card__content" key={id}>
                <span className="coverage-card__content--title">
                  {translation.SUPPORTING_INFORMATION_CATEGORIES[category]}
                </span>
                <div
                  className={get(
                    coverageCardContentStyle,
                    category,
                    "coverage-card__content--sec"
                  )}
                >
                  {coverageBenefitLimitValue(value)[category] || value}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Card;
