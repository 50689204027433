import { get } from "lodash";
import { SUPPORTING_INFORMATION_CATEGORIES } from "utils/constants";

const coverageCardContentStyle = {
  [SUPPORTING_INFORMATION_CATEGORIES.BASE_REFUND]:
    "coverage-card__content--base",
};

const SupportingInfoItem = ({ category, value, translation }) => {
  return (
    <div className="coverage-card__content">
      <span className="coverage-card__content--title">
        {
          translation.TEMPLATES.COMPARISON.SUPPORTING_INFORMATION_CATEGORIES[
            category
          ]
        }
      </span>
      <div
        className={get(
          coverageCardContentStyle,
          category,
          "coverage-card__content--sec"
        )}
      >
        {value}
      </div>
    </div>
  );
};

export default SupportingInfoItem;
