import cuid from "cuid";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import Icon from "react-material-symbols/rounded";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import {
  BANK_ACCOUNT_OWNER_TYPE,
  CONTACT_POINT_SYSTEM,
  DEBIT_POSSIBLE_DATES,
  PAYMENT_FREQUENCIES,
  PAYMENT_TYPES,
  REPETITION_INDEX,
  SUBSCRIPTION_FORM_KEYS,
} from "utils/constants";
import { bindInputProps, setDefaultIfEmpty } from "utils/helpers/input";

const Payment = ({
  formProps,
  values,
  setDisabled,
  setPercent,
  setFieldValue,
  isFieldRequired,
  streetType: { options, handleSearch },
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });

  const PAYMENT_PAYER = {
    [true]: () => {
      setFieldValue("payment.payer.owner", {
        id: get(values, "payment.payer.owner.id", cuid()),
        firstName: get(values, "project.contact.user.firstname"),
        lastName: get(values, "project.contact.user.lastname"),
        isAdherent: true,
        type: BANK_ACCOUNT_OWNER_TYPE.PARTICULAR,
      });
      setFieldValue("telecoms.payer", get(values, "telecoms.contact", []));
      setDisabled((prev) => ({
        ...prev,
        telecoms: {
          ...prev.telecoms,
          payer: {
            firstName: true,
            lastName: true,
            [CONTACT_POINT_SYSTEM.ADDRESS_NUMBER]: {
              value: true,
            },
            [CONTACT_POINT_SYSTEM.REPETITION_INDEX]: {
              value: true,
            },
            [CONTACT_POINT_SYSTEM.STREET]: {
              value: true,
            },
            [CONTACT_POINT_SYSTEM.STREET_TYPE]: {
              value: true,
            },
            [CONTACT_POINT_SYSTEM.ZIP_CODE]: {
              value: true,
            },
            [CONTACT_POINT_SYSTEM.CITY]: {
              value: true,
            },
          },
        },
      }));
    },
    [false]: () => {
      setFieldValue("payment.payer.owner", {
        id: get(values, "payment.payer.owner.id", cuid()),
        isAdherent: false,
        type: BANK_ACCOUNT_OWNER_TYPE.PARTICULAR,
      });
      setFieldValue("telecoms.payer", {});
      setDisabled((prev) => ({
        ...prev,
        telecoms: {
          ...prev.telecoms,
          payer: undefined,
        },
      }));
    },
  };

  return (
    <div
      className="subscription-form--wrapper"
      id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.PAYMENT}-section`}
    >
      <Form
        type="vertical"
        title={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT.TITLE}
      >
        <div className="grid--1">
          <Input
            type="radio"
            variant="group"
            icon={<Icon icon="payments" size={16} />}
            label={SUBSCRIPTION.FORM.PAYMENT_TYPE.LABEL}
            values={setDefaultIfEmpty(
              get(values, "insurancePlan.fields.payment.type"),
              Object.values(PAYMENT_TYPES)
            ).map((value) => ({
              value,
              label: SUBSCRIPTION.FORM.PAYMENT_TYPE.OPTIONS[value],
            }))}
            {...bindInputProps({
              name: "payment.type",
              values,
              ...formProps,
            })}
            onBlur={() => setPercent(values)}
            required={isFieldRequired("payment.type")}
          />
          <Input
            type="radio"
            variant="group"
            icon={<Icon icon="replay" size={16} />}
            label={SUBSCRIPTION.FORM.PAYMENT_FREQUENCY.LABEL}
            values={setDefaultIfEmpty(
              get(values, "insurancePlan.fields.payment.frequency"),
              Object.values(PAYMENT_FREQUENCIES)
            ).map((value) => ({
              value,
              label: SUBSCRIPTION.FORM.PAYMENT_FREQUENCY.OPTIONS[value],
            }))}
            {...bindInputProps({
              name: "payment.frequency",
              values,
              ...formProps,
            })}
            onBlur={() => setPercent(values)}
            required={isFieldRequired("payment.frequency")}
          />
          <Select
            options={setDefaultIfEmpty(
              get(values, "insurancePlan.fields.payment.debitDay"),
              DEBIT_POSSIBLE_DATES
            ).map((value) => ({
              value,
              label: value,
            }))}
            placeholder={SUBSCRIPTION.FORM.DEBIT_DATE}
            label={SUBSCRIPTION.FORM.DEBIT_DATE}
            icon={<Icon icon="edit_calendar" size={16} />}
            {...bindInputProps({
              name: "payment.debitDay",
              values,
              ...formProps,
            })}
            onBlur={() => setPercent(values)}
            required={isFieldRequired("payment.debitDay")}
          />
          <Input
            label={SUBSCRIPTION.FORM.IBAN.LABEL}
            icon={<Icon icon="qr_code" size={16} />}
            placeholder={SUBSCRIPTION.FORM.IBAN.PLACEHOLDER}
            {...bindInputProps({
              name: "payment.payer.IBAN",
              values,
              ...formProps,
              onBlur: () => setPercent(values),
            })}
            required={isFieldRequired("payment.payer.IBAN")}
          />
          {/* {get(values, "project.contact.optMadelin") && (
            <Alert
              showIcon
              type="info"
              message={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.ADHERENT.INFO}
            />
          )} */}
          <Input
            label={SUBSCRIPTION.FORM.BIC.LABEL}
            icon={<Icon icon="qr_code" size={16} />}
            placeholder={SUBSCRIPTION.FORM.BIC.PLACEHOLDER}
            {...bindInputProps({
              name: "payment.payer.BIC",
              values,
              ...formProps,
              onBlur: () => setPercent(values),
            })}
            required={isFieldRequired("payment.payer.BIC")}
          />
          {get(values, "payment.payer.owner.type") ===
            BANK_ACCOUNT_OWNER_TYPE.PARTICULAR && (
            <Input
              type="radio"
              variant="group"
              icon={<Icon icon="person" size={16} />}
              label={
                SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT.PAYER.LABEL
              }
              values={[
                {
                  value: true,
                  label:
                    SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT.PAYER
                      .OPTIONS.ADHERENT,
                },
                {
                  value: false,
                  label:
                    SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT.PAYER
                      .OPTIONS.ANOTHER_PERSON,
                  popover:
                    get(
                      values,
                      "insurancePlan.fields.payment.shouldBeAdherent"
                    ) &&
                    SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT
                      .SHOULD_BE_ADHERENT,
                },
              ]}
              disabled={get(
                values,
                "insurancePlan.fields.payment.shouldBeAdherent"
              )}
              {...bindInputProps({
                name: "payment.payer.owner.isAdherent",
                values,
                ...formProps,
              })}
              onBlur={() => setPercent(values)}
              onChange={(value) => {
                setFieldValue("payment.payer.owner.isAdherent", value);
                setDisabled((prev) => ({
                  ...prev,
                  proof: {
                    ...prev.proof,
                    payerCin: value,
                  },
                }));
                PAYMENT_PAYER[value]();
              }}
              required={isFieldRequired("payment.payer.owner.isAdherent")}
            />
          )}
          {(get(values, "insurancePlan.fields.tns") ||
            !get(values, "payment.payer.owner.isAdherent")) && (
            <>
              {get(values, "insurancePlan.fields.tns") ? (
                <Form
                  type="highlight"
                  title={
                    SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT.PAYER
                      .ORGANIZATION.TITLE
                  }
                >
                  <div className="grid--1">
                    <Input
                      label={
                        SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT.PAYER
                          .ORGANIZATION.SOCIAL_REASON
                      }
                      icon={<Icon icon="badge" size={16} />}
                      placeholder={
                        SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT.PAYER
                          .ORGANIZATION.SOCIAL_REASON
                      }
                      {...bindInputProps({
                        name: "payment.payer.owner.socialReason",
                        values,
                        ...formProps,
                        onBlur: () => setPercent(values),
                      })}
                      required={isFieldRequired(
                        "payment.payer.owner.socialReason"
                      )}
                    />
                  </div>
                </Form>
              ) : (
                <Form
                  type="highlight"
                  title={
                    SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT.PAYER
                      .IDENTITY.TITLE
                  }
                >
                  <div className="grid--1">
                    <Input
                      label={
                        SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT.PAYER
                          .IDENTITY.FIRST_NAME.LABEL
                      }
                      icon={<Icon icon="badge" size={16} />}
                      placeholder={
                        SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT.PAYER
                          .IDENTITY.FIRST_NAME.PLACEHOLDER
                      }
                      {...bindInputProps({
                        name: "payment.payer.owner.firstName",
                        values,
                        ...formProps,
                        onBlur: () => setPercent(values),
                      })}
                      required={isFieldRequired(
                        "payment.payer.owner.firstName"
                      )}
                    />
                    <Input
                      label={
                        SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT.PAYER
                          .IDENTITY.LAST_NAME.LABEL
                      }
                      icon={<Icon icon="badge" size={16} />}
                      placeholder={
                        SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT.PAYER
                          .IDENTITY.LAST_NAME.PLACEHOLDER
                      }
                      {...bindInputProps({
                        name: "payment.payer.owner.lastName",
                        values,
                        ...formProps,
                        onBlur: () => setPercent(values),
                      })}
                      required={isFieldRequired("payment.payer.owner.lastName")}
                    />
                  </div>
                </Form>
              )}
              <Form
                type="highlight"
                title={
                  SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PAYMENT.PAYER.ADDRESS
                    .TITLE
                }
              >
                <div className="grid--1">
                  <Input
                    label={SUBSCRIPTION.FORM.NUMBER.LABEL}
                    icon={<Icon icon="numbers" size={16} />}
                    placeholder={SUBSCRIPTION.FORM.NUMBER.PLACEHOLDER}
                    {...bindInputProps({
                      name: `telecoms.payer.${CONTACT_POINT_SYSTEM.ADDRESS_NUMBER}.value`,
                      values,
                      ...formProps,
                      onBlur: () => setPercent(values),
                    })}
                    required={isFieldRequired(
                      `telecoms.payer.${CONTACT_POINT_SYSTEM.ADDRESS_NUMBER}.value`
                    )}
                  />
                  <Select
                    options={REPETITION_INDEX.map((index) => ({
                      value: index,
                      label: index,
                    }))}
                    placeholder={SUBSCRIPTION.FORM.REPETITION_INDEX.PLACEHOLDER}
                    label={SUBSCRIPTION.FORM.REPETITION_INDEX.LABEL}
                    icon={<Icon icon="restart_alt" size={16} />}
                    {...bindInputProps({
                      name: `telecoms.payer.${CONTACT_POINT_SYSTEM.REPETITION_INDEX}.value`,
                      values,
                      ...formProps,
                      onBlur: () => setPercent(values),
                    })}
                    onChange={(value) =>
                      setFieldValue(
                        `telecoms.payer.${CONTACT_POINT_SYSTEM.REPETITION_INDEX}.value`,
                        value || null
                      )
                    }
                    allowClear
                    required={isFieldRequired(
                      `telecoms.payer.${CONTACT_POINT_SYSTEM.REPETITION_INDEX}.value`
                    )}
                  />
                  <Select
                    label={SUBSCRIPTION.FORM.TYPE.LABEL}
                    placeholder={SUBSCRIPTION.FORM.TYPE.PLACEHOLDER}
                    onSearch={handleSearch}
                    showSearch
                    options={options}
                    {...bindInputProps({
                      name: `telecoms.payer.${CONTACT_POINT_SYSTEM.STREET_TYPE}.value`,
                      values,
                      ...formProps,
                      onBlur: () => setPercent(values),
                    })}
                    // required={isFieldRequired(
                    //   `telecoms.payer.${CONTACT_POINT_SYSTEM.STREET_TYPE}.value`
                    // )}
                  />
                  <Input
                    label={SUBSCRIPTION.FORM.STREET.LABEL}
                    icon={<Icon icon="add_road" size={16} />}
                    placeholder={SUBSCRIPTION.FORM.STREET.PLACEHOLDER}
                    {...bindInputProps({
                      name: `telecoms.payer.${CONTACT_POINT_SYSTEM.STREET}.value`,
                      values,
                      ...formProps,
                      onBlur: () => setPercent(values),
                    })}
                    required={isFieldRequired(
                      `telecoms.payer.${CONTACT_POINT_SYSTEM.STREET}.value`
                    )}
                  />
                  <Input
                    label={SUBSCRIPTION.FORM.POSTAL_CODE.LABEL}
                    icon={<Icon icon="pin_drop" size={16} />}
                    placeholder={SUBSCRIPTION.FORM.POSTAL_CODE.PLACEHOLDER}
                    {...bindInputProps({
                      name: `telecoms.payer.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`,
                      values,
                      ...formProps,
                      onBlur: () => setPercent(values),
                    })}
                    required={isFieldRequired(
                      `telecoms.payer.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`
                    )}
                  />
                  <Input
                    icon={<Icon icon="south_america" size={16} />}
                    label={SUBSCRIPTION.FORM.CITY.LABEL}
                    placeholder={SUBSCRIPTION.FORM.CITY.PLACEHOLDER}
                    {...bindInputProps({
                      name: `telecoms.payer.${CONTACT_POINT_SYSTEM.CITY}.value`,
                      values,
                      ...formProps,
                      onBlur: () => setPercent(values),
                    })}
                    required={isFieldRequired(
                      `telecoms.payer.${CONTACT_POINT_SYSTEM.CITY}.value`
                    )}
                  />
                </div>
              </Form>
            </>
          )}
        </div>
      </Form>
    </div>
  );
};

export default Payment;
