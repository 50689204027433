import gql from "graphql-tag";

export const CREATE_SOURCE = gql`
  mutation AddSource($data: SourceDataInput) {
    addSource(data: $data) {
      id
    }
  }
`;

export const UPDATE_SOURCE = gql`
  mutation UpdateSource($data: SourceDataInput, $where: SourceWhereInput) {
    updateSource(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_SOURCE = gql`
  mutation DeleteSource($where: SourceWhereInput) {
    deleteSource(where: $where) {
      id
    }
  }
`;

const sources = { CREATE_SOURCE, UPDATE_SOURCE, DELETE_SOURCE };

export default sources;
