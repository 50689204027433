import WithRoutePermission from "shared/components/HOC/WithRoutePermission";
import { MODULES, SCREENS, SUBMODULES } from "utils/constants";

import List from "./List";

const routes = [
  {
    path: SCREENS.PRIVATE.BROKER.COMMISSION_STATEMENTS.LIST.path,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.COMMISSION_STATEMENT]: SUBMODULES.BROKER_LIST }}
        WrappedComponent={List}
      />
    ),
  },
];

export default routes;
