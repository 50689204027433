import * as yup from "yup";

const validation = () => {
  return yup.object().shape({
    name: yup.string(),
    description: yup.string(),
    alias: yup.string(),
  });
};

export default validation;
