import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { SCREENS } from "utils/constants";
import View from "./View";

const Delete = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [visible, setVisible] = useState(true);
  const cancel = () => navigate(SCREENS.PRIVATE.BROKER.CONTRACTS.LIST.path);
  const [deleteContract] = useMutation(graphql.mutations.DELETE_CONTRACT, {
    onCompleted: () => {
      setVisible(false);
      navigate(SCREENS.PRIVATE.BROKER.CONTRACTS.LIST.path);
    },
    onError: () => {},
  });
  const onSubmit = () => {
    return deleteContract({
      variables: {
        where: {
          id,
        },
      },
    });
  };
  return <View onSubmit={onSubmit} cancel={cancel} visible={visible} />;
};

export default Delete;
