import { Drawer, Modal, Table } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BsCheck,
  BsChevronLeft,
  BsPencil,
  BsPlus,
  BsPlusCircle,
  BsSearch,
  BsTrash,
} from "react-icons/bs";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Descriptions from "shared/components/Descriptions";
import Empty from "shared/components/Empty";
import Format from "shared/components/Format";
import Head from "shared/components/Head";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Label from "shared/components/Label";
import Select from "shared/components/Select";
import SubTableRow from "shared/components/SubTableRow";
import ToggleButton from "shared/components/ToggleButton";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";

const ProductInfo = [
  {
    id: "1",
    label: "ID produit",
    value: "clfh42f8c000l2v6drlj77vfd",
  },
  {
    id: "2",
    label: "Nom du produit",
    value: "Tunnel 2",
  },
  {
    id: "1",
    label: "Description produit",
    value: "conseillerassurance.fr/form2",
  },
  {
    id: "1",
    label: "Alias",
    value: "En ligne",
  },
  {
    id: "1",
    label: "Icône du produit",
    value: "-",
  },
  {
    id: "1",
    label: "Date de début",
    value: "01/06/2023",
  },
  {
    id: "1",
    label: "Date de fin",
    value: "25/06/2023",
  },
  {
    id: "1",
    label: "Date d’effet",
    value: "25/06/2023",
  },
  {
    id: "1",
    label: "Date début",
    value: "25/06/2023",
  },
  {
    id: "1",
    label: "Durée",
    value: "0",
  },
  {
    id: "1",
    label: "Unité de calcule",
    value: "Mois",
  },
  {
    id: "1",
    label: "Age minimal",
    value: "20",
  },
  {
    id: "1",
    label: "Age maximal",
    value: "65",
  },
];

const SubItems = [
  {
    id: "1",
    name: "Accesseur",
    value: "Status.moveToNext",
  },
  {
    id: "2",
    name: "Opération",
    value: "Identity",
  },
  {
    id: "3",
    name: "Valeur",
    value: "True",
  },
];

const GuaranteeList = [
  {
    id: "1",
    name: "Séjour",
    value: "100% BR",
  },
  {
    id: "1",
    name: "Honoraires OPTAM",
    value: "100% BR",
  },
  {
    id: "1",
    name: "Honoraires hors OPTAM",
    value: "100% BR",
  },
  {
    id: "1",
    name: "Chambre particulière",
    value: "100% BR",
  },
];

const CreatStatus = ({ onClose, open }) => {
  return (
    <Drawer
      title="Nouveau statut"
      width={"40%"}
      onClose={onClose}
      open={open}
      className="drawer__custom"
    >
      <div className="grid--1">
        <Input label={"Nom du statut"} placeholder={"Nom du statut"} />

        <Input label={"Progression"} placeholder={"Progression"} />
        <Input label={"Ordre"} placeholder={"Ordre"} />

        <Select label={"Modèle"} />

        <Input label={"Couleur"} placeholder={"Couleur"} type={"colorPicker"} />
        <Input
          label={"Couleur arrière plan"}
          placeholder={"Couleur arrière plan"}
          type={"colorPicker"}
        />

        <Button
          type="primary"
          size={"full--width"}
          onClick={() => console.log()}
          icon={<BsCheck size={24} />}
        >
          Enregistrer
        </Button>
      </div>
    </Drawer>
  );
};

const CreatGuarantee = ({ onClose, open }) => {
  return (
    <Drawer
      title="Nouvelle garantie"
      width={"40%"}
      onClose={onClose}
      open={open}
      className="drawer__custom"
    >
      <div className="grid--1">
        <Input
          label={"Nom de la garantie"}
          placeholder={"Nom de la garantie"}
        />

        <Select label={"Catégorie"} />

        <Input label={"Plan d'Assurance"} placeholder={"Plan d'Assurance"} />

        <Input label={"Jours de carence"} placeholder={"Jours de carence"} />

        <Button
          type="primary"
          size={"full--width"}
          onClick={() => console.log()}
          icon={<BsCheck size={24} />}
        >
          Enregistrer
        </Button>
      </div>
    </Drawer>
  );
};

const ListGuarantee = ({ onClose, open }) => {
  return (
    <Drawer
      title="Hospitalisation"
      width={"80%"}
      onClose={onClose}
      open={open}
      className="drawer__custom"
    >
      <div className="guarantee__wrapper">
        <div className="guarantee__wrapper--list">
          {GuaranteeList.map((item) => (
            <Card type={"guarantee"} name={item.name} value={item.value} />
          ))}
        </div>
        <div className="guarantee__wrapper--options">
          <Head title="Médecine douce" action={<BsTrash color={"red"} />} />
          <div className="m-bottom-20" />
          <Format label={"Format"} />

          <div className="guarantee__wrapper--composants">
            <Label label="Composants" />
            <div className="d-flex flex--wrap flex--grow">
              <ToggleButton name={"Psychologue"} />
              <ToggleButton name={"Psychomotricien"} />
              <ToggleButton
                name={"Equilibre alimentaire et produits diététiques"}
              />
            </div>
          </div>
          <div className="guarantee__wrapper--infos">
            <Label label="Informations complémentaires" />

            <div className="form">
              <Select placeholder="Sélectionner une catégorie" />
              <Input type={"textarea"} placeholder={"Valeur"} />
              <Button
                type="primary--light"
                size={"full--width"}
                icon={<BsPlus size={24} />}
              >
                Ajouter
              </Button>

              <div className="list">
                <div className="item">
                  <div className="head">
                    <h4>Bonus :</h4>
                    <span onClick={() => {}}>
                      <BsTrash color="red" />
                    </span>
                  </div>
                  <p>
                    Fidélisation optique après 2 années d'adhésion la garantie
                    est de : 150€
                  </p>
                </div>
                <div className="item">
                  <div className="head">
                    <h4>Bonus :</h4>
                    <span onClick={() => {}}>
                      <BsTrash color="red" />
                    </span>
                  </div>
                  <p>
                    Fidélisation optique après 2 années d'adhésion la garantie
                    est de : 150€
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

const List = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const dataSource = [
    {
      key: "1",
      name: "Hospitalisation",
      category: "Hospitalisation",
      plan: "Spvie Santé Seniors",
      days: 0,
      action: (
        <Button type="danger--link" icon={<BsTrash />}>
          Supprimer{" "}
        </Button>
      ),
      components: (
        <>
          <div className="d-flex align--center">
            <BsTrash color={COLORS.C_DANGER} size={20} />
            <Select placeholder="Statut suivant" />
          </div>
          <div className="m-top-20 m-bottom-20">
            <Button type="primary--link" icon={<BsPlusCircle />}>
              Ajouter une condition
            </Button>
          </div>
          <SubTableRow
            items={SubItems}
            onShow={() => setModalOpen(true)}
            onDelete={() => {}}
          />
        </>
      ),
    },
  ];

  const columns = [
    {
      title: "Nom de la couverture",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Catégorie",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Plan d'Assurance",
      dataIndex: "plan",
      key: "plan",
    },
    {
      title: "Jours de carence",
      dataIndex: "days",
      key: "days",
    },
    {
      title: "Actions",
      dataIndex: "action",
      align: "right",
      key: "action",
      render: (_) => (
        <div className="d-flex justify--end">
          <Button type="danger--link" icon={<BsTrash />}>
            Supprimer{" "}
          </Button>
          <Button type="primary--link" icon={<BsSearch />} onClick={showList}>
            Affichier{" "}
          </Button>
        </div>
      ),
    },
  ];

  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.WEBHOOK);
  const [openCreateStatus, setOpenCreateStatus] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openList, setOpenList] = useState(false);
  const showCreate = () => {
    setOpenCreate(true);
  };
  const onCloseCreate = () => {
    setOpenCreate(false);
  };

  const showCreateStatus = () => {
    setOpenCreateStatus(true);
  };
  const onCloseCreateStatus = () => {
    setOpenCreateStatus(false);
  };

  const showList = () => {
    setOpenList(true);
  };
  const onCloseList = () => {
    setOpenList(false);
  };

  return (
    <>
      <Header
        key={"header"}
        title={"Informations sur le produit"}
        actions={[]}
      />
      <Card
        type={"simple"}
        head={true}
        title="Informations produit"
        actions={
          <Button type="primary--outlined" icon={<BsPencil />}>
            Modifier le produit{" "}
          </Button>
        }
      >
        <Descriptions items={ProductInfo} />
      </Card>
      <div className="m-bottom-24" />
      <Card
        type={"simple"}
        head={true}
        title="Garanties"
        actions={
          <Button
            type="primary--outlined"
            icon={<BsPlusCircle />}
            onClick={showCreate}
          >
            Ajouter une garantie{" "}
          </Button>
        }
      >
        <Empty />

        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          scroll={{ x: true }}
          className="table__wrapper"
          rowKey={"id"}
        />

        <CreatGuarantee onClose={onCloseCreate} open={openCreate} />
        <ListGuarantee onClose={onCloseList} open={openList} />
      </Card>
      <div className="m-bottom-24" />
      <Card
        type={"simple"}
        head={true}
        title="Statut"
        actions={
          <Button
            type="primary--outlined"
            icon={<BsPlusCircle />}
            onClick={showCreateStatus}
          >
            Créer un statut{" "}
          </Button>
        }
      >
        <Empty />

        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          scroll={{ x: true }}
          className="table__wrapper"
          rowKey={"id"}
          expandable={{
            expandedRowRender: (record) => record.components,
            rowExpandable: (record) => record.id !== "Not Expandable",
          }}
        />
        <CreatStatus onClose={onCloseCreateStatus} open={openCreateStatus} />
      </Card>

      <Modal
        title="Conditions"
        centered
        open={modalOpen}
        footer={null}
        className="custom__modal"
      >
        <div className="d-flex align--center justify--between m-top-24">
          <Input label={"Accesseur"} placeholder={"Accesseur"} />

          <Input label={"Opération"} placeholder={"Opération"} />

          <Input label={"Valeur"} placeholder={"Valeur"} />
        </div>

        <div className="d-flex m-top-24 btn--grow">
          <Button icon={<BsPlusCircle size={18} />} type={"primary--outlined"}>
            Ajouter une condition
          </Button>
        </div>

        <div className="d-flex align--center justify--end m-top-24">
          <Button
            icon={<BsChevronLeft size={18} />}
            type={"primary--outlined"}
            onClick={() => setModalOpen(false)}
          >
            Annuler
          </Button>
          <Button
            icon={<BsCheck size={24} />}
            type={"primary"}
            onClick={() => setModalOpen(false)}
          >
            Enregistrer
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default List;
