import Subscriptions from "entities/Subscriptions";
import View from "./View";

const List = ({ onClose, project }) => {
  return (
    <Subscriptions
      filter={{ where: { project: { id: project } } }}
      View={View}
      NoData={() => null}
      onClose={onClose}
    />
  );
};

export default List;
