import { Row } from "antd";
import cuid from "cuid";
import { Formik } from "formik";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "shared/components/Button";
import Form from "shared/components/Form";
import Header from "shared/components/Header";
import { useMutation, useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";

import {
  CONTACT_POINT_SYSTEM,
  RELATIONSHIP,
  SCREENS,
  SOURCE_TYPES,
} from "utils/constants";
import STEPS from "./Forms";
import validation from "./validation";

export const STEPS_KEYS = {
  ADHERENT: "ADHERENT",
  SPOUSE: "SPOUSE",
  CHILDREN: "CHILDREN",
};

const Contact = () => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CLIENT);
  const navigate = useNavigate();
  const CREATE = t("CREATE", { returnObjects: true });
  const { data: qualifier } = useQuery(graphql.queries.QUALIFIER, {
    variables: {
      where: { isDefault: true },
    },
  });
  const { data: source } = useQuery(graphql.queries.SOURCE, {
    variables: {
      where: { name: SOURCE_TYPES.MANUAL },
    },
  });
  const [createQualification] = useMutation(
    graphql.mutations.CREATE_QUALIFICATIONS,
    {
      onCompleted: () =>
        navigate(SCREENS.PRIVATE.BROKER.QUALIFICATIONS.LIST.path),
      onError: () => {},
    }
  );

  const submit = ({
    spouse,
    children,
    phone,
    email,
    zipCode,
    socialRegime,
    ...user
  }) => {
    createQualification({
      variables: {
        data: {
          id: cuid(),
          contact: {
            id: cuid(),
            socialRegime,
            footprint: {
              id: cuid(),
              source: { id: get(source, "source.id") },
            },
            user,
            relatedPersons: [
              ...(!isEmpty(spouse)
                ? [
                    {
                      id: cuid(),
                      relationship: RELATIONSHIP.SPOUSE,
                      identity: {
                        id: cuid(),
                        socialRegime: spouse.socialRegime,
                        user: {
                          id: cuid(),
                          birthDate: spouse.birthDate,
                        },
                      },
                    },
                  ]
                : []),
              ...children,
            ],
            telecoms: [
              {
                id: cuid(),
                system: CONTACT_POINT_SYSTEM.EMAIL,
                value: email,
              },
              {
                id: cuid(),
                system: CONTACT_POINT_SYSTEM.PHONE,
                value: phone,
              },
              {
                id: cuid(),
                system: CONTACT_POINT_SYSTEM.ZIP_CODE,
                value: zipCode,
              },
            ],
          },
          qualifier: {
            id: get(qualifier, "qualifier.id"),
          },
        },
      },
    });
  };

  return (
    <Formik
      initialValues={{
        id: cuid(),
        children: [],
      }}
      onSubmit={submit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, values, ...formProps }) => [
        <Header key={"header"} title={CREATE.TITLE} actions={[]} />,
        <div className="contact-form" key="form_area">
          <div className="contact-form__content">
            <Form title={CREATE.TITLE} description={CREATE.DESCRIPTION}>
              {Object.keys(STEPS_KEYS).map((step, index) => {
                return STEPS(
                  {
                    index: index + 1,
                    isValid,
                    handleSubmit,
                    values,
                    ...formProps,
                  },
                  { translation: CREATE.STEPS }
                )[step];
              })}
            </Form>
          </div>
          <div className="contact-form__action">
            <Row justify={"end"}>
              <Button type="primary" onClick={handleSubmit}>
                {CREATE.SUBMIT}
              </Button>
            </Row>
          </div>
        </div>,
      ]}
    </Formik>
  );
};

export default Contact;
