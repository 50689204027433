import { gql } from "@apollo/client";

export const SOURCES = gql`
  query Sources(
    $where: SourceWhereInput
    $like: SourceWhereInput
    $take: Int
    $skip: Int
  ) {
    sources(where: $where, take: $take, skip: $skip, like: $like) {
      data {
        id
        name
        platform
      }
      count
    }
  }
`;

export const SOURCE = gql`
  query Source($where: SourceWhereInput) {
    source(where: $where) {
      id
      name
      platform
    }
  }
`;

const sources = { SOURCES, SOURCE };

export default sources;
