import PropTypes from "prop-types";
import { BsChevronExpand } from "react-icons/bs";

const Profile = ({ avatar, title, type, onClick = () => {} }) => {
  return (
    <div className="profile__area" onClick={onClick}>
      <div className="profile__area--media">{avatar}</div>
      <div className="profile__area--content">
        <h4>{title}</h4>
        <h6>{type}</h6>
      </div>
      <div className="profile__area--icon">
        <BsChevronExpand style={{ fontSize: 14 }} />
      </div>
    </div>
  );
};

Profile.propTypes = {
  avatar: PropTypes.node,
  onClick: PropTypes.func,
  image: PropTypes.string,
};

Profile.defaultProps = {
  image: "https://via.placeholder.com/90x90",
};

export default Profile;
