import gql from "graphql-tag";

export const CREATE_REMINDER = gql`
  mutation AddReminder($data: ReminderDataInput) {
    addReminder(data: $data) {
      id
    }
  }
`;

const reminders = { CREATE_REMINDER };

export default reminders;
