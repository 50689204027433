import React, { useState } from "react";

export const Context = React.createContext("store");
export const { Consumer } = Context;

export const Provider = ({ children }) => {
  const [navigationFilter, setNavigationFilter] = useState([]);

  return (
    <Context.Provider
      value={{
        navigationFilter,
        setNavigationFilter,
      }}
    >
      {children}
    </Context.Provider>
  );
};
