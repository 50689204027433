import { Table } from "antd";
import { chain, flattenDeep, get, groupBy, isEmpty, sortBy } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import AlternativeMedicine from "shared/assets/images/ayurveda.png";
import Dental from "shared/assets/images/dental-checkup.png";
import Equipment from "shared/assets/images/hearing-aid.png";
import Hospitalization from "shared/assets/images/hospital-bed.png";
import Maternity from "shared/assets/images/maternity.png";
import MedicalCare from "shared/assets/images/medical-team.png";
import Others from "shared/assets/images/others.png";
import Prevention from "shared/assets/images/prevention.png";
import Optical from "shared/assets/images/vision.png";
import Alert from "shared/components/Alert";
import Card from "shared/components/Card";
import Empty from "shared/components/Empty";
import Header from "shared/components/Header";
import { NAME_SPACES } from "shared/locales/constants";
import { COVERAGE_CATEGORIES } from "utils/constants";
import CoverageBenefitCard from "./Widgets/Coverages/Card";
import CoverageCategoryHeader from "./Widgets/Coverages/Header";
import CoverageLabel from "./Widgets/Coverages/Label";
import InsurancePlanCard from "./Widgets/InsurancePlans";

export const COVERAGE_CATEGORIES_ICONS = {
  [COVERAGE_CATEGORIES.HOSPITALIZATION]: Hospitalization,
  [COVERAGE_CATEGORIES.MEDICAL_CARE]: MedicalCare,
  [COVERAGE_CATEGORIES.OPTICAL]: Optical,
  [COVERAGE_CATEGORIES.DENTAL]: Dental,
  [COVERAGE_CATEGORIES.EQUIPMENT]: Equipment,
  [COVERAGE_CATEGORIES.ALTERNATIVE_MEDICINE]: AlternativeMedicine,
  [COVERAGE_CATEGORIES.PREVENTION]: Prevention,
  [COVERAGE_CATEGORIES.MATERNITY]: Maternity,
  [COVERAGE_CATEGORIES.OTHER]: Others,
};
const View = ({ project }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const [hideDetail, setHideDetail] = useState({});
  const toggleCoverage = (name, hide) => {
    setHideDetail((prev) => ({
      ...prev,
      [name]: hide,
    }));
  };

  const HIDE_DETAIL = {
    [false]: (
      <>
        <span>{DETAIL.TEMPLATES.COMPARISON.SEE_MORE}</span>
        <MdOutlineExpandMore />
      </>
    ),
    [true]: (
      <>
        <span>{DETAIL.TEMPLATES.COMPARISON.SEE_LESS}</span>
        <MdOutlineExpandLess />
      </>
    ),
  };

  const [generalCosts, dataSource] = useMemo(() => {
    const costs = get(project, "generalCosts", []).filter(({ insurancePlan }) =>
      get(project, "fields.insurancePlans", []).includes(insurancePlan.id)
    );
    const groupedCoverageBenefits = chain(costs)
      .flatMap((cost) => cost.insurancePlan.coverages)
      .flatMap((coverage) => {
        return get(coverage, "coverageBenefits", []).map((benefit) => ({
          coverageId: coverage.id,
          coverageType: coverage.type,
          benefitType: benefit.type,
          benefitSubtype: benefit.subtype,
          benefitInfo: benefit.additionalInfo,
          benefitOrder: benefit.order,
          benefit,
        }));
      })
      .groupBy("coverageType")
      .mapValues((coverageBenefits) => {
        const sortedBenefits = sortBy(coverageBenefits, "benefitOrder");
        return groupBy(sortedBenefits, "benefitType");
      })
      .value();

    const dataSource = flattenDeep(
      Object.entries(COVERAGE_CATEGORIES_ICONS).map(([key, icon], index) => [
        ...(isEmpty(Object.entries(get(groupedCoverageBenefits, key, {})))
          ? []
          : [{ id: `${key}-${index}`, label: key, icon }]),
        Object.entries(get(groupedCoverageBenefits, key, {})).map(
          ([label, values], i) => {
            return {
              label,
              benefits: values,
              id: `${label}-${index}-${i}`,
            };
          }
        ),
      ])
    );

    return [costs, dataSource];
  }, []);

  const columns = [
    {
      width: 300,
      dataIndex: "label",
      render: (label, row) =>
        row.icon ? (
          <CoverageCategoryHeader
            className="coverage-header"
            src={get(row, "icon")}
            type={label}
            label={DETAIL.TEMPLATES.COMPARISON.COVERAGES_CATEGORIES[label]}
          />
        ) : (
          <CoverageLabel
            label={label}
            subLabel={get(row, "benefits.0.benefitSubtype", "")}
            info={get(row, "benefits.0.benefitInfo", "")}
            expanded={hideDetail[label]}
            link={HIDE_DETAIL[get(hideDetail, label, false)]}
            onToggle={() => toggleCoverage(label, !hideDetail[label])}
          />
        ),
      fixed: true,
    },
    ...(generalCosts?.map(({ insurancePlan, cost, currency }) => ({
      dataIndex: "benefits",
      title: () => (
        <InsurancePlanCard
          insurancePlan={insurancePlan}
          cost={cost}
          currency={currency}
          translation={DETAIL.TEMPLATES.COMPARISON}
          expired={project.expired}
        />
      ),
      render: (benefits, row) => {
        return benefits ? (
          <CoverageBenefitCard
            translation={DETAIL.TEMPLATES.COMPARISON}
            expanded={hideDetail[row.label]}
            coverageBenefit={get(
              benefits.find(({ coverageId }) => {
                return get(insurancePlan, "coverages", [])
                  .map(({ id }) => id)
                  .includes(coverageId);
              }),
              "benefit",
              []
            )}
          />
        ) : null;
      },
    })) || []),
  ];

  return (
    <div>
      <Header
        key={"header"}
        title={DETAIL.TEMPLATES.COMPARISON.TITLE}
        actions={[]}
      />
      <div className="comparison-container">
        <Card type="simple">
          {project.expired ? (
            <Alert
              message={DETAIL.TEMPLATES.COMPARISON.QUOTE_EXPIRED}
              showIcon
              type="error"
            />
          ) : isEmpty(generalCosts) ? (
            <Empty type="normal" />
          ) : (
            <Table
              dataSource={dataSource}
              columns={columns}
              className="comparison-table__wrapper"
              rowKey={"id"}
              pagination={false}
              scroll={{ x: true, y: 500 }}
            />
          )}
        </Card>
      </div>
    </div>
  );
};

export default View;
