import { Badge, Card, Col, Row, Timeline, Typography } from "antd";
import { concat, get, isEmpty, orderBy } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { DATE_TIME_FORMAT } from "utils/constants";

const { Text } = Typography;

const Interactions = ({ qualification }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.QUALIFICATION);
  const INTERACTIONS = t("SHOW.INTERACTIONS", { returnObjects: true });
  const { contact } = qualification;
  const { data: notes } = useQuery(graphql.queries.NOTES, {
    variables: {
      where: {
        contact: {
          id: contact.id,
        },
      },
      orderBy: [{ createdDate: "desc" }],
    },
  });
  const { data: qualifications } = useQuery(graphql.queries.QUALIFICATIONS, {
    variables: {
      where: {
        contact: {
          id: contact.id,
        },
        hasNote: false,
      },
      orderBy: [{ createdDate: "desc" }],
    },
  });

  const mergedData = orderBy(
    concat(
      get(notes, "notes.data", []).map((note) => ({
        note,
      })),
      get(qualifications, "qualifications.data", []).map((qualification) => ({
        qualification,
      }))
    ),
    ["createdDate"],
    ["desc"]
  );

  const interactions = mergedData.map((note) => {
    const content = get(note, "qualification.content", {});
    const qualifier = get(note, "qualification.qualifier.name", "");
    const author = get(note, "author");

    const text = isEmpty(author)
      ? INTERACTIONS.UNKNOWN_BROKER
      : `${get(author, "firstname", "")} ${get(author, "lastname", "")} : ${
          note.content
        }`;

    return {
      label: moment.utc(note.createdDate).format(DATE_TIME_FORMAT),
      children: (
        <Badge.Ribbon text={qualifier}>
          <Card size="small">
            <Row>
              <Col lg={24} xs={24}>
                {Object.entries(content).map(([key, value]) => (
                  <div key={key}>
                    {key}: {value}
                  </div>
                ))}
                <Text italic>
                  {moment.utc(note.createdDate).format(DATE_TIME_FORMAT)}
                </Text>
                <br />
                <Text type="secondary">{text}</Text>
              </Col>
            </Row>
          </Card>
        </Badge.Ribbon>
      ),
    };
  });

  return <Timeline mode="left" items={interactions} />;
};

export default Interactions;
