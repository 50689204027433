import All from "./All";
import Selected from "./Selected";

const Widgets = {
  all: All,
  selected: Selected,
};

const InsurancePlans = (props) => {
  const Widget = Widgets[props.display];
  return <Widget {...props} />;
};

export default InsurancePlans;
