import CoveragesEntity from "entities/Coverages";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsPlusCircle } from "react-icons/bs";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Empty from "shared/components/Empty";
import { NAME_SPACES } from "shared/locales/constants";
import { INITIAL_VALUES, SCREENS } from "utils/constants";
import CreateCoverage from "./Create";
import ListCoverages from "./List";

const Coverages = ({ addCoverage }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.INSURANCE_PLAN);
  const FORM = t("FORM", { returnObjects: true });

  const [openCreate, setOpenCreate] = useState(false);
  const [openCoverageBenefits, setOpenCoverageBenefits] = useState({
    show: false,
  });
  const [filter, setFilter] = useState({
    where: { insurancePlan: { id } },
    ...INITIAL_VALUES,
  });
  const showCreate = () => {
    setOpenCreate(true);
  };
  const onCloseCreate = () => {
    setOpenCreate(false);
  };

  const onDeleteCoverage = (id) =>
    navigate(`${SCREENS.PRIVATE.ADMIN.COVERAGES.DELETE.path}/${id}`);

  const showCoverageBenefits = (coverage) => {
    setOpenCoverageBenefits({ show: true, coverage });
    navigate(`${SCREENS.PRIVATE.ADMIN.COVERAGES.DETAIL.path}/${coverage.id}`);
  };
  const onCloseCoverageBenefits = () => {
    setOpenCoverageBenefits({ show: false });
  };

  return (
    <Card
      type={"simple"}
      head={true}
      title={FORM.COVERAGE.TITLE}
      actions={
        <Button
          type="primary--outlined"
          icon={<BsPlusCircle />}
          onClick={showCreate}
        >
          {FORM.COVERAGE.ACTIONS.ADD}
        </Button>
      }
    >
      <CoveragesEntity
        View={ListCoverages}
        NoData={Empty}
        filter={filter}
        skip={filter.skip || 0}
        setFilter={setFilter}
        showCoverageBenefits={showCoverageBenefits}
        deleteCoverage={onDeleteCoverage}
      />
      <CreateCoverage
        onClose={onCloseCreate}
        open={openCreate}
        translation={FORM.COVERAGE}
        addCoverage={addCoverage}
      />
    </Card>
  );
};

export default Coverages;
