import Header from "@bit/taillislabs.pega.header";
import Label from "@bit/taillislabs.pega.label";
import { Col, Row } from "antd";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Form from "shared/components/Form";
import Input from "shared/components/Form/Input";
import Select from "shared/components/Form/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { ORGANIZATION_TYPE } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Create = ({ onSubmit, id, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.ORGANIZATION);
  const CREATE = t("CREATE", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const TYPE = t("TYPE", { returnObjects: true });
  return (
    <Formik
      initialValues={{ name: "", type: "" }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, ...formProps }) => [
        <Header
          key={"header"}
          title={CREATE.HEADER.TITLE}
          subtitle={CREATE.HEADER.ACTION}
          okText={CREATE.HEADER.SAVE}
          hideButtons={!isValid}
          cancelText={CREATE.HEADER.DISCARD}
          onCancelClick={cancel}
          onOkClick={handleSubmit}
        />,
        <Row gutter={[16, 16]} key={"row"}>
          <Col span={24}>
            <Form title={FORM.GENERAL_INFO}>
              <Col lg={24}>
                <Label placeholder={FORM.ID} value={id} />
              </Col>
              <Col lg={12}>
                <Input
                  placeholder={FORM.NAME}
                  {...bindInputProps({ name: "name", ...formProps })}
                />
              </Col>
              <Col lg={12}>
                <Select
                  items={ORGANIZATION_TYPE.map((el) => ({
                    label: TYPE[el],
                    value: el,
                  }))}
                  placeholder={FORM.TYPE}
                  {...bindInputProps({ name: "type", ...formProps })}
                />
              </Col>
            </Form>
          </Col>
        </Row>,
      ]}
    </Formik>
  );
};

export default Create;
