import cuid from "cuid";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { SOURCES } from "utils/api/graphql/queries/sources";
import { INITIAL_VALUES, SCREENS } from "utils/constants";
import View from "./View";

const Create = () => {
  const navigate = useNavigate();
  const id = useMemo(() => cuid(), []);
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.SOURCES.LIST.path);
  const [addSource] = useMutation(graphql.mutations.CREATE_SOURCE, {
    refetchQueries: [
      {
        query: SOURCES,
        awaitRefetchQueries: true,
        variables: INITIAL_VALUES,
      },
    ],
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.ADMIN.SOURCES.LIST.path);
    },
    onError: () => {},
  });
  const onSubmit = (data) => {
    return addSource({
      variables: {
        data,
      },
    });
  };
  return <View onSubmit={onSubmit} id={id} cancel={cancel} />;
};

export default Create;
