import { gql } from "@apollo/client";

export const QUOTES = gql`
  query Quotes($where: QuoteWhereInput, $take: Int, $skip: Int) {
    quotes(where: $where, take: $take, skip: $skip) {
      data {
        id
      }
      count
    }
  }
`;

export const QUOTE = gql`
  query Quote($where: QuoteWhereInput) {
    quote(where: $where) {
      id
      project {
        id
        name
        expired
        status {
          id
          template
          name
          progress
          order
          moveToNext
          possibleStatuses {
            nextStatus
            conditions {
              operation
              rightOperand
              leftOperand
            }
          }
          process {
            id
          }
        }
        fields
        generalCosts {
          id
          cost
          currency
          commission {
            id
          }
          insurancePlan {
            id
            icon
            name
            description
            distributions {
              id
              broker {
                id
              }
              commissions {
                id
                value
                type
                withholding
              }
            }
            params {
              deltaStart
              deltaStartRIA
            }
            coverages {
              id
              type
              identifier
              coverageBenefits {
                id
                type
                subtype
                additionalInfo
                requirement
                order
                limits {
                  id
                  code
                  value
                }
                supportingInformation {
                  id
                  category
                  value
                }
              }
            }
          }
          project {
            id
          }
        }
        contact {
          id
          name
        }
      }
    }
  }
`;

const quotes = { QUOTES, QUOTE };

export default quotes;
