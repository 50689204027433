import gql from "graphql-tag";

export const CREATE_CONTRACT = gql`
  mutation AddContract($data: ContractDataInput) {
    addContract(data: $data)
  }
`;

export const UPDATE_CONTRACT = gql`
  mutation UpdateContract(
    $where: ContractWhereInput
    $data: ContractDataInput
  ) {
    updateContract(where: $where, data: $data)
  }
`;

export const DELETE_CONTRACT = gql`
  mutation RemoveContract($where: ContractWhereInput) {
    removeContract(where: $where)
  }
`;

const contracts = { CREATE_CONTRACT, UPDATE_CONTRACT, DELETE_CONTRACT };

export default contracts;
