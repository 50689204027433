import Adherent from "./Adherent";
import Children from "./Children";
import Contract from "./Contract";
import Spouse from "./Spouse";

const STEPS_KEYS = {
  CONTRACT: "CONTRACT",
  ADHERENT: "ADHERENT",
  SPOUSE: "SPOUSE",
  CHILDREN: "CHILDREN",
};

const STEPS = ({ index, values, translation, ...formProps }) => ({
  [STEPS_KEYS.CONTRACT]: Contract({
    index,
    translation,
    values,
    ...formProps,
  }),
  [STEPS_KEYS.ADHERENT]: Adherent({
    index,
    translation,
    values,
    ...formProps,
  }),
  [STEPS_KEYS.SPOUSE]: Spouse({
    index,
    translation,
    values,
    ...formProps,
  }),
  [STEPS_KEYS.CHILDREN]: Children({
    index,
    translation,
    values,
    ...formProps,
  }),
});

export default STEPS;
