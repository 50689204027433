import { Formik } from "formik";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { COMMISSION_TYPES, SCREENS } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Detail = ({ onSubmit, data, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.COMMISSION);
  const navigate = useNavigate();
  const DETAIL = t("DETAIL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  return (
    <Formik
      initialValues={data}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, setFieldValue, values, ...formProps }) => [
        <Header
          key={"header"}
          title={DETAIL.HEADER.TITLE}
          subtitle={DETAIL.HEADER.ACTION}
          actions={[
            {
              text: DETAIL.HEADER.DISCARD,
              onClick: cancel,
              type: "primary--outlined",
              icon: <BiChevronLeft size={20} />,
            },
            {
              text: DETAIL.HEADER.DELETE,
              onClick: () =>
                navigate(
                  `${SCREENS.PRIVATE.ADMIN.COMMISSIONS.DELETE.path}/${data.id}`
                ),
              type: "danger--outlined",
              icon: <BiTrash size={20} />,
            },
            {
              text: DETAIL.HEADER.SAVE,
              onClick: handleSubmit,
              type: "primary",
              icon: <BiCheck size={20} />,
            },
          ]}
        />,
        <Card title={FORM.TITLE} divider={false} key="commission">
          <div className="d-flex flex--wrap">
            <div className="grid--4 flex--grow-1 m-bottom-16">
              <Select
                options={Object.entries(COMMISSION_TYPES).map(
                  ([key, value]) => ({
                    label: FORM.TYPES[key],
                    value,
                  })
                )}
                placeholder={FORM.TYPE}
                label={FORM.TYPE}
                {...bindInputProps({
                  name: "type",
                  values,
                  ...formProps,
                })}
              />
              <Input
                placeholder={FORM.VALUE}
                label={FORM.VALUE}
                type="number"
                {...bindInputProps({
                  name: "value",
                  values,
                  ...formProps,
                })}
              />
              <Input
                placeholder={FORM.WITHHOLDING_SECOND}
                label={FORM.WITHHOLDING_SECOND}
                type="number"
                {...bindInputProps({
                  name: "withholding",
                  values,
                  ...formProps,
                })}
                disabled={get(values, `type`) === COMMISSION_TYPES.LINEAR}
              />
            </div>
          </div>
        </Card>,
      ]}
    </Formik>
  );
};

export default Detail;
