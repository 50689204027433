import cuid from "cuid";
import { find, get } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { INITIAL_VALUES, SCREENS, TRACER_TYPES } from "utils/constants";

const Fast = () => {
  const navigate = useNavigate();
  const id = useMemo(() => cuid(), []);
  const [addProject] = useMutation(graphql.mutations.CREATE_PROJECT, {
    refetchQueries: [
      {
        query: graphql.queries.PROJECTS,
        awaitRefetchQueries: true,
        variables: INITIAL_VALUES,
      },
    ],
    onCompleted: () => {
      navigate(`${SCREENS.PRIVATE.BROKER.PROJECTS.DETAIL.path}/${id}`);
    },
    onError: () => {},
  });
  const { data } = useQuery(graphql.queries.TRACERS, {
    variables: { where: { type: TRACER_TYPES.MANUAL } },
  });
  useEffect(() => {
    if (get(data, "tracers.data")) {
      const tracers = get(data, "tracers.data");
      const tracer = find(tracers, (tracer) => tracer.name === "Quick tracer");
      const {
        flow: { process },
      } = tracer;
      const status = omitDeep(process.processStatuses, "__typename").reduce(
        (previous, current) =>
          Number(previous?.order) < Number(current.order) ? previous : current
      );
      addProject({
        variables: {
          data: {
            id,
            name: "",
            status,
          },
        },
      });
    }
  }, [navigate, data, addProject, id]);

  return <Loading />;
};

export default Fast;
