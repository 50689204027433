import gql from "graphql-tag";

export const CREATE_CONTACT = gql`
  mutation AddContact($data: ContactDataInput) {
    addContact(data: $data) {
      id
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation UpdateContact($where: ContactWhereInput, $data: ContactDataInput) {
    updateContact(where: $where, data: $data) {
      id
    }
  }
`;

export const DELETE_CONTACT = gql`
  mutation DeleteContact($where: ContactWhereInput) {
    deleteContact(where: $where) {
      id
    }
  }
`;

const contacts = { CREATE_CONTACT, UPDATE_CONTACT, DELETE_CONTACT };

export default contacts;
