import { Radio } from "antd";
import PropTypes from "prop-types";

const Component = ({ name, items, onChange, value }) => {
  return (
    <div className="slider-dot--wrapper">
      <div className="slider-dot--container">
        <div className="slider-dot--content">
          {items.map((item) => {
            return (
              <div key={"radio-" + item} className="input-row">
                <Radio
                  name={name}
                  onChange={() => onChange(item)}
                  checked={item === value}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

Component.propTypes = {
  name: PropTypes.string,
  items: PropTypes.array,
  onChange: PropTypes.func,
};

Component.defaultProps = {
  name: "",
  items: [],
  onChange: () => {},
};

export default Component;
