import dayjs from "dayjs";
import moment from "moment";
import { DATE_TIME_FORMAT } from "utils/constants";

export const isMajor = dayjs().subtract(18, "years");

export const displayDate = ({
  date,
  format = DATE_TIME_FORMAT,
  defaultValue = "-",
}) =>
  date && moment(date).isValid() ? moment(date).format(format) : defaultValue;
