import { Breadcrumb } from "antd";
import PropTypes from "prop-types";

const Component = ({ items, separator, ...rest }) => {
  return (
    <div className="breadcrumb__wrapper">
      <Breadcrumb separator={separator} {...rest} items={items} />
    </div>
  );
};

Component.prototype = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ),
  separator: PropTypes.node,
};

Component.defaultProps = {
  items: [],
  separator: ">",
};

export default Component;
