import cuid from "cuid";
import { FieldArray, Formik } from "formik";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft, BiTrash } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import { COMMISSION_TYPES } from "utils/constants";
import { bindArrayInputProps } from "utils/helpers/input";
import validation from "./validation";

const Create = ({ onSubmit, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.COMMISSION);
  const CREATE = t("CREATE", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  return (
    <Formik
      initialValues={{
        commissions: [
          { id: cuid(), type: null, value: null, withholding: null },
        ],
      }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, setFieldValue, values, ...formProps }) => [
        <Header
          key={"header"}
          title={CREATE.HEADER.TITLE}
          subtitle={CREATE.HEADER.ACTION}
          actions={[
            {
              text: CREATE.HEADER.DISCARD,
              onClick: cancel,
              type: "primary--outlined",
              icon: <BiChevronLeft size={20} />,
            },
            {
              text: CREATE.HEADER.SAVE,
              onClick: handleSubmit,
              type: "primary",
              icon: <BiCheck size={20} />,
            },
          ]}
        />,
        <Card title={FORM.TITLE} divider={false} key="commissions">
          <FieldArray
            name={"commissions"}
            render={(arrayHelpers) => [
              get(values, "commissions", []).map((_, i) => (
                <div className="d-flex flex--wrap" key={_.id}>
                  <div className="grid--4 flex--grow-1 m-bottom-16">
                    <Select
                      options={Object.entries(COMMISSION_TYPES).map(
                        ([key, value]) => ({
                          label: FORM.TYPES[key],
                          value,
                        })
                      )}
                      placeholder={FORM.TYPE}
                      label={FORM.TYPE}
                      {...bindArrayInputProps({
                        parent: "commissions",
                        index: i,
                        name: "type",
                        values,
                        ...formProps,
                      })}
                    />
                    <Input
                      placeholder={FORM.VALUE}
                      label={FORM.VALUE}
                      type="number"
                      {...bindArrayInputProps({
                        parent: "commissions",
                        index: i,
                        name: "value",
                        values,
                        ...formProps,
                      })}
                    />
                    <Input
                      placeholder={FORM.WITHHOLDING_SECOND}
                      label={FORM.WITHHOLDING_SECOND}
                      type="number"
                      {...bindArrayInputProps({
                        parent: "commissions",
                        index: i,
                        name: "withholding",
                        values,
                        ...formProps,
                      })}
                      disabled={
                        get(values, `commissions.${i}.type`) ===
                        COMMISSION_TYPES.LINEAR
                      }
                    />
                  </div>
                  <BiTrash
                    color={COLORS.C_DANGER}
                    size={24}
                    onClick={() => {
                      arrayHelpers.remove(i);
                    }}
                  />
                </div>
              )),
              <Button
                type="primary"
                key="add-row"
                onClick={() => {
                  arrayHelpers.push({ id: cuid() });
                }}
              >
                <MdAdd />
              </Button>,
            ]}
          />
        </Card>,
      ]}
    </Formik>
  );
};

export default Create;
