import { gql } from "@apollo/client";

export const COMMISSIONS = gql`
  query commissions(
    $where: CommissionWhereInput
    $like: JSONObject
    $take: Int
    $skip: Int
    $orderBy: [JSONObject]
  ) {
    commissions(
      where: $where
      take: $take
      skip: $skip
      orderBy: $orderBy
      like: $like
    ) {
      data {
        id
        value
        type
        withholding
      }
      count
    }
  }
`;

export const COMMISSION = gql`
  query commission($where: CommissionWhereInput) {
    commission(where: $where) {
      id
      value
      type
      withholding
    }
  }
`;

const commissions = { COMMISSIONS, COMMISSION };

export default commissions;
