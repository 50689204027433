import { gql } from "@apollo/client";

const TRACER_ADDED = gql`
  subscription tracerAdded($where: TracerWhereInput) {
    tracerAdded(where: $where) {
      id
      name
      destination
      source {
        id
        name
      }
      flow {
        id
        process {
          id
          processStatuses {
            id
            name
            progress
            template
            order
            possibleStatuses {
              nextStatus
              conditions {
                operation
                rightOperand
                leftOperand
              }
            }
            moveToNext
          }
        }
      }
    }
  }
`;

const TRACER_UPDATED = gql`
  subscription tracerUpdated($where: TracerWhereInput) {
    tracerUpdated(where: $where) {
      id
      name
      destination
      source {
        id
        name
      }
      flow {
        id
        process {
          id
          processStatuses {
            id
            name
            progress
            template
            order
            possibleStatuses {
              nextStatus
              conditions {
                operation
                rightOperand
                leftOperand
              }
            }
            moveToNext
          }
        }
      }
    }
  }
`;

const TRACER_DELETED = gql`
  subscription tracerDeleted($where: TracerWhereInput) {
    tracerDeleted(where: $where) {
      id
      name
      destination
      source {
        id
        name
      }
      flow {
        id
        process {
          id
          processStatuses {
            id
            name
            progress
            template
            order
            possibleStatuses {
              nextStatus
              conditions {
                operation
                rightOperand
                leftOperand
              }
            }
            moveToNext
          }
        }
      }
    }
  }
`;

const tracers = { TRACER_ADDED, TRACER_UPDATED, TRACER_DELETED };

export default tracers;
