import { Descriptions } from "antd";

const Components = ({ layout = "vertical", items, ...props }) => {
  return (
    <div className="description-wrapper">
      <Descriptions layout={layout} items={items} {...props} />
    </div>
  );
};

export default Components;
