import gql from "graphql-tag";

export const CREATE_SUBSCRIPTION = gql`
  mutation AddSubscription($data: SubscriptionDataInput) {
    addSubscription(data: $data) {
      id
    }
  }
`;

export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription(
    $data: SubscriptionDataInput
    $where: SubscriptionWhereInput
  ) {
    updateSubscription(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_SUBSCRIPTION = gql`
  mutation DeleteSubscription($where: SubscriptionWhereInput) {
    deleteSubscription(where: $where) {
      id
    }
  }
`;

export const SEND_SUBSCRIPTION_ATTACHMENTS = gql`
  mutation sendSubscriptionAttachments(
    $where: SubscriptionWhereInput
    $data: SubscriptionAttachmentsInput
  ) {
    sendSubscriptionAttachments(where: $where, data: $data) {
      id
    }
  }
`;

export const EXPORT_SUBSCRIPTIONS = gql`
  mutation exportSubscriptions($where: SubscriptionWhereInput) {
    exportSubscriptions(where: $where)
  }
`;

export const SEND_SUBSCRIPTION_ADDITIONAL_DOCUMENTS = gql`
  mutation sendSubscriptionAdditionalDocument(
    $where: SubscriptionWhereInput
    $data: [AttachmentDataInput]
  ) {
    sendSubscriptionAdditionalDocument(where: $where, data: $data) {
      id
    }
  }
`;

const subscriptions = {
  CREATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
};

export default subscriptions;
