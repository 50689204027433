import { isEmpty } from "lodash";
import Comparison from "./Comparison";
import Filters from "./Filters";

const Widgets = ({ project }) => ({
  [true]: Filters,
  [isEmpty(project.fields)]: Filters,
  [!isEmpty(project.fields) && !isEmpty(project.fields.offer)]: Comparison,
});

const Product = ({ project, onNext }) => {
  const Widget = Widgets({ project })[true];
  return <Widget {...{ project, onNext }} />;
};

export default Product;
