import * as yup from "yup";

const validator = (messages) => {
  const requiredString = yup
    .string()
    .typeError(messages.REQUIRED)
    .required(messages.REQUIRED);
  return yup.object().shape({
    code: requiredString,
    socialReason: requiredString,
    orias: requiredString,
    organization: yup.object().shape({
      name: requiredString,
      form: requiredString,
    }),
    user: yup.object().shape({
      gender: requiredString,
      firstname: requiredString,
      lastname: requiredString,
      phone: requiredString,
      email: requiredString,
    }),
    distributions: yup.array().of(
      yup.object().shape({
        insurancePlan: yup.object().shape({
          id: requiredString,
        }),
        commissions: yup.array().of(requiredString).min(1, messages.REQUIRED),
      })
    ),
  });
};

export default validator;
