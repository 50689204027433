import { Menu as MenuAntd } from "antd";
import { get } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

const Menu = ({ topics }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <MenuAntd
      mode="inline"
      defaultSelectedKeys={[
        get(
          topics.findLast((topic) => pathname.includes(topic.key)),
          "key"
        ),
      ]}
      className="items"
      onClick={({ key }) => navigate(key)}
      items={topics}
    />
  );
};

export default Menu;
