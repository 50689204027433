import cuid from "cuid";
import { get, isEmpty, isNumber } from "lodash";
import moment from "moment";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import {
  DELTA_END_TYPES,
  INITIAL_VALUES,
  PERIOD_UNITS,
  SCREENS,
} from "utils/constants";
import View from "./View";

const DELTA_END_VALUES = {
  [DELTA_END_TYPES.END_OF_YEAR]: () => 0,
  [DELTA_END_TYPES.ADD_PERIOD]: (start, end, unit = PERIOD_UNITS.DAYS) => {
    if (!isNumber(end) || !isNumber(start)) return null;
    return moment()
      .add(start || 0, "days")
      .add(end, unit)
      .diff(moment().startOf("day"), "days");
  },
  DEFAULT: () => null,
};

const Create = () => {
  const navigate = useNavigate();
  const id = useMemo(() => cuid(), []);
  const cancel = () =>
    navigate(SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.LIST.path);
  const [addInsurancePlan] = useMutation(
    graphql.mutations.CREATE_INSURANCE_PLAN,
    {
      refetchQueries: [
        {
          query: graphql.queries.INSURANCE_PLANS,
          awaitRefetchQueries: true,
          variables: INITIAL_VALUES,
        },
      ],
      onCompleted: () => {
        navigate(SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.LIST.path);
      },
      onError: () => {},
    }
  );

  const onSubmit = (values) => {
    const {
      date,
      deltaEndType,
      deltaEndUnit,
      params,
      iconAttachment,
      icon,
      ...rest
    } = values;

    addInsurancePlan({
      variables: {
        data: {
          id,
          identifier: cuid(),
          startDate: get(date, "0"),
          endDate: get(date, "1"),
          icon: iconAttachment,
          ...(!isEmpty(params) && {
            params: params.map(
              ({
                deltaStart,
                deltaStartRIA,
                deltaEnd,
                deltaEndRIA,
                exerciseFrame,
                minAge,
                maxAge,
                socialRegimes,
                ...values
              }) => ({
                ...values,
                deltaStart: deltaStart || 0,
                deltaStartRIA: deltaStartRIA || 30,
                deltaEnd: DELTA_END_VALUES[deltaEnd?.type || "DEFAULT"](
                  deltaStart,
                  deltaEnd?.value,
                  deltaEnd?.unit
                ),
                deltaEndRIA: DELTA_END_VALUES[deltaEndRIA?.type || "DEFAULT"](
                  deltaStartRIA,
                  deltaEndRIA?.value,
                  deltaEndRIA?.unit
                ),
                socialRegimes: isEmpty(socialRegimes) ? null : socialRegimes,
                exerciseFrame: exerciseFrame || null,
                minAge: minAge || null,
                maxAge: maxAge || null,
              })
            ),
          }),
          ...rest,
        },
      },
    });
  };
  return <View onSubmit={onSubmit} id={id} cancel={cancel} />;
};

export default Create;
