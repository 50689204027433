import { Col, Row } from "antd";
import { get, range } from "lodash";
import { useTranslation } from "react-i18next";
import AlternativeMedicine from "shared/assets/images/ayurveda.png";
import Dental from "shared/assets/images/dental-checkup.png";
import Equipment from "shared/assets/images/hearing-aid.png";
import Hospitalization from "shared/assets/images/hospital-bed.png";
import MedicalCare from "shared/assets/images/medical-team.png";
import Optical from "shared/assets/images/vision.png";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import Slider from "shared/components/Slider";
import { NAME_SPACES } from "shared/locales/constants";
import { COVERAGE_NEEDS } from "utils/constants";

const Filters = ({ fields, setFields, onFilter, needs, setNeeds }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const COVERAGE_CATEGORIES_ENTRIES = [
    {
      icon: Hospitalization,
      label: DETAIL.TEMPLATES.FILTERS.COVERAGE_CLASSES.HOSPITALIZATION,
      key: COVERAGE_NEEDS.HOSPITALIZATION,
    },
    {
      icon: MedicalCare,
      label: DETAIL.TEMPLATES.FILTERS.COVERAGE_CLASSES.MEDICAL_CARE,
      key: COVERAGE_NEEDS.MEDICAL_CARE,
    },
    {
      icon: Optical,
      label: DETAIL.TEMPLATES.FILTERS.COVERAGE_CLASSES.OPTICAL,
      key: COVERAGE_NEEDS.OPTICAL,
    },
    {
      icon: Dental,
      label: DETAIL.TEMPLATES.FILTERS.COVERAGE_CLASSES.DENTAL,
      key: COVERAGE_NEEDS.DENTAL,
    },
    {
      icon: Equipment,
      label: DETAIL.TEMPLATES.FILTERS.COVERAGE_CLASSES.EQUIPMENT,
      key: COVERAGE_NEEDS.EQUIPMENT,
    },
    {
      icon: AlternativeMedicine,
      label: DETAIL.TEMPLATES.FILTERS.COVERAGE_CLASSES.ALTERNATIVE_MEDICINE,
      key: COVERAGE_NEEDS.ALTERNATIVE_MEDICINE,
    },
  ];
  const levels = range(0, 6);

  return (
    <div className="card-skeleton--container highlighted">
      <div className="card-skeleton--wrapper">
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <h3 className="title--md">
              {DETAIL.TEMPLATES.FILTERS.LEVEL_OF_GUARANTEES}
            </h3>
          </Col>
          <Col lg={24} xs={24}>
            <Row gutter={[8, 8]}>
              {COVERAGE_CATEGORIES_ENTRIES.map(({ icon, label, key }) => (
                <Col key={key} lg={24} xs={24}>
                  <div className="levels-coverage--wrapper">
                    <div className="d-flex align--center">
                      <img className="img" src={icon} alt={label} />
                      <span className="label-coverage">{label}</span>
                    </div>
                    <div className="custom-slider--wrapper">
                      <Slider
                        type="dot"
                        items={levels}
                        name={"grp" + label}
                        value={get(needs, key)}
                        onChange={(item) =>
                          setNeeds((prev) => ({
                            ...prev,
                            [[key]]: item,
                          }))
                        }
                      />
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <div className="row-divider" />
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <h3 className="title--md">
              {DETAIL.TEMPLATES.FILTERS.BUDGET.TITLE}
            </h3>
          </Col>
          <Col lg={24} xs={24}>
            <Row gutter={[24, 24]}>
              <Col lg={24} xs={24}>
                <div className="custom-slider--wrapper">
                  <Slider
                    key={
                      "key-" +
                      get(fields, "budget.min") +
                      get(fields, "budget.max")
                    }
                    type="line"
                    tooltip={{
                      open: true,
                      formatter: (value) => `${value} €`,
                    }}
                    range
                    min={0}
                    max={1000}
                    defaultValue={[
                      get(fields, "budget.min"),
                      get(fields, "budget.max"),
                    ]}
                    onAfterChange={(data) =>
                      setFields((fields) => ({
                        ...fields,
                        budget: {
                          ...fields.budget,
                          min: data[0],
                          max: data[1],
                        },
                      }))
                    }
                  />
                </div>
              </Col>
              <Col lg={24} xs={24}>
                <div className="custom-input__wrapper reverse">
                  <Row align="top" gutter={[7, 7]}>
                    <Col lg={11} xs={24}>
                      <Input
                        type="number"
                        suffix="€"
                        min="0"
                        max={get(fields, "budget.max")}
                        value={get(fields, "budget.min")}
                        label={DETAIL.TEMPLATES.FILTERS.BUDGET.MIN}
                        onChange={({ target: { value } }) =>
                          setFields((fields) => ({
                            ...fields,
                            budget: {
                              ...fields.budget,
                              min: Number(value),
                            },
                          }))
                        }
                      />
                    </Col>
                    <Col lg={2} xs={24}>
                      <div className="divider-input" />
                    </Col>
                    <Col lg={11} xs={24}>
                      <Input
                        type="number"
                        suffix="€"
                        min={get(fields, "budget.min")}
                        max="300"
                        value={get(fields, "budget.max")}
                        label={DETAIL.TEMPLATES.FILTERS.BUDGET.MAX}
                        onChange={({ target: { value } }) =>
                          setFields((fields) => ({
                            ...fields,
                            budget: {
                              ...fields.budget,
                              max: Number(value),
                            },
                          }))
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={24} xs={24}>
            <Button
              onClick={() =>
                onFilter({
                  min: get(fields, "budget.min"),
                  max: get(fields, "budget.max"),
                  needs,
                })
              }
              type="secondary"
              block
            >
              {DETAIL.TEMPLATES.FILTERS.BUTTON.TEXT.REFRESH_PRODUCT_LIST}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Filters;
