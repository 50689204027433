import { notification } from "antd";
import cuid from "cuid";
import { get, groupBy } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "react-material-symbols/rounded";
import Button from "shared/components/Button";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import Modal from "shared/components/Modal";
import Loading from "shared/components/Spin";
import { NAME_SPACES } from "shared/locales/constants";
import { downloadDocument, downloadFiles } from "utils/helpers/files";
import { STEPPER_KEYS } from "../../../..";
import File, { FILE_MODE } from "../elements/File";

const Documents = ({
  additionalInfoPercentage,
  setActive,
  sendAttachments,
  subscription,
  downloadDocuments,
  generateEsingDocments,
  generateDutyOfAdviceDocument,
  generateMembershipFormDocument,
  generateTerminationMandateDocument,
  generateTerminationLetterDocument,
  generateSepaDirectDebitMandateDocument,
  downloadSingleDocument,
  updateSubscription,
}) => {
  const [selectedData, setSelectedData] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const sendEsignRequest = ({ attachmentsId }) => {
    const sendAttachmentsOptions = {
      variables: {
        where: { id: subscription.id },
        data: {
          attachmentsId,
          locked: true,
        },
      },
      onCompleted: () =>
        notification.open({
          message: DOCUMENTS.NOTIFICATION.SUCCESS,
          duration: 5,
          type: "success",
        }),
    };
    return sendAttachments(sendAttachmentsOptions);
  };

  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", {
    returnObjects: true,
  });
  const DOCUMENTS = t("SUBSCRIPTION.DOCUMENT.DOCUMENTS", {
    returnObjects: true,
  });

  const onDownloadCompleted = async ({ base64, contentType, name }) => {
    updateSubscription({
      variables: {
        where: {
          id: get(subscription, "id"),
        },
        data: {
          locked: true,
        },
      },
      onCompleted: () =>
        downloadDocument({ base64, contentType, name: `${name}.pdf` }),
    });
  };

  const SECTIONS = useMemo(() => {
    const {
      MEMBERSHIP_FORM = [],
      DUTY_OF_ADVICE = [],
      SEPA_DIRECT_DEBIT_MANDATE = [],
      TERMINATION_LETTER = [],
      TERMINATION_MANDATE = [],
      IPID = [],
      COVERAGE_GRID = [],
      INFO_NOTICE = [],
      BROCHURE = [],
    } = groupBy(subscription?.attachments, "type");

    return [
      {
        title: DOCUMENTS.PERSONALIZED_STUDY,
        isChecked: true,
        files: [
          ...(subscription.locked && DUTY_OF_ADVICE.length
            ? DUTY_OF_ADVICE.map(({ name, ...rest }) => ({
                ...rest,
                filename: name,
                mode: FILE_MODE.SIMPLE_FILE,
              }))
            : [
                {
                  id: cuid(),
                  filename: "Devoir de conseil",
                  mode: FILE_MODE.SIMPLE_FILE,
                  actions: [],
                  disabled: additionalInfoPercentage < 100,
                  generate: true,
                  onClick: () =>
                    generateDutyOfAdviceDocument({
                      variables: {
                        data: {
                          payload: {
                            subscription: { id: get(subscription, "id") },
                          },
                        },
                      },
                      onCompleted: async ({
                        generateDutyOfAdviceDocument: {
                          base64,
                          contentType,
                          name,
                        },
                      }) => onDownloadCompleted({ base64, contentType, name }),
                    }),
                },
              ]),
          ...(subscription.locked && SEPA_DIRECT_DEBIT_MANDATE.length
            ? SEPA_DIRECT_DEBIT_MANDATE.map(({ name, ...rest }) => ({
                ...rest,
                filename: name,
                mode: FILE_MODE.SIMPLE_FILE,
              }))
            : [
                {
                  id: cuid(),
                  filename: "mandat de prélèvement SEPA",
                  mode: FILE_MODE.SIMPLE_FILE,
                  actions: [],
                  disabled: additionalInfoPercentage < 100,
                  generate: true,
                  onClick: () =>
                    generateSepaDirectDebitMandateDocument({
                      variables: {
                        data: {
                          payload: {
                            subscription: { id: get(subscription, "id") },
                          },
                        },
                      },
                      onCompleted: async ({
                        generateSepaDirectDebitMandateDocument: {
                          base64,
                          contentType,
                          name,
                        },
                      }) => onDownloadCompleted({ base64, contentType, name }),
                    }),
                },
              ]),
        ],
      },
      {
        title: DOCUMENTS.TERMINATION_DOCUMENTS,
        isChecked: true,
        isRia: true,
        files: [
          ...(subscription.locked && TERMINATION_LETTER.length
            ? TERMINATION_LETTER.map(({ name, ...rest }) => ({
                ...rest,
                filename: name,
                mode: FILE_MODE.SIMPLE_FILE,
              }))
            : [
                {
                  id: cuid(),
                  filename: "Courrier de résiliation",
                  mode: FILE_MODE.SIMPLE_FILE,
                  actions: [],
                  disabled: additionalInfoPercentage < 100,
                  generate: true,
                  onClick: () =>
                    generateTerminationLetterDocument({
                      variables: {
                        data: {
                          payload: {
                            subscription: { id: get(subscription, "id") },
                          },
                        },
                      },
                      onCompleted: async ({
                        generateTerminationLetterDocument: {
                          base64,
                          contentType,
                          name,
                        },
                      }) => onDownloadCompleted({ base64, contentType, name }),
                    }),
                },
              ]),
          ...(subscription.locked && TERMINATION_MANDATE.length
            ? TERMINATION_MANDATE.map(({ name, ...rest }) => ({
                ...rest,
                filename: name,
                mode: FILE_MODE.SIMPLE_FILE,
              }))
            : [
                {
                  id: cuid(),
                  filename: "Mandat de résiliation",
                  mode: FILE_MODE.SIMPLE_FILE,
                  actions: [],
                  disabled: additionalInfoPercentage < 100,
                  generate: true,
                  onClick: () =>
                    generateTerminationMandateDocument({
                      variables: {
                        data: {
                          payload: {
                            subscription: { id: get(subscription, "id") },
                          },
                        },
                      },
                      onCompleted: async ({
                        generateTerminationMandateDocument: {
                          base64,
                          contentType,
                          name,
                        },
                      }) => onDownloadCompleted({ base64, contentType, name }),
                    }),
                },
              ]),
        ],
      },
      {
        title: DOCUMENTS.MEMBERSHIP_APPLICATION,
        isChecked: true,
        files: [
          ...(subscription.locked && MEMBERSHIP_FORM.length
            ? MEMBERSHIP_FORM.map(({ name, ...rest }) => ({
                ...rest,
                filename: name,
                mode: FILE_MODE.SIMPLE_FILE,
              }))
            : [
                {
                  id: cuid(),
                  filename: "Demande d'adhésion",
                  mode: FILE_MODE.FILE_WITH_PERCENTAGE,
                  percentageLabel: `${additionalInfoPercentage}% ${SUBSCRIPTION.DONE}`,
                  percentage: additionalInfoPercentage,
                  generate: true,
                  actions: [
                    ...(additionalInfoPercentage < 100
                      ? [
                          {
                            icon: <Icon icon="edit_note" size={16} />,
                            text: "Remplir",
                            onClick: () =>
                              setActive(STEPPER_KEYS.ADDITIONAL_INFORMATION),
                          },
                        ]
                      : []),
                  ],
                  disabled: additionalInfoPercentage < 100,
                  onClick: () =>
                    generateMembershipFormDocument({
                      variables: {
                        data: {
                          payload: {
                            subscription: { id: get(subscription, "id") },
                          },
                        },
                      },
                      onCompleted: async ({
                        generateMembershipFormDocument: {
                          base64,
                          contentType,
                          name,
                        },
                      }) => onDownloadCompleted({ base64, contentType, name }),
                    }),
                },
              ]),
        ],
      },
      {
        title: DOCUMENTS.NOTICES,
        files: [
          ...INFO_NOTICE.map(({ name, ...rest }) => ({
            ...rest,
            filename: name,
          })),
          ...IPID.map(({ name, ...rest }) => ({
            ...rest,
            filename: name,
          })),
          ...BROCHURE.map(({ name, ...rest }) => ({
            ...rest,
            filename: name,
          })),
        ],
      },
    ];
  }, [additionalInfoPercentage]);

  const onCheck = (record, state) => {
    setSelectedData((prev) => {
      const data = state
        ? [...prev, record]
        : [...prev.filter((item) => item !== record)];
      setIsAllChecked(
        data.length ===
          SECTIONS.reduce((acc, { files = [] }) => acc + files.length, 0)
      );
      return data;
    });
  };

  const selectAll = (e) => {
    setIsAllChecked(e.target.checked);
    e.target.checked
      ? SECTIONS.map((record) =>
          record.files
            .filter(
              ({ mode, percentage }) =>
                percentage === 100 || mode !== FILE_MODE.FILE_WITH_PERCENTAGE
            )
            .map((file) => setSelectedData((prev) => [...prev, file]))
        )
      : setSelectedData([]);
  };

  const downloadSelected = async () => {
    if (
      selectedData.find(({ mode }) => mode === FILE_MODE.FILE_WITH_PERCENTAGE)
    )
      return setVisible(true);
    setLoading(true);
    const documents = await selectedData.map((document) => document.id);
    await downloadDocuments({
      variables: {
        data: {
          isIn: {
            id: documents,
          },
        },
      },
      onCompleted: (_) => {
        const { base64 } = omitDeep(_.download, "__typename");
        downloadFiles(base64);
        setLoading(false);
      },
    });
  };

  const download = async (file) => {
    const { id, generate } = file;
    if (generate) return file.onClick();

    await downloadSingleDocument({
      variables: { where: { id } },
      onCompleted: (_) => {
        const { document } = omitDeep(_.downloadSingleDocument, "__typename");
        downloadDocument(document);
      },
    });
  };

  const onSubmitModal = async () => {
    setLoading(true);
    return generateEsingDocments({
      variables: {
        data: { id: get(subscription, "id") },
      },
      onCompleted: async ({ generateEsingDocuments: { base64 } }) => {
        await updateSubscription({
          variables: {
            where: {
              id: get(subscription, "id"),
            },
            data: {
              locked: true,
            },
          },
          onCompleted: async () => {
            downloadFiles(base64);
            setVisible(false);
            setLoading(true);
          },
        });
      },
    });
  };

  const onSend = async () => {
    const dynamicFile = selectedData.find(
      ({ mode }) => mode === FILE_MODE.FILE_WITH_PERCENTAGE
    );

    const attachmentsId = selectedData
      .filter(({ mode }) => mode === FILE_MODE.SIMPLE_FILE)
      .map(({ id }) => id);

    if (!!dynamicFile) {
      return generateEsingDocments({
        variables: {
          data: { id: get(subscription, "id") },
        },
        onCompleted: async ({ generateEsingDocuments: { attachments } }) =>
          await sendEsignRequest({
            attachmentsId: attachmentsId.concat(
              ...attachments.map(({ id }) => id)
            ),
          }),
      });
    } else await sendEsignRequest({ attachmentsId });

    setSelectedData([]);
  };

  if (loading) return <Loading />;

  return (
    <div className="subscription-form--wrapper">
      <Modal
        mode="simple"
        type="warning"
        okText={DOCUMENTS.MODAL.OK}
        cancelText={DOCUMENTS.MODAL.CANCEL}
        onCancel={() => setVisible(false)}
        onOk={onSubmitModal}
        message={DOCUMENTS.MODAL.MESSAGE}
        open={visible}
      />
      <Form type="vertical" title={DOCUMENTS.TITLE}>
        <div className="snippet_wrapper">
          <span className="snippet_wrapper--title">
            {DOCUMENTS.SNIPPET.TITLE}
          </span>
          <span className="snippet_wrapper--description">
            {DOCUMENTS.SNIPPET.DESCRIPTION}
          </span>

          <div className="snippet_wrapper--detail">
            <div className="snippet_wrapper--detail__row">
              <span>{DOCUMENTS.SNIPPET.BY_EMAIL}</span>
              <span>service_gestion@mcci.fr</span>
            </div>
            <div className="snippet_wrapper--detail__divider">Ou</div>
            <div className="snippet_wrapper--detail__row">
              <span>{DOCUMENTS.SNIPPET.BY_MAIL}</span>
              <span>MCCI – TSA 99985 – 75839 Paris cedex 17</span>
            </div>
          </div>
        </div>

        <div className="document-list_wrapper">
          <div className="document-list_wrapper--actions">
            <div className="right_column">
              <Input
                type="checkbox"
                onChange={selectAll}
                label={DOCUMENTS.SELECT_ALL}
                checked={isAllChecked}
              />
            </div>
            <div className="left_column">
              <Button
                type="primary--link"
                alignment="center"
                disabled={!selectedData.length}
                onClick={onSend}
              >
                <Icon icon="attach_email" size={16} />
                {DOCUMENTS.SEND}
              </Button>
              <Button
                type="primary--link"
                alignment="center"
                onClick={downloadSelected}
                disabled={!selectedData.length}
              >
                <Icon icon="download" size={16} />
                {DOCUMENTS.DOWNLOAD}
              </Button>
            </div>
          </div>
          {SECTIONS.map(
            (item, index) =>
              (!item.isRia ||
                item.isRia === get(subscription, "project.ria")) && (
                <div
                  className="document-list_wrapper--file-section"
                  key={`section-${index}`}
                >
                  <span className="document-list_wrapper--file-section__title">
                    {item.title}
                  </span>
                  <div className="document-list_wrapper--file-section__content">
                    {item.files.map((file, index) => (
                      <div className="file_wrapper" key={`file-${index}`}>
                        <div className="file_wrapper--metadata">
                          <div className="file_wrapper--metadata__content">
                            <Input
                              type="checkbox"
                              checked={
                                !!selectedData.find(
                                  (item) => item.id === file.id
                                )
                              }
                              onChange={(e) => onCheck(file, e.target.checked)}
                              disabled={file.disabled}
                            />
                            <File element={file} mode={file.mode} />
                          </div>
                        </div>
                        <div className="file_wrapper--action">
                          <Button
                            disabled={file.disabled}
                            onClick={() => download(file)}
                          >
                            <Icon icon="download_for_offline" size={20} />
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )
          )}
        </div>
      </Form>
    </div>
  );
};

export default Documents;
