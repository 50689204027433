import { Table } from "antd";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { MdDeleteOutline, MdOpenInNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Pagination from "shared/components/Pagination";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import { SCREENS } from "utils/constants";

function List({ data, count, setValues, skip }) {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.COMMISSION);
  const LIST = t("LIST", { returnObjects: true });

  const columns = [
    {
      title: LIST.TYPE,
      dataIndex: "type",
      render: (record) => get(LIST, `TYPES.${record}`),
    },
    {
      title: LIST.VALUE,
      dataIndex: "value",
      render: (record, row) => `${record}/${row.withholding || record}`,
    },
    {
      title: LIST.ACTIONS,
      dataIndex: "id",
      align: "right",
      render: (id) => (
        <div className="d-flex justify--end">
          <div
            onClick={() =>
              navigate(`${SCREENS.PRIVATE.ADMIN.COMMISSIONS.DETAIL.path}/${id}`)
            }
          >
            <MdOpenInNew size={24} color={COLORS.C_PRIMARY} />
          </div>
          <div
            onClick={() =>
              navigate(`${SCREENS.PRIVATE.ADMIN.COMMISSIONS.DELETE.path}/${id}`)
            }
          >
            <MdDeleteOutline size={24} color={COLORS.C_DANGER} />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        dataSource={data}
        className="table__wrapper"
        columns={columns}
        rowKey={"id"}
        pagination={false}
        scroll={{ x: true }}
      />
      <Pagination count={count} setValues={setValues} skip={skip} />
    </>
  );
}

export default List;
