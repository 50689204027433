import { Modal } from "antd";
import PropTypes from "prop-types";

const Component = ({ message, child, ...rest }) => (
  <Modal className="modal-container" {...rest}>
    {message}
    {child}
  </Modal>
);

Component.propTypes = {
  child: PropTypes.node,
  message: PropTypes.string,
};
Component.defaultProps = {};

export default Component;
