import { gql } from "@apollo/client";

export const CONTRACTS = gql`
  query Contracts(
    $where: ContractWhereInput
    $take: Int
    $skip: Int
    $orderBy: [JSONObject]
  ) {
    contracts(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
      data {
        id
        url
        status
        legalState
        issuanceDate
        contentDerivative
        createdDate
        expirationDate
        expirationType
        instantiatesUri
        subjects
        name
        title
        subTitle
        alias
        scope
        type
        subType
      }
      count
    }
  }
`;

export const CONTRACT = gql`
  query Contract($where: ContractWhereInput) {
    contract(where: $where) {
      id
      identifier
      url
      status
      legalState
      issuanceDate
      contentDerivative
      createdDate
      expirationDate
      expirationType
      instantiatesUri
      subjects
      name
      title
      subTitle
      alias
      scope
      type
      subType
    }
  }
`;

const contracts = { CONTRACTS, CONTRACT };

export default contracts;
