import { Modal } from "antd";
import cuid from "cuid";
import { get } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { useMutation, useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { INPUT_TYPES, NAVIGATION_DIRECTIONS } from "utils/constants";

const INPUTS = {
  [INPUT_TYPES.TEXT]: ({ onChange, ...rest }) => (
    <Input onChange={(e) => onChange(e.target.value)} {...rest} />
  ),
  [INPUT_TYPES.DATE]: ({ onChange, ...rest }) => (
    <Input type="date" onChange={(_, date) => onChange(date)} {...rest} />
  ),
  [INPUT_TYPES.SELECT]: (props) => <Select {...props} />,
};

const Create = () => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.QUALIFICATION);
  const CREATE = t("CREATE", { returnObjects: true });
  const [content, setContent] = useState({});
  const navigate = useNavigate();
  const { state } = useLocation();
  const { contact, qualifier } = state;

  const [createQualification] = useMutation(
    graphql.mutations.CREATE_QUALIFICATIONS,
    {
      onCompleted: () => {
        return navigate(NAVIGATION_DIRECTIONS.BACK);
      },
      onError: () => {},
    }
  );
  const { data } = useQuery(graphql.queries.QUALIFICATION, {
    variables: {
      where: { qualifier: { id: qualifier.id } },
    },
  });

  return (
    <Modal
      open={true}
      okText={CREATE.MODAL.SAVE}
      cancelText={CREATE.MODAL.CANCEL}
      onOk={() => {
        createQualification({
          variables: {
            data: {
              id: cuid(),
              contact: { id: contact.id },
              qualifier: { id: qualifier.id },
              content: { ...content },
            },
          },
        });
      }}
      onCancel={() => navigate(NAVIGATION_DIRECTIONS.BACK)}
    >
      {get(data, "qualification.qualifier.fields.requirements", [])?.map(
        (element) => {
          const template = get(INPUTS, element.type, INPUT_TYPES.TEXT);
          return template({
            key: element.name,
            placeholder: element.name,
            options: get(element, "options", []),
            onChange: (value) => {
              return setContent((content) => ({
                ...content,
                [element.name]: value,
              }));
            },
          });
        }
      )}
    </Modal>
  );
};

export default Create;
