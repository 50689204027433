import { get, isNil } from "lodash";
import { useState } from "react";
import Input from "shared/components/Input";
import { CURRENCY_SYMBOL } from "utils/constants";

const DUMMY_ICON = "https://icon-library.com/images/icon-url/icon-url-15.jpg";

const Card = ({ generalCost, period, onBlur }) => {
  const [price, setPrice] = useState(get(generalCost, "cost", ""));
  const currency = CURRENCY_SYMBOL[get(generalCost, "cost.currency", "EUR")];
  return (
    <div className="insurance-plan">
      <img
        className="insurance-plan__icon"
        src={
          isNil(generalCost.insurancePlan.icon)
            ? DUMMY_ICON
            : generalCost.insurancePlan.icon
        }
        alt={generalCost.insurancePlan.name}
      />
      <div className="insurance-plan__title">
        {generalCost.insurancePlan.name}
        <div className="insurance-plan__title--light">
          {generalCost.insurancePlan.description}
        </div>
      </div>
      <div className="insurance-plan__cost">
        <Input
          type="number"
          placeholder={"00.00"}
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          onBlur={(e) => onBlur(e.target.value)}
        />
        <span className="insurance-plan__cost--integer">{currency}</span>
        <span className="insurance-plan__cost--period">{` /${period}`}</span>
      </div>
    </div>
  );
};

export default Card;
