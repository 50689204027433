import { PlusOutlined } from "@ant-design/icons";
import { Col, Row, Switch } from "antd";
import cuid from "cuid";
import { FieldArray, Formik } from "formik";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft, BiTrash } from "react-icons/bi";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import DatePicker from "shared/components/Form/DatePicker";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import queries from "utils/api/graphql/queries";
import {
  ATTACHMENT_TYPES,
  CONTACT_EXERCISE_FRAME,
  COVERAGE_NEEDS,
  DELTA_END_TYPES,
  PAYMENT_FREQUENCIES,
  PAYMENT_TYPES,
  PERIOD_UNITS,
  SOCIAL_REGIMES,
} from "utils/constants";
import { bindArrayInputProps, bindInputProps } from "utils/helpers/input";
import Recommendation from "./Blocks/Recommendation";
import validation from "./validation";

const Create = ({ onSubmit, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.INSURANCE_PLAN);
  const CREATE = t("CREATE", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const handleFileUpload = async (file, setFieldValue) => {
    const { base64, ...fileData } = file;
    setFieldValue("iconAttachment", {
      ...fileData,
      id: cuid(),
      content: base64,
      type: ATTACHMENT_TYPES.ICON,
    });
    setFieldValue("icon", file.base64);
  };
  return (
    <Formik
      initialValues={{
        coverages: [],
        commissions: [],
        needs: {
          id: cuid(),
          [COVERAGE_NEEDS.OPTICAL]: 0,
          [COVERAGE_NEEDS.DENTAL]: 0,
          [COVERAGE_NEEDS.MEDICAL_CARE]: 0,
          [COVERAGE_NEEDS.HOSPITALIZATION]: 0,
          [COVERAGE_NEEDS.EQUIPMENT]: 0,
          [COVERAGE_NEEDS.ALTERNATIVE_MEDICINE]: 0,
        },
      }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({
        isValid,
        handleSubmit,
        setFieldValue,
        setValues,
        values,
        ...formProps
      }) => [
        <Header
          key={"header"}
          title={CREATE.HEADER.TITLE}
          subtitle={CREATE.HEADER.ACTION}
          actions={[
            {
              text: CREATE.HEADER.DISCARD,
              onClick: cancel,
              type: "primary--outlined",
              icon: <BiChevronLeft size={20} />,
            },
            {
              text: CREATE.HEADER.SAVE,
              onClick: handleSubmit,
              type: "primary",
              icon: <BiCheck size={20} />,
            },
          ]}
        />,
        <Card key={"generalInfo"} divider={false} title={FORM.GENERAL_INFO}>
          <div className="grid--2">
            <Input
              placeholder={FORM.NAME}
              label={FORM.NAME}
              {...bindInputProps({ name: "name", values, ...formProps })}
            />

            <Input
              placeholder={FORM.DESCRIPTION}
              label={FORM.DESCRIPTION}
              {...bindInputProps({
                name: "description",
                values,
                ...formProps,
              })}
            />
            <Input
              placeholder={FORM.ALIAS}
              label={FORM.ALIAS}
              {...bindInputProps({ name: "alias", values, ...formProps })}
            />
            <DatePicker
              placeholder={FORM.DATE}
              label={FORM.DATE}
              placeholders={[FORM.START_DATE, FORM.END_DATE]}
              onChange={(_, dates) => setFieldValue("date", dates)}
            />
            <div className="d-flex">
              <strong>{FORM.ACTIVE}</strong>
              <Switch
                className="button__switch"
                checkedChildren={FORM.YES}
                unCheckedChildren={FORM.NO}
                defaultChecked={false}
                onChange={(e, _) => setFieldValue("active", e)}
              />
            </div>
            <div className="d-flex">
              <span>{FORM.MADELIN}</span>
              <Switch
                className="button__switch"
                checkedChildren={FORM.YES}
                unCheckedChildren={FORM.NO}
                defaultChecked={false}
                onChange={(e, _) => setFieldValue("fields.madelin", e)}
              />
            </div>
            <Input
              type="file"
              variant="uploader"
              listType="picture"
              showUploadList={false}
              maxCount={1}
              onUpload={([file]) => handleFileUpload(file, setFieldValue)}
              fileList={
                isEmpty(get(values, "icon", [])) ? [] : [get(values, "icon")]
              }
            >
              <Button type="primary--link">{FORM.ICON}</Button>
              <div>
                {get(values, "icon") && (
                  <img className="icon" alt="icon" src={get(values, "icon")} />
                )}
              </div>
            </Input>
          </div>
        </Card>,
        <div className="m-bottom-24" key="divider-1" />,
        <Card title={FORM.COMMISSIONS.TITLE} divider={false} key="commission">
          <div className="grid--4">
            <Select
              type="async"
              mode="multiple"
              node={{
                query: queries.COMMISSIONS,
                accessors: {
                  root: "commissions.data",
                  label: {
                    separator: "/",
                    keys: ["value", "withholding/value"],
                  },
                },
              }}
              placeholder={FORM.COMMISSIONS.TITLE}
              label={FORM.COMMISSIONS.TITLE}
              {...bindInputProps({
                name: "commissions",
                values,
                ...formProps,
              })}
              onChange={(value) => setFieldValue("commissions", value)}
            />
          </div>
        </Card>,
        <div className="m-bottom-24" key="divider-2" />,
        <Card divider={false} title={FORM.CONDITIONS.TITLE} key="conditions">
          <Col span={24}>
            <Row gutter={[16, 16]} align={"middle"}>
              <Col span={8}>
                <Select
                  mode="multiple"
                  options={Object.entries(PAYMENT_TYPES).map(
                    ([key, value]) => ({
                      value,
                      label: FORM.CONDITIONS.PAYMENT_TYPE.OPTIONS[key],
                    })
                  )}
                  placeholder={FORM.CONDITIONS.PAYMENT_TYPE.LABEL}
                  label={FORM.CONDITIONS.PAYMENT_TYPE.LABEL}
                  {...bindInputProps({
                    name: "fields.payment.type",
                    values,
                    ...formProps,
                  })}
                  onChange={(value) =>
                    setFieldValue(`fields.payment.type`, value)
                  }
                />
              </Col>
              <Col span={8}>
                <Select
                  mode="multiple"
                  options={Object.entries(PAYMENT_FREQUENCIES).map(
                    ([key, value]) => ({
                      value,
                      label: FORM.CONDITIONS.PAYMENT_FREQUENCY.OPTIONS[key],
                    })
                  )}
                  placeholder={FORM.CONDITIONS.PAYMENT_FREQUENCY.LABEL}
                  label={FORM.CONDITIONS.PAYMENT_FREQUENCY.LABEL}
                  {...bindInputProps({
                    name: "fields.payment.frequency",
                    values,
                    ...formProps,
                  })}
                  onChange={(value) =>
                    setFieldValue(`fields.payment.frequency`, value)
                  }
                />
              </Col>
              <Col span={8}>
                <Input
                  type="checkbox"
                  label={FORM.CONDITIONS.ALLOWED_PAYER}
                  {...bindInputProps({
                    name: "fields.payment.shouldBeAdherent",
                    ...formProps,
                  })}
                  checked={!!get(values, "fields.payment.shouldBeAdherent")}
                />
              </Col>
            </Row>
          </Col>
          <FieldArray
            name={"params"}
            render={(arrayHelpers) => [
              get(values, "params", []).map((_, i) => [
                <Col span={24} key="conditions">
                  <div key="params" className="grid--1">
                    <div
                      className={"d-flex justify--between m-top-8 delete-icon"}
                    >
                      <h3 className="card--title">{`${
                        FORM.CONDITIONS.PARAMETERS
                      } ${i + 1}`}</h3>
                      <BiTrash
                        color={COLORS.C_DANGER}
                        size={24}
                        onClick={() => {
                          arrayHelpers.remove(i);
                        }}
                      />
                    </div>

                    <Row gutter={[16, 16]} align={"middle"}>
                      <Col span={4}>{FORM.CONDITIONS.ISSUANCE_DATE}</Col>
                      <Col sm={24} md={10}>
                        <Row align={"middle"} gutter={8}>
                          <Col>{FORM.CONDITIONS.TODAY + " +"}</Col>
                          <Col flex="1">
                            <Input
                              type="number"
                              placeholder={FORM.CONDITIONS.START_DATE}
                              {...bindArrayInputProps({
                                parent: "params",
                                index: i,
                                name: "deltaStart",
                                values,
                                ...formProps,
                              })}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={24} md={10}>
                        <Row align={"middle"} gutter={8}>
                          <Col flex={1}>
                            <Select
                              options={Object.entries(DELTA_END_TYPES).map(
                                ([key, value]) => ({
                                  label:
                                    FORM.CONDITIONS.MAX_ISSUANCE_DATE_OPTIONS[
                                      key
                                    ],
                                  value,
                                })
                              )}
                              placeholder={FORM.CONDITIONS.END_DATE}
                              {...bindArrayInputProps({
                                parent: "params",
                                index: i,
                                name: "deltaEnd.type",
                                values,
                                ...formProps,
                              })}
                            />
                          </Col>
                          {get(values, `params.${i}.deltaEnd.type`) ===
                            DELTA_END_TYPES.ADD_PERIOD && (
                            <>
                              <Col span={9}>
                                <Input
                                  type="number"
                                  placeholder={FORM.CONDITIONS.NUMBER_OF}
                                  {...bindArrayInputProps({
                                    parent: "params",
                                    index: i,
                                    name: "deltaEnd.value",
                                    values,
                                    ...formProps,
                                  })}
                                />
                              </Col>
                              <Col span={5}>
                                <Select
                                  options={Object.entries(PERIOD_UNITS).map(
                                    ([key, value]) => ({
                                      label: FORM.CONDITIONS[key],
                                      value,
                                    })
                                  )}
                                  placeholder={FORM.CONDITIONS.UNIT}
                                  {...bindArrayInputProps({
                                    parent: "params",
                                    index: i,
                                    name: "deltaEnd.unit",
                                    values,
                                    ...formProps,
                                  })}
                                />
                              </Col>
                            </>
                          )}
                        </Row>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]} align={"middle"}>
                      <Col span={4}>{FORM.CONDITIONS.ISSUANCE_DATE_RIA}</Col>
                      <Col span={10}>
                        <Row align={"middle"} gutter={8}>
                          <Col>{FORM.CONDITIONS.TODAY + " +"}</Col>
                          <Col flex="1">
                            <Input
                              type="number"
                              placeholder={FORM.CONDITIONS.START_DATE_RIA}
                              {...bindArrayInputProps({
                                parent: "params",
                                index: i,
                                name: "deltaStartRIA",
                                values,
                                ...formProps,
                              })}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={10}>
                        <Row align={"middle"} gutter={8}>
                          <Col flex={1}>
                            <Select
                              options={Object.entries(DELTA_END_TYPES).map(
                                ([key, value]) => ({
                                  label:
                                    FORM.CONDITIONS.MAX_ISSUANCE_DATE_OPTIONS[
                                      key
                                    ],
                                  value,
                                })
                              )}
                              placeholder={FORM.CONDITIONS.END_DATE_RIA}
                              {...bindArrayInputProps({
                                parent: "params",
                                index: i,
                                name: "deltaEndRIA.type",
                                values,
                                ...formProps,
                              })}
                            />
                          </Col>
                          {get(values, `params.${i}.deltaEndRIA.type`) ===
                            DELTA_END_TYPES.ADD_PERIOD && (
                            <>
                              <Col span={9}>
                                <Input
                                  type="number"
                                  placeholder={FORM.CONDITIONS.NUMBER_OF}
                                  {...bindArrayInputProps({
                                    parent: "params",
                                    index: i,
                                    name: "deltaEndRIA.value",
                                    values,
                                    ...formProps,
                                  })}
                                />
                              </Col>
                              <Col span={5}>
                                <Select
                                  options={Object.entries(PERIOD_UNITS).map(
                                    ([key, value]) => ({
                                      label: FORM.CONDITIONS[key],
                                      value,
                                    })
                                  )}
                                  placeholder={FORM.CONDITIONS.UNIT}
                                  {...bindArrayInputProps({
                                    parent: "params",
                                    index: i,
                                    name: "deltaEndRIA.unit",
                                    values,
                                    ...formProps,
                                  })}
                                />
                              </Col>
                            </>
                          )}
                        </Row>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]} align={"middle"}>
                      <Col span={4}>{FORM.CONDITIONS.AGE}</Col>
                      <Col span={10}>
                        <Input
                          type="number"
                          placeholder={FORM.CONDITIONS.MIN}
                          {...bindArrayInputProps({
                            parent: "params",
                            index: i,
                            name: "minAge",
                            values,
                            ...formProps,
                          })}
                        />
                      </Col>
                      <Col sm={10}>
                        <Input
                          type="number"
                          placeholder={FORM.CONDITIONS.MAX}
                          {...bindArrayInputProps({
                            parent: "params",
                            index: i,
                            name: "maxAge",
                            values,
                            ...formProps,
                          })}
                        />
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]} align={"middle"}>
                      <Col span={4}>{FORM.CONDITIONS.SOCIAL_REGIMES}</Col>
                      <Col sm={20} lg={10}>
                        <Select
                          mode="multiple"
                          options={Object.entries(SOCIAL_REGIMES).map(
                            ([key, value]) => ({
                              value,
                              label:
                                FORM.CONDITIONS.SOCIAL_REGIMES_OPTIONS[key],
                            })
                          )}
                          value={get(values, "params.socialRegimes") || []}
                          placeholder={FORM.CONDITIONS.SOCIAL_REGIMES}
                          {...bindArrayInputProps({
                            parent: "params",
                            index: i,
                            name: "socialRegimes",
                            values,
                            ...formProps,
                          })}
                          onChange={(value) =>
                            setFieldValue(`params.${i}.socialRegimes`, value)
                          }
                        />
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]} align={"middle"}>
                      <Col span={4}>{FORM.CONDITIONS.EXERCISE_FRAME.LABEL}</Col>
                      <Col span={20}>
                        <Input
                          type="radio"
                          variant="group"
                          values={[
                            {
                              value: CONTACT_EXERCISE_FRAME.EMPLOYEE,
                              label:
                                FORM.CONDITIONS.EXERCISE_FRAME.OPTIONS.EMPLOYEE,
                            },
                            {
                              value: CONTACT_EXERCISE_FRAME.INDEPENDENT,
                              label:
                                FORM.CONDITIONS.EXERCISE_FRAME.OPTIONS
                                  .INDEPENDENT,
                            },
                            {
                              value: "",
                              label: FORM.CONDITIONS.EXERCISE_FRAME.OPTIONS.ANY,
                            },
                          ]}
                          {...bindArrayInputProps({
                            parent: "params",
                            index: i,
                            name: "exerciseFrame",
                            values,
                            ...formProps,
                          })}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>,
              ]),
              <div className="m-top-16" key="divider" />,
              <Button
                type="primary"
                key="add-row"
                onClick={() => {
                  arrayHelpers.push({
                    id: cuid(),
                    socialRegimes: [],
                    deltaEnd: { type: null, unit: null },
                    deltaEndRIA: { type: null, unit: null },
                  });
                }}
              >
                <PlusOutlined color={COLORS.C_WHITE} />
              </Button>,
            ]}
          />
        </Card>,
        <div key="margin-3" className="m-bottom-16" />,
        <Card
          title={FORM.RECOMMENDATION.TITLE}
          divider={false}
          key="recommendation"
        >
          <div className="grid--1">
            <Recommendation
              setFieldValue={setFieldValue}
              needs={get(values, "needs", {})}
            />
          </div>
        </Card>,
      ]}
    </Formik>
  );
};

export default Create;
