import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { INITIAL_VALUES, SCREENS } from "utils/constants";
import View from "./View";

const Delete = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [visible, setVisible] = useState(true);
  const cancel = () =>
    navigate(SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.LIST.path);
  const [deleteInsurancePlan] = useMutation(
    graphql.mutations.DELETE_INSURANCE_PLAN,
    {
      refetchQueries: [
        {
          query: graphql.queries.INSURANCE_PLANS,
          awaitRefetchQueries: true,
          variables: INITIAL_VALUES,
        },
      ],
      onCompleted: () => {
        setVisible(false);
        navigate(SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.LIST.path, {
          replace: true,
        });
      },
      onError: () => {},
    }
  );
  const onSubmit = () => {
    return deleteInsurancePlan({
      variables: {
        where: {
          id,
        },
      },
    });
  };
  return <View onSubmit={onSubmit} cancel={cancel} visible={visible} />;
};

export default Delete;
