const Simple = ({ children, head = false ,title, actions }) => {
  return (
    <div className="card__wrapper">
      {head &&
        <div className="card__wrapper--head">
          <h3 className="card__wrapper--head--title">{title}</h3>
          <span className="card__wrapper--head--actions">{actions}</span>
        </div>
      }
      <div className="card__wrapper--body">{children}</div>
    </div>
  );
};

export default Simple;
