import { get } from "lodash";
import { useState } from "react";
import {
  COVERAGE_LIMIT_CATEGORIES,
  SUPPORTING_INFORMATION_CATEGORIES,
} from "utils/constants";
import HoverCard from "./HoverCard";
import SupportingInfoItem from "./SupportingInfoItem";

const coverageCardContentStyle = {
  [SUPPORTING_INFORMATION_CATEGORIES.BASE_REFUND]:
    "coverage-card__content--base",
};

const HIGHLIGHTED_STYLE = {
  [true]: "coverage-card coverage-card--highlighted",
  [false]: "coverage-card",
};

const Card = ({
  coverageBenefit,
  translation,
  toggleHighlighted,
  isHighlighted,
}) => {
  const { supportingInformation, limits = [{ value: {} }] } = coverageBenefit;
  const coverageBenefitLimitValue = (value) => ({
    [COVERAGE_LIMIT_CATEGORIES.BASE_REFUND]: `${value} % BR`,
    [COVERAGE_LIMIT_CATEGORIES.REAL_COSTS]:
      translation.TEMPLATES.COMPARISON.REAL_COSTS,
    [COVERAGE_LIMIT_CATEGORIES.INTEGRAL_RBT]:
      translation.TEMPLATES.COMPARISON.INTEGRAL_RBT,
    [COVERAGE_LIMIT_CATEGORIES.COST]: `${value} €`,
    [COVERAGE_LIMIT_CATEGORIES.COST_PER_YEAR]: `${translation.TEMPLATES.COMPARISON.UP_TO} ${value} € / ${translation.TEMPLATES.COMPARISON.YEAR}`,
    [COVERAGE_LIMIT_CATEGORIES.TEXT]: value,
  });
  const [hover, setHover] = useState(false);
  return (
    <div
      className={HIGHLIGHTED_STYLE[isHighlighted]}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover && (
        <HoverCard
          coverageBenefit={coverageBenefit}
          translation={translation}
          setHover={setHover}
          toggleHighlighted={toggleHighlighted}
        />
      )}
      <div className="coverage-card__header">
        {limits.map(({ id, value }) => (
          <span key={id}>
            {
              coverageBenefitLimitValue(get(value, "value", "-"))[
                get(value, "category", COVERAGE_LIMIT_CATEGORIES.TEXT)
              ]
            }
          </span>
        ))}
      </div>
      <div className="coverage-card__body">
        {supportingInformation.map((information) => {
          const { id, category, value } = information;
          return (
            <SupportingInfoItem
              key={id}
              category={category}
              value={value}
              translation={translation}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Card;
