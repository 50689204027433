import { get } from "lodash";
import qs from "qs";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { SCREENS, TRACER_TYPES } from "utils/constants";

const Identify = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data, loading, error } = useQuery(graphql.queries.TRACER, {
    variables: { where: { type: get(state, "tracer", TRACER_TYPES.MANUAL) } },
  });

  useEffect(() => {
    if (get(data, "tracer.flow.id")) {
      navigate(
        `${SCREENS.PRIVATE.BROKER.PROJECTS.CREATE.NORMAL.path}?${qs.stringify(
          get(data, "tracer.flow.process")
        )}`,
        {
          state: {
            project: get(state, "project", {}),
          },
        }
      );
    }
  }, [navigate, data, state]);

  if (loading || error) return "Loading or Error";
  return <></>;
};

export default Identify;
