import cuid from "cuid";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft } from "react-icons/bi";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Create = ({ onSubmit, id, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.WORKFLOW);
  const CREATE = t("CREATE", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  return (
    <Formik
      initialValues={{ id: cuid(), name: "" }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, ...formProps }) => [
        <Header
          key={"header"}
          title={CREATE.HEADER.TITLE}
          subtitle={CREATE.HEADER.ACTION}
          actions={[
            {
              text: CREATE.HEADER.DISCARD,
              onClick: cancel,
              type: "primary--outlined",
              icon: <BiChevronLeft size={20} />,
            },
            {
              text: CREATE.HEADER.SAVE,
              onClick: handleSubmit,
              type: "primary",
              icon: <BiCheck size={20} />,
            },
          ]}
        />,
        <Card key="card" type={"simple"}>
          <div className="grid--2">
            <Input
              label={FORM.NAME}
              placeholder={FORM.NAME}
              {...bindInputProps({ name: "name", ...formProps })}
            />
            <Input
              label={FORM.URL}
              placeholder={FORM.URL}
              {...bindInputProps({ name: "url", ...formProps })}
            />
          </div>
        </Card>,
      ]}
    </Formik>
  );
};

export default Create;
