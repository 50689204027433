import dayjs from "dayjs";
import { get, isEmpty } from "lodash";

export const bindInputProps = ({
  name,
  values,
  handleChange,
  setFieldTouched,
  touched,
  errors,
  onBlur = () => {},
}) => {
  return {
    value: get(values, name, undefined),
    onChange: handleChange(name),
    onBlur: () => {
      setFieldTouched(name);
      onBlur();
    },
    touched: get(touched, name, false),
    errors: get(errors, name, ""),
    name,
  };
};

export const bindDateInputProps = ({
  name,
  values,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  onBlur = () => {},
}) => {
  const value = get(values, name, undefined);
  return {
    value: value && dayjs(value),
    defaultValue: value && dayjs(value),
    onChange: (date, _) => {
      setFieldValue(name, date);
    },
    onBlur: () => {
      setFieldTouched(name);
      onBlur();
    },
    touched: get(touched, name, false),
    errors: get(errors, name, ""),
    name,
  };
};

export const bindArrayInputProps = ({
  parent,
  index,
  name,
  values,
  handleChange,
  setFieldTouched,
  touched,
  errors,
  onBlur = () => {},
}) => {
  const childName = `${parent}.${index}.${name}`;
  return {
    value: get(values, childName, ""),
    onChange: handleChange(childName),
    onBlur: () => {
      setFieldTouched(childName);
      onBlur();
    },
    touched: get(touched, childName, false),
    errors: get(errors, childName, ""),
    name: childName,
  };
};

export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const setDefaultIfEmpty = (value, defaultValue) =>
  isEmpty(value) ? defaultValue : value;
