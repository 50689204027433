import classnames from "classnames";
import PropTypes from "prop-types";
import Label from "shared/components/Label";

const Checkbox = ({
  label,
  placeholder,
  errors,
  touched,
  icon,
  required,
  ...rest
}) => {
  const inputClass = classnames({
    "input--borderd--red": touched && errors,
    "disable--input": rest.disabled,
  });
  const errorClass = classnames({
    "input--errors": true,
    hide: !touched,
  });
  return (
    <div className="checkbox__wrapper">
      <input
        id={label}
        type="checkbox"
        className={inputClass}
        placeholder={placeholder}
        {...rest}
      />
      <Label for={label} label={label} icon={icon} required={required} />
      <p className={errorClass}>{errors}</p>
    </div>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

Checkbox.defaultProps = {
  label: "",
  placeholder: "",
};

export default Checkbox;
