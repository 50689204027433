import { gql } from "@apollo/client";

export const WORKFLOWS = gql`
  query Workflows(
    $where: WorkflowWhereInput
    $like: WorkflowWhereInput
    $take: Int
    $skip: Int
  ) {
    workflows(where: $where, take: $take, skip: $skip, like: $like) {
      data {
        id
        name
        url
      }
      count
    }
  }
`;

export const WORKFLOW = gql`
  query Workflow($where: WorkflowWhereInput) {
    workflow(where: $where) {
      id
      name
      url
    }
  }
`;

const workflows = { WORKFLOW, WORKFLOWS };

export default workflows;
