import PropTypes from "prop-types";
import { COMPONENTS_SIZES, CURRENCY_SYMBOL } from "utils/constants";

const Cost = ({ cost, variant, size, currency, period }) => {
  const [integer, decimal = "00"] = cost?.toString().split(".") || ["-"];
  return (
    <div className={`cost-tag cost-tag--${size} cost-tag--${variant}`}>
      <span>{`${integer} ${CURRENCY_SYMBOL[currency]} `}</span>
      <span>
        <sup>{decimal?.slice(0, 2)}</sup>
      </span>
      <span className="cost-tag--period">{period}</span>
    </div>
  );
};

Cost.propTypes = {
  cost: PropTypes.number | PropTypes.string,
  currency: PropTypes.oneOf(Object.keys(CURRENCY_SYMBOL)),
  size: PropTypes.oneOf(Object.values(COMPONENTS_SIZES)),
  variant: PropTypes.oneOf(PropTypes.oneOf(["primary", "secondary"])),
};

Cost.defaultProps = {
  cost: "00.00",
  currency: CURRENCY_SYMBOL.DEFAULT,
  size: COMPONENTS_SIZES.MEDIUM,
  variant: "primary",
};

export default Cost;
