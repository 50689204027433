import { gql } from "@apollo/client";

export const DISTRIBUTIONS = gql`
  query distributions(
    $where: DistributionWhereInput
    $like: DistributionWhereInput
    $take: Int
    $skip: Int
    $orderBy: [DistributionWhereInput]
  ) {
    distributions(
      where: $where
      take: $take
      skip: $skip
      orderBy: $orderBy
      like: $like
    ) {
      data {
        id
        insurancePlan {
          id
          name
        }
        commissions {
          id
          type
          value
          withholding
        }
      }
      count
    }
  }
`;

export const DISTRIBUTION = gql`
  query distribution($where: DistributionWhereInput) {
    distribution(where: $where) {
      id
      insurancePlan {
        id
        name
      }
      commissions {
        id
        type
        value
        withholding
      }
    }
  }
`;

const distributions = { DISTRIBUTIONS, DISTRIBUTION };

export default distributions;
