import { gql } from "@apollo/client";

const CONTACT_ADDED = gql`
  subscription contactAdded($where: ContactWhereInput) {
    contactAdded(where: $where) {
      id
      name
      status
      createdDate
      notes {
        id
        content
        createdDate
      }
      raw
    }
  }
`;

const CONTACT_UPDATED = gql`
  subscription contactUpdated($where: ContactWhereInput) {
    contactUpdated(where: $where) {
      id
      name
      status
      createdDate
      notes {
        id
        content
        createdDate
      }
      raw
    }
  }
`;

const CONTACT_DELETED = gql`
  subscription ContacDeleted($where: ContacWhereInput) {
    contactDeleted(where: $where) {
      id
      name
      status
      createdDate
      notes {
        id
        content
        createdDate
      }
      raw
    }
  }
`;

const contacts = { CONTACT_ADDED, CONTACT_UPDATED, CONTACT_DELETED };

export default contacts;
