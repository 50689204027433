import { gql } from "@apollo/client";

export const ORGANIZATIONS = gql`
  query Organizations($where: OrganizationWhereInput, $take: Int, $skip: Int) {
    organizations(where: $where, take: $take, skip: $skip) {
      data {
        id
        name
        type
        insurancePlans {
          id
        }
      }
      count
    }
  }
`;

export const ORGANIZATION = gql`
  query Organization($where: OrganizationWhereInput) {
    organization(where: $where) {
      id
      name
      type
    }
  }
`;

const organizations = { ORGANIZATIONS, ORGANIZATION };

export default organizations;
