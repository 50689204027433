import WithRoutePermission from "shared/components/HOC/WithRoutePermission";
import { MODULES, SCREENS } from "utils/constants";
import Collaborators from "./Collaborators";
import commissionStatements from "./CommissionStatements";
import Contracts from "./Contracts";
import Dashboard from "./Dashboard";
import Organizations from "./Organizations";
import Projects from "./Projects";
import Subscription from "./Subscription";
import Users from "./Users";

const routes = {
  [MODULES.ORGANIZATION]: Organizations,
  [MODULES.CONTRACT]: Contracts,
  [MODULES.PROJECT]: Projects,
  [MODULES.USER]: Users,
  // [MODULES.CONTACT]: Clients,
  // [MODULES.REMINDER]: Reminders,
  // [MODULES.QUALIFICATION]: Qualifications,
  // [MODULES.NOTE]: Notes,
  [MODULES.SUBSCRIPTION]: Subscription,
  [MODULES.COMMISSION_STATEMENT]: commissionStatements,
};

const screens = (permissions) => [
  {
    exact: false,
    path: SCREENS.PRIVATE.BROKER.INDEX.path,
    element: (
      <WithRoutePermission submodules={{}} WrappedComponent={Dashboard} />
    ),
  },
  ...Collaborators,
  ...Object.entries(routes)
    .filter(([module]) =>
      permissions?.find(
        ({ module: permissionModule, granted }) =>
          granted && module === permissionModule
      )
    )
    .reduce((acc, [_, item]) => [...acc, ...item], []),
];

export default screens;
