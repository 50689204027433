import { notification } from "antd";
import { useTranslation } from "react-i18next";
import Loading from "shared/components/Spin";
import { useMutation, useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { CREATE_CATEGORY_WEIGHT } from "utils/api/graphql/mutations/category-weight";
import { CATEGORIES_WEIGHT } from "utils/api/graphql/queries/category-weight";
import View from "./View";

const Detail = () => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.CATEGORIES_WEIGHT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const [addCategoryWeight] = useMutation(CREATE_CATEGORY_WEIGHT, {
    onCompleted: () => {
      notification.success({ message: DETAIL.SUCCESS });
    },
  });
  const { data, loading, error } = useQuery(CATEGORIES_WEIGHT);
  const onSubmit = (data) => {
    return addCategoryWeight({
      variables: {
        data,
      },
    });
  };

  if (loading || error) return <Loading />;

  return <View onSubmit={onSubmit} data={data.categoryWeight} />;
};

export default Detail;
