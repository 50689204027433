export const updateOperationHeader = (operation, accessToken) =>
  operation.setContext(({ headers = {} }) => ({
    headers: {
      authorization: accessToken ? `Bearer ${accessToken}` : "",
      ...headers,
    },
  }));

export const onError = (observer) => ({
  next: observer.next.bind(observer),
  error: observer.error.bind(observer),
  complete: observer.complete.bind(observer),
});
