import Icon from "react-material-symbols/rounded";
import Button from "shared/components/Button";

const Draft = ({ disabled, translation, signDocument }) => {
  return (
    <Button
      type="secondary"
      alignment="center"
      disabled={disabled}
      onClick={() => signDocument()}
    >
      <Icon icon="draw" size={16} />
      {translation.E_SIGNATURE}
    </Button>
  );
};

export default Draft;
