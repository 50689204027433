import gql from "graphql-tag";

export const CREATE_WORKFLOW = gql`
  mutation AddWorkflow($data: WorkflowDataInput) {
    addWorkflow(data: $data) {
      id
    }
  }
`;

export const UPDATE_WORKFLOW = gql`
  mutation UpdateWorkflow(
    $data: WorkflowDataInput
    $where: WorkflowWhereInput
  ) {
    updateWorkflow(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_WORKFLOW = gql`
  mutation DeleteWorkflow($where: WorkflowWhereInput) {
    deleteWorkflow(where: $where) {
      id
    }
  }
`;

const workflows = { CREATE_WORKFLOW, UPDATE_WORKFLOW, DELETE_WORKFLOW };

export default workflows;
