import { gql } from "@apollo/client";

const SOURCE_ADDED = gql`
  subscription sourceAdded($where: SourceWhereInput) {
    sourceAdded(where: $where) {
      id
      name
      platform
    }
  }
`;

const SOURCE_UPDATED = gql`
  subscription sourceUpdated($where: SourceWhereInput) {
    sourceUpdated(where: $where) {
      id
      name
      platform
    }
  }
`;

const SOURCE_DELETED = gql`
  subscription sourceDeleted($where: SourceWhereInput) {
    sourceDeleted(where: $where) {
      id
      name
      platform
    }
  }
`;

const sources = { SOURCE_ADDED, SOURCE_UPDATED, SOURCE_DELETED };

export default sources;
