import { isEmpty } from "lodash";
import { BiPlus } from "react-icons/bi";
import Lottie from "react-lottie";
import animationData from "shared/assets/lotties/empty";
import Button from "shared/components/Button";

const EmptyTable = ({ message, label, onClick }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className={"empty__table"}>
      <div className="lottiefiles">
        <Lottie options={defaultOptions} />
      </div>

      <h3>{message}</h3>
      {!isEmpty(onClick) && (
        <Button onClick={onClick} type={"primary"}>
          <span className="icon">
            <BiPlus size={20} />
          </span>
          <span>{label}</span>
        </Button>
      )}
    </div>
  );
};

export default EmptyTable;
