import { gql } from "@apollo/client";

export const ATTACHMENTS = gql`
  query Attachments($where: AttachmentWhereInput, $isIn: JSONObject) {
    attachments(where: $where, isIn: $isIn) {
      data {
        id
        name
        fileUrl
        contentType
        type
      }
      count
    }
  }
`;

const attachments = { ATTACHMENTS };

export default attachments;
