import { Col, Row, notification } from "antd";
import { flatten, get, uniq } from "lodash";
import { useState } from "react";
import {
  MdInfoOutline,
  MdOutlineExpandLess,
  MdOutlineExpandMore,
} from "react-icons/md";
import AlternativeMedicine from "shared/assets/images/ayurveda.png";
import Dental from "shared/assets/images/dental-checkup.png";
import Equipment from "shared/assets/images/hearing-aid.png";
import Hospitalization from "shared/assets/images/hospital-bed.png";
import Maternity from "shared/assets/images/maternity.png";
import MedicalCare from "shared/assets/images/medical-team.png";
import Others from "shared/assets/images/others.png";
import Prevention from "shared/assets/images/prevention.png";
import Optical from "shared/assets/images/vision.png";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { COVERAGE_CATEGORIES } from "utils/constants";
import Card from "./Card";
import Header from "./Header";

const COVERAGE_CATEGORIES_ICONS = {
  [COVERAGE_CATEGORIES.HOSPITALIZATION]: Hospitalization,
  [COVERAGE_CATEGORIES.MEDICAL_CARE]: MedicalCare,
  [COVERAGE_CATEGORIES.OPTICAL]: Optical,
  [COVERAGE_CATEGORIES.DENTAL]: Dental,
  [COVERAGE_CATEGORIES.EQUIPMENT]: Equipment,
  [COVERAGE_CATEGORIES.ALTERNATIVE_MEDICINE]: AlternativeMedicine,
  [COVERAGE_CATEGORIES.PREVENTION]: Prevention,
  [COVERAGE_CATEGORIES.MATERNITY]: Maternity,
  [COVERAGE_CATEGORIES.OTHER]: Others,
};

const Coverages = ({ insurancePlans, translation, project }) => {
  const [updateProject] = useMutation(graphql.mutations.UPDATE_PROJECT);
  const toggleHighlighted = (id) => {
    const highlighted = get(project, "fields.highlighted", []);
    const highlightedValue = {
      [true]: {
        value: highlighted.filter((item) => item !== id),
        notification: translation.TEMPLATES.COMPARISON.NOTIFICATION.REMOVED,
      },
      [false]: {
        value: [...highlighted, id],
        notification: translation.TEMPLATES.COMPARISON.NOTIFICATION.HIGHLIGHTED,
      },
    };
    updateProject({
      variables: {
        where: { id: project.id },
        data: {
          fields: {
            ...project.fields,
            highlighted: highlightedValue[highlighted.includes(id)].value,
          },
        },
      },
      onCompleted: () =>
        notification.open({
          message: highlightedValue[highlighted.includes(id)].notification,
          duration: 3,
          placement: "bottomRight",
          type: "info",
        }),
    });
  };
  const coverageCategories = uniq(
    flatten(insurancePlans.map((insurancePlan) => insurancePlan.coverages)).map(
      (coverage) => coverage.type
    )
  );
  const coverageBenefitsCategories = coverageCategories.reduce(
    (accumulator, current) => {
      accumulator[[current]] = uniq(
        flatten(
          flatten(
            insurancePlans.map((insurancePlan) => insurancePlan.coverages)
          )
            .filter((coverage) => coverage.type === current)
            .map((coverage) => coverage.coverageBenefits)
        ).map((coverageBenefit) => coverageBenefit.type)
      );
      return accumulator;
    },
    {}
  );
  const [hideDetail, setHideDetail] = useState({});
  const toggleCoverage = (name, hide) => {
    setHideDetail((prev) => ({
      ...prev,
      [name]: hide,
    }));
  };

  const HIDE_DETAIL = {
    [false]: {
      style: "",
      label: translation.TEMPLATES.COMPARISON.SEE_LESS,
      icon: <MdOutlineExpandLess />,
    },
    [true]: {
      style: "--disable",
      label: translation.TEMPLATES.COMPARISON.SEE_MORE,
      icon: <MdOutlineExpandMore />,
    },
  };

  return coverageCategories.map((coverageType) => (
    <div className="coverage-group" key={coverageType}>
      <Row gutter={[10, 8]}>
        <Col span={4} />
        <Col span={20}>
          <Header
            src={COVERAGE_CATEGORIES_ICONS[coverageType]}
            type={coverageType}
            label={
              translation.TEMPLATES.COMPARISON.COVERAGES_CATEGORIES[
                coverageType
              ]
            }
          />
        </Col>
        {coverageBenefitsCategories[coverageType].map(
          (coverageBenefitsCategory) => {
            const { style, icon, label } =
              HIDE_DETAIL[get(hideDetail, coverageBenefitsCategory, false)];
            return (
              <Col span={24} key={coverageBenefitsCategory}>
                <Row gutter={[16, 8]} className={`coverage-group__row${style}`}>
                  <Col span={4}>
                    <div
                      className="coverage-group__left"
                      onClick={() =>
                        toggleCoverage(
                          coverageBenefitsCategory,
                          !hideDetail[coverageBenefitsCategory]
                        )
                      }
                    >
                      <div>
                        {coverageBenefitsCategory}
                        <div className="coverage-group__left--link">
                          {label}
                          {icon}
                        </div>
                      </div>
                      <div className="coverage-group__left--icon">
                        <MdInfoOutline size={10} />
                      </div>
                    </div>
                  </Col>
                  {insurancePlans.map((insurancePlan) => (
                    <Col
                      flex="1"
                      key={`benefits-${insurancePlan.id}`}
                      className="coverage-group__row--col"
                    >
                      {flatten(
                        insurancePlan.coverages
                          .filter((coverage) => coverage.type === coverageType)

                          .map((coverage) => coverage.coverageBenefits)
                      )
                        .filter(
                          (benefit) => benefit.type === coverageBenefitsCategory
                        )
                        .map((coverageBenefit) => (
                          <Card
                            key={coverageBenefit.id}
                            isHighlighted={get(
                              project,
                              "fields.highlighted",
                              []
                            ).includes(coverageBenefit.id)}
                            coverageBenefit={coverageBenefit}
                            translation={translation}
                            toggleHighlighted={toggleHighlighted}
                          />
                        ))}
                    </Col>
                  ))}
                </Row>
              </Col>
            );
          }
        )}
      </Row>
    </div>
  ));
};

export default Coverages;
