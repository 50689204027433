import Auth from "./Auth";
import NotFound from "./NotFound";
import Private from "./Private";
import Public from "./Public";

const screens = ({ user, layouts }) => {
  return [
    ...Private({ user })
      .filter((x) => x)
      .map(({ element, path, breadcrumb = [], ...rest }) => {
        const Layout = layouts[true].element;
        const header = layouts[true].header;
        const sider = layouts[true].sider;
        const drawers = layouts[true].drawers;
        const Widget = (
          <Layout
            header={header}
            sider={sider}
            drawers={drawers}
            breadcrumb={breadcrumb}
          >
            {element}
          </Layout>
        );
        return {
          element: Widget,
          path,
          breadcrumb,
          ...rest,
        };
      }),
    ...Auth,
    ...Public,
    NotFound,
  ];
};

export default screens;
