import gql from "graphql-tag";

export const CREATE_PRICER = gql`
  mutation AddPricer($data: PricerDataInput) {
    addPricer(data: $data) {
      id
    }
  }
`;

export const DUPLICATE_PRICER = gql`
  mutation DuplicatePricer($where: PricerWhereInput) {
    duplicatePricer(where: $where) {
      id
    }
  }
`;

export const UPDATE_PRICER = gql`
  mutation UpdatePricer($data: PricerDataInput, $where: PricerWhereInput) {
    updatePricer(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_PRICER = gql`
  mutation DeletePricer($where: PricerWhereInput) {
    deletePricer(where: $where) {
      id
    }
  }
`;

const pricers = {
  CREATE_PRICER,
  UPDATE_PRICER,
  DELETE_PRICER,
  DUPLICATE_PRICER,
};

export default pricers;
