import { gql } from "@apollo/client";

export const WEBHOOKS = gql`
  query Webhooks($where: WebhookWhereInput, $take: Int, $skip: Int) {
    webhooks(where: $where, take: $take, skip: $skip) {
      data {
        id
        type
        meta
        contact {
          id
          user {
            id
            firstname
            lastname
          }
        }
      }
      count
    }
  }
`;

export const WEBHOOK = gql`
  query Webhook($where: WebhookWhereInput) {
    webhook(where: $where) {
      id
      type
      meta
      contact {
        id
        user {
          id
          firstname
          lastname
        }
      }
    }
  }
`;

const webhooks = { WEBHOOKS, WEBHOOK };

export default webhooks;
