import PropTypes from "prop-types";
import { COMPONENTS_SIZES } from "utils/constants";
import { getRandomColor } from "utils/helpers/getRandomColor";

const COLORS = ["default", "primary", "second", "random"];

const Rand = ({ value, size, color }) => {
  const randColor = getRandomColor();
  return (
    <div
      className={`avatar--text avatar--${size} avatar--bg--${color}`}
      style={{ backgroundColor: randColor }}
    >
      <span>{value.substring(0, 2)}</span>
    </div>
  );
};

Rand.propTypes = {
  value: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(COMPONENTS_SIZES)),
  color: PropTypes.oneOf(COLORS),
};

Rand.defaultProps = {
  size: "small",
  color: "primary",
};

export default Rand;
