import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { MdSend } from "react-icons/md";
import logo from "shared/assets/images/logo.svg";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import Modal from "shared/components/Modal";
import { NAME_SPACES } from "shared/locales/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const View = ({ onSubmit, onLinkClick, success }) => {
  const { t: auth } = useTranslation(NAME_SPACES.AUTH);
  const { t: errors } = useTranslation(NAME_SPACES.ERRORS);
  const FORGOT_PASSWORD = auth("FORGOT_PASSWORD", {
    returnObjects: true,
  });
  const VALIDATIONS = errors("VALIDATIONS", {
    returnObjects: true,
  });
  return (
    <div className="auth">
      <img alt="logo" src={logo} />
      <Formik
        initialValues={{ password: "" }}
        onSubmit={onSubmit}
        validationSchema={validation(VALIDATIONS)}
        validateOnBlur
        validateOnChange={false}
      >
        {({ isValid, handleSubmit, ...formProps }) => (
          <div className="auth__body">
            <h3 className="auth__body--title">{FORGOT_PASSWORD.TITLE}</h3>
            <Input
              label={FORGOT_PASSWORD.EMAIL}
              placeholder={FORGOT_PASSWORD.EMAIL}
              {...bindInputProps({
                name: "email",
                ...formProps,
              })}
            />
            <Button type="primary" onClick={handleSubmit}>
              {FORGOT_PASSWORD.SUBMIT}
              <MdSend size={16} />
            </Button>
          </div>
        )}
      </Formik>
      <span className="auth__link" onClick={onLinkClick}>
        {FORGOT_PASSWORD.LINK}
      </span>
      <Modal
        type="info"
        closable={false}
        visible={success}
        title={FORGOT_PASSWORD.MODAL.TITLE}
        message={FORGOT_PASSWORD.MODAL.MESSAGE}
        okText={FORGOT_PASSWORD.LINK}
        onOk={onLinkClick}
      />
    </div>
  );
};

export default View;
