import gql from "graphql-tag";

export const CREATE_COVERAGE_BENEFIT = gql`
  mutation AddCoverageBenefit($data: CoverageBenefitDataInput) {
    addCoverageBenefit(data: $data) {
      id
    }
  }
`;

export const UPDATE_COVERAGE_BENEFIT = gql`
  mutation UpdateCoverageBenefit(
    $where: CoverageBenefitWhereInput
    $data: CoverageBenefitDataInput
  ) {
    updateCoverageBenefit(where: $where, data: $data) {
      id
    }
  }
`;

export const DELETE_COVERAGE_BENEFIT = gql`
  mutation DeleteCoverageBenefit($where: CoverageBenefitWhereInput) {
    deleteCoverageBenefit(where: $where) {
      id
    }
  }
`;

const coverages = {
  CREATE_COVERAGE_BENEFIT,
  UPDATE_COVERAGE_BENEFIT,
  DELETE_COVERAGE_BENEFIT,
};

export default coverages;
