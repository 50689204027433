import { gql } from "@apollo/client";

const FLOW_ADDED = gql`
  subscription flowAdded($where: FlowWhereInput) {
    flowAdded(where: $where) {
      id
      name
      tracers {
        id
        name
      }
      process {
        id
        processStatuses {
          id
          name
          template
        }
      }
    }
  }
`;

const FLOW_UPDATED = gql`
  subscription flowUpdated($where: FlowWhereInput) {
    flowUpdated(where: $where) {
      id
      name
      tracers {
        id
        name
      }
      process {
        id
        processStatuses {
          id
          name
          template
        }
      }
    }
  }
`;

const FLOW_DELETED = gql`
  subscription flowDeleted($where: FlowWhereInput) {
    flowDeleted(where: $where) {
      id
      name
      tracers {
        id
        name
      }
      process {
        id
        processStatuses {
          id
          name
          template
        }
      }
    }
  }
`;

const flows = { FLOW_ADDED, FLOW_UPDATED, FLOW_DELETED };

export default flows;
