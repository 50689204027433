import Card from "./Card";

const InsurancePlans = ({ insurancePlan, cost, currency, translation }) => {
  return (
    <Card
      key={insurancePlan.id}
      insurancePlan={insurancePlan}
      cost={cost}
      currency={currency}
      period={translation.MONTH}
    />
  );
};

export default InsurancePlans;
