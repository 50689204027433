import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { INITIAL_VALUES, SCREENS } from "utils/constants";
import View from "./View";

const Detail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.TRACERS.LIST.path);
  const [updateTracer] = useMutation(graphql.mutations.UPDATE_TRACER, {
    refetchQueries: [
      {
        query: graphql.queries.TRACERS,
        awaitRefetchQueries: true,
        variables: INITIAL_VALUES,
      },
    ],
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.ADMIN.TRACERS.LIST.path);
    },
    onError: () => {},
  });
  const { data, loading, error } = useQuery(graphql.queries.TRACER, {
    variables: { where: { id } },
  });
  const onSubmit = (_) => {
    const { name, source, destination } = _;
    return updateTracer({
      variables: {
        where: {
          id,
        },
        data: {
          destination,
          name,
          source: { id: source },
        },
      },
    });
  };

  if (loading || error) return "Loading";

  return <View onSubmit={onSubmit} data={data.tracer} cancel={cancel} />;
};

export default Detail;
