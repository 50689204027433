import cuid from "cuid";
import dayjs from "dayjs";
import { FieldArray } from "formik";
import Icon from "react-material-symbols/rounded";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { DATE_FORMAT, RELATIONSHIP, SOCIAL_REGIMES } from "utils/constants";
import { bindDateInputProps, bindInputProps } from "utils/helpers/input";

export const step = { CHILDREN: "CHILDREN" };

const Children = ({ index, translation, values, ...formProps }) => (
  <div className="contact-stepper" key={step.CHILDREN}>
    <div className="contact-stepper__header">
      <div className="contact-stepper__header--counter">
        <span>{index}</span>
      </div>
      <div className="contact-stepper__header--content">
        <h1 className="contact-stepper__header--content--title">
          <span>
            {translation.TEMPLATES.CONTACT.STEPS[step.CHILDREN].TITLE}
          </span>
        </h1>
        <p className="contact-stepper__header--content--description">
          {translation.TEMPLATES.CONTACT.STEPS[step.CHILDREN].DESCRIPTION}
        </p>
      </div>
    </div>
    <div className="quote quote--warning">
      <Icon className="quote--icon" icon="report" size={36} />
      <p className="quote--description">
        {translation.TEMPLATES.CONTACT.STEPS[step.CHILDREN].QUOTE}
      </p>
    </div>
    <div className="contact-stepper__content">
      <FieldArray
        name={"children"}
        render={(arrayHelpers) => [
          values.children.map((_, i) => [
            <div
              className="contact-stepper__content--box"
              key={`child-${_.id}`}
            >
              <div className="contact-stepper__content--box--header">
                <h1 className="contact-stepper__content--box--header--title">
                  {`${
                    translation.TEMPLATES.CONTACT.STEPS[step.CHILDREN].FORM
                      .CHILD
                  } ${i + 1}`}
                </h1>
                <div
                  className="contact-stepper__content--box--header--action"
                  onClick={() => {
                    arrayHelpers.remove(i);
                  }}
                >
                  <Icon icon="delete" size={14} />
                  <span>
                    {
                      translation.TEMPLATES.CONTACT.STEPS[step.CHILDREN].FORM
                        .DELETE_CHILD
                    }
                  </span>
                </div>
              </div>
              <Input
                icon={<Icon icon="cake" size={22} />}
                label={
                  translation.TEMPLATES.CONTACT.STEPS[step.CHILDREN].FORM
                    .BIRTHDAY.LABEL
                }
                placeholder={
                  translation.TEMPLATES.CONTACT.STEPS[step.CHILDREN].FORM
                    .BIRTHDAY.PLACEHOLDER
                }
                type="date"
                variant="simple"
                format={DATE_FORMAT}
                disabledDate={(current) => current > dayjs()}
                defaultPickerValue={dayjs().subtract(1, "years")}
                {...bindDateInputProps({
                  name: `children.${i}.identity.user.birthDate`,
                  values,
                  ...formProps,
                })}
              />
              <Select
                icon={<Icon icon="health_and_safety" size={22} />}
                options={Object.entries(SOCIAL_REGIMES).map(([key, value]) => ({
                  value,
                  label: translation.SOCIAL_REGIMES[key],
                }))}
                label={
                  translation.TEMPLATES.CONTACT.STEPS[step.CHILDREN].FORM
                    .SOCIAL_REGIME.LABEL
                }
                placeholder={
                  translation.TEMPLATES.CONTACT.STEPS[step.CHILDREN].FORM
                    .SOCIAL_REGIME.PLACEHOLDER
                }
                {...bindInputProps({
                  name: `children.${i}.identity.socialRegime`,
                  values,
                  ...formProps,
                })}
              />
            </div>,
          ]),
          <button
            key="add-child"
            className="button__action"
            onClick={() => {
              arrayHelpers.push({
                id: cuid(),
                relationship: RELATIONSHIP.CHILD,
                identity: {
                  id: cuid(),
                  socialRegime: null,
                  user: {
                    id: cuid(),
                    birthDate: null,
                  },
                },
              });
            }}
          >
            <Icon icon="add" size={22} />
            {
              translation.TEMPLATES.CONTACT.STEPS[step.CHILDREN].FORM
                .ADD_NEW_CHILD
            }
          </button>,
        ]}
      />
    </div>
  </div>
);
export default Children;
