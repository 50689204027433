import { Col, Row } from "antd";
import cuid from "cuid";
import { FieldArray } from "formik";
import { get } from "lodash";
import { BiPlus, BiTrash } from "react-icons/bi";
import Button from "shared/components/Button";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import Modal from "shared/components/Modal";
import { COLORS } from "shared/style/colors";
import { BOOLEAN } from "utils/constants";
import { bindArrayInputProps } from "utils/helpers/input";

const ConditionsModal = ({
  modal,
  setModal,
  values,
  translation,
  formProps,
  setFieldValue,
}) => {
  return (
    <Modal
      key="modal"
      okText={"OK"}
      cancelText={"CANCEL"}
      onCancel={() => setModal({ visible: false })}
      onOk={() => setModal({ visible: false })}
      visible={modal.visible}
      width={1000}
      child={
        <Form title={translation.CONDITIONS_INFO}>
          <Col span={24}>
            <Row gutter={16}>
              <FieldArray
                name={`processStatuses.${modal.processStatusIndex}.${modal.name}.${modal.childIndex}.conditions`}
                render={(arrayHelpers) => [
                  get(
                    values,
                    `processStatuses.${modal.processStatusIndex}.${modal.name}.${modal.childIndex}.conditions`,
                    []
                  ).map((_, i) => [
                    <Col lg={6} key={`conditionLeft-${_.id}`}>
                      <Input
                        placeholder={translation.ACCESSOR}
                        label={translation.ACCESSOR}
                        {...bindArrayInputProps({
                          parent: `processStatuses.${modal.processStatusIndex}.${modal.name}.${modal.childIndex}.conditions`,
                          index: i,
                          name: `leftOperand.accessor`,
                          values,
                          ...formProps,
                        })}
                      />
                    </Col>,
                    <Col lg={6} key={`condition-${_.id}`}>
                      <Input
                        placeholder={translation.OPERATION}
                        label={translation.OPERATION}
                        {...bindArrayInputProps({
                          parent: `processStatuses.${modal.processStatusIndex}.${modal.name}.${modal.childIndex}.conditions`,
                          index: i,
                          name: "operation",
                          values,
                          ...formProps,
                        })}
                      />
                    </Col>,
                    <Col lg={6} key={`conditionRight-${_.id}`}>
                      <Input
                        placeholder={translation.VALUE}
                        label={translation.VALUE}
                        {...bindArrayInputProps({
                          parent: `processStatuses.${modal.processStatusIndex}.${modal.name}.${modal.childIndex}.conditions`,
                          index: i,
                          name: "rightOperand.value",
                          values,
                          ...formProps,
                        })}
                        onChange={(e) => {
                          setFieldValue(
                            `processStatuses.${modal.processStatusIndex}.${modal.name}.${modal.childIndex}.conditions.${i}.rightOperand.value`,
                            BOOLEAN[e.target.value] ?? e.target.value
                          );
                        }}
                      />
                    </Col>,
                    <Col lg={2} key={`delete-${_.id}`}>
                      <div
                        className={"delete-icon"}
                        onClick={() => {
                          arrayHelpers.remove(i);
                        }}
                      >
                        <BiTrash color={COLORS.C_DANGER} size={24} />
                      </div>
                    </Col>,
                  ]),
                  <Button
                    key="add-row"
                    type="primary"
                    onClick={() => {
                      arrayHelpers.push({
                        id: cuid(),
                        leftOperand: {},
                        rightOperand: {},
                      });
                    }}
                  >
                    <BiPlus size={24} />
                  </Button>,
                ]}
              />
            </Row>
          </Col>
        </Form>
      }
    />
  );
};

export default ConditionsModal;
