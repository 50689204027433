import cuid from "cuid";
import { Formik } from "formik";
import { defaultTo } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useTranslation } from "react-i18next";
import { BiCheck } from "react-icons/bi";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { COVERAGE_NEEDS } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Detail = ({ onSubmit, data }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.CATEGORIES_WEIGHT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  return (
    <Formik
      initialValues={defaultTo(omitDeep(data, "__typename"), {
        id: cuid(),
        [COVERAGE_NEEDS.OPTICAL]: 0,
        [COVERAGE_NEEDS.DENTAL]: 0,
        [COVERAGE_NEEDS.MEDICAL_CARE]: 0,
        [COVERAGE_NEEDS.HOSPITALIZATION]: 0,
        [COVERAGE_NEEDS.EQUIPMENT]: 0,
        [COVERAGE_NEEDS.ALTERNATIVE_MEDICINE]: 0,
      })}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, ...formProps }) => [
        <Header
          key={"header"}
          title={DETAIL.HEADER.TITLE}
          actions={[
            {
              text: DETAIL.HEADER.SAVE,
              onClick: handleSubmit,
              type: "primary",
              icon: <BiCheck size={20} />,
            },
          ]}
        />,
        <Card key="card" type={"simple"}>
          <div className="grid--1">
            <div className="grid--4 m-bottom-16">
              <strong>{FORM.COVERAGE_CATEGORY}</strong>
              <strong>{FORM.WEIGHT}</strong>
            </div>
            {Object.entries(COVERAGE_NEEDS).map(([key, name]) => (
              <div className="grid--4" key={key}>
                <span>{FORM.COVERAGE_CLASSES[key]}</span>
                <Input
                  type="number"
                  placeholder={FORM.DESCRIPTION}
                  {...bindInputProps({
                    name,
                    ...formProps,
                  })}
                />
              </div>
            ))}
          </div>
        </Card>,
      ]}
    </Formik>
  );
};

export default Detail;
