import { gql } from "@apollo/client";

const USER_ADDED = gql`
  subscription userAdded($where: UserWhereInput) {
    userAdded(where: $where) {
      id
      firstname
      lastname
      phone
      type
      meta
      email
      isActive
      organization {
        telecoms {
          system
          value
        }
      }
    }
  }
`;

const USER_UPDATED = gql`
  subscription userUpdated($where: UserWhereInput) {
    userUpdated(where: $where) {
      id
      firstname
      lastname
      phone
      type
      meta
      isActive
      email
      organization {
        telecoms {
          system
          value
        }
      }
    }
  }
`;

const USER_DELETED = gql`
  subscription userDeleted($where: UserWhereInput) {
    userDeleted(where: $where) {
      id
      firstname
      lastname
      phone
      type
      meta
      email
      isActive
      organization {
        telecoms {
          system
          value
        }
      }
    }
  }
`;

const users = { USER_ADDED, USER_UPDATED, USER_DELETED };

export default users;
