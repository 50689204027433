import { useMemo } from "react";

import PropTypes from "prop-types";

import Percent from "./Percent";
import Simple from "./Simple";

export const FILE_MODE = {
  SIMPLE_FILE: "SIMPLE_FILE",
  FILE_WITH_PERCENTAGE: "FILE_WITH_PERCENTAGE",
};

const MODES = {
  [FILE_MODE.FILE_WITH_PERCENTAGE]: Percent,
  [FILE_MODE.SIMPLE_FILE]: Simple,
};

const File = ({ mode, ...props }) => {
  const View = useMemo(() => MODES[mode], [mode]);
  return <View {...props} />;
};

File.propTypes = {
  mode: PropTypes.oneOf(Object.keys(MODES)),
};

File.defaultProps = {
  mode: FILE_MODE.SIMPLE_FILE,
};

export default File;
