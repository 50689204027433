import { gql } from "@apollo/client";

const COVERAGE_ADDED = gql`
  subscription CoverageAdded($where: CoverageWhereInput) {
    coverageAdded(where: $where) {
      id
      type
      identifier
      coverageBenefits {
        id
        type
        limits {
          id
          value
        }
      }
      insurancePlan {
        id
        name
      }
      waitingDays
    }
  }
`;

const COVERAGE_DUPLICATED = gql`
  subscription CoverageDuplicated($where: CoverageWhereInput) {
    coverageDuplicated(where: $where) {
      id
      type
      identifier
      coverageBenefits {
        id
        type
        limits {
          id
          value
        }
      }
      insurancePlan {
        id
        name
      }
      waitingDays
    }
  }
`;

const COVERAGE_UPDATED = gql`
  subscription CoverageUpdated($where: CoverageWhereInput) {
    coverageUpdated(where: $where) {
      id
      type
      identifier
      coverageBenefits {
        id
        type
        limits {
          id
          value
        }
      }
      insurancePlan {
        id
        name
      }
      waitingDays
    }
  }
`;

const COVERAGE_DELETED = gql`
  subscription CoverageDeleted($where: CoverageWhereInput) {
    coverageDeleted(where: $where) {
      id
      type
      identifier
      coverageBenefits {
        id
        type
        limits {
          id
          value
        }
      }
      insurancePlan {
        id
        name
      }
      waitingDays
    }
  }
`;

const coverages = {
  COVERAGE_ADDED,
  COVERAGE_UPDATED,
  COVERAGE_DELETED,
  COVERAGE_DUPLICATED,
};

export default coverages;
