import { SUBSCRIPTION_FORM_KEYS } from "utils/constants";
import BankAccount from "./BankAccount";
import Id from "./Id";
import Kbis from "./Kbis";
import SocialSecurityCertificate from "./SocialSecurityCertificate";

const generateProofForm = ({ isTNS, acceptTeletransmission }) => ({
  [SUBSCRIPTION_FORM_KEYS.PROOF.SOCIAL_SECURITY_CERTIFICATE]:
    acceptTeletransmission && SocialSecurityCertificate,
  [SUBSCRIPTION_FORM_KEYS.PROOF.ID]: Id,
  [SUBSCRIPTION_FORM_KEYS.PROOF.BANK_ACCOUNT]: BankAccount,
  [SUBSCRIPTION_FORM_KEYS.PROOF.KBIS]: isTNS && Kbis,
});

export default generateProofForm;
