import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "shared/components/Spin";
import { SCREENS } from "utils/constants";

const Redirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(SCREENS.AUTH.SIGN_IN.path, { replace: true });
  }, []);

  return <Loading />;
};

export default Redirect;
