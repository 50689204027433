import { Col, Row } from "antd";
import { useParams } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import Card from "./Card";

const InsurancePlans = ({ generalCosts, translation }) => {
  const [updateGeneralCost] = useMutation(
    graphql.mutations.UPDATE_GENERAL_COST
  );
  const { id } = useParams();
  return (
    <Row gutter={[10, 8]} wrap={false}>
      <Col span={4} />
      {generalCosts.map((generalCost) => {
        return (
          <Col flex="1" key={generalCost.id}>
            <Card
              generalCost={generalCost}
              period={translation.TEMPLATES.COMPARISON.MONTH}
              onBlur={(cost) =>
                updateGeneralCost({
                  variables: {
                    where: { id: generalCost.id },
                    data: {
                      insurancePlan: { id: generalCost.insurancePlan.id },
                      project: { id },
                      cost,
                    },
                  },
                })
              }
            />
          </Col>
        );
      })}
    </Row>
  );
};

export default InsurancePlans;
