import gql from "graphql-tag";

export const CREATE_QUALIFIER = gql`
  mutation AddQualifier($data: QualifierDataInput) {
    addQualifier(data: $data) {
      id
    }
  }
`;

export const UPDATE_QUALIFIER = gql`
  mutation UpdateQualifier(
    $data: QualifierDataInput
    $where: QualifierWhereInput
  ) {
    updateQualifier(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_QUALIFIER = gql`
  mutation DeleteQualifier($where: QualifierWhereInput) {
    deleteQualifier(where: $where) {
      id
    }
  }
`;

const qualifiers = { CREATE_QUALIFIER, UPDATE_QUALIFIER, DELETE_QUALIFIER };

export default qualifiers;
