import { useMemo } from "react";

import PropTypes from "prop-types";

import Category from "./Category";
import Custom from "./Custom";
import Simple from "./Simple";

const MODES = {
  simple: Simple,
  custom: Custom,
  category: Category,
};

const Status = ({ type, ...props }) => {
  const View = useMemo(() => MODES[type], [type]);
  return <View {...props} />;
};

Status.propTypes = {
  type: PropTypes.oneOf(Object.keys(MODES)),
};

Status.defaultProps = {
  type: "simple",
};

export default Status;
