import cuid from "cuid";
import { Formik } from "formik";
import { defaultsDeep, filter, find, get } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "react-material-symbols/rounded";
import Form from "shared/components/Form";
import Header from "shared/components/Header";
import { useLazyQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { ADDRESS_ZIP_CODE_CITY } from "utils/api/graphql/queries/referentials";
import { CONTACT_POINT_SYSTEM, RELATIONSHIP } from "utils/constants";
import Steps from "./Widgets/Steps";
import validation from "./validation";

const STEPS_KEYS = {
  CONTRACT: "CONTRACT",
  ADHERENT: "ADHERENT",
  SPOUSE: "SPOUSE",
  CHILDREN: "CHILDREN",
};

const Contact = ({ onNext, project }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const [city, setCity] = useState({
    id: cuid(),
    system: CONTACT_POINT_SYSTEM.CITY,
    value: null,
  });
  const [addressZipCodeCity] = useLazyQuery(ADDRESS_ZIP_CODE_CITY);

  const spouse = find(get(project, "contact.relatedPersons", []), {
    relationship: RELATIONSHIP.SPOUSE,
  });
  const initialValues = useMemo(
    () =>
      defaultsDeep(
        {
          contract: { ...project.contract },
          contact: { ...project.contact },
          children: filter(get(project, "contact.relatedPersons", []), {
            relationship: RELATIONSHIP.CHILD,
          }),
          spouse,
          zipCode: find(get(project, "contact.telecoms", []), {
            system: CONTACT_POINT_SYSTEM.ZIP_CODE,
          }),
          ria: project.ria,
        },
        {
          projectType: null,
          ria: false,
          hasSpouse: !!spouse,
          children: [],
          spouse: {
            id: cuid(),
            relationship: RELATIONSHIP.SPOUSE,
            identity: {
              id: cuid(),
              socialRegime: null,
              user: { id: cuid(), birthDate: null },
            },
          },
          zipCode: {
            id: cuid(),
            system: CONTACT_POINT_SYSTEM.ZIP_CODE,
            value: null,
          },
          contract: { id: cuid(), issuanceDate: null },
          contact: {
            id: cuid(),
            socialRegime: null,
            socioProfessionalCategory: null,
            politicallyExposedPerson: false,
            exerciseFrame: null,
            user: {
              id: cuid(),
              gender: null,
              firstname: null,
              lastname: null,
              birthDate: null,
            },
          },
        }
      ),
    []
  );
  const onSubmit = (record) => {
    const { hasSpouse, spouse, children, zipCode, contact, ...rest } = record;
    onNext({
      payload: {
        ...rest,
        contact: {
          ...contact,
          user: contact.user,
          relatedPersons: [...(hasSpouse ? [spouse] : []), ...children],
          telecoms: [
            ...get(contact, "telecoms", []).filter(
              ({ system }) => system !== CONTACT_POINT_SYSTEM.ZIP_CODE
            ),
            zipCode,
            city,
          ],
        },
        expired: false,
      },
    });
  };

  return (
    <>
      <Header key={"header"} title={DETAIL.TEMPLATES.CONTACT.TITLE} />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validation(
          t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true }),
          addressZipCodeCity,
          setCity
        )}
      >
        {({ isValid, handleSubmit, values, ...formProps }) => [
          <div className="contact-form" key="form_area">
            <div className="contact-form__content">
              <Form
                title={DETAIL.TEMPLATES.CONTACT.TITLE}
                description={DETAIL.TEMPLATES.CONTACT.DESCRIPTION}
              >
                {Object.keys(STEPS_KEYS).map((step, index) => {
                  return Steps({
                    index: index + 1,
                    values,
                    translation: DETAIL,
                    ...formProps,
                  })[step];
                })}
              </Form>
            </div>
            <div className="contact-form__action">
              <button
                className="contact-form__action--submit"
                onClick={handleSubmit}
                type="submit"
              >
                {DETAIL.TEMPLATES.CONTACT.SUBMIT}
                <Icon icon="arrow_forward_ios" size={20} />
              </button>
            </div>
          </div>,
        ]}
      </Formik>
    </>
  );
};

export default Contact;
