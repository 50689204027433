import gql from "graphql-tag";

export const CREATE_COMMISSION_STATEMENT = gql`
  mutation AddCommissionStatement($data: CommissionStatementDataInput) {
    addCommissionStatement(data: $data) {
      id
    }
  }
`;

export const UPDATE_COMMISSION_STATEMENT = gql`
  mutation UpdateCommissionStatement(
    $data: CommissionStatementDataInput
    $where: CommissionStatementWhereInput
  ) {
    updateCommissionStatement(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_COMMISSION_STATEMENT = gql`
  mutation DeleteCommissionStatement($where: CommissionStatementWhereInput) {
    deleteCommissionStatement(where: $where) {
      id
    }
  }
`;

const commissionStatements = {
  CREATE_COMMISSION_STATEMENT,
  UPDATE_COMMISSION_STATEMENT,
  DELETE_COMMISSION_STATEMENT,
};

export default commissionStatements;
