import Card from "antd/es/card/Card";
import Brokers from "entities/Brokers";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Header from "shared/components/Header";
import Search from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { INITIAL_VALUES, ORGANIZATION_TYPES, SCREENS } from "utils/constants";
import View from "./View";

const List = () => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.BROKER);
  const LIST = t("LIST", { returnObjects: true });
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState({
    where: { organization: { type: ORGANIZATION_TYPES.BROKER } },
    ...INITIAL_VALUES,
  });

  const navigate = useNavigate();

  const onSearch = (value) => {
    setFilter((prev) => ({
      ...prev,
      like: {
        ...prev.like,
        OR: [{ name: `%${value}%` }],
      },
    }));
  };

  const buttons = [
    {
      icon: <MdAdd size={24} />,
      text: LIST.HEADER.SAVE,
      onClick: () => navigate(SCREENS.PRIVATE.ADMIN.BROKERS.CREATE.path),
    },
  ];

  return (
    <>
      <Header title={LIST.HEADER.TITLE} actions={buttons} />
      <Card type="simple">
        <div className="m-bottom-20 grid--4">
          <Search
            placeholder={LIST.SEARCH}
            type="search"
            onChange={({ target: { value } }) => onSearch(value)}
          />
        </div>
        <Brokers
          View={View}
          filter={{ ...filter, ...pagination }}
          setValues={setPagination}
          skip={pagination.skip || 0}
          NoData={View}
        />
      </Card>
    </>
  );
};

export default List;
