import cuid from "cuid";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import { CREATE_WORKFLOW } from "utils/api/graphql/mutations/workflows";
import { WORKFLOWS } from "utils/api/graphql/queries/workflows";
import { INITIAL_VALUES, SCREENS } from "utils/constants";
import View from "./View";

const Create = () => {
  const navigate = useNavigate();
  const id = useMemo(() => cuid(), []);
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.WORKFLOWS.LIST.path);
  const [addWorkflow] = useMutation(CREATE_WORKFLOW, {
    refetchQueries: [
      {
        query: WORKFLOWS,
        awaitRefetchQueries: true,
        variables: INITIAL_VALUES,
      },
      {
        query: WORKFLOWS,
        awaitRefetchQueries: true,
      },
    ],
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.ADMIN.WORKFLOWS.LIST.path);
    },
    onError: () => {},
  });
  const onSubmit = (data) => {
    return addWorkflow({
      variables: {
        data,
      },
    });
  };
  return <View onSubmit={onSubmit} id={id} cancel={cancel} />;
};

export default Create;
