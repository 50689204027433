import { Col, Row, Switch } from "antd";
import dayjs from "dayjs";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import Icon from "react-material-symbols/rounded";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import {
  DATE_FORMAT,
  RELATIONSHIP,
  SOCIAL_REGIMES,
  SOCIO_PROFESSIONAL_CATEGORIES,
  SUBSCRIPTION_FORM_KEYS,
} from "utils/constants";
import { bindInputProps } from "utils/helpers/input";

const Partner = ({
  formProps,
  values,
  setFieldValue,
  setPercent,
  isFieldRequired,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });
  const birthDate = get(values, "spouse.identity.user.birthDate", undefined);

  return (
    get(values, "project.contact.relatedPersons", []).find(
      (person) => person.relationship === RELATIONSHIP.SPOUSE
    ) && (
      <div
        className="subscription-form--wrapper"
        id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.PARTNER}-section`}
      >
        <Form
          type="vertical"
          title={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PARTNER.TITLE}
          subtitle={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.PARTNER.SUBTITLE}
        >
          <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
              <div className="d-flex justify--between">
                <Icon
                  className="contact-stepper__content--radio--icon"
                  icon="change_circle"
                  size={22}
                />
                <span className="contact-stepper__content--radio--title">
                  {SUBSCRIPTION.FORM.TELETRANSMISSION.LABEL}
                </span>
                <Switch
                  className="contact-stepper__content--radio--switch button__switch"
                  checkedChildren={
                    SUBSCRIPTION.FORM.TELETRANSMISSION.OPTIONS.YES
                  }
                  unCheckedChildren={
                    SUBSCRIPTION.FORM.TELETRANSMISSION.OPTIONS.NO
                  }
                  checked={get(
                    values,
                    "spouse.identity.fields.teletransmission"
                  )}
                  defaultChecked={get(
                    values,
                    "spouse.identity.fields.teletransmission"
                  )}
                  onChange={(e) => {
                    setFieldValue("spouse.identity.fields.teletransmission", e);
                  }}
                  disabled={
                    !get(values, "project.contact.fields.teletransmission")
                  }
                />
              </div>
            </Col>
            <Col lg={24} xs={24}>
              <Input
                placeholder={SUBSCRIPTION.FORM.LAST_NAME.PLACEHOLDER}
                label={SUBSCRIPTION.FORM.LAST_NAME.LABEL}
                icon={<Icon icon="badge" size={16} />}
                {...bindInputProps({
                  name: "spouse.identity.user.lastname",
                  values,
                  ...formProps,
                  onBlur: () => setPercent(values),
                })}
                required={isFieldRequired("spouse.identity.user.lastname")}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Input
                label={SUBSCRIPTION.FORM.FIRST_NAME.LABEL}
                icon={<Icon icon="badge" size={16} />}
                placeholder={SUBSCRIPTION.FORM.LAST_NAME.PLACEHOLDER}
                {...bindInputProps({
                  name: "spouse.identity.user.firstname",
                  values,
                  ...formProps,
                  onBlur: () => setPercent(values),
                })}
                required={isFieldRequired("spouse.identity.user.firstname")}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Input
                label={SUBSCRIPTION.FORM.BIRTH_DATE.LABEL}
                icon={<Icon icon="cake" size={16} />}
                placeholder={SUBSCRIPTION.FORM.BIRTH_DATE.PLACEHOLDER}
                type="date"
                variant="simple"
                defaultValue={birthDate && dayjs(birthDate)}
                format={DATE_FORMAT}
                disabled
                required={isFieldRequired("spouse.identity.user.birthDate")}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Select
                type="simple"
                label={SUBSCRIPTION.FORM.SOCIO_PROFESSIONAL_CATEGORY.LABEL}
                icon={<Icon icon="work" size={16} />}
                placeholder={
                  SUBSCRIPTION.FORM.SOCIO_PROFESSIONAL_CATEGORY.PLACEHOLDER
                }
                options={Object.entries(SOCIO_PROFESSIONAL_CATEGORIES).map(
                  ([key, value]) => ({
                    value,
                    label:
                      SUBSCRIPTION.FORM.SOCIO_PROFESSIONAL_CATEGORY.OPTIONS[
                        key
                      ],
                  })
                )}
                {...bindInputProps({
                  name: "spouse.identity.socioProfessionalCategory",
                  values,
                  ...formProps,
                  onBlur: () => setPercent(values),
                })}
                required={isFieldRequired(
                  "spouse.identity.socioProfessionalCategory"
                )}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Select
                icon={<Icon icon="health_and_safety" size={16} />}
                options={Object.entries(SOCIAL_REGIMES).map(([key, value]) => ({
                  value,
                  label: SUBSCRIPTION.FORM.MANDATORY_SCHEME.OPTIONS[key],
                }))}
                label={SUBSCRIPTION.FORM.MANDATORY_SCHEME.LABEL}
                placeholder={SUBSCRIPTION.FORM.MANDATORY_SCHEME.PLACEHOLDER}
                {...bindInputProps({
                  name: "spouse.identity.socialRegime",
                  values,
                  ...formProps,
                })}
                disabled
                required={isFieldRequired("spouse.identity.socialRegime")}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Input
                label={SUBSCRIPTION.FORM.SOCIAL_SECURITY_NUMBER.LABEL}
                icon={<Icon icon="subtitles" size={16} />}
                placeholder={
                  SUBSCRIPTION.FORM.SOCIAL_SECURITY_NUMBER.PLACEHOLDER
                }
                {...bindInputProps({
                  name: "spouse.identity.socialSecurityNumber",
                  values,
                  ...formProps,
                  onBlur: () => setPercent(values),
                })}
                required={isFieldRequired(
                  "spouse.identity.socialSecurityNumber"
                )}
              />
            </Col>
          </Row>
        </Form>
      </div>
    )
  );
};

export default Partner;
