import { Col, Row } from "antd";
import PropTypes from "prop-types";

const Component = ({ title, subtitle, children }) => {
  return (
    <div className="form-vertical--wrapper">
      <Row gutter={[16, 16]}>
        <Col lg={24} xs={24}>
          <div className="form-vertical--title">
            <h3>{title}</h3>
            <span>{subtitle}</span>
          </div>
        </Col>
        <Col lg={24} xs={24}>
          <div className="form-vertical--content">{children}</div>
        </Col>
      </Row>
    </div>
  );
};

Component.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
};

export default Component;
