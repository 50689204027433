import { gql } from "@apollo/client";

export const QUALIFICATIONS = gql`
  query Qualifications(
    $where: QualificationWhereInput
    $like: JSONObject
    $concat: JSONObject
    $take: Int
    $skip: Int
    $gt: QualificationWhereInput
    $lt: QualificationWhereInput
    $orderBy: [JSONObject]
  ) {
    qualifications(
      where: $where
      like: $like
      concat: $concat
      take: $take
      skip: $skip
      gt: $gt
      lt: $lt
      orderBy: $orderBy
    ) {
      data {
        id
        last
        hasNote
        createdDate
        content
        contact {
          id
          name
          createdDate
          notes {
            id
            content
            createdDate
          }
          footprint {
            id
            source {
              id
              name
            }
          }
          raw
          fields
          user {
            id
            firstname
            lastname
            gender
            phone
            birthDate
          }
          relatedPersons {
            id
            relationship
            identity {
              id
              socialRegime
              socioProfessionalCategory
              user {
                id
                firstname
                lastname
                birthDate
              }
            }
          }
          telecoms {
            id
            system
            value
          }
        }
        qualifier {
          id
          name
          fields
          isDefault
        }
        broker {
          id
          firstname
          lastname
          username
        }
        notes {
          id
          content
          createdDate
          author {
            id
            firstname
            lastname
          }
        }
      }
      count
    }
  }
`;

export const QUALIFICATION = gql`
  query Qualification($where: QualificationWhereInput) {
    qualification(where: $where) {
      id
      last
      hasNote
      contact {
        id
        name
        createdDate
        notes {
          id
          content
          createdDate
        }
        raw
        fields
      }
      qualifier {
        id
        name
        fields
      }
      notes {
        id
        content
        createdDate
        author {
          id
          firstname
          lastname
        }
      }
    }
  }
`;

const qualifications = { QUALIFICATIONS, QUALIFICATION };

export default qualifications;
