import { BsClockHistory } from "react-icons/bs";
import Alert from "shared/components/Alert";

const Pending = ({ translation }) => {
  return (
    <>
      <Alert showIcon type="info" message={translation.E_SIGNATURE_PENDING} />
      <div className="waiting_signature">
        <BsClockHistory size={18} />
        <span>{translation.WAITING_E_SIGNATURE}</span>
      </div>
    </>
  );
};

export default Pending;
