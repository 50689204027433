import classNames from "classnames";
import PropTypes from "prop-types";

const Popover = ({ text, small }) => (
  <div
    className={classNames({
      "custom-popover": true,
      small,
    })}
  >
    <div className="popover-arrow"></div>
    <p>{text}</p>
  </div>
);

Popover.propTypes = {
  text: PropTypes.string,
  small: PropTypes.bool,
};

Popover.defaultProps = {
  small: false,
};

export default Popover;
