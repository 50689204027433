import React from "react";
import { DatePicker as DatePickerField } from "antd";
import "./style.scss";
import PropTypes from "prop-types";

const DatePicker = ({ placeholder, placeholders, errors, touched, ...rest }) => {
  return (
    <div className={"datepicker"}>
      <p className={"datepicker-placeholder"}>{placeholder}</p>
      <DatePickerField.RangePicker
        className={`${touched && errors && "datepicker-datepicker-error"} datepicker-datepicker`}
        placeholder={placeholders}
        {...rest}
      />
      {touched && <small className={"datepicker-error"}>{errors}</small>}
    </div>
  );
};

DatePicker.propTypes = {
  placeholder: PropTypes.string,
};

DatePicker.defaultProps = {
  placeholder: "",
};

export default DatePicker;
