import { gql } from "@apollo/client";

export const PROCESS_STATUSES = gql`
  query ProcessStatuses($where: ProcessStatusWhereInput) {
    processStatuses(where: $where) {
      data {
        id
        name
      }
      count
    }
  }
`;

export const PROCESS_STATUS = gql`
  query ProcessStatus($where: ProcessStatusWhereInput) {
    processStatus(where: $where) {
      id
      name
    }
  }
`;

const processStatuses = { PROCESS_STATUSES, PROCESS_STATUS };

export default processStatuses;
