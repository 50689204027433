import { Table } from "antd";
import { chain, flattenDeep, get, groupBy, isEmpty, sortBy } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import Icon from "react-material-symbols/rounded";
import AlternativeMedicine from "shared/assets/images/ayurveda.png";
import Dental from "shared/assets/images/dental-checkup.png";
import Equipment from "shared/assets/images/hearing-aid.png";
import Hospitalization from "shared/assets/images/hospital-bed.png";
import Maternity from "shared/assets/images/maternity.png";
import MedicalCare from "shared/assets/images/medical-team.png";
import Others from "shared/assets/images/others.png";
import Prevention from "shared/assets/images/prevention.png";
import Optical from "shared/assets/images/vision.png";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import { NAME_SPACES } from "shared/locales/constants";
import { COVERAGE_CATEGORIES } from "utils/constants";
import CoverageBenefitCard from "./Widgets/Coverages/Card";
import CoverageCategoryHeader from "./Widgets/Coverages/Header";
import CoverageLabel from "./Widgets/Coverages/Label";
import DownloadOrSend from "./Widgets/DownloadOrSend";
import InsurancePlanCard from "./Widgets/InsurancePlans";

export const COVERAGE_CATEGORIES_ICONS = {
  [COVERAGE_CATEGORIES.HOSPITALIZATION]: Hospitalization,
  [COVERAGE_CATEGORIES.MEDICAL_CARE]: MedicalCare,
  [COVERAGE_CATEGORIES.OPTICAL]: Optical,
  [COVERAGE_CATEGORIES.DENTAL]: Dental,
  [COVERAGE_CATEGORIES.EQUIPMENT]: Equipment,
  [COVERAGE_CATEGORIES.ALTERNATIVE_MEDICINE]: AlternativeMedicine,
  [COVERAGE_CATEGORIES.PREVENTION]: Prevention,
  [COVERAGE_CATEGORIES.MATERNITY]: Maternity,
  [COVERAGE_CATEGORIES.OTHER]: Others,
};

const View = ({
  project,
  onSubscribe,
  onDownload,
  onSendQuote,
  data,
  onBack,
}) => {
  const { locked, fields, subscriptions, contract } = project;
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const [showNext, setShowNext] = useState(false);
  const [hideDetail, setHideDetail] = useState({});
  const toggleCoverage = (name, hide) => {
    setHideDetail((prev) => ({
      ...prev,
      [name]: hide,
    }));
  };

  const HIDE_DETAIL = {
    [false]: (
      <>
        <span>{DETAIL.TEMPLATES.COMPARISON.SEE_MORE}</span>
        <MdOutlineExpandMore />
      </>
    ),
    [true]: (
      <>
        <span>{DETAIL.TEMPLATES.COMPARISON.SEE_LESS}</span>
        <MdOutlineExpandLess />
      </>
    ),
  };

  const dataSource = useMemo(() => {
    const groupedCoverageBenefits = chain(data)
      .flatMap((cost) => cost.insurancePlan.coverages)
      .flatMap((coverage) => {
        return get(coverage, "coverageBenefits", []).map((benefit) => ({
          coverageId: coverage.id,
          coverageType: coverage.type,
          benefitType: benefit.type,
          benefitSubtype: benefit.subtype,
          benefitInfo: benefit.additionalInfo,
          benefitOrder: benefit.order,
          benefit,
        }));
      })
      .groupBy("coverageType")
      .mapValues((coverageBenefits) => {
        const sortedBenefits = sortBy(coverageBenefits, "benefitOrder");
        return groupBy(sortedBenefits, "benefitType");
      })
      .value();

    const dataSource = flattenDeep(
      Object.entries(COVERAGE_CATEGORIES_ICONS).map(([key, icon], index) => [
        ...(isEmpty(Object.entries(get(groupedCoverageBenefits, key, {})))
          ? []
          : [{ id: `${key}-${index}`, label: key, icon }]),
        Object.entries(get(groupedCoverageBenefits, key, {})).map(
          ([label, values], i) => {
            return {
              label,
              benefits: values,
              id: `${label}-${index}-${i}`,
            };
          }
        ),
      ])
    );
    return dataSource;
  }, []);

  const columns = [
    {
      width: 300,
      dataIndex: "label",
      render: (label, row) =>
        row.icon ? (
          <CoverageCategoryHeader
            className="coverage-header"
            src={get(row, "icon")}
            type={label}
            label={DETAIL.TEMPLATES.COMPARISON.COVERAGES_CATEGORIES[label]}
          />
        ) : (
          <CoverageLabel
            label={label}
            subLabel={get(row, "benefits.0.benefitSubtype", "")}
            info={get(row, "benefits.0.benefitInfo", "")}
            expanded={hideDetail[label]}
            link={HIDE_DETAIL[get(hideDetail, label, false)]}
            onToggle={() => toggleCoverage(label, !hideDetail[label])}
          />
        ),
      fixed: true,
    },
    ...(data?.map(({ insurancePlan, cost, currency }) => ({
      dataIndex: "benefits",
      title: () => (
        <InsurancePlanCard
          insurancePlan={insurancePlan}
          subscriptions={subscriptions}
          issuanceDate={get(contract, "issuanceDate")}
          isRIA={get(project, "ria", false)}
          cost={cost}
          currency={currency}
          translation={DETAIL.TEMPLATES.COMPARISON}
          onSubscribe={onSubscribe}
          expired={project.expired}
        />
      ),
      render: (benefits, row) => {
        return benefits ? (
          <CoverageBenefitCard
            translation={DETAIL.TEMPLATES.COMPARISON}
            expanded={hideDetail[row.label]}
            coverageBenefit={get(
              benefits.find(({ coverageId }) => {
                return get(insurancePlan, "coverages", [])
                  .map(({ id }) => id)
                  .includes(coverageId);
              }),
              "benefit",
              []
            )}
          />
        ) : null;
      },
    })) || []),
  ];

  return (
    <div>
      <Header
        key={"header"}
        title={DETAIL.TEMPLATES.COMPARISON.TITLE}
        actions={[]}
      />
      <div className="comparison-container">
        <div className="d-flex justify--end">
          <Button
            onClick={() => setShowNext(true)}
            type="primary--outlined"
            disabled={locked}
          >
            {DETAIL.TEMPLATES.COMPARISON.NEXT}
            <Icon icon="arrow_forward_ios" size={16} />
          </Button>
        </div>
        <Card type="simple">
          <DownloadOrSend
            data={data}
            open={showNext}
            onClose={() => setShowNext(false)}
            onDownload={onDownload}
            onSendQuote={onSendQuote}
            fields={fields}
          />
          <Table
            dataSource={dataSource}
            columns={columns}
            className="comparison-table__wrapper"
            rowKey={"id"}
            pagination={false}
            scroll={{ x: true, y: 400 }}
          />
        </Card>

        <Button
          onClick={onBack}
          type="secondary--link"
          disabled={project.locked}
        >
          <Icon icon="arrow_back_ios" size={16} />
          {DETAIL.TEMPLATES.COMPARISON.PREVIOUS}
        </Button>
      </div>
    </div>
  );
};

export default View;
