import PropTypes from "prop-types";
import Loader from "shared/components/Loader";
import { useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { INITIAL_VALUES } from "../utils/constants";

const Projects = ({ View, filter, ...props }) => {
  const variables = {
    ...filter,
    skip: parseInt(filter.skip) || INITIAL_VALUES.skip,
    take: parseInt(filter.take) || INITIAL_VALUES.take,
  };
  const query = useQuery(graphql.queries.PROJECTS, {
    variables,
  });
  const WrappedView = Loader(View);
  return (
    <WrappedView
      {...{
        ...props,
        ...query,
        type: "projects",
      }}
    />
  );
};

Projects.propTypes = {
  View: PropTypes.func.isRequired,
  filter: PropTypes.object,
};

Projects.defaultProps = {
  filter: {},
};

export default Projects;
