import classNames from "classnames";
import Icon from "react-material-symbols/rounded";
import ProgressCircle from "shared/components/Progress";

const Progress = ({ icon, slideTo, form, percent, title, translation }) => {
  return (
    <div
      className={classNames("subscription-progress", {
        complete: percent === 100,
      })}
      onClick={() => slideTo(form)}
    >
      <div className="subscription-progress--header">
        <ProgressCircle
          type="circle"
          percent={percent}
          size="small"
          strokeWidth={2}
          format={() => icon}
        />
        <span className="subscription-progress--header__status">
          {percent === 100 && <Icon icon="check_small" size={8} />}
        </span>
      </div>
      <div className="subscription-progress--content">
        <h5 className="subscription-progress--content__title">{title}</h5>
        <p className="subscription-progress--content__description">
          {percent || 0}% {translation.PROGRESS}
        </p>
      </div>
    </div>
  );
};
export default Progress;
