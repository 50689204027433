import WithRoutePermission from "shared/components/HOC/WithRoutePermission";
import { MODULES, SCREENS, SUBMODULES } from "utils/constants";
import List from "./List";
const routes = [
  {
    path: SCREENS.PRIVATE.ADMIN.WEBHOOKS.LIST.path,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.WEBHOOK]: SUBMODULES.ADMIN_LIST }}
        WrappedComponent={List}
      />
    ),
  },
];

export default routes;
