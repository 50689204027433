import { Select } from "antd";
import PropTypes from "prop-types";

const { Option } = Select;

const Component = ({ defaultValue, items, onChange, placeholder, ...rest }) => {
  return (
    <div className="input">
      <p className={"input-placeholder"}>{placeholder}</p>
      <Select
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChange}
        showArrow={false}
        className="market--select"
        {...rest}
      >
        {items.map((item) => {
          return (
            <Option value={item.value} key={item.value}>
              {item.icon && <img src={item.icon} alt={item.name} />}
              <span>{item.label}</span>
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

Select.propTypes = {
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  items: PropTypes.object,
  onChange: PropTypes.func,
};

Select.defaultProps = {
  placeholder: "",
};

export default Component;
