import omitDeep from "omit-deep-lodash";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useMutation, useQuery } from "shared/hooks/useApi";
import { UPDATE_COMMISSION } from "utils/api/graphql/mutations/commissions";
import { COMMISSION, COMMISSIONS } from "utils/api/graphql/queries/commissions";
import { SCREENS } from "utils/constants";
import View from "./View";

const Detail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.COMMISSIONS.LIST.path);
  const [updateCommission] = useMutation(UPDATE_COMMISSION, {
    refetchQueries: [
      {
        query: COMMISSIONS,
        awaitRefetchQueries: true,
      },
    ],
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.ADMIN.COMMISSIONS.LIST.path);
    },
  });
  const { data, loading, error } = useQuery(COMMISSION, {
    variables: { where: { id } },
  });
  const onSubmit = (data) => {
    return updateCommission({
      variables: {
        where: {
          id,
        },
        data,
      },
    });
  };

  if (loading || error) return <Loading />;

  return (
    <View
      onSubmit={onSubmit}
      data={omitDeep(data.commission, "__typename")}
      cancel={cancel}
    />
  );
};

export default Detail;
