import omitDeep from "omit-deep-lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { INITIAL_VALUES, SCREENS } from "utils/constants";
import View from "./View";

const Detail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.COVERAGES.LIST.path);
  const variables = {
    where: { id },
    orderBy: [{ "coverageBenefits.order": "asc" }],
  };
  const [updateCoverage] = useMutation(graphql.mutations.UPDATE_COVERAGE, {
    refetchQueries: [
      {
        query: graphql.queries.COVERAGES,
        awaitRefetchQueries: true,
        variables: INITIAL_VALUES,
      },
      {
        query: graphql.queries.COVERAGE,
        awaitRefetchQueries: true,
        variables,
      },
    ],
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.ADMIN.COVERAGES.LIST.path);
    },
    onError: () => {},
  });
  const [duplicateCoverage] = useMutation(
    graphql.mutations.DUPLICATE_COVERAGE,
    {
      variables: {
        where: {
          id,
        },
      },
      refetchQueries: [
        {
          query: graphql.queries.COVERAGES,
          awaitRefetchQueries: true,
          variables: INITIAL_VALUES,
        },
      ],
      onCompleted: () => {
        navigate(SCREENS.PRIVATE.ADMIN.COVERAGES.LIST.path);
      },
      onError: () => {},
    }
  );

  const { data, loading, error } = useQuery(graphql.queries.COVERAGE, {
    variables,
  });

  const create = (values) => {
    return updateCoverage({
      variables: {
        where: {
          id,
        },
        data: {
          id,
          ...values,
        },
      },
    });
  };

  if (loading || error) return "Loading";

  return (
    <View
      actions={{ create, duplicate: duplicateCoverage }}
      data={omitDeep(data.coverage, "__typename")}
      cancel={cancel}
    />
  );
};

export default Detail;
