import gql from "graphql-tag";

export const CREATE_CATEGORY_WEIGHT = gql`
  mutation AddCategoryWeight($data: CategoryWeightDataInput) {
    addCategoryWeight(data: $data) {
      id
    }
  }
`;

export const UPDATE_CATEGORY_WEIGHT = gql`
  mutation UpdateCategoryWeight(
    $data: CategoryWeightDataInput
    $where: CategoryWeightWhereInput
  ) {
    updateCategoryWeight(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_CATEGORY_WEIGHT = gql`
  mutation DeleteCategoryWeight($where: CategoryWeightWhereInput) {
    deleteCategoryWeight(where: $where) {
      id
    }
  }
`;

const categoryWeights = {
  CREATE_CATEGORY_WEIGHT,
  UPDATE_CATEGORY_WEIGHT,
  DELETE_CATEGORY_WEIGHT,
};

export default categoryWeights;
