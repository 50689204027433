import { Input } from "antd";
import Label from "../Label";
import React from "react";

const DEFAULT = {
  fOne: true,
  fTwo: false,
  fThree: false,
  fFour: false,
};

const Format = ({
  label,
  placeholder,
  innerRef,
  icon,
  ...rest
}) => {

  const [state, setState] = React.useState(DEFAULT);
  const [show, setShow] = React.useState(false);

  const handleToggle = () => setShow(!show);

  const handle = () => {
    setState(DEFAULT);
    handleToggle();
  };

  const handleClick = (field) => {
    const newState = {};
    Object.keys(DEFAULT).forEach((element) => {
      if (element !== field) {
        newState[element] = false;
      } else {
        newState[element] = true;
      }
    });
    setState(newState);
  };





  return (
    <div className="format__area">
      <div className={"input__second"}>
        <Label label={label} icon={icon} />


        {state.fOne && (
          <div className={`input__second--field`} onClick={handle}>
            <Input
              placeholder={placeholder}
              ref={innerRef}
              suffix={'% BR'}
              {...rest}
            />
          </div>
        )}

        {state.fTwo && (
          <div className={`input__second--field d-flex justify--between`} onClick={handle}>
            <Input
              placeholder={placeholder}
              ref={innerRef}
              suffix={'€/jour'}
              {...rest}
            />

            <span style={{ flexGrow: 1 }}>limité</span>

            <Input
              placeholder={placeholder}
              ref={innerRef}
              suffix={'jours/an'}
              {...rest}
            />
          </div>
        )}

        {state.fThree && (
          <div className={`input__second--field d-flex justify--between`} onClick={handle}>
            <Input
              placeholder={placeholder}
              ref={innerRef}
              suffix={'% BR'}
              {...rest}
            />

            <span style={{ flexGrow: 1 }}>+</span>

            <Input
              placeholder={placeholder}
              ref={innerRef}
              suffix={'€/an'}
              {...rest}
            />
          </div>
        )}

        {state.fFour && (
          <div className={`input__second--field d-flex justify--between`} onClick={handle}>
            <Input
              placeholder={placeholder}
              ref={innerRef}
              suffix={'% BR'}
              {...rest}
            />

            <span style={{ flexGrow: 1 }}>+</span>

            <Input
              placeholder={placeholder}
              ref={innerRef}
              suffix={'€/oreille'}
              {...rest}
            />

            <span style={{ flexGrow: 1 }}>limité</span>

            <Input
              placeholder={placeholder}
              ref={innerRef}
              suffix={'€/oreille'}
              {...rest}
            />
          </div>
        )}


        {show && (
          <div className="format__area--list">
            <div className="item" onClick={() => handleClick('fOne')}>___ % BR</div>
            <div className="item" onClick={() => handleClick('fTwo')}>___ €/jour,max ___ jours/an</div>
            <div className="item" onClick={() => handleClick('fThree')}>___ %BR + ___ €/an</div>
            <div className="item" onClick={() => handleClick('fFour')}>___ %BR + ___ €/oreille, max ___ €/oreille</div>
          </div>
        )}

      </div>
    </div>
  );
};

Format.propTypes = {
};

Format.defaultProps = {};

export default Format;
