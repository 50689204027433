import { useApolloClient } from "@apollo/client";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import Loading from "shared/components/Spin";
import { StoreContext } from "shared/store";
import { SCREENS } from "utils/constants";

const Redirect = () => {
  const { clearStore } = useContext(StoreContext);
  const navigate = useNavigate();
  const client = useApolloClient();

  useEffect(() => {
    const logout = async () => {
      await client.clearStore();
      await client.resetStore();
      clearStore();
      navigate(SCREENS.PUBLIC.INDEX.path);
    };
    logout();
  }, [clearStore, navigate]);

  return (
    <div>
      <Loading />
    </div>
  );
};

export default Redirect;
