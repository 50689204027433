import PropTypes from "prop-types";

const Head = ({ title, onClick,action }) => {
  return (
    <div className="head__area">
        <h4>{title}</h4>
        <span onClick={onClick}>{action}</span>
    </div>
  );
};

Head.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  action: PropTypes.node.isRequired,
};

Head.defaultProps = {};

export default Head;
