import { Formik } from "formik";
import { get } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { SCREENS } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Detail = ({ onSubmit, cancel, data }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.TRACER);
  const navigate = useNavigate();
  const DETAIL = t("DETAIL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  return (
    <Formik
      initialValues={{
        ...omitDeep(data, "__typename"),
        source: get(data, "source.id"),
      }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, ...formProps }) => [
        <Header
          key={"header"}
          title={DETAIL.HEADER.TITLE}
          subtitle={DETAIL.HEADER.ACTION}
          actions={[
            {
              text: DETAIL.HEADER.DISCARD,
              onClick: cancel,
              type: "primary--outlined",
              icon: <BiChevronLeft size={20} />,
            },
            {
              text: DETAIL.HEADER.DELETE,
              onClick: () =>
                navigate(
                  `${SCREENS.PRIVATE.ADMIN.TRACERS.DELETE.path}/${data.id}`
                ),
              type: "danger--outlined",
              icon: <BiTrash size={20} />,
            },
            {
              text: DETAIL.HEADER.SAVE,
              onClick: handleSubmit,
              type: "primary",
              icon: <BiCheck size={20} />,
            },
          ]}
        />,
        <Card key="card" type={"simple"}>
          <div className="grid--2">
            <Input
              placeholder={FORM.NAME}
              label={FORM.NAME}
              {...bindInputProps({ name: "name", ...formProps })}
            />
            <Input
              placeholder={FORM.DESTINATION}
              label={FORM.DESTINATION}
              {...bindInputProps({ name: "destination", ...formProps })}
            />

            <Select
              type="async"
              node={{
                query: graphql.queries.SOURCES,
                variables: { where: {} },
                accessors: { root: "sources.data" },
              }}
              placeholder={FORM.SOURCE}
              label={FORM.SOURCE}
              {...bindInputProps({ name: "source", ...formProps })}
            />
          </div>
        </Card>,
      ]}
    </Formik>
  );
};

export default Detail;
