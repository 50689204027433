import { get } from "lodash";
import moment from "moment";
import Card from "./Card";

const InsurancePlans = ({
  insurancePlan,
  cost,
  currency,
  translation,
  subscriptions = [],
  onSubscribe,
  issuanceDate,
  isRIA,
  expired: projectExpired,
}) => {
  const subscription = subscriptions.find(
    (subscription) => get(subscription, "insurancePlan.id") === insurancePlan.id
  );
  const expired =
    projectExpired ||
    get(insurancePlan, "params", []).some(
      (param) =>
        moment(issuanceDate)
          .startOf("day")
          .diff(moment().startOf("day"), "days") <
        get(param, `${isRIA ? "deltaStartRIA" : "deltaStart"}`)
    );
  return (
    <Card
      key={insurancePlan.id}
      insurancePlan={insurancePlan}
      cost={cost}
      currency={currency}
      period={translation.MONTH}
      popover={{
        active: subscription && expired,
        text: translation.POPOVER,
      }}
      action={{
        label: translation.SUBSCRIPTION,
        onClick: () => {
          onSubscribe({ actionsTemplate: { id: subscription?.id } });
        },
        disabled: !subscription || expired,
      }}
    />
  );
};

export default InsurancePlans;
