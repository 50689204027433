import { Select } from "antd";
import classNames from "classnames";
import Label from "shared/components/Label";

const Simple = ({ label, errors, icon, touched, required, ...rest }) => {
  return (
    <div
      className={classNames("select__wrapper", { __error: touched && errors })}
    >
      <Label label={label} icon={icon} required={required} />
      <div className={`select__wrapper--field`}>
        <Select
          size="large"
          className={classNames({ "disable--input": rest.disabled })}
          {...rest}
        />
        {touched && errors && <p className={"input--errors"}>{errors}</p>}
      </div>
    </div>
  );
};

export default Simple;
