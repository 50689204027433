const { GraphQLWsLink } = require("@apollo/client/link/subscriptions");
const { createClient } = require("graphql-ws");

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_WS_BFF_URL,
  })
);

export default wsLink;
