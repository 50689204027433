import WithRoutePermission from "shared/components/HOC/WithRoutePermission";
import { MODULES, SCREENS, SUBMODULES } from "utils/constants";
import Detail from "./Detail";

const routes = [
  {
    path: `${SCREENS.PRIVATE.ADMIN.COVERAGE_BENEFITS.DETAIL.path}/:id`,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.COVERAGE_BENEFIT]: SUBMODULES.ADMIN_DETAIL }}
        WrappedComponent={Detail}
      />
    ),
  },
];

export default routes;
