import auth from "./auth";
import authenticated from "./authenticated";
import components from "./components";
import errors from "./errors";
import generic from "./generic";
import home from "./home";
import unauthenticated from "./unauthenticated";

export const frTranslation = {
  home,
  generic,
  errors,
  auth,
  components,
  ...unauthenticated,
  ...authenticated,
};
