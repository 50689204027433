import { useState } from "react";
import View from "./View";

const List = () => {
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState({ where: { last: true } });

  return (
    <View
      filter={{ ...filter, ...pagination }}
      setFilter={setFilter}
      setPagination={setPagination}
    />
  );
};

export default List;
