import React from "react";
import { FiMenu } from "react-icons/fi";
import Menu from "./Menu";
import Sidebar from "./Sidebar";

const GlobalLayout = ({ children, sider, breadcrumb }) => {
  const menu = <Menu topics={sider.menu} />;

  const [isOpen, setIsopen] = React.useState(false);
  const toggleSidebar = () => setIsopen((value) => !value);
  return (
    <main className="layout">
      <Sidebar
        isOpen={isOpen}
        onClick={toggleSidebar}
        menu={menu}
        actions={sider.actions}
      />
      <div className="children--layout container">{children}</div>
      <div className="float__menu" onClick={() => setIsopen(true)}>
        <FiMenu size={24} /> <span>Menu</span>
      </div>
    </main>
  );
};

export default GlobalLayout;
