import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { INITIAL_VALUES, ORGANIZATION_TYPES, SCREENS } from "utils/constants";
import View from "./View";

const Delete = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [visible, setVisible] = useState(true);
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.BROKERS.LIST.path);
  const [getUsers, { loading }] = useLazyQuery(graphql.queries.USERS);
  const [getContracts] = useLazyQuery(graphql.queries.CONTRACTS, {
    variables: {
      where: { organization: { broker: { id } } },
    },
  });
  const [updateUser] = useMutation(graphql.mutations.UPDATE_USER_AUTH);
  const [deleteBroker] = useMutation(graphql.mutations.DELETE_BROKER, {
    refetchQueries: [
      {
        query: graphql.queries.BROKERS,
        awaitRefetchQueries: true,
        variables: {
          where: { organization: { type: ORGANIZATION_TYPES.BROKER } },
          ...INITIAL_VALUES,
        },
      },
    ],
    onCompleted: () => {
      setVisible(false);
      navigate(SCREENS.PRIVATE.ADMIN.BROKERS.LIST.path, { replace: true });
    },
    onError: () => {},
  });
  const onSubmit = async () => {
    const { data: contractsData } = await getContracts();

    const organizations =
      contractsData?.contracts?.data?.map(({ subjects }) => ({
        organization: { id: subjects?.signedWith },
      })) || [];

    const { data: usersData } = await getUsers({
      variables: {
        where: {
          OR: [{ organization: { broker: { id } } }, ...organizations],
        },
      },
    });

    await Promise.all(
      usersData?.users?.data?.map(async ({ id }) => {
        await updateUser({
          variables: {
            where: { id },
            data: {
              phone: null,
              username: null,
              email: null,
              password: null,
            },
          },
        });
      })
    );

    await deleteBroker({
      variables: { where: { id } },
    });

    navigate(SCREENS.PRIVATE.ADMIN.BROKERS.LIST.path, { replace: true });
    setVisible(false);
  };
  if (loading) return <Loading />;

  return <View onSubmit={onSubmit} cancel={cancel} visible={visible} />;
};

export default Delete;
