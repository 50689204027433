import { faker } from "@faker-js/faker";
import { notification } from "antd";
import cuid from "cuid";
import { defaults, map } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import {
  INITIAL_VALUES,
  ORGANIZATION_TYPES,
  SCREENS,
  USER_TYPES,
} from "utils/constants";
import View from "./View";

const Create = () => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.BROKER);
  const FORM = t("FORM", { returnObjects: true });
  const { t: errors } = useTranslation(NAME_SPACES.ERRORS);
  const API_ERRORS = errors("API", { returnObjects: true });
  const navigate = useNavigate();
  const [userId, orgId] = useMemo(() => [cuid(), cuid()], []);
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.BROKERS.LIST.path);
  const [signUpAuth] = useMutation(graphql.mutations.SIGN_UP_AUTH, {
    onError: () => notification.error({ message: FORM.IN_USE }),
  });
  const [updateUser] = useMutation(graphql.mutations.UPDATE_USER_AUTH);

  const [signUp] = useMutation(graphql.mutations.SIGN_UP);
  const [addBroker] = useMutation(graphql.mutations.CREATE_BROKER, {
    refetchQueries: [
      {
        query: graphql.queries.BROKERS,
        awaitRefetchQueries: true,
        variables: {
          where: { organization: { type: ORGANIZATION_TYPES.BROKER } },
          ...INITIAL_VALUES,
        },
      },
    ],
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.ADMIN.BROKERS.LIST.path);
    },
  });

  const onError = (id) => {
    updateUser({
      variables: {
        where: { id },
        data: {
          phone: null,
          username: null,
          email: null,
          password: null,
        },
      },
    });
    notification.error({
      message: API_ERRORS.GENERIC,
    });
  };

  const onSubmit = ({
    telecoms,
    organization,
    iconAttachment,
    user,
    distributions,
    ...values
  }) => {
    const organizationTelecoms = map(telecoms, (item, key) =>
      defaults(item, { id: cuid(), system: key })
    );
    signUpAuth({
      variables: {
        data: {
          id: userId,
          username: user.email,
          email: user.email,
          phone: user.phone,
          password: faker.internet.password(),
          meta: { type: USER_TYPES.BROKER_ADMIN.type },
          organization: {
            id: orgId,
            name: organization.name,
          },
        },
      },
      onCompleted: () =>
        signUp({
          variables: {
            data: {
              id: userId,
              ...user,
              username: user.email,
              meta: { type: USER_TYPES.BROKER_ADMIN.type },
              organization: {
                id: orgId,
                ...organization,
                telecoms: organizationTelecoms,
                users: undefined,
                icon: iconAttachment,
              },
            },
          },
          onError: () => onError(userId),
          onCompleted: () =>
            addBroker({
              variables: {
                data: {
                  id: cuid(),
                  ...values,
                  organization: { id: orgId },
                  distributions: distributions?.map(
                    ({ commissions, ...rest }) => ({
                      ...rest,
                      commissions: commissions?.map((commission) => ({
                        id: commission,
                      })),
                    })
                  ),
                },
              },
              onError: () => onError(userId),
            }),
        }),
    });
  };

  return <View onSubmit={onSubmit} cancel={cancel} />;
};

export default Create;
