/* eslint-disable implicit-arrow-linebreak */
import { HttpLink } from "@apollo/client";
import { ApolloLink, Observable } from "apollo-link";
import { KEYS } from "utils/constants";
import { updateOperationHeader } from "./utils";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_REST_BFF_URL,
});

const TokenInterceptor = () =>
  new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        const accessToken = localStorage.getItem(KEYS.ACCESS_TOKEN);
        updateOperationHeader(operation, accessToken);
        forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
  );

const bffLink = ApolloLink.from([TokenInterceptor(), httpLink]);

export default bffLink;
