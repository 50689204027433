import { get, range } from "lodash";
import { useTranslation } from "react-i18next";
import AlternativeMedicine from "shared/assets/images/ayurveda.png";
import Dental from "shared/assets/images/dental-checkup.png";
import Equipment from "shared/assets/images/hearing-aid.png";
import Hospitalization from "shared/assets/images/hospital-bed.png";
import MedicalCare from "shared/assets/images/medical-team.png";
import Optical from "shared/assets/images/vision.png";
import Popover from "shared/components/Popover";
import Slider from "shared/components/Slider";
import { NAME_SPACES } from "shared/locales/constants";
import { COVERAGE_NEEDS } from "utils/constants";

const Recommendation = ({ needs, setFieldValue }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const COVERAGE_CATEGORIES_ENTRIES = [
    {
      icon: Hospitalization,
      label: DETAIL.TEMPLATES.FILTERS.COVERAGE_CLASSES.HOSPITALIZATION,
      key: COVERAGE_NEEDS.HOSPITALIZATION,
    },
    {
      icon: MedicalCare,
      label: DETAIL.TEMPLATES.FILTERS.COVERAGE_CLASSES.MEDICAL_CARE,
      key: COVERAGE_NEEDS.MEDICAL_CARE,
    },
    {
      icon: Optical,
      label: DETAIL.TEMPLATES.FILTERS.COVERAGE_CLASSES.OPTICAL,
      key: COVERAGE_NEEDS.OPTICAL,
    },
    {
      icon: Dental,
      label: DETAIL.TEMPLATES.FILTERS.COVERAGE_CLASSES.DENTAL,
      key: COVERAGE_NEEDS.DENTAL,
    },
    {
      icon: Equipment,
      label: DETAIL.TEMPLATES.FILTERS.COVERAGE_CLASSES.EQUIPMENT,
      key: COVERAGE_NEEDS.EQUIPMENT,
    },
    {
      icon: AlternativeMedicine,
      label: DETAIL.TEMPLATES.FILTERS.COVERAGE_CLASSES.ALTERNATIVE_MEDICINE,
      key: COVERAGE_NEEDS.ALTERNATIVE_MEDICINE,
    },
  ];
  const levels = range(0, 6);

  return (
    <div className="template-product-filters--container">
      <div className="template-product-filters--wrapper">
        <div className="levels--container">
          <div className="levels--wrapper">
            <span />
            {levels.map((element) => (
              <div key={element} className="label">
                <div className="popover-wrapper">
                  <Popover
                    text={DETAIL.TEMPLATES.FILTERS.HEADER.LEVELS[element]}
                  />
                  {`${DETAIL.TEMPLATES.FILTERS.HEADER.LEVEL} ${element}`}
                </div>
              </div>
            ))}
          </div>
          <div className="levels-coverage--container">
            {COVERAGE_CATEGORIES_ENTRIES.map(({ icon, label, key }) => (
              <div key={key} className="levels-coverage--wrapper">
                <div className="coverage-trailing--wrapper">
                  <img className="img" src={icon} alt={label} />
                  <span className="label">{label}</span>
                </div>
                <div className="custom-slider--wrapper">
                  <Slider
                    type="dot"
                    items={levels}
                    name={"grp" + label}
                    value={get(needs, key)}
                    onChange={(item) => setFieldValue(`needs.${key}`, item)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recommendation;
