import { Slider } from "antd";

const Component = ({ ...rest }) => {
  return (
    <div className="slider-line--wrapper">
      <Slider {...rest} />
    </div>
  );
};

export default Component;
