import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import { KEYS } from "utils/constants";

export const Context = React.createContext("store");
export const { Consumer } = Context;

export const Provider = ({ children }) => {
  const { i18n } = useTranslation(NAME_SPACES.HOME);
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem(KEYS.ACCESS_TOKEN)
  );
  const [user, setUser] = useState(JSON.parse(localStorage.getItem(KEYS.USER)));
  const [language, setLanguage] = useState(localStorage.getItem(KEYS.LANGUAGE));

  const clearStore = () => {
    localStorage.clear();
    setAccessToken("");
    setUser(undefined);
    setLanguage("");
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const handleSetAccessToken = (data) => {
    setAccessToken(data.accessToken);
    return localStorage.setItem(KEYS.ACCESS_TOKEN, data.accessToken);
  };

  const handleSetUser = (data) => {
    setUser(data.user);
    return localStorage.setItem(KEYS.USER, JSON.stringify(data.user));
  };

  const handleSetLanguage = (data) => {
    setLanguage(data.language);
    return localStorage.setItem(KEYS.LANGUAGE, data.language);
  };

  return (
    <Context.Provider
      value={{
        clearStore,
        setAccessToken: handleSetAccessToken,
        accessToken,
        setUser: handleSetUser,
        user,
        setLanguage: handleSetLanguage,
        language,
      }}
    >
      {children}
    </Context.Provider>
  );
};
