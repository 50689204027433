import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { SCREENS } from "utils/constants";
import View from "./View";

const Detail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const cancel = () => navigate(SCREENS.PRIVATE.BROKER.CONTRACTS.LIST.path);
  const [updateContract] = useMutation(graphql.mutations.UPDATE_CONTRACT, {
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.BROKER.CONTRACTS.LIST.path);
    },
    onError: () => {},
  });
  const { data, loading, error } = useQuery(graphql.queries.CONTRACT, {
    variables: { where: { id } },
  });
  const onSubmit = (data) => {
    return updateContract({
      variables: {
        where: {
          id,
        },
        data,
      },
    });
  };

  if (loading || error) return "Loading";

  return <View onSubmit={onSubmit} contract={data.contract} cancel={cancel} />;
};

export default Detail;
