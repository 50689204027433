import { Drawer } from "antd";
import cuid from "cuid";
import { FieldArray } from "formik";
import { get, isNil } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOpenInNew } from "react-icons/md";
import { RiAddFill, RiDeleteBinFill } from "react-icons/ri";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import {
  COVERAGE_LIMIT_CATEGORIES,
  SUPPORTING_INFORMATION_CATEGORIES,
} from "utils/constants";
import { bindArrayInputProps } from "utils/helpers/input";

const CoverageBenefitsList = ({ values, formProps }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.COVERAGE_BENEFIT);
  const FORM = t("LIST", { returnObjects: true });
  const [coverageBenefit, setCoverageBenefit] = useState();

  return (
    <>
      <Card title={FORM.GENERAL_INFO}>
        <FieldArray
          name={"coverageBenefits"}
          render={(arrayHelpers) => (
            <div className="coverage-benefits-wrapper">
              {values?.map((_, i) => (
                <div key={_.id} className="coverage__input">
                  <div>
                    <div className="coverage__input--header">
                      <h3>{`${FORM.COVERAGE_BENEFIT} ${i + 1}`}</h3>
                      <div className="d-flex">
                        <div onClick={() => setCoverageBenefit(i)}>
                          <MdOpenInNew size={24} color={COLORS.C_PRIMARY} />
                        </div>
                        <div onClick={() => arrayHelpers.remove(i)}>
                          <RiDeleteBinFill size={24} color={COLORS.C_DANGER} />
                        </div>
                      </div>
                    </div>
                    <div className="coverage__input--info">
                      <Input
                        type="number"
                        label={FORM.ORDER}
                        placeholder={FORM.ORDER}
                        min={1}
                        {...bindArrayInputProps({
                          parent: "coverageBenefits",
                          index: i,
                          name: "order",
                          values,
                          ...formProps,
                        })}
                      />
                      <Input
                        label={FORM.CATEGORY}
                        placeholder={FORM.CATEGORY}
                        {...bindArrayInputProps({
                          parent: "coverageBenefits",
                          index: i,
                          name: "type",
                          values,
                          ...formProps,
                        })}
                      />
                      <Input
                        label={FORM.SUBTYPE}
                        placeholder={FORM.SUBTYPE}
                        {...bindArrayInputProps({
                          parent: "coverageBenefits",
                          index: i,
                          name: "subtype",
                          values,
                          ...formProps,
                        })}
                      />
                      <Input
                        type="textarea"
                        label={FORM.INFO}
                        placeholder={FORM.INFO}
                        rows={2}
                        {...bindArrayInputProps({
                          parent: "coverageBenefits",
                          index: i,
                          name: "additionalInfo",
                          values,
                          ...formProps,
                        })}
                      />
                    </div>
                  </div>
                  <div>
                    <h3>{FORM.LIMITS}</h3>
                    <FieldArray
                      name={`coverageBenefits.${i}.limits`}
                      render={(arrayHelpers) => (
                        <div className="coverage__input--row">
                          <div>
                            {get(values, `${i}.limits`, []).map((_, index) => (
                              <div
                                key={_.id}
                                className="coverage__input--limits"
                              >
                                <Input
                                  label={FORM.LIMIT_VALUE}
                                  placeholder={FORM.LIMIT_VALUE}
                                  {...bindArrayInputProps({
                                    parent: `coverageBenefits.${i}.limits`,
                                    index: index,
                                    name: "value.value",
                                    values,
                                    ...formProps,
                                  })}
                                />
                                <Select
                                  options={Object.entries(
                                    COVERAGE_LIMIT_CATEGORIES
                                  ).map(([key, value]) => ({
                                    label: FORM.COVERAGE_LIMITS.CATEGORIES[key],
                                    value,
                                  }))}
                                  label={FORM.LIMIT_CATEGORY}
                                  placeholder={FORM.LIMIT_CATEGORY}
                                  {...bindArrayInputProps({
                                    parent: `coverageBenefits.${i}.limits`,
                                    index: index,
                                    name: "value.category",
                                    values,
                                    ...formProps,
                                  })}
                                />
                                <div
                                  onClick={() => {
                                    arrayHelpers.remove(index);
                                  }}
                                >
                                  <RiDeleteBinFill
                                    size={24}
                                    color={COLORS.C_DANGER}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                          <div
                            onClick={() => {
                              arrayHelpers.push({
                                id: cuid(),
                              });
                            }}
                          >
                            <RiAddFill color={COLORS.C_PRIMARY} size={24} />
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>
              ))}
              <Button
                key="add-row"
                onClick={() => {
                  arrayHelpers.push({ id: cuid(), limits: [{ id: cuid() }] });
                }}
                type={"primary"}
              >
                {FORM.ADD_BENEFIT}
                <RiAddFill />
              </Button>
            </div>
          )}
        />
      </Card>
      <Drawer
        title={FORM.SUPPORTING_INFORMATION.TITLE}
        width={"50%"}
        open={!isNil(coverageBenefit)}
        className="drawer__custom"
        onClose={() => setCoverageBenefit(null)}
      >
        <div className="grid--1">
          <FieldArray
            name={`coverageBenefits.${coverageBenefit}.supportingInformation`}
            render={(arrayHelpers) => [
              get(values, `${coverageBenefit}.supportingInformation`, []).map(
                (_, index) => (
                  <div key={_.id} className="d-flex">
                    <div className="grid--2 flex--grow-1">
                      <Select
                        options={Object.entries(
                          SUPPORTING_INFORMATION_CATEGORIES
                        ).map(([key, value]) => ({
                          label: FORM.SUPPORTING_INFORMATION.CATEGORIES[key],
                          value,
                        }))}
                        placeholder={FORM.SUPPORTING_INFORMATION.CATEGORY}
                        label={FORM.SUPPORTING_INFORMATION.CATEGORY}
                        {...bindArrayInputProps({
                          parent: `coverageBenefits.${coverageBenefit}.supportingInformation`,
                          index: index,
                          name: "category",
                          values,
                          ...formProps,
                        })}
                      />

                      <Input
                        placeholder={FORM.SUPPORTING_INFORMATION.VALUE}
                        label={FORM.SUPPORTING_INFORMATION.VALUE}
                        {...bindArrayInputProps({
                          parent: `coverageBenefits.${coverageBenefit}.supportingInformation`,
                          index: index,
                          name: "value",
                          values,
                          ...formProps,
                        })}
                      />
                    </div>
                    <div
                      className={"delete-icon"}
                      onClick={() => {
                        arrayHelpers.remove(index);
                      }}
                    >
                      <RiDeleteBinFill color={COLORS.C_DANGER} size={24} />
                    </div>
                  </div>
                )
              ),
              <Button
                key="add-row"
                onClick={() => {
                  arrayHelpers.push({ id: cuid() });
                }}
                type="primary"
              >
                <RiAddFill />
              </Button>,
            ]}
          />
        </div>
      </Drawer>
    </>
  );
};

export default CoverageBenefitsList;
