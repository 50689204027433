import { gql } from "@apollo/client";

const ORGANIZATION_ADDED = gql`
  subscription organizationAdded($where: OrganizationWhereInput) {
    organizationAdded(where: $where) {
      id
      name
      type
      insurancePlans {
        id
      }
    }
  }
`;

const ORGANIZATION_UPDATED = gql`
  subscription organizationUpdated($where: OrganizationWhereInput) {
    organizationUpdated(where: $where) {
      id
      name
      type
      insurancePlans {
        id
      }
    }
  }
`;

const ORGANIZATION_DELETED = gql`
  subscription organizationDeleted($where: OrganizationWhereInput) {
    organizationDeleted(where: $where) {
      id
      name
      type
      insurancePlans {
        id
      }
    }
  }
`;

const organizations = {
  ORGANIZATION_ADDED,
  ORGANIZATION_UPDATED,
  ORGANIZATION_DELETED,
};

export default organizations;
