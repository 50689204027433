import PropTypes from "prop-types";
import Async from "./Async";
import Sync from "./Sync";

const TYPES = {
  sync: Sync,
  async: Async,
};
const Select = ({ touched, ...props }) => {
  const { type } = props;
  const Widget = TYPES[type];
  return <Widget {...props} />;
};

Select.propTypes = {
  type: PropTypes.string.isRequired,
};

Select.defaultProps = {
  type: "sync",
};

export default Select;
