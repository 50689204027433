import gql from "graphql-tag";

export const DOWNLOAD_DOCUMENT = gql`
  mutation Download($data: DownloadWhereInput) {
    download(data: $data) {
      base64
    }
  }
`;

export const DOWNLOAD_SINGLE_DOCUMENT = gql`
  mutation DownloadSingleDocument($where: AttachmentWhereInput) {
    downloadSingleDocument(where: $where) {
      document
    }
  }
`;
const downloadDocuments = {
  DOWNLOAD_DOCUMENT,
  DOWNLOAD_SINGLE_DOCUMENT,
};

export default downloadDocuments;
