import gql from "graphql-tag";

export const CREATE_ORGANIZATION = gql`
  mutation AddOrganization($data: OrganizationDataInput) {
    addOrganization(data: $data)
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization(
    $data: OrganizationDataInput
    $where: OrganizationWhereInput
  ) {
    updateOrganization(where: $where, data: $data)
  }
`;

export const DELETE_ORGANIZATION = gql`
  mutation DeleteOrganization($where: OrganizationWhereInput) {
    deleteOrganization(where: $where) {
      id
    }
  }
`;

const organizations = {
  CREATE_ORGANIZATION,
  UPDATE_ORGANIZATION,
  DELETE_ORGANIZATION,
};

export default organizations;
