import attachments from "./attachment";
import broker from "./broker";
import contacts from "./contacts";
import contracts from "./contracts";
import costs from "./costs";
import coverageBenefits from "./coverage-benefits";
import coverages from "./coverages";
import flows from "./flows";
import insurancePlans from "./insurance-plans";
import notes from "./notes";
import organizations from "./organizations";
import processes from "./processes";
import projects from "./projects";
import qualifications from "./qualifications";
import qualifiers from "./qualifier";
import reminders from "./reminders";
import sources from "./sources";
import subs from "./subscriptions";
import tracers from "./tracers";
import users from "./users";

const subscriptions = {
  ...contacts,
  ...sources,
  ...tracers,
  ...contracts,
  ...flows,
  ...organizations,
  ...processes,
  ...projects,
  ...insurancePlans,
  ...users,
  ...coverages,
  ...costs,
  ...qualifications,
  ...notes,
  ...qualifiers,
  ...reminders,
  ...coverageBenefits,
  ...attachments,
  ...subs,
  ...broker,
};

export default subscriptions;
