import cuid from "cuid";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { CONTRACT_TYPES, SCREENS } from "utils/constants";
import View from "./View";

const Contract = ({ project, onNext }) => {
  const navigate = useNavigate();
  const id = useMemo(() => cuid(), []);
  const cancel = () => navigate(SCREENS.PRIVATE.BROKER.PROJECTS.LIST.path);
  const [addContract] = useMutation(graphql.mutations.CREATE_CONTRACT, {
    onCompleted: (_) => {
      onNext({ payload: { contract: { id } } });
    },
    onError: (_) => {},
  });

  const onSubmit = () => {
    addContract({
      variables: {
        data: {
          id,
          identifier: cuid(),
          author: {},
          type: CONTRACT_TYPES.HEALTHINSURANCE,
        },
      },
    });
  };

  return <View onSubmit={onSubmit} project={project} cancel={cancel} />;
};

export default Contract;
