import { useTranslation } from "react-i18next";
import { BiChevronLeft, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router";
import Header from "shared/components/Header";
import { NAME_SPACES } from "shared/locales/constants";
import { SCREENS } from "utils/constants";
import Coverages from "./Blocks/Coverages";
import InsurancePlan from "./Blocks/InsurancePlan";

const Detail = ({ cancel, data, addCoverage }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.INSURANCE_PLAN);
  const DETAIL = t("DETAIL", { returnObjects: true });

  const BREADCRUMB = [
    {
      title: DETAIL.HEADER.BREADCRUMB.LIST,
      path: SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.LIST.path,
    },
    {
      title: DETAIL.HEADER.BREADCRUMB.DETAIL,
    },
  ];

  return (
    <>
      <Header
        key="header"
        title={DETAIL.HEADER.TITLE}
        actions={[
          {
            text: DETAIL.HEADER.DISCARD,
            onClick: cancel,
            type: "primary--outlined",
            icon: <BiChevronLeft size={20} />,
          },
          {
            text: DETAIL.HEADER.DELETE,
            onClick: () =>
              navigate(
                `${SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.DELETE.path}/${data.id}`
              ),
            type: "danger--outlined",
            icon: <BiTrash size={20} />,
          },
        ]}
        breadcrumb={BREADCRUMB}
      />
      <InsurancePlan data={data} />
      <div className="m-bottom-24" />
      <Coverages addCoverage={addCoverage} />
    </>
  );
};

export default Detail;
