import { useNavigate } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import { CREATE_COMMISSIONS } from "utils/api/graphql/mutations/commissions";
import { COMMISSIONS } from "utils/api/graphql/queries/commissions";
import { INITIAL_VALUES, SCREENS } from "utils/constants";
import View from "./View";

const Create = () => {
  const navigate = useNavigate();
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.COMMISSIONS.LIST.path);
  const [createCommissions] = useMutation(CREATE_COMMISSIONS, {
    refetchQueries: [
      {
        query: COMMISSIONS,
        awaitRefetchQueries: true,
        variables: INITIAL_VALUES,
      },
    ],
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.ADMIN.COMMISSIONS.LIST.path);
    },
  });

  const onSubmit = ({ commissions }) => {
    return createCommissions({
      variables: {
        data: commissions,
      },
    });
  };

  return <View onSubmit={onSubmit} cancel={cancel} />;
};

export default Create;
