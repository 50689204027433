import { Col, Row, Table } from "antd";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import Icon from "react-material-symbols/rounded";
import { useNavigate } from "react-router-dom";
import Pagination from "shared/components/Pagination";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import { SCREENS } from "utils/constants";

function List({ data, count, setValues, skip }) {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.TRACER);
  const LIST = t("LIST", { returnObjects: true });
  const columns = [
    {
      title: LIST.NAME,
      dataIndex: "name",
    },
    {
      title: LIST.DESTINATION,
      dataIndex: "destination",
    },
    {
      title: LIST.SOURCE,
      dataIndex: "source",
      render: (source) => get(source, "name", "-"),
    },
    {
      title: LIST.ACTIONS,
      dataIndex: "id",
      align: "right",
      render: (id) => (
        <Row gutter={8} justify="end" align="center" wrap={false}>
          <Col
            onClick={() =>
              navigate(`${SCREENS.PRIVATE.ADMIN.TRACERS.DETAIL.path}/${id}`)
            }
          >
            <Icon icon="open_in_new" size={24} color={COLORS.C_PRIMARY} />
          </Col>
          <Col
            onClick={() =>
              navigate(`${SCREENS.PRIVATE.ADMIN.TRACERS.DELETE.path}/${id}`)
            }
          >
            <Icon icon="delete_outline" size={24} color={COLORS.C_DANGER} />
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <>
      <>
        <Table
          dataSource={data}
          className="table__wrapper"
          columns={columns}
          rowKey={"id"}
          pagination={false}
          scroll={{ x: true }}
        />
        <Pagination count={count} setValues={setValues} skip={skip} />
      </>
    </>
  );
}

export default List;
