import { SCREENS } from "utils/constants";
import List from "./List";

const routes = [
  {
    path: SCREENS.PRIVATE.ADMIN.UI.LIST.path,
    exact: true,
    element: <List />,
  },
];

export default routes;
