import { get, isEmpty, isNumber } from "lodash";
import moment from "moment";
import omitDeep from "omit-deep-lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import {
  CREATE_ATTACHMENT,
  DELETE_ATTACHMENT,
} from "utils/api/graphql/mutations/attachment";
import {
  DELTA_END_TYPES,
  INITIAL_VALUES,
  PERIOD_UNITS,
  SCREENS,
} from "utils/constants";
import View from "./View";

const DELTA_END_VALUES = {
  [DELTA_END_TYPES.END_OF_YEAR]: () => 0,
  [DELTA_END_TYPES.ADD_PERIOD]: (start, end, unit = PERIOD_UNITS.DAYS) => {
    if (!isNumber(end) || !isNumber(start)) return null;
    return moment()
      .add(start || 0, "days")
      .add(end, unit)
      .diff(moment().startOf("day"), "days");
  },
  DEFAULT: () => null,
};

const Detail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [addAttachment] = useMutation(CREATE_ATTACHMENT);
  const [deleteAttachment] = useMutation(DELETE_ATTACHMENT);

  const cancel = () =>
    navigate(SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.LIST.path);
  const [updateInsurancePlan] = useMutation(
    graphql.mutations.UPDATE_INSURANCE_PLAN,
    {
      refetchQueries: [
        {
          query: graphql.queries.INSURANCE_PLANS,
          awaitRefetchQueries: true,
          variables: INITIAL_VALUES,
        },
        {
          query: graphql.queries.INSURANCE_PLANS,
          awaitRefetchQueries: true,
        },
        {
          query: graphql.queries.INSURANCE_PLAN,
          awaitRefetchQueries: true,
          variables: { where: { id } },
        },
      ],
      onCompleted: () => {
        navigate(SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.LIST.path);
      },
      onError: () => {},
    }
  );
  const { data, loading, error } = useQuery(graphql.queries.INSURANCE_PLAN, {
    variables: { where: { id } },
  });
  const onSubmit = (values) => {
    const {
      date,
      deltaEndType,
      deltaEndUnit,
      params,
      attachments,
      newAttachments,
      commissions,
      icon,
      iconAttachment,
      ...rest
    } = omitDeep(values, "__typename");
    return updateInsurancePlan({
      variables: {
        where: {
          id,
        },
        data: {
          startDate: get(date, "0") || null,
          endDate: get(date, "1") || null,
          icon: iconAttachment,
          params: isEmpty(params)
            ? []
            : params.map(
                ({
                  deltaStart,
                  deltaStartRIA,
                  deltaEnd,
                  deltaEndRIA,
                  exerciseFrame,
                  minAge,
                  maxAge,
                  socialRegimes,
                  ...values
                }) => ({
                  ...values,
                  deltaStart: deltaStart || 0,
                  deltaStartRIA: deltaStartRIA || 30,
                  deltaEnd: DELTA_END_VALUES[deltaEnd?.type || "DEFAULT"](
                    deltaStart,
                    deltaEnd?.value,
                    deltaEnd?.unit
                  ),
                  deltaEndRIA: DELTA_END_VALUES[deltaEndRIA?.type || "DEFAULT"](
                    deltaStartRIA,
                    deltaEndRIA?.value,
                    deltaEndRIA?.unit
                  ),
                  socialRegimes: isEmpty(socialRegimes) ? null : socialRegimes,
                  exerciseFrame: exerciseFrame || null,
                  minAge: minAge || null,
                  maxAge: maxAge || null,
                })
              ),

          commissions: commissions.map((commission) => ({ id: commission })),
          ...rest,
        },
      },
    });
  };

  if (loading || error) return "Loading";

  return (
    <View
      onSubmit={onSubmit}
      data={data.insurancePlan}
      cancel={cancel}
      addAttachment={addAttachment}
      deleteAttachment={deleteAttachment}
    />
  );
};

export default Detail;
