import { useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import View from "./View";

const Filters = ({ project, onNext }) => {
  const { data, loading, error } = useQuery(graphql.queries.INSURANCE_PLANS);

  const next = (offer) => {
    onNext({
      payload: {
        fields: {
          ...project.fields,
          offer,
        },
      },
    });
  };

  if (loading || error) return "Loading or Error";

  const {
    insurancePlans: { data: insurancePlans },
  } = data;

  return <View next={next} insurancePlans={insurancePlans} />;
};

export default Filters;
