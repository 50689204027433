import Card from "antd/es/card/Card";
import CommissionStatements from "entities/CommissionStatements";
import { range } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "shared/components/Header";
import Select from "shared/components/Select";
import { useMutation } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { DOWNLOAD_SINGLE_DOCUMENT } from "utils/api/graphql/mutations/download-document";
import { MONTH_OPTIONS } from "utils/constants";
import View from "./View";

const List = () => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.COMMISSION_STATEMENT);
  const [downloadSingleDocument] = useMutation(DOWNLOAD_SINGLE_DOCUMENT);
  const yearsList = range(2023, moment().year() + 3);
  const LIST = t("LIST", { returnObjects: true });
  const MONTHS = t("MONTHS", { returnObjects: true });
  const FILTER = t("FILTER", { returnObjects: true });
  const [pagination, setPagination] = useState({});
  const [where, setWhere] = useState({});
  const filter = {
    skip: 0,
    take: 12,
    orderBy: [{ year: "DESC", month: "DESC" }],
  };

  return (
    <div>
      <Header title={LIST.HEADER.TITLE} />

      <Card type="simple">
        <div className="d-flex m-bottom-24">
          <span>{FILTER.FILTER_BY}</span>
          <div className="grid--4 flex--grow-1">
            <Select
              options={yearsList.map((year) => ({
                value: `${year}`,
                label: year,
              }))}
              allowClear
              placeholder={FILTER.YEAR}
              value={where.year}
              onChange={(year) => {
                setWhere((prev) => ({
                  ...prev,
                  year,
                }));
              }}
            />
            <Select
              placeholder={FILTER.MONTH}
              value={where.month}
              allowClear
              options={MONTH_OPTIONS.map((value) => ({
                value,
                label: MONTHS[value],
              }))}
              onChange={(month) =>
                setWhere((prev) => ({
                  ...prev,
                  month,
                }))
              }
            />
          </div>
        </div>
        <CommissionStatements
          View={View}
          filter={{ ...filter, ...pagination, where }}
          setWhere={setWhere}
          setValues={setPagination}
          skip={pagination.skip || 0}
          NoData={View}
          downloadSingleDocument={downloadSingleDocument}
        />
      </Card>
    </div>
  );
};

export default List;
