import WithRoutePermission from "shared/components/HOC/WithRoutePermission";
import { MODULES, SCREENS, SUBMODULES } from "utils/constants";
import Create from "./Create";
import List from "./List";
import Show from "./Show";

const routes = [
  {
    path: SCREENS.PRIVATE.ADMIN.QUALIFICATIONS.LIST.path,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.QUALIFICATION]: SUBMODULES.ADMIN_LIST }}
        WrappedComponent={List}
      />
    ),
  },
  {
    path: SCREENS.PRIVATE.ADMIN.QUALIFICATIONS.CREATE.path,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.QUALIFICATION]: SUBMODULES.ADMIN_CREATE }}
        WrappedComponent={Create}
      />
    ),
  },
  {
    path: `${SCREENS.PRIVATE.ADMIN.QUALIFICATIONS.SHOW.path}/:id`,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.QUALIFICATION]: SUBMODULES.ADMIN_SHOW }}
        WrappedComponent={Show}
      />
    ),
  },
];

export default routes;
