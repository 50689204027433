import { Formik } from "formik";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft, BiDuplicate, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { COVERAGE_CATEGORIES, SCREENS } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import CoverageBenefitsList from "./CoverageBenefits";
import validation from "./validation";

const Detail = ({ actions, data, cancel }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.COVERAGE);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  return (
    <>
      <Formik
        initialValues={{
          ...data,
          coverageBenefits: [...get(data, "coverageBenefits")].sort(
            (a, b) => a.order - b.order
          ),
        }}
        onSubmit={actions.create}
        validationSchema={validation(
          t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
        )}
      >
        {({ isValid, handleSubmit, values, ...formProps }) => [
          <Header
            key={"header"}
            title={DETAIL.HEADER.TITLE}
            subtitle={DETAIL.HEADER.ACTION}
            actions={[
              {
                text: DETAIL.HEADER.DISCARD,
                onClick: cancel,
                type: "gray--outlined",
                icon: <BiChevronLeft size={20} />,
              },
              {
                text: DETAIL.HEADER.DELETE,
                onClick: () =>
                  navigate(
                    `${SCREENS.PRIVATE.ADMIN.COVERAGES.DELETE.path}/${data.id}`,
                    { replace: true }
                  ),
                type: "danger--outlined",
                icon: <BiTrash size={20} />,
              },
              {
                text: DETAIL.HEADER.DUPLICATE,
                onClick: actions.duplicate,
                type: "primary--outlined",
                icon: <BiDuplicate size={20} />,
              },
              {
                text: DETAIL.HEADER.SAVE,
                onClick: handleSubmit,
                type: "primary",
                icon: <BiCheck size={20} />,
              },
            ]}
          />,
          <div key="coverage" className="coverage-container">
            <Card title={FORM.GENERAL_INFO}>
              <div className="grid--3">
                <Input
                  placeholder={FORM.NAME}
                  label={FORM.NAME}
                  {...bindInputProps({
                    name: "identifier",
                    values,
                    ...formProps,
                  })}
                />
                <Select
                  options={Object.entries(COVERAGE_CATEGORIES).map(
                    ([key, value]) => ({
                      label: FORM.CATEGORIES[key],
                      value,
                    })
                  )}
                  placeholder={FORM.CATEGORY}
                  label={FORM.CATEGORY}
                  {...bindInputProps({
                    name: "type",
                    values,
                    ...formProps,
                  })}
                />
                <Select
                  type="async"
                  node={{
                    query: graphql.queries.INSURANCE_PLANS,
                    variables: { where: {} },
                    accessors: { root: "insurancePlans.data" },
                  }}
                  placeholder={FORM.INSURANCE_PLAN}
                  label={FORM.INSURANCE_PLAN}
                  {...bindInputProps({
                    name: "insurancePlan.id",
                    values,
                    ...formProps,
                  })}
                />
              </div>
            </Card>
            <Card title={FORM.PARAMETERS}>
              <div className="grid--3">
                <Input
                  min="0"
                  type="number"
                  placeholder={FORM.WAITING_DAYS}
                  label={FORM.WAITING_DAYS}
                  {...bindInputProps({
                    name: "waitingDays",
                    values,
                    ...formProps,
                  })}
                />
              </div>
            </Card>
            <CoverageBenefitsList
              key={"coverageBenefits"}
              coverageId={data.id}
              values={get(values, "coverageBenefits")}
              formProps={{ values, ...formProps }}
            />
          </div>,
        ]}
      </Formik>
    </>
  );
};

export default Detail;
