import { gql } from "@apollo/client";

const REMINDER_ADDED = gql`
  subscription reminderAdded($where: ReminderWhereInput) {
    reminderAdded(where: $where) {
      id
      heading
      content
      contact {
        user {
          firstname
          lastname
        }
        telecoms {
          value
          system
        }
      }
      qualifier {
        id
        name
      }
      scheduledDate
    }
  }
`;

const REMINDER_DELETED = gql`
  subscription reminderDeleted($where: ReminderWhereInput) {
    reminderDeleted(where: $where) {
      id
      heading
      content
      contact {
        user {
          firstname
          lastname
        }
        telecoms {
          value
          system
        }
      }
      qualifier {
        id
        name
      }
      scheduledDate
    }
  }
`;

const reminders = { REMINDER_ADDED, REMINDER_DELETED };

export default reminders;
