import gql from "graphql-tag";

export const CREATE_FLOW = gql`
  mutation AddFlow($data: FlowDataInput) {
    addFlow(data: $data) {
      id
    }
  }
`;

export const UPDATE_FLOW = gql`
  mutation UpdateFlow($data: FlowDataInput, $where: FlowWhereInput) {
    updateFlow(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_FLOW = gql`
  mutation DeleteFlow($where: FlowWhereInput) {
    deleteFlow(where: $where) {
      id
    }
  }
`;

const flows = { CREATE_FLOW, UPDATE_FLOW, DELETE_FLOW };

export default flows;
