import { Col, Row } from "antd";
import GeneralCostsEntity from "entities/GeneralCosts";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import Icon from "react-material-symbols/rounded";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import { NAME_SPACES } from "shared/locales/constants";
import Coverages from "./Widgets/Coverages";
import GeneralCosts from "./Widgets/GeneralCosts";

const View = ({ project, onNext }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const generalCostsFilter = {
    where: {
      project: { id: project.id },
    },
    isIn: {
      insurancePlan: get(project, "fields.selectedToCompare"),
    },
  };

  return (
    <div className="comparison-container">
      <GeneralCostsEntity
        filter={generalCostsFilter}
        View={({ data }) => (
          <>
            <Card
              title={DETAIL.TEMPLATES.COMPARISON.TITLE}
              subtitle={DETAIL.TEMPLATES.COMPARISON.SUBTITLE}
            >
              <GeneralCosts generalCosts={data} translation={DETAIL} />
              <Coverages
                insurancePlans={data?.insurancePlans}
                project={project}
                translation={DETAIL}
              />
            </Card>
            <Row>
              <Col lg={12} xs={24}>
                <Button
                  onClick={() => {
                    onNext({
                      payload: {
                        fields: {},
                        generalCosts: [],
                      },
                    });
                  }}
                  type="secondary--link"
                >
                  <Icon icon="arrow_back_ios" size={16} />
                  {DETAIL.TEMPLATES.COMPARISON.PREVIOUS}
                </Button>
              </Col>
              <Col align="right" lg={12} xs={24}>
                <Button
                  onClick={() => {
                    onNext({
                      payload: {
                        fields: {
                          ...project.fields,
                          insurancePlans: data?.insurancePlans.map(
                            (insurancePlan) => insurancePlan.id
                          ),
                        },
                      },
                    });
                  }}
                  type="primary"
                >
                  {DETAIL.TEMPLATES.COMPARISON.NEXT}
                  <Icon icon="arrow_forward_ios" size={16} />
                </Button>
              </Col>
            </Row>
          </>
        )}
      />
    </div>
  );
};

export default View;
