import React from "react";
import { Spin } from 'antd';


const Loading = () => (
  <div className="loader__wrapper">
    <Spin />
  </div>
   
  
);

Loading.propTypes = {};

Loading.defaultProps = {};

export default Loading;
