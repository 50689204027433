import WithRoutePermission from "shared/components/HOC/WithRoutePermission";
import { MODULES, SCREENS, SUBMODULES } from "utils/constants";
import Fast from "./Fast";
import Normal from "./Normal";

const routes = [
  {
    path: SCREENS.PRIVATE.BROKER.PROJECTS.CREATE.NORMAL.path,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.PROJECT]: SUBMODULES.BROKER_CREATE }}
        WrappedComponent={Normal}
      />
    ),
  },
  {
    path: SCREENS.PRIVATE.BROKER.PROJECTS.CREATE.FAST.path,
    exact: true,
    element: (
      <WithRoutePermission
        submodules={{ [MODULES.PROJECT]: SUBMODULES.BROKER_CREATE }}
        WrappedComponent={Fast}
      />
    ),
  },
];

export default routes;
