import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { INITIAL_VALUES, SCREENS } from "utils/constants";
import View from "./View";

const Detail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.FLOWS.LIST.path);
  const [updateFlow] = useMutation(graphql.mutations.UPDATE_FLOW, {
    refetchQueries: [
      {
        query: graphql.queries.FLOWS,
        awaitRefetchQueries: true,
        variables: INITIAL_VALUES,
      },
    ],
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.ADMIN.FLOWS.LIST.path);
    },
    onError: () => {},
  });
  const { data, loading, error } = useQuery(graphql.queries.FLOW, {
    variables: { where: { id } },
  });
  const onSubmit = (_) => {
    const { name, tracers, process } = _;
    return updateFlow({
      variables: {
        where: {
          id,
        },
        data: {
          name,
          tracers: tracers.map((tracer) => ({ id: tracer })),
          process,
        },
      },
    });
  };

  if (loading || error) return "Loading";

  return <View onSubmit={onSubmit} data={data.flow} cancel={cancel} />;
};

export default Detail;
