import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { INITIAL_VALUES, SCREENS } from "utils/constants";
import View from "./View";

const Delete = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [visible, setVisible] = useState(true);
  const cancel = () => navigate(SCREENS.PRIVATE.BROKER.PROJECTS.LIST.path);
  const [deleteProject] = useMutation(graphql.mutations.DELETE_PROJECT, {
    refetchQueries: [
      {
        query: graphql.queries.PROJECTS,
        awaitRefetchQueries: true,
        variables: INITIAL_VALUES,
      },
    ],
    onCompleted: () => {
      setVisible(false);
      navigate(SCREENS.PRIVATE.BROKER.PROJECTS.LIST.path);
    },
    onError: () => {},
  });
  const onSubmit = () => {
    return deleteProject({
      variables: {
        where: {
          id,
        },
      },
    });
  };
  return <View onSubmit={onSubmit} cancel={cancel} visible={visible} />;
};

export default Delete;
