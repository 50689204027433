import { Select } from "antd";
import classnames from "classnames";
import { get } from "lodash";
import Label from "shared/components/Label";
import { useQuery } from "shared/hooks/useApi";

const Async = ({
  label: selectLabel,
  node,
  errors,
  icon,
  onChange = () => {},
  onSearch = () => {},
  ...rest
}) => {
  const {
    query,
    variables,
    accessors: { root, label = "name", value = "id" },
  } = node;
  const { data, loading } = useQuery(query, { variables });
  const items = get(data, root, []).map((item) => ({
    label: get(item, label),
    value: get(item, value),
  }));
  return (
    <div className={classnames("select__wrapper", { __error: errors })}>
      <Label label={selectLabel} icon={icon} />
      <div className={`select__wrapper--field`}>
        <Select
          size="large"
          className={classnames({ "disable--input": rest.disabled })}
          loading={loading}
          onChange={(value) => {
            return onChange(
              value,
              get(data, root, []).find((item) => item.id === value)
            );
          }}
          showSearch
          onSearch={onSearch}
          options={items}
          {...rest}
        />
        {errors && <p className={"input--errors"}>{errors}</p>}
      </div>
    </div>
  );
};

export default Async;
