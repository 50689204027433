import { get, isEmpty } from "lodash";
import moment from "moment";
import omitDeep from "omit-deep-lodash";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import {
  BUILD_GENERAL_COST,
  BUILD_GENERAL_COSTS,
} from "utils/api/graphql/mutations/costs";
import { UPDATE_PROJECT } from "utils/api/graphql/mutations/projects";
import { GENERAL_COSTS } from "utils/api/graphql/queries/general-cost";
import { INITIAL_VALUES } from "utils/constants";
import View from "./View";

const List = ({ project, onNext, onBack }) => {
  const { id } = useParams();
  const take = 5;
  const [skip, setSkip] = useState(INITIAL_VALUES.skip);
  const insurancePlanFilter = useMemo(() => {
    const issuanceDate = moment(get(project, "contract.issuanceDate")).startOf(
      "day"
    );
    const issuanceDateDifference = issuanceDate.diff(
      moment().startOf("day"),
      "days"
    );

    const adherentAge = moment(get(project, "contact.user.birthDate")).startOf(
      "year"
    );

    const age = moment().diff(adherentAge, "years");

    const [startKey, endKey] = project.ria
      ? ["deltaStartRIA", "deltaEndRIA"]
      : ["deltaStart", "deltaEnd"];

    return {
      // take,
      // skip,
      custom: {
        AND: [
          {
            OR: [
              { startDate: null },
              {
                startDate: issuanceDate,
                operation: "<=",
              },
            ],
          },
          {
            OR: [
              { endDate: null },
              {
                endDate: issuanceDate,
                operation: ">=",
              },
            ],
          },
          {
            OR: [
              { params: { [startKey]: null } },
              {
                params: { [startKey]: issuanceDateDifference },
                operation: "<=",
              },
            ],
          },
          {
            OR: [
              { params: { [endKey]: null } },
              {
                params: {
                  [endKey]: issuanceDateDifference,
                },
                operation: ">=",
              },
              ...(moment(get(project, "contract.issuanceDate")).year() ===
              moment().year()
                ? [{ params: { [endKey]: 0 }, operation: "=" }]
                : []),
            ],
          },
          {
            OR: [
              { params: { minAge: null } },
              {
                params: { minAge: age },
                operation: "<=",
              },
            ],
          },
          {
            OR: [
              { params: { maxAge: null } },
              {
                params: { maxAge: age },
                operation: ">=",
              },
            ],
          },
          {
            OR: [
              {
                params: {
                  socialRegimes: get(project, "contact.socialRegime"),
                },
                operation: "= any",
              },
              {
                params: {
                  socialRegimes: null,
                },
              },
            ],
          },
          {
            OR: [
              {
                params: {
                  exerciseFrame: get(project, "contact.exerciseFrame"),
                },
                operation: "=",
              },
              {
                params: {
                  exerciseFrame: null,
                },
              },
            ],
          },
        ],
      },
    };
  }, []);
  const [filter, setFilter] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedGeneralCosts, { loading }] = useLazyQuery(GENERAL_COSTS, {
    variables: {
      where: { project: { id } },
      isIn: {
        insurancePlan: { id: get(project, "fields.selectedToCompare", []) },
      },
    },
    onCompleted: ({ generalCosts: { data } }) => {
      setSelectedProducts(omitDeep(data, "__typename"));
    },
  });
  const [buildGeneralCost] = useMutation(BUILD_GENERAL_COST);
  const [updateProject] = useMutation(UPDATE_PROJECT);
  const [buildGeneralCosts] = useMutation(BUILD_GENERAL_COSTS, {
    variables: {
      data: {
        project: { id },
        insurancePlanFilter,
      },
    },
    onCompleted: ({ buildGeneralCosts }) => {
      selectedGeneralCosts();
      setFilter({
        skip: 0,
        take,
        where: {
          project: { id },
        },
        gt: { cost: +get(project, "fields.budget.min") - 1 },
        lt: { cost: +get(project, "fields.budget.max") + 1 },
        isIn: { id: buildGeneralCosts.map(({ id }) => id) },
      });
    },
  });

  const onAdd = (product) => setSelectedProducts((prev) => [...prev, product]);

  const onRemove = (product) =>
    setSelectedProducts((prev) => prev.filter(({ id }) => id !== product.id));

  const onChangeCommission = ({ commission, insurancePlan }) => {
    buildGeneralCost({
      variables: {
        data: {
          project: { id },
          insurancePlan,
          commission,
        },
      },
    });
  };
  const onFilter = ({ min, max, needs }) => {
    updateProject({
      variables: { where: { id }, data: { needs } },
      onCompleted: () => {
        setFilter((prev) => ({
          ...prev,
          where: {
            ...prev.where,
            project: {
              ...prev.where.project,
              needs,
            },
          },
          gt: { cost: min - 1 },
          lt: { cost: max + 1 },
        }));
      },
    });
  };

  useEffect(() => {
    if (project.locked)
      return setFilter({
        skip: INITIAL_VALUES.skip,
        take,
        where: { project: { id } },
      });
    buildGeneralCosts();
  }, []);

  return (
    <View
      initialFields={project.fields}
      initialNeeds={project.needs}
      onNext={onNext}
      filter={filter}
      take={take}
      onBack={onBack}
      onAdd={onAdd}
      onRemove={onRemove}
      selectedProducts={selectedProducts}
      onChangeCommission={onChangeCommission}
      onFilter={onFilter}
      onLoadMore={setSkip}
      loading={isEmpty(filter) || loading}
    />
  );
};

export default List;
