import gql from "graphql-tag";

export const CREATE_TRACER = gql`
  mutation AddTracer($data: TracerDataInput) {
    addTracer(data: $data) {
      id
    }
  }
`;

export const UPDATE_TRACER = gql`
  mutation UpdateTracer($data: TracerDataInput, $where: TracerWhereInput) {
    updateTracer(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_TRACER = gql`
  mutation DeleteTracer($where: TracerWhereInput) {
    deleteTracer(where: $where) {
      id
    }
  }
`;

const tracers = { CREATE_TRACER, UPDATE_TRACER, DELETE_TRACER };

export default tracers;
