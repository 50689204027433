import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

const authWSLink = new GraphQLWsLink(
  createClient({
    url: 'ws://id.preprod.kayros.tech/id', // TODO: reset to process.env.REACT_APP_WS_AUTH_BFF_URL
  })
);

export default authWSLink;
