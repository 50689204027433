import { BsSearch, BsTrash } from "react-icons/bs";
import { COLORS } from "shared/style/colors";

const SubTableRow = ({ items, onShow, onDelete }) => {
    return (
        <div className="table__row">
            {items.map(({ name, value }, index) => (
                <div className="table__row--item" key={"element-" + index}>
                    <span>{name} :</span>
                    <p>{value}</p>
                </div>
            ))}

            <div className="table__row--actions">
                <span onClick={onShow}><BsSearch size={20} color={COLORS.C_PRIMARY} /></span>
                <span onClick={onDelete}><BsTrash size={20} color={COLORS.C_DANGER}/></span>
            </div>
        </div>
    );
};

export default SubTableRow;