import { useMemo } from "react";

import PropTypes from "prop-types";

import Main from "./Main";
import Simple from "./Simple";
import Guarantee from "./Guarantee";

const MODES = {
  main: Main,
  simple: Simple,
  guarantee: Guarantee,
};

const Card = ({ type, ...props }) => {
  const View = useMemo(() => MODES[type], [type]);
  return <View {...props} />;
};

Card.propTypes = {
  type: PropTypes.oneOf(Object.keys(MODES)),
};

Card.defaultProps = {
  type: "main",
};

export default Card;
