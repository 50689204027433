import Card from "antd/es/card/Card";
import CommissionStatements from "entities/CommissionStatements";
import { get, range } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdAddCircleOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Header from "shared/components/Header";
import Select from "shared/components/Select";
import { useMutation } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { DOWNLOAD_SINGLE_DOCUMENT } from "utils/api/graphql/mutations/download-document";
import { BROKERS } from "utils/api/graphql/queries/brokers";
import {
  INITIAL_VALUES,
  MONTH_OPTIONS,
  ORGANIZATION_TYPES,
  SCREENS,
} from "utils/constants";
import View from "./View";

const List = () => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.COMMISSION_STATEMENT);
  const [downloadSingleDocument] = useMutation(DOWNLOAD_SINGLE_DOCUMENT);
  const yearsList = range(2023, moment().year() + 3);
  const LIST = t("LIST", { returnObjects: true });
  const MONTHS = t("MONTHS", { returnObjects: true });
  const FILTER = t("FILTER", { returnObjects: true });
  const [pagination, setPagination] = useState({});
  const [where, setWhere] = useState({});
  const filter = {
    ...INITIAL_VALUES,
    orderBy: [{ year: "DESC" }, { month: "DESC" }],
  };

  const navigate = useNavigate();

  const buttons = [
    {
      icon: <MdAddCircleOutline size={24} />,
      text: LIST.HEADER.SAVE,
      onClick: () =>
        navigate(SCREENS.PRIVATE.ADMIN.COMMISSION_STATEMENTS.CREATE.path, {
          state: pagination,
        }),
    },
  ];

  return (
    <div>
      <Header title={LIST.HEADER.TITLE} actions={buttons} />

      <Card type="simple">
        <div className="d-flex m-bottom-24">
          <span>{FILTER.FILTER_BY}</span>
          <div className="grid--4 flex--grow-1">
            <Select
              options={yearsList.map((year) => ({
                value: `${year}`,
                label: year,
              }))}
              placeholder={FILTER.YEAR}
              value={where.year}
              allowClear
              onChange={(year) => {
                setWhere((prev) => ({
                  ...prev,
                  year,
                }));
              }}
            />
            <Select
              placeholder={FILTER.MONTH}
              value={where.month}
              options={MONTH_OPTIONS.map((value) => ({
                value,
                label: MONTHS[value],
              }))}
              allowClear
              onChange={(month) =>
                setWhere((prev) => ({
                  ...prev,
                  month,
                }))
              }
            />
            <Select
              type="async"
              node={{
                query: BROKERS,
                variables: {
                  where: {
                    OR: [
                      { organization: { type: ORGANIZATION_TYPES.BROKER } },
                      { isInternal: true },
                    ],
                  },
                  orderBy: [{ socialReason: "ASC" }],
                },
                accessors: {
                  root: "brokers.data",
                  label: "socialReason",
                  value: "socialReason",
                },
              }}
              allowClear
              value={get(where, "broker.socialReason")}
              placeholder={FILTER.BROKER}
              onChange={(socialReason) =>
                setWhere((prev) =>
                  socialReason
                    ? {
                        ...prev,
                        broker: { socialReason },
                      }
                    : { ...prev, broker: undefined }
                )
              }
            />
          </div>
        </div>
        <CommissionStatements
          View={View}
          filter={{ ...filter, ...pagination, where }}
          setWhere={setWhere}
          setValues={setPagination}
          skip={pagination.skip || 0}
          NoData={View}
          downloadSingleDocument={downloadSingleDocument}
        />
      </Card>
    </div>
  );
};

export default List;
