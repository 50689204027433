import { Drawer } from "antd";
import { find, get } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import Subscription from "shared/components/Subscription";
import { NAME_SPACES } from "shared/locales/constants";
import { COMMISSION_TYPES, SCREENS } from "utils/constants";

const Subscriptions = ({ onClose, data }) => {
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 991px)" });
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const LIST = t("LIST", { returnObjects: true });
  const onClick = (id) =>
    navigate(`${SCREENS.PRIVATE.BROKER.SUBSCRIPTIONS.DETAIL.path}/${id}`);
  return (
    <Drawer
      title="Liste des produits"
      placement="right"
      onClose={onClose}
      open={true}
      className="drawer__area"
      contentWrapperStyle={{
        width: isTabletOrMobile ? "98%" : "600px",
      }}
    >
      {data.map(({ id, status, insurancePlan, project }) => {
        const { cost, currency, commission } = find(
          get(project, "generalCosts"),
          (cost) => get(cost, "insurancePlan.id") === get(insurancePlan, "id")
        );
        const expired =
          project.expired ||
          get(insurancePlan, "params", []).some(
            (param) =>
              moment(get(project, "contract.issuanceDate"))
                .startOf("day")
                .diff(moment().startOf("day"), "days") <
              (get(
                param,
                `${get(project, "ria") ? "deltaStartRIA" : "deltaStart"}`
              ) || 0)
          );
        return (
          <Subscription
            key={id}
            translation={LIST.SUBSCRIPTION}
            date={get(project, "contract.issuanceDate")}
            user={`${get(project, "contact.user.firstname")} ${get(
              project,
              "contact.user.lastname"
            )}`}
            status={LIST.SUBSCRIPTION.STATUSES[status]}
            product={get(insurancePlan, "name")}
            category={get(insurancePlan, "description")}
            icon={get(insurancePlan, "icon")}
            cost={cost}
            currency={currency}
            onClick={() => onClick(id)}
            disabled={{ status: expired, text: LIST.SUBSCRIPTION.POPOVER }}
            tag={
              get(commission, "type") === COMMISSION_TYPES.LINEAR
                ? `${get(commission, "value", "-")}/${get(
                    commission,
                    "value",
                    "-"
                  )}`
                : `${get(commission, "value", "-")}/${get(
                    commission,
                    "withholding",
                    "-"
                  )}`
            }
          />
        );
      })}
    </Drawer>
  );
};

export default Subscriptions;
