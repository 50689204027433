import { useState } from "react";
import { useNavigate } from "react-router";
import { useMutation } from "shared/hooks/useApi";
import { FORGOT_PASSWORD } from "utils/api/graphql/mutations/users";
import { SCREENS } from "utils/constants";
import View from "./View";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [forgotPassword] = useMutation(FORGOT_PASSWORD);
  const [success, setSuccess] = useState(false);

  const onSubmit = ({ email }) => {
    forgotPassword({
      variables: {
        data: {
          email,
        },
      },
      onCompleted: () => setSuccess(true),
    });
  };

  const onLinkClick = () => navigate(SCREENS.AUTH.SIGN_IN.path);

  return (
    <View onSubmit={onSubmit} onLinkClick={onLinkClick} success={success} />
  );
};

export default ForgotPassword;
