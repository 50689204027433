import Icon from "react-material-symbols/rounded";

const Simple = ({ element }) => {
  return (
    <div className="__file">
      <div>
        <div>
          <Icon icon="description" size={20} />
          {element.filename}
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default Simple;
