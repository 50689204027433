import { Switch } from "antd";
import dayjs from "dayjs";
import { get } from "lodash";
import Icon from "react-material-symbols/rounded";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { DATE_FORMAT, SOCIAL_REGIMES } from "utils/constants";
import { isMajor } from "utils/helpers/date";
import { bindDateInputProps, bindInputProps } from "utils/helpers/input";

export const step = { SPOUSE: "SPOUSE" };

const Spouse = ({
  index,
  translation,
  values,
  setFieldValue,
  ...formProps
}) => {
  return (
    <div className="contact-stepper" key={step.SPOUSE}>
      <div className="contact-stepper__header--switch">
        <div className="contact-stepper__header">
          <div className="contact-stepper__header--counter">
            <span>{index}</span>
          </div>
          <div className="contact-stepper__header--content">
            <h1 className="contact-stepper__header--content--title">
              <span>
                {translation.TEMPLATES.CONTACT.STEPS[step.SPOUSE].TITLE}
              </span>
            </h1>
            <p className="contact-stepper__header--content--description">
              {translation.TEMPLATES.CONTACT.STEPS[step.SPOUSE].DESCRIPTION}
            </p>
          </div>
        </div>
        <Switch
          className="contact-stepper__content--radio--switch button__switch"
          checkedChildren={
            translation.TEMPLATES.CONTACT.STEPS[step.SPOUSE].OPTIONS.YES
          }
          unCheckedChildren={
            translation.TEMPLATES.CONTACT.STEPS[step.SPOUSE].OPTIONS.NO
          }
          defaultChecked={get(values, "hasSpouse", false)}
          onChange={(e) => setFieldValue("hasSpouse", e)}
        />
      </div>
      {get(values, "hasSpouse", false) && (
        <div className="contact-stepper__content">
          <Input
            icon={<Icon icon="cake" size={22} />}
            label={
              translation.TEMPLATES.CONTACT.STEPS[step.SPOUSE].FORM.BIRTHDAY
                .LABEL
            }
            placeholder={
              translation.TEMPLATES.CONTACT.STEPS[step.SPOUSE].FORM.BIRTHDAY
                .PLACEHOLDER
            }
            format={DATE_FORMAT}
            type="date"
            variant="simple"
            disabledDate={(current) => current > isMajor}
            defaultPickerValue={dayjs().subtract(30, "years")}
            {...bindDateInputProps({
              name: "spouse.identity.user.birthDate",
              values,
              setFieldValue,
              ...formProps,
            })}
          />
          <Select
            icon={<Icon icon="health_and_safety" size={22} />}
            options={Object.entries(SOCIAL_REGIMES).map(([key, value]) => ({
              value,
              label: translation.SOCIAL_REGIMES[key],
            }))}
            label={
              translation.TEMPLATES.CONTACT.STEPS[step.SPOUSE].FORM
                .SOCIAL_REGIME.LABEL
            }
            placeholder={
              translation.TEMPLATES.CONTACT.STEPS[step.SPOUSE].FORM
                .SOCIAL_REGIME.PLACEHOLDER
            }
            {...bindInputProps({
              name: "spouse.identity.socialRegime",
              values,
              ...formProps,
            })}
          />
        </div>
      )}
    </div>
  );
};
export default Spouse;
