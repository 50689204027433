import { Drawer } from "antd";
import cuid from "cuid";
import { useFormik } from "formik";
import { BsCheck } from "react-icons/bs";
import { useParams } from "react-router-dom";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { COVERAGE_CATEGORIES } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";

const CreateCoverage = ({ onClose, open, translation, addCoverage }) => {
  const { id } = useParams();
  const { values, handleSubmit, resetForm, ...formProps } = useFormik({
    initialValues: {
      identifier: null,
      type: null,
    },
    onSubmit: (values) => {
      addCoverage({
        variables: {
          data: {
            ...values,
            id: cuid(),
            insurancePlan: { id },
          },
        },
        onCompleted: closeForm,
      });
    },
  });

  const closeForm = () => {
    onClose();
    resetForm();
  };

  return (
    <Drawer
      title={translation.NEW_COVERAGE}
      width={"40%"}
      onClose={closeForm}
      open={open}
      className="drawer__custom"
    >
      <div className="grid--1">
        <Input
          label={translation.NAME}
          placeholder={translation.NAME}
          {...bindInputProps({ name: "identifier", values, ...formProps })}
        />
        <Select
          label={translation.CATEGORY}
          placeholder={translation.CATEGORY}
          options={Object.entries(COVERAGE_CATEGORIES).map(([key, value]) => ({
            label: translation.CATEGORIES[key],
            value,
          }))}
          {...bindInputProps({ name: "type", values, ...formProps })}
        />

        <Input
          type="number"
          label={translation.WAITING_DAYS}
          placeholder={translation.WAITING_DAYS}
          {...bindInputProps({ name: "waitingDays", values, ...formProps })}
        />

        <Button
          type="primary"
          size={"full--width"}
          icon={<BsCheck size={24} />}
          onClick={handleSubmit}
        >
          {translation.ACTIONS.SAVE}
        </Button>
      </div>
    </Drawer>
  );
};

export default CreateCoverage;
