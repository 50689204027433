import PropTypes from "prop-types";
import { COMPONENTS_SIZES } from "utils/constants";

const COLORS = ["default", "primary", "second"];

const Text = ({ value, size, color }) => {
  return (
    <div className={`avatar--text avatar--${size} avatar--bg--${color}`}>
      <span>{value.substring(0, 2)}</span>
    </div>
  );
};

Text.propTypes = {
  value: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(COMPONENTS_SIZES)),
  color: PropTypes.oneOf(COLORS),
};

Text.defaultProps = {
  size: "small",
  color: "primary",
};

export default Text;
