import { gql } from "@apollo/client";

const NOTE_ADDED = gql`
  subscription noteAdded($where: NoteWhereInput) {
    noteAdded(where: $where) {
      id
      content
      createdDate
      author {
        id
        firstname
        lastname
      }
      qualification {
        id
        content
        qualifier {
          id
          name
          fields
        }
      }
    }
  }
`;

const NOTE_DELETED = gql`
  subscription noteDeleted($where: NoteWhereInput) {
    noteDeleted(where: $where) {
      id
      content
      createdDate
      author {
        id
        firstname
        lastname
      }
      qualification {
        id
        qualifier {
          id
          name
          fields
        }
      }
    }
  }
`;

const notes = { NOTE_ADDED, NOTE_DELETED };

export default notes;
