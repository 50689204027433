import { gql } from "@apollo/client";

export const CONTACTS = gql`
  query Contacts(
    $where: ContactWhereInput
    $take: Int
    $skip: Int
    $like: JSONObject
    $orderBy: [JSONObject]
  ) {
    contacts(
      where: $where
      take: $take
      like: $like
      skip: $skip
      orderBy: $orderBy
    ) {
      data {
        id
        name
        qualifications {
          id
          qualifier {
            id
            name
          }
        }
        createdDate
        notes {
          id
          content
          createdDate
        }
        raw
        user {
          id
          firstname
          lastname
          gender
          phone
          birthDate
        }
        relatedPersons {
          id
          relationship
          identity {
            id
            socialRegime
            socioProfessionalCategory
            user {
              id
              firstname
              lastname
              birthDate
            }
          }
        }
        telecoms {
          id
          system
          value
        }
        fields
      }
      count
    }
  }
`;

export const CONTACT = gql`
  query Contact($where: ContactWhereInput) {
    contact(where: $where) {
      id
      name
      qualifications {
        id
        qualifier {
          id
          name
        }
      }
      createdDate
      notes {
        id
        content
        createdDate
      }
      raw
    }
  }
`;

const contacts = { CONTACTS, CONTACT };

export default contacts;
