import * as yup from "yup";

const validation = (errors) => {
  return yup.object().shape({
    username: yup.string().required(errors.REQUIRED),
    password: yup.string().required(errors.REQUIRED),
  });
};

export default validation;
