import CheckList from "@editorjs/checklist";
import Code from "@editorjs/code";
import Delimiter from "@editorjs/delimiter";
import Embed from "@editorjs/embed";
import Header from "@editorjs/header";
import Image from "@editorjs/image";
import InlineCode from "@editorjs/inline-code";
import LinkTool from "@editorjs/link";
import List from "@editorjs/list";
import Marker from "@editorjs/marker";
import Quote from "@editorjs/quote";
import Raw from "@editorjs/raw";
import SimpleImage from "@editorjs/simple-image";
import Table from "@editorjs/table";
import Warning from "@editorjs/warning";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { createReactEditorJS } from "react-editor-js";
import { convertToBase64 } from "utils/helpers/input";

import PropTypes from "prop-types";

const EDITOR_JS_TOOLS = {
  embed: Embed,
  table: Table,
  list: List,
  warning: Warning,
  code: Code,
  linkTool: LinkTool,
  image: {
    class: Image,
    config: {
      uploader: {
        uploadByFile: async (file) => {
          const base64 = await convertToBase64(file);
          return Promise.resolve({
            success: 1,
            file: {
              url: base64,
            },
          });
        },
        uploadByUrl: async (file) => {
          const base64 = await convertToBase64(file);
          return Promise.resolve({
            success: 1,
            file: {
              url: base64,
            },
          });
        },
      },
    },
  },
  raw: Raw,
  header: Header,
  quote: Quote,
  marker: Marker,
  checklist: CheckList,
  delimiter: Delimiter,
  inlineCode: InlineCode,
  simpleImage: SimpleImage,
};

const ReactEditorJS = createReactEditorJS();

const Component = forwardRef((props, ref) => {
  const inputRef = useRef(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        handleSave: () => {
          return inputRef.current.save();
        },
      };
    },
    []
  );

  return (
    <div className="text-editor--wrapper">
      <ReactEditorJS
        onInitialize={(instance) => {
          inputRef.current = instance;
        }}
        tools={EDITOR_JS_TOOLS}
        {...props}
        ref={inputRef}
      />
    </div>
  );
});

Component.prototype = {
  defaultValue: PropTypes.shape({
    blocks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        data: PropTypes.objectOf(PropTypes.number),
      })
    ),
  }),
};

Component.defaultProps = {
  blocks: [],
};

export default Component;
