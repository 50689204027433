import cuid from "cuid";
import GeneralCosts from "entities/GeneralCosts";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useMutation } from "shared/hooks/useApi";
import {
  GENERATE_DUTY_OF_ADVICE_QUOTE_DOCUMENT,
  GENERATE_QUOTE_DOCUMENT,
  GENERATE_QUOTE_DOCUMENTS,
} from "utils/api/graphql/mutations/attachment";
import { BUILD_GENERAL_COSTS } from "utils/api/graphql/mutations/costs";
import { CREATE_SUBSCRIPTION } from "utils/api/graphql/mutations/subscription";
import { downloadFiles } from "utils/helpers/files";
import View from "./View";

const Comparison = ({ project, onNext, onBack }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [addSubscription] = useMutation(CREATE_SUBSCRIPTION);
  const [generateQuoteDocuments] = useMutation(GENERATE_QUOTE_DOCUMENTS);
  const [generateDutyOfAdviceQuoteDocument] = useMutation(
    GENERATE_DUTY_OF_ADVICE_QUOTE_DOCUMENT
  );
  const [generateQuoteDocument] = useMutation(GENERATE_QUOTE_DOCUMENT);
  const [buildGeneralCosts] = useMutation(BUILD_GENERAL_COSTS, {
    variables: {
      data: {
        project: { id },
        insurancePlanFilter: {
          isIn: {
            id: get(project, "fields.selectedToCompare", []),
          },
        },
      },
    },
    onCompleted: () => setLoading(false),
  });

  const onDownload = async ({
    selectedData = [],
    payload = {},
    actionsTemplate,
  }) => {
    return generateQuoteDocuments({
      variables: {
        data: {
          project: { id },
          insurancePlans: selectedData.map((id) => ({ id })),
        },
      },
      onCompleted: async ({ generateQuoteDocuments: { base64 } }) => {
        downloadFiles(base64);
        selectedData.forEach((insurancePlanId) => {
          addSubscription({
            variables: {
              data: {
                id: cuid(),
                project: { id },
                insurancePlan: {
                  id: insurancePlanId,
                },
              },
            },
          });
        });
        onNext({ payload, actionsTemplate });
      },
    });
  };

  const onSubscribe = async ({ payload = {}, actionsTemplate }) => {
    onNext({ payload, actionsTemplate });
  };

  const onSendQuote = async ({
    selectedData = [],
    payload = {},
    actionsTemplate,
  }) => {
    await generateDutyOfAdviceQuoteDocument({
      variables: {
        data: {
          payload: {
            project: { id },
            products: selectedData,
          },
        },
      },
    });
    await generateQuoteDocument({
      variables: {
        data: {
          payload: {
            project: { id },
            products: selectedData,
          },
        },
      },
    });
    onNext({ payload, actionsTemplate });
  };

  useEffect(() => {
    if (project.locked) return setLoading(false);
    buildGeneralCosts();
  }, []);

  if (loading) return <Loading />;

  return (
    <GeneralCosts
      project={project}
      onSubscribe={onSubscribe}
      onDownload={onDownload}
      onSendQuote={onSendQuote}
      View={View}
      NoData={View}
      filter={{
        where: {
          project: { id: project.id },
        },
        isIn: {
          insurancePlan: { id: get(project, "fields.selectedToCompare") },
        },
      }}
      onBack={onBack}
    />
  );
};

export default Comparison;
