import gql from "graphql-tag";

export const CREATE_PROCESS = gql`
  mutation AddProcess($data: ProcessDataInput) {
    addProcess(data: $data) {
      id
    }
  }
`;

export const UPDATE_PROCESS = gql`
  mutation UpdateProcess($where: ProcessWhereInput, $data: ProcessDataInput) {
    updateProcess(where: $where, data: $data) {
      id
    }
  }
`;

export const DELETE_PROCESS = gql`
  mutation DeleteProcess($where: ProcessWhereInput) {
    deleteProcess(where: $where) {
      id
    }
  }
`;

const processes = { CREATE_PROCESS, UPDATE_PROCESS, DELETE_PROCESS };

export default processes;
