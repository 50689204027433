import cuid from "cuid";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { INITIAL_VALUES, SCREENS } from "utils/constants";
import View from "./View";

const Create = () => {
  const navigate = useNavigate();
  const id = useMemo(() => cuid(), []);
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.COVERAGES.LIST.path);
  const [addCoverage] = useMutation(graphql.mutations.CREATE_COVERAGE, {
    refetchQueries: [
      {
        query: graphql.queries.COVERAGES,
        awaitRefetchQueries: true,
        variables: INITIAL_VALUES,
      },
    ],
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.ADMIN.COVERAGES.LIST.path);
    },
    onError: () => {},
  });
  const onSubmit = (values) => {
    return addCoverage({
      variables: {
        data: {
          id,
          ...values,
        },
      },
    });
  };
  return <View onSubmit={onSubmit} id={id} cancel={cancel} />;
};

export default Create;
