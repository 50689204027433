import { DatePicker } from "antd";
import classnames from "classnames";
import Label from "shared/components/Label";

const { RangePicker } = DatePicker;

const Simple = ({ label, errors, touched, icon, required, ...rest }) => {
  return (
    <div
      className={classnames("datepicker__wrapper", {
        __error: touched && errors,
      })}
    >
      <Label label={label} icon={icon} required={required} />
      <div className={`datepicker__wrapper--field`}>
        <RangePicker
          className={classnames({ "disabled--input": rest.disabled })}
          {...rest}
        />
        {touched && errors && <p className={"input--errors"}>{errors}</p>}
      </div>
    </div>
  );
};

export default Simple;
