import React from "react";

import { Radio } from "antd";
import PropTypes from "prop-types";

const Component = ({ onChange, label, value, active }) => (
  <div className="radio__wrapper">
    <Radio className={`custom ${active}`} onChange={onChange} value={value}>
      {label}
    </Radio>
  </div>
);

Component.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  active: PropTypes.string,
};

Component.defaultProps = {
  onChange: () => {},
  label: "",
  value: "",
  active: "",
};

export default Component;
