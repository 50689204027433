import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "shared/hooks/useApi";
import useSubscription from "shared/services/subscriptions";
import { names } from "shared/services/subscriptions/constants";
import graphql from "utils/api/graphql";
import { SCREENS } from "utils/constants";
import View from "./View";

const Detail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.COVERAGES.LIST.path);
  const [updateCoverageBenefit] = useMutation(
    graphql.mutations.UPDATE_COVERAGE_BENEFIT,
    {
      onCompleted: () => {
        navigate(SCREENS.PRIVATE.ADMIN.COVERAGES.LIST.path);
      },
      onError: () => {},
    }
  );
  const variables = { where: { id } };
  const { data, loading, error } = useQuery(graphql.queries.COVERAGE_BENEFIT, {
    variables,
  });
  useSubscription({ query: names.COVERAGE_BENEFITS, variables });
  const onSubmit = (values) => {
    return updateCoverageBenefit({
      variables: {
        where: {
          id,
        },
        data: values,
      },
    });
  };

  if (loading || error) return "Loading";

  return (
    <View onSubmit={onSubmit} data={data.coverageBenefit} cancel={cancel} />
  );
};

export default Detail;
