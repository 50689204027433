const Card = ({ title, subtitle, children, divider = true }) => {
  return (
    <div className="card-container">
      <div className="card-header">
        <h2 className="card-title">{title}</h2>
        <span className="card-subtitle">{subtitle}</span>
      </div>
      {divider && <div className="divider" />}
      <div className="card-body">{children}</div>
    </div>
  );
};

export default Card;
