import cuid from "cuid";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { SCREENS } from "utils/constants";
import View from "./View";

const Create = ({ project }) => {
  const navigate = useNavigate();
  const [updateProject] = useMutation(graphql.mutations.UPDATE_PROJECT, {
    onCompleted: () => {
      navigate(SCREENS.PRIVATE.BROKER.PROJECTS.LIST.path);
    },
    onError: () => {},
  });
  const onSubmit = ({ contact, fields }) => {
    const data = {
      fields: {
        ...fields,
        budget: {
          actual: Number(get(fields, "budget.actual", 0)),
          proposed: Number(get(fields, "budget.proposed", 0)),
        },
      },
      contact: {
        id: cuid(),
        name: contact,
      },
    };
    return updateProject({ variables: { where: { id: project.id }, data } });
  };
  return <View onSubmit={onSubmit} project={project} />;
};

export default Create;
