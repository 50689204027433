import { gql } from "@apollo/client";

const CONTRACT_ADDED = gql`
  subscription contractAdded($where: ContractWhereInput) {
    contractAdded(where: $where) {
      id
      identifier
      url
      status
      legalState
      issuanceDate
      contentDerivative
      createdDate
      expirationDate
      expirationType
      instantiatesUri
      subjects
      authorities {
        id
      }
      domaines {
        id
      }
      sites {
        id
      }
      name
      title
      subTitle
      alias
      author {
        id
        identifier
        createdDate
        active
        type
        name
        alias
        billingCycle
      }
      scope
      type
      subType
      coverage {
        id
      }
    }
  }
`;

const CONTRACT_UPDATED = gql`
  subscription contractUpdated($where: ContractWhereInput) {
    contractUpdated(where: $where) {
      id
      identifier
      url
      status
      legalState
      issuanceDate
      contentDerivative
      createdDate
      expirationDate
      expirationType
      instantiatesUri
      subjects
      authorities {
        id
      }
      domaines {
        id
      }
      sites {
        id
      }
      name
      title
      subTitle
      alias
      author {
        id
        identifier
        createdDate
        active
        type
        name
        alias
        billingCycle
      }
      scope
      type
      subType
      coverage {
        id
      }
    }
  }
`;

const CONTRACT_DELETED = gql`
  subscription contractRemoved($where: ContractWhereInput) {
    contractRemoved(where: $where) {
      id
      identifier
      url
      status
      legalState
      issuanceDate
      contentDerivative
      createdDate
      expirationDate
      expirationType
      instantiatesUri
      subjects
      authorities {
        id
      }
      domaines {
        id
      }
      sites {
        id
      }
      name
      title
      subTitle
      alias
      author {
        id
        identifier
        createdDate
        active
        type
        name
        alias
        billingCycle
      }
      scope
      type
      subType
      coverage {
        id
      }
    }
  }
`;

const contracts = { CONTRACT_ADDED, CONTRACT_UPDATED, CONTRACT_DELETED };

export default contracts;
