import { gql } from "@apollo/client";
import { PRIVATE_AUTH_ANNOTATION } from "shared/graphql/links/authBff.link";

export const USERS = gql`
  query Users(
    $where: UserWhereInput
    $isIn: JSONObject
    $take: Int
    $skip: Int
    $like: UserWhereInput
  ) {
    users(where: $where, take: $take, skip: $skip, like: $like, isIn: $isIn) {
      data {
        id
        firstname
        lastname
        gender
        phone
        type
        meta
        email
        isActive
        organization {
          telecoms {
            system
            value
          }
        }
      }
      count
    }
  }
`;

export const USER = gql`
  query User($where: UserWhereInput) {
    user(where: $where) {
      id
      firstname
      lastname
      phone
      gender
      meta
      email
      organization {
        id
        broker {
          id
          orias
          socialReason
          code
          rcsNumber
        }
        telecoms {
          id
          system
          value
        }
      }
    }
  }
`;

export const CHECK_USER = gql`
  query Profile {
    profile {
      id
      firstname
      lastname
      type
      meta
      organization {
        name
        id
        telecoms {
          id
          system
          value
        }
        broker {
          id
          isInternal
          orias
          code
          rcsNumber
        }
      }
      permissions {
        granted
        module
        submodules {
          name
          granted
          actions
        }
      }
    }
  }
`;

export const USER_AUTH = gql`
  query user($where: UserWhereInput) {
    user(where: $where) @${PRIVATE_AUTH_ANNOTATION} {
      id
    }
  }
`;

const users = { USERS, USER, CHECK_USER, USER_AUTH };

export default users;
