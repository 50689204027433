import { useMemo } from "react";

import PropTypes from "prop-types";

import Image from "./Image";
import Rand from "./Rand";
import Text from "./Text";

const MODE = {
  image: Image,
  text: Text,
  rand: Rand,
};

const Avatar = ({ mode, ...props }) => {
  const View = useMemo(() => MODE[mode], [mode]);
  return <View {...props} />;
};

Avatar.propTypes = {
  mode: PropTypes.oneOf(Object.keys(MODE)),
};

Avatar.defaultProps = {
  mode: "image",
};

export default Avatar;
