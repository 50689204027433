import moment from "moment";
import PropTypes from "prop-types";
import { useMemo } from "react";

import Range from "./Range";
import Simple from "./Simple";

const MODES = {
  simple: Simple,
  range: Range,
};

const Date = ({ variant, defaultValue, ...props }) => {
  const View = useMemo(() => MODES[variant], [variant]);

  return (
    <View
      defaultValue={moment(defaultValue).isValid() ? defaultValue : undefined}
      {...props}
    />
  );
};

Date.propTypes = {
  variant: PropTypes.oneOf(Object.keys(MODES)),
};

Date.defaultProps = {
  variant: "simple",
};

export default Date;
