import { Col, Row } from "antd";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import Icon from "react-material-symbols/rounded";
import Alert from "shared/components/Alert";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import {
  CONTACT_EXERCISE_FRAME,
  JOB_LIST,
  SOCIO_PROFESSIONAL_CATEGORIES,
  SUBSCRIPTION_FORM_KEYS,
} from "utils/constants";
import { bindInputProps } from "utils/helpers/input";

export const showExerciseFrame = (value) =>
  [
    SOCIO_PROFESSIONAL_CATEGORIES.FARMERS,
    SOCIO_PROFESSIONAL_CATEGORIES.ARTISANS,
    SOCIO_PROFESSIONAL_CATEGORIES.LIBERAL_PROFESSIONS_AND_SIMILAR,
    SOCIO_PROFESSIONAL_CATEGORIES.TRADERS_AND_SIMILAR,
    SOCIO_PROFESSIONAL_CATEGORIES.ENTREPRENEURS,
  ].includes(value);

const Activity = ({
  formProps,
  values,
  setPercent,
  setFieldValue,
  disabled,
  setDisabled,
  isFieldRequired,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });

  return (
    <div
      className="subscription-form--wrapper"
      id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.ACTIVITY}-section`}
    >
      <Form
        type="vertical"
        title={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.ACTIVITY.TITLE}
      >
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <Select
              options={Object.entries(SOCIO_PROFESSIONAL_CATEGORIES).map(
                ([key, value]) => ({
                  value,
                  label:
                    SUBSCRIPTION.FORM.SOCIO_PROFESSIONAL_CATEGORY.OPTIONS[key],
                })
              )}
              label={SUBSCRIPTION.FORM.SOCIO_PROFESSIONAL_CATEGORY.LABEL}
              icon={<Icon icon="work" size={16} />}
              placeholder={
                SUBSCRIPTION.FORM.SOCIO_PROFESSIONAL_CATEGORY.PLACEHOLDER
              }
              {...bindInputProps({
                name: "project.contact.socioProfessionalCategory",
                values,
                ...formProps,
                onBlur: () => setPercent(values),
              })}
              onChange={(value) => {
                setFieldValue(
                  "project.contact.socioProfessionalCategory",
                  value
                );
                if (!showExerciseFrame(value))
                  setFieldValue(
                    "project.contact.exerciseFrame",
                    CONTACT_EXERCISE_FRAME.EMPLOYEE
                  );
                setDisabled((prev) => ({
                  ...prev,
                  project: {
                    ...prev.project,
                    contact: {
                      ...prev.project.contact,
                      exerciseFrame: !showExerciseFrame(value),
                    },
                  },
                }));
              }}
              required={isFieldRequired(
                "project.contact.socioProfessionalCategory"
              )}
            />
          </Col>
          {showExerciseFrame(
            get(values, "project.contact.socioProfessionalCategory")
          ) && (
            <Col lg={24} xs={24}>
              <Input
                type="radio"
                variant="group"
                icon={<Icon icon="work_history" size={16} />}
                label={SUBSCRIPTION.FORM.EXERCISE_FRAMEWORK.LABEL}
                values={[
                  {
                    value: CONTACT_EXERCISE_FRAME.EMPLOYEE,
                    label:
                      SUBSCRIPTION.FORM.EXERCISE_FRAMEWORK.OPTIONS.EMPLOYEE,
                  },
                  {
                    value: CONTACT_EXERCISE_FRAME.INDEPENDENT,
                    label:
                      SUBSCRIPTION.FORM.EXERCISE_FRAMEWORK.OPTIONS.INDEPENDENT,
                  },
                ]}
                {...bindInputProps({
                  name: "project.contact.exerciseFrame",
                  values,
                  ...formProps,
                  onBlur: () => setPercent(values),
                })}
                onChange={(value) => {
                  setFieldValue("project.contact.exerciseFrame", value);
                  if (value === CONTACT_EXERCISE_FRAME.EMPLOYEE)
                    setFieldValue("project.contact.siretNumber", null);
                }}
                required={isFieldRequired("project.contact.exerciseFrame")}
              />
            </Col>
          )}
          {get(values, "project.contact.exerciseFrame") ===
            CONTACT_EXERCISE_FRAME.INDEPENDENT && (
            <Col lg={24} xs={24}>
              <Input
                label={SUBSCRIPTION.FORM.SIRET.LABEL}
                icon={<Icon icon="qr_code" size={16} />}
                placeholder={SUBSCRIPTION.FORM.SIRET.PLACEHOLDER}
                {...bindInputProps({
                  name: "project.contact.siretNumber",
                  values,
                  ...formProps,
                  onBlur: () => setPercent(values),
                })}
                required={isFieldRequired("project.contact.siretNumber")}
              />
            </Col>
          )}
          <Col lg={24} xs={24}>
            <Select
              options={Object.entries(JOB_LIST).map(([key, value]) => ({
                label: SUBSCRIPTION.FORM.PROFESSION.LIST[key],
                value,
              }))}
              placeholder={SUBSCRIPTION.FORM.PROFESSION.PLACEHOLDER}
              label={SUBSCRIPTION.FORM.PROFESSION.LABEL}
              {...bindInputProps({
                name: "project.contact.profession",
                values,
                ...formProps,
                onBlur: () => setPercent(values),
              })}
              required={isFieldRequired("project.contact.profession")}
            />
          </Col>
          {!get(disabled, "project.contact.optMadelin") && (
            <>
              <Col lg={24} xs={24}>
                <Input
                  type="radio"
                  variant="group"
                  icon={<Icon icon="south_america" size={16} />}
                  label={SUBSCRIPTION.FORM.MADELIN_LAW.LABEL}
                  values={[
                    {
                      value: true,
                      label: SUBSCRIPTION.FORM.MADELIN_LAW.OPTIONS.YES,
                    },
                    {
                      value: false,
                      label: SUBSCRIPTION.FORM.MADELIN_LAW.OPTIONS.NO,
                    },
                  ]}
                  {...bindInputProps({
                    name: "project.contact.optMadelin",
                    values,
                    ...formProps,
                    onBlur: () => setPercent(values),
                  })}
                  onChange={(value) =>
                    setFieldValue("project.contact.optMadelin", value)
                  }
                  required={isFieldRequired("project.contact.optMadelin")}
                />
              </Col>
              <Col lg={24} xs={24}>
                <Alert
                  showIcon
                  type="info"
                  message={SUBSCRIPTION.FORM.MADELIN_LAW.INFO}
                />
              </Col>
            </>
          )}
        </Row>
      </Form>
    </div>
  );
};

export default Activity;
