import { Alert, Col, Row } from "antd";
import { isNil } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "react-material-symbols/rounded";
import Button from "shared/components/Button";
import { NAME_SPACES } from "shared/locales/constants";

const DUMMY_ICON = "https://icon-library.com/images/icon-url/icon-url-15.jpg";
const MIN_INSURANCE_PLANS_TO_COMPARE = 2;

const View = ({ insurancePlans, next }) => {
  const [selectedInsurancePlans, setSelectedInsurancePlans] = useState([]);
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  return (
    <div className="select-products">
      <div className="select-products__header">
        <span>{DETAIL.TEMPLATES.SELECT_PRODUCTS.TITLE}</span>
      </div>
      <Alert
        type="info"
        showIcon
        icon={<Icon icon="emergency_home" size={16} />}
        message={DETAIL.TEMPLATES.SELECT_PRODUCTS.INFO}
      />
      <div className="select-products__cards">
        {insurancePlans.map((insurancePlan) => {
          const selected = !isNil(
            selectedInsurancePlans.find((x) => x.id === insurancePlan.id)
          );
          return (
            <div
              key={insurancePlan.id}
              className={`select-products__card${selected ? "--selected" : ""}`}
              onClick={() => {
                setSelectedInsurancePlans((selectedInsurancePlans) =>
                  selected
                    ? selectedInsurancePlans.filter(
                        (x) => x.id !== insurancePlan.id
                      )
                    : [...selectedInsurancePlans, insurancePlan]
                );
              }}
            >
              <img
                className="select-products__card--icon"
                src={
                  isNil(insurancePlan.icon) ? DUMMY_ICON : insurancePlan.icon
                }
                alt={insurancePlan.name}
              />
              <div className="select-products__card--text">
                {insurancePlan.name}
                <span>{insurancePlan.description}</span>
              </div>
              <div className="select-products__card--check">
                <Icon icon="done" size={24} color="white" />
              </div>
            </div>
          );
        })}
      </div>
      <Row>
        <Col lg={12} xs={24}>
          <Button onClick={() => {}} type="secondary--link">
            <Icon icon="arrow_back_ios" size={16} />
            {DETAIL.TEMPLATES.SELECT_PRODUCTS.PREVIOUS}
          </Button>
        </Col>
        <Col align="right" lg={12} xs={24}>
          <Button
            onClick={() => next(selectedInsurancePlans)}
            type="primary"
            disabled={
              selectedInsurancePlans.length < MIN_INSURANCE_PLANS_TO_COMPARE
            }
          >
            {DETAIL.TEMPLATES.SELECT_PRODUCTS.NEXT}
            <Icon icon="arrow_forward_ios" size={16} />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default View;
