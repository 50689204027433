import { Col, Row } from "antd";
import cuid from "cuid";
import { defaultTo, get, isEmpty, range } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "react-material-symbols/rounded";
import AlternativeMedicine from "shared/assets/images/ayurveda.png";
import Dental from "shared/assets/images/dental-checkup.png";
import Equipment from "shared/assets/images/hearing-aid.png";
import Hospitalization from "shared/assets/images/hospital-bed.png";
import MedicalCare from "shared/assets/images/medical-team.png";
import Optical from "shared/assets/images/vision.png";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Popover from "shared/components/Popover";
import Slider from "shared/components/Slider";
import { NAME_SPACES } from "shared/locales/constants";
import { COVERAGE_NEEDS } from "utils/constants";

const View = ({ project, next, onBack }) => {
  const [needs, setNeeds] = useState(
    defaultTo(project.needs, {
      id: cuid(),
      [COVERAGE_NEEDS.OPTICAL]: 0,
      [COVERAGE_NEEDS.DENTAL]: 0,
      [COVERAGE_NEEDS.MEDICAL_CARE]: 0,
      [COVERAGE_NEEDS.HOSPITALIZATION]: 0,
      [COVERAGE_NEEDS.EQUIPMENT]: 0,
      [COVERAGE_NEEDS.ALTERNATIVE_MEDICINE]: 0,
    })
  );
  const [fields, setFields] = useState({
    ...project.fields,
    budget: {
      ...get(project.fields, "budget", { min: 0, max: 1000 }),
    },
  });
  const levels = range(0, 6);
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const COVERAGE_CATEGORIES_ENTRIES = [
    {
      icon: Hospitalization,
      label: DETAIL.TEMPLATES.FILTERS.COVERAGE_CLASSES.HOSPITALIZATION,
      key: COVERAGE_NEEDS.HOSPITALIZATION,
    },
    {
      icon: MedicalCare,
      label: DETAIL.TEMPLATES.FILTERS.COVERAGE_CLASSES.MEDICAL_CARE,
      key: COVERAGE_NEEDS.MEDICAL_CARE,
    },
    {
      icon: Optical,
      label: DETAIL.TEMPLATES.FILTERS.COVERAGE_CLASSES.OPTICAL,
      key: COVERAGE_NEEDS.OPTICAL,
    },
    {
      icon: Dental,
      label: DETAIL.TEMPLATES.FILTERS.COVERAGE_CLASSES.DENTAL,
      key: COVERAGE_NEEDS.DENTAL,
    },
    {
      icon: Equipment,
      label: DETAIL.TEMPLATES.FILTERS.COVERAGE_CLASSES.EQUIPMENT,
      key: COVERAGE_NEEDS.EQUIPMENT,
    },
    {
      icon: AlternativeMedicine,
      label: DETAIL.TEMPLATES.FILTERS.COVERAGE_CLASSES.ALTERNATIVE_MEDICINE,
      key: COVERAGE_NEEDS.ALTERNATIVE_MEDICINE,
    },
  ];

  return (
    <>
      <Header
        key={"header"}
        title={DETAIL.TEMPLATES.CONTACT.TITLE}
        actions={[]}
      />
      <div className="template-product-filters--container">
        <div className="template-product-filters--wrapper">
          <Card
            title={DETAIL.TEMPLATES.FILTERS.HEADER.TITLE}
            subtitle={DETAIL.TEMPLATES.FILTERS.HEADER.SUBTITLE}
          >
            <div className="levels--container">
              <div className="levels--wrapper">
                <span />
                {levels.map((element) => (
                  <div key={element} className="label">
                    <div className="popover-wrapper">
                      <Popover
                        text={DETAIL.TEMPLATES.FILTERS.HEADER.LEVELS[element]}
                      />
                      {`${DETAIL.TEMPLATES.FILTERS.HEADER.LEVEL} ${element}`}
                    </div>
                  </div>
                ))}
              </div>
              <div className="levels-coverage--container">
                {COVERAGE_CATEGORIES_ENTRIES.map(({ icon, label, key }) => (
                  <div key={key} className="levels-coverage--wrapper">
                    <div className="coverage-trailing--wrapper">
                      <img className="img" src={icon} alt={label} />
                      <span className="label">{label}</span>
                    </div>
                    <div className="custom-slider--wrapper">
                      <Slider
                        type="dot"
                        items={levels}
                        name={"grp" + label}
                        value={get(needs, key)}
                        onChange={(item) =>
                          setNeeds((prev) => ({
                            ...prev,
                            [[key]]: item,
                          }))
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Card>
          <div className="budget--container">
            <Row align="middle" gutter={[16, 16]}>
              <Col lg={8} xs={24}>
                <div className="trailing--wrapper">
                  <img
                    className="img"
                    src={AlternativeMedicine}
                    alt="Budget client"
                  />
                  <div className="text">
                    <span className="main">
                      {DETAIL.TEMPLATES.FILTERS.BUDGET.TITLE}
                    </span>
                    <span className="second">
                      {DETAIL.TEMPLATES.FILTERS.BUDGET.SUBTITLE}
                    </span>
                  </div>
                </div>
              </Col>
              <Col lg={16} xs={24}>
                <div className="budget-action--wrapper">
                  <Row gutter={[24, 24]}>
                    <Col lg={24} xs={24}>
                      <div className="custom-slider--wrapper">
                        <Slider
                          key={
                            "key-" +
                            get(fields, "budget.min") +
                            get(fields, "budget.max")
                          }
                          type="line"
                          tooltip={{ open: true }}
                          range
                          min={0}
                          max={1000}
                          defaultValue={[
                            get(fields, "budget.min"),
                            get(fields, "budget.max"),
                          ]}
                          onAfterChange={(data) =>
                            setFields((fields) => ({
                              ...fields,
                              budget: {
                                ...fields.budget,
                                min: data[0],
                                max: data[1],
                              },
                            }))
                          }
                        />
                      </div>
                    </Col>
                    <Col lg={24} xs={24}>
                      <div className="custom-input--wrapper">
                        <Row align="bottom" gutter={[7, 7]}>
                          <Col lg={11} xs={24}>
                            <Input
                              type="number"
                              suffix="€"
                              value={get(fields, "budget.min")}
                              label={DETAIL.TEMPLATES.FILTERS.BUDGET.MIN}
                              onChange={({ target: { value } }) =>
                                setFields((fields) => ({
                                  ...fields,
                                  budget: {
                                    ...fields.budget,
                                    min: Number(value),
                                  },
                                }))
                              }
                            />
                          </Col>
                          <Col lg={2} xs={24}>
                            <div className="divider-input" />
                          </Col>
                          <Col lg={11} xs={24}>
                            <Input
                              type="number"
                              suffix="€"
                              value={get(fields, "budget.max")}
                              label={DETAIL.TEMPLATES.FILTERS.BUDGET.MAX}
                              onChange={({ target: { value } }) =>
                                setFields((fields) => ({
                                  ...fields,
                                  budget: {
                                    ...fields.budget,
                                    max: Number(value),
                                  },
                                }))
                              }
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
          <div className="d-flex justify--between">
            <Button onClick={onBack} type="secondary--link">
              <Icon icon="arrow_back_ios" size={16} />
              {DETAIL.TEMPLATES.FILTERS.BUTTON.TEXT.PREVIOUS}
            </Button>

            <Button
              onClick={() => next(fields, needs)}
              type="primary"
              disabled={isEmpty(fields)}
            >
              {DETAIL.TEMPLATES.FILTERS.BUTTON.TEXT.SHOW_PRODUCT_LIST}
              <Icon icon="arrow_forward_ios" size={16} />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default View;
