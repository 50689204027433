import { SUBSCRIPTION_FORM_KEYS } from "utils/constants";
import Documents from "./Documents";
import Esignature from "./Esignature";

const generateDocumentForm = (isSigned) => ({
  [SUBSCRIPTION_FORM_KEYS.SIGNATURE.E_SIGNATURE]: Esignature,
  [SUBSCRIPTION_FORM_KEYS.SIGNATURE.DOCUMENTS]: !isSigned && Documents,
});

export default generateDocumentForm;
