import { Col, Row } from "antd";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import Icon from "react-material-symbols/rounded";
import Alert from "shared/components/Alert";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { DATE_FORMAT, SUBSCRIPTION_FORM_KEYS } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";

const TerminationRequest = ({
  formProps,
  values,
  setPercent,
  setFieldValue,
  isFieldRequired,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });

  return (
    get(values, "project.ria", false) && (
      <div
        className="subscription-form--wrapper"
        id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.TERMINATION_REQUEST}-section`}
      >
        <Form
          type="vertical"
          title={
            SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.TERMINATION_REQUEST.TITLE
          }
          subtitle={
            SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.TERMINATION_REQUEST
              .SUBTITLE
          }
        >
          <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
              <Alert
                showIcon
                type="info"
                message={SUBSCRIPTION.FORM.TERMINATION_ALERT}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Input
                placeholder={SUBSCRIPTION.FORM.CONTRACT_NUMBER.PLACEHOLDER}
                label={SUBSCRIPTION.FORM.CONTRACT_NUMBER.LABEL}
                icon={<Icon icon="front_hand" size={16} />}
                {...bindInputProps({
                  name: "project.terminationRequest.contractNumber",
                  values,
                  ...formProps,
                  onBlur: () => setPercent(values),
                })}
                required={isFieldRequired(
                  "project.terminationRequest.contractNumber"
                )}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Input
                placeholder={SUBSCRIPTION.FORM.DESTINATION.PLACEHOLDER}
                label={SUBSCRIPTION.FORM.DESTINATION.LABEL}
                icon={<Icon icon="share_location" size={16} />}
                {...bindInputProps({
                  name: "project.terminationRequest.recipient",
                  values,
                  ...formProps,
                  onBlur: () => setPercent(values),
                })}
                required={isFieldRequired(
                  "project.terminationRequest.recipient"
                )}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Input
                placeholder={SUBSCRIPTION.FORM.DUE_DATE.PLACEHOLDER}
                type="date"
                format={DATE_FORMAT}
                variant="simple"
                disabled={get(values, "dueDate") && true}
                label={SUBSCRIPTION.FORM.DUE_DATE.LABEL}
                icon={<Icon icon="free_cancellation" size={16} />}
                {...bindInputProps({
                  name: "project.terminationRequest.dueDate",
                  values,
                  ...formProps,
                  onBlur: () => setPercent(values),
                })}
                onChange={(value) =>
                  setFieldValue("project.terminationRequest.dueDate", value)
                }
                required={isFieldRequired("project.terminationRequest.dueDate")}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Input
                placeholder={SUBSCRIPTION.FORM.MADE_IN.PLACEHOLDER}
                label={SUBSCRIPTION.FORM.MADE_IN.LABEL}
                icon={<Icon icon="free_cancellation" size={16} />}
                {...bindInputProps({
                  name: "project.terminationRequest.madeIn",
                  values,
                  ...formProps,
                  onBlur: () => setPercent(values),
                })}
                required={isFieldRequired("project.terminationRequest.madeIn")}
              />
            </Col>
            <Col lg={24} xs={24}>
              <Input
                placeholder={SUBSCRIPTION.FORM.ADDRESS.PLACEHOLDER}
                label={SUBSCRIPTION.FORM.ADDRESS.LABEL}
                icon={<Icon icon="pin_drop" size={16} />}
                {...bindInputProps({
                  name: "project.terminationRequest.address",
                  values,
                  ...formProps,
                  onBlur: () => setPercent(values),
                })}
                required={isFieldRequired("project.terminationRequest.address")}
              />
            </Col>
          </Row>
        </Form>
      </div>
    )
  );
};

export default TerminationRequest;
