import { isEmpty } from "lodash";
import { useContext } from "react";
import { StoreContext } from "shared/store";

const useScreenActionPermissions = ({ module, submodule }) => {
  const {
    user: { permissions },
  } = useContext(StoreContext);
  const modulePermissions = permissions.find(
    ({ module: permissionModule, granted }) =>
      granted && permissionModule === module
  );
  if (isEmpty(modulePermissions)) return {};
  return (
    modulePermissions.submodules.find(({ name }) => name === submodule)
      ?.actions || {}
  );
};

export { useScreenActionPermissions };
