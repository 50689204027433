import { gql } from "@apollo/client";

export const QUALIFIERS = gql`
  query Qualifiers($where: QualifierWhereInput, $take: Int, $skip: Int) {
    qualifiers(where: $where, take: $take, skip: $skip) {
      data {
        id
        name
        priority
        isDefault
        isBlocking
        hasReminder
        fields
      }
      count
    }
  }
`;

export const QUALIFIER = gql`
  query Qualifier($where: QualifierWhereInput) {
    qualifier(where: $where) {
      id
      name
      priority
      isDefault
      isBlocking
      hasReminder
      fields
    }
  }
`;

const qualifiers = { QUALIFIERS, QUALIFIER };

export default qualifiers;
